import * as React from 'react'
import styled from 'styled-components/macro'
import {Broadcast} from 'phosphor-react'
import UnassignedInfoWindow from './infoWindow/UnassignedInfoWindow'
import {useMapStore} from 'map/useMapStore'

const Container = styled.div`
  position: relative;
  z-index: 1;
  .hidden {
    display: none !important;
  }
`

const MarkerWhiteBorder = styled.div`
  width: 46px;
  height: 46px;
  /* transform: translate3d(-50%, -50%, 0); */
  border-radius: 50%;
  background-color: white;
  position: relative;
  box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.5);
`

const MarkerInnerCircle = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  background-color: var(--asc-platinum);
  transform: translate3d(-50%, -50%, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed var(--asc-dimgray);

  .hidden {
    display: none;
  }
`

//lat and lng are needed only for map positioning
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UnassignedMarker = ({device, lat, lng}) => {
  const setActiveUnassignedMarker = useMapStore(
    state => state.setActiveUnassignedMarker,
  )
  return (
    <Container>
      <MarkerWhiteBorder>
        <MarkerInnerCircle>
          <Broadcast size={30} weight={'fill'} color={'var(--asc-dimgray)'} />
        </MarkerInnerCircle>
      </MarkerWhiteBorder>
      <UnassignedInfoWindow
        device={device}
        closeInfoWindow={() => {
          setActiveUnassignedMarker(undefined)
        }}
      />
    </Container>
  )
}
export default UnassignedMarker
