import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

const getDeviceStatuses = async () => {
  const {
    data,
  }: {data: {idDevice_Status: number; Status_Description: string}[]} =
    await Axios.get(`/devices/status`)
  data.sort((a, b) => {
    if (a.Status_Description < b.Status_Description) return -1
    if (a.Status_Description > b.Status_Description) return 1
    return 0
  })
  data.map((d: {idDevice_Status: number; Status_Description: string}) => {
    return {
      ...d,
      text: d.Status_Description,
      value: d.idDevice_Status,
      key: d.idDevice_Status,
    }
  })
  return data
}

const useDeviceStatuses = () => {
  const user = useStore(state => state.user)
  return useQuery(['devicestatuses'], () => getDeviceStatuses(), {
    enabled: Boolean(user.token),
  })
}

export default useDeviceStatuses
