const createHTMLMapMarker = ({
  OverlayView = window.google.maps.OverlayView,
  ...args
}) => {
  class HTMLMapMarker extends OverlayView {
    constructor() {
      super()
      this.latlng = args.latlng
      this.html = args.html
      this.setMap(args.map)
      this.idDevice = args.idDevice
      this.idAsset = args.idAsset
      this.id = args.id
      this.showAssetLabels = args.showAssetLabels
    }

    createDiv() {
      this.div = document.createElement('div')
      this.div.className = 'custom-marker'
      if (this.id) {
        this.div.id = `idc-${args.id}`
      } else {
        this.div.id = `ida-${args.idAsset}`
      }
      this.div.style.position = 'absolute'
      if (this.html) {
        this.div.innerHTML = this.html
      }
      if (this.showAssetLabels === false) {
        const labelDiv = this.div.getElementsByClassName('marker-label')
        if (labelDiv && labelDiv.length) {
          labelDiv[0].classList.add('hidden')
        }
      }
      this.div.addEventListener('click', () => {
        window.google.maps.event.trigger(this, 'click')
      })
    }

    appendDivToOverlay() {
      const panes = this.getPanes()
      panes?.overlayMouseTarget.appendChild(this.div)
    }

    positionDiv() {
      const projection = this.getProjection()
      if (projection) {
        //if marker joins a cluster it no longer has projection and this would cause an error
        const point = this.getProjection().fromLatLngToDivPixel(this.latlng)
        let offset = 20
        if (point) {
          this.div.style.left = `${point.x - offset}px`
          this.div.style.top = `${point.y - offset}px`
        }
      }
    }

    removeLabels() {
      if (this.div) {
        const div = this.div.getElementsByClassName('marker-label')
        if (div && div.length) {
          div[0].classList.add('hidden')
        }
      }
    }

    addLabels() {
      if (this.div) {
        const div = this.div.getElementsByClassName('marker-label')
        if (div && div.length) {
          div[0].classList.remove('hidden')
        }
      }
    }

    draw() {
      if (!this.div) {
        this.createDiv()
        this.appendDivToOverlay()
      }
      this.positionDiv()
    }

    remove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div)
        this.div = null
      }
      this.setMap(null)
    }

    getPosition() {
      return this.latlng
    }

    setPosition(latlng) {
      this.latlng = latlng
      this.draw()
    }

    animateTo(latLng) {
      let duration = 1000
      window.requestAnimationFrame =
        window.requestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.msRequestAnimationFrame
      window.cancelAnimationFrame =
        window.cancelAnimationFrame || window.mozCancelAnimationFrame

      // Save current position. prefixed to avoid name collisions. separate for lat/lng to avoid calling lat()/lng() in every frame
      this.AT_startPosition_lat = this.latlng.lat()
      this.AT_startPosition_lng = this.latlng.lng()
      let newPosition_lat = latLng.lat()
      let newPosition_lng = latLng.lng()

      // Crossing the 180° meridian and going the long way around the earth?
      if (Math.abs(newPosition_lng - this.AT_startPosition_lng) > 180) {
        if (newPosition_lng > this.AT_startPosition_lng) {
          newPosition_lng -= 360
        } else {
          newPosition_lng += 360
        }
      }

      const animateStep = function (marker, startTime) {
        let elapsedTime = new Date().getTime() - startTime
        let durationRatio = elapsedTime / duration // 0 - 1
        let easingDurationRatio = durationRatio

        if (durationRatio < 1) {
          let deltaPosition = new window.google.maps.LatLng(
            marker.AT_startPosition_lat +
              (newPosition_lat - marker.AT_startPosition_lat) *
                easingDurationRatio,
            marker.AT_startPosition_lng +
              (newPosition_lng - marker.AT_startPosition_lng) *
                easingDurationRatio,
          )
          marker.setPosition(deltaPosition)

          // Use requestAnimationFrame if it exists on this browser. If not, use setTimeout with ~60 fps
          if (window.requestAnimationFrame) {
            marker.AT_animationHandler = window.requestAnimationFrame(
              function () {
                animateStep(marker, startTime)
              },
            )
          } else {
            marker.AT_animationHandler = setTimeout(function () {
              animateStep(marker, startTime)
            }, 17)
          }
        } else {
          marker.setPosition(latLng)
        }
      }

      // Stop possibly running animation
      if (window.cancelAnimationFrame) {
        window.cancelAnimationFrame(this.AT_animationHandler)
      } else {
        clearTimeout(this.AT_animationHandler)
      }

      animateStep(this, new Date().getTime())
    }

    getDraggable() {
      return false
    }
  }

  return new HTMLMapMarker()
}

export default createHTMLMapMarker
