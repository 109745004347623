import Swal from 'sweetalert2'

export const getDestination = (origin, destination) => {
  if (origin && destination) {
    window.open(
      'https://www.google.com/maps/dir/' +
        "'" +
        origin +
        "'" +
        '/' +
        "'" +
        destination +
        "'/am=t/",
    )
    return true
  } else {
    Swal.fire(
      'Missing information.',
      'Please provide starting point and destination',
      'error',
    )

    return false
  }
}
