import * as React from 'react'
import styled from 'styled-components/macro'
import {Form, Input, Label} from 'semantic-ui-react'
import {XCircle} from 'phosphor-react'
const AutoCompleteInput = styled(Form.Field)`
  display: flex;
  flex: 1;
  margin: 10px 0;
  flex-direction: column;
  && .label {
    display: ${props => (props.primary ? 'none' : 'block')};
    margin: 0 5px;
  }
  && input {
    padding: 10px;
    padding-right: 35px;
    border-radius: 5px;
    max-width: 99%;
    height: 30px;
    width: 200px;
    margin: 5px;
    font-size: 14px;
    margin-left: 0;
  }
`
const AutoCompleteLabel = styled(Label)`
  border-radius: 0 !important;
`
const StyledInput = styled(Input)`
  display: flex;
  align-items: center;
  svg {
    position: absolute;
    left: 245px;
    cursor: pointer;
  }
`

type SearchInputProps = {
  label?: string
  displayValue: string
  dataCy: string
  onChange: (location?: google.maps.LatLng) => void
  onChangeText: React.Dispatch<React.SetStateAction<string>>
  primary: boolean
}

function SearchInput({
  label,
  displayValue,
  dataCy,
  onChange,
  onChangeText,
  primary,
}: SearchInputProps) {
  const autoCompleteInputRef = React.useRef(null)
  const [initialized, setInitialized] = React.useState(false)

  React.useEffect(() => {
    //on init - create google autocomplete and set reference
    if (!initialized && autoCompleteInputRef.current) {
      const autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteInputRef.current,
        {types: ['geocode', 'establishment']},
      )

      autoComplete.setFields([
        'address_components',
        'formatted_address',
        'geometry',
      ])
      autoComplete.addListener('place_changed', () => {
        const addressObject = autoComplete.getPlace()
        if (addressObject.geometry?.location) {
          onChange(addressObject.geometry.location)
        }
        onChangeText(String(addressObject?.formatted_address))
        if (!addressObject) return
      })

      setInitialized(true)
    }
  }, [initialized, onChange, onChangeText])

  return (
    <AutoCompleteInput primary={String(primary)}>
      <AutoCompleteLabel htmlFor={`${dataCy}-location-search`}>
        {label}
      </AutoCompleteLabel>
      <StyledInput
        data-cy={`${dataCy}-search-input`}
        aria-label="location-input"
        id={`${dataCy}-location-search`}
        placeholder="Search Map . . ."
        value={displayValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onChangeText(event.target.value)
        }
      >
        <input ref={autoCompleteInputRef} />
        <XCircle
          color="var(--primary)"
          weight="fill"
          size={20}
          onClick={() => {
            onChange()
            onChangeText('')
          }}
        />
      </StyledInput>
    </AutoCompleteInput>
  )
}

export default SearchInput
