import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'
import {EditDeviceType} from 'management/components/TemedaAdmin/Devices/hooks/usePutDevice'

const getHotSwapDevices = async () => {
  const {data}: {data: EditDeviceType[]} = await Axios.get(
    '/devices/swap-devices',
  )
  return data
}

const useHotSwapDevices = () => {
  const user = useStore(state => state.user)
  return useQuery(['hotSwapDevices'], () => getHotSwapDevices(), {
    enabled: Boolean(user.token),
  })
}

export default useHotSwapDevices
