import * as React from 'react'
import styled from 'styled-components/macro'
import useOpening5 from 'opening/hooks/useOpening5'
import {filterZones} from './zoneListHelpers'
import ZoneCard from './ZoneCard'

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-margin: 30px;
  background-color: white;
  padding: 0px 5px;
`

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
`

const ZoneToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: transparent;
  select {
    padding: 5px;
  }
  border-bottom: 1px solid var(--table-borders);
`

const reducer = (state, action) => {
  let types = []
  let newFilteredZones
  switch (action.type) {
    case 'loadZones':
      action.data.zones.forEach(zone => {
        if (zone.Landmark_Style && !types.includes(zone.Landmark_Style)) {
          types.push(zone.Landmark_Style)
        }
      })
      types = types.sort((a, b) => a.localeCompare(b))
      types.unshift('All')
      return {
        ...state,
        filteredZones: action.data.zones,
        allZones: action.data.zones,
        types: types,
      }
    case 'filterZones':
      newFilteredZones = filterZones({
        allZones: state.allZones,
        searchValue: action.data,
        typeSelected: state.typeSelected,
        showing: state.showing,
      })

      return {
        ...state,
        filteredZones: newFilteredZones.zones,
      }
    case 'typeChanged':
      newFilteredZones = filterZones({
        allZones: state.allZones,
        searchValue: action.data.searchValue,
        typeSelected: action.data.newType,
        showing: state.showing,
      })
      return {
        ...state,
        filteredZones: newFilteredZones.zones,
        typeSelected: action.data.newType,
      }
    case 'setShowing':
      newFilteredZones = filterZones({
        allZones: state.allZones,
        searchValue: action.data.searchValue,
        typeSelected: state.typeSelected,
        showing: action.data.showing,
      })
      return {
        ...state,
        showing: action.data.showing,
        filteredZones: newFilteredZones.zones,
      }
    case 'clearAll':
      state.allZones.forEach(zone => {
        if (zone.Landmark_Style && !types.includes(zone.Landmark_Style)) {
          types.push(zone.Landmark_Style)
        }
      })
      types = types.sort((a, b) => a.localeCompare(b))
      types.unshift('All')
      return {
        ...state,
        filteredZones: action.data.zones,
        searchValue: '',
        typeSelected: 'All',
        showing: 'All',
        types: types,
      }

    default:
      console.error('Invalid action type', action)
      break
  }
}

const StyledSelect = styled.select`
  border-radius: 5px;
  border: 1px solid var(--asc-graphite);
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
`

const ZoneList = ({
  dispatch: openingDispatch,
  selectedZones = [],
  clearAssetFilter,
  searchValue,
}) => {
  const initialState = {
    filteredZones: [],
    allZones: [],
    searchValue: '',
    types: ['All'],
    typeSelected: 'All',
    showing: 'All', //'All' 'Zones' or 'Zones'
  }

  const [state, dispatch] = React.useReducer(reducer, initialState)
  const {data: opening5} = useOpening5()

  React.useEffect(() => {
    const zones = opening5?.zones || []

    const allSorted = zones.sort((a, b) =>
      a.Landmark_Name.localeCompare(b.Landmark_Name),
    )

    dispatch({
      type: 'loadZones',
      data: {zones: allSorted},
    })
  }, [opening5])

  React.useEffect(() => {
    if (searchValue !== '') {
      dispatch({type: 'filterZones', data: searchValue})
    } else {
      dispatch({type: 'filterZones', data: ''})
    }
  }, [searchValue])

  const toggleZone = e => {
    const zoneID = Number(e.currentTarget.dataset.zoneid)
    let newSelectedZoneIDs
    if (selectedZones.includes(zoneID)) {
      newSelectedZoneIDs = selectedZones.filter(zone => zone !== zoneID)
      // removeZones([zoneID], map)
    } else {
      newSelectedZoneIDs = [...selectedZones, zoneID]
      state.filteredZones.find(zon => {
        return zon.idLandmark === zoneID
      })
    }
    if (newSelectedZoneIDs.length === 0) {
      const zones = opening5?.zones || []

      let allSorted = [...zones].sort((a, b) =>
        a.Landmark_Name.localeCompare(b.Landmark_Name),
      )
      dispatch({type: 'clearAll', data: {zones: allSorted}})
      clearAssetFilter()
      openingDispatch({
        type: 'setSelectedZones',
        data: [],
      })
    }
    openingDispatch({
      type: 'setSelectedZones',
      data: newSelectedZoneIDs,
    })
  }

  const typeChanged = e => {
    dispatch({
      type: 'typeChanged',
      data: {
        newType: e.currentTarget.value,
        searchValue: searchValue,
      },
    })
  }

  return (
    <ListContainer>
      <ZoneToolbar>
        <StyledSelect
          onChange={typeChanged}
          value={state.typeSelected || 'All'}
        >
          {state.types.map(type => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </StyledSelect>
      </ZoneToolbar>
      <List>
        {state?.filteredZones.map(zone => (
          <ZoneCard
            key={zone.idLandmark}
            zone={zone}
            selectedZoneIDs={selectedZones}
            toggleZone={toggleZone}
          />
        ))}
      </List>
    </ListContainer>
  )
}
export default ZoneList
