import * as React from 'react'
import MapControl from './MapControl'
import SearchMap from 'mapControl/SearchMap'
import RecentAssets from './RecentAssets'
import {useMapStore} from 'map/useMapStore'
import BoxSearchDialog from './BoxSearchDialog'
import {ActiveMarker} from 'common/types/typesModule'
import {Asset} from 'common/types/opening1Response'
import {SetAssetFilterProps} from 'opening/openingHelpers'
// import {clearDrawingManager} from './boxSearchHelper'

type Props = {
  map: google.maps.Map
  activeMarker: ActiveMarker | undefined
  setActiveMarker: (marker: ActiveMarker) => void
  assetsRef: React.MutableRefObject<Asset[] | undefined>
  setAssetFilter: (inp: SetAssetFilterProps) => void
  clearAssetFilter: () => void
  setLocationsFilter: () => void
  clearMap: () => void
  allowSearch?: boolean
  allowOptions?: boolean
  allowRecent?: boolean
}

const MapControlDisplay = ({
  map,
  // activeMarker,
  setActiveMarker,
  assetsRef,
  setAssetFilter,
  clearAssetFilter,
  setLocationsFilter,
  clearMap,
  allowSearch = true,
  allowOptions = true,
  allowRecent = true,
}: Props) => {
  const [showSearch, setShowSearch] = React.useState(false)
  const [addLocationMarker, setAddLocationMarker] =
    React.useState<google.maps.Marker | null>(null)
  const [showRecentAssets, setShowRecentAssets] = React.useState(false)
  const [recentAssetsLoaded, setRecentAssetsLoaded] = React.useState(false)
  const removeDirections = useMapStore(state => state.removeDirections)
  const showBoxSearch = useMapStore(state => state.showBoxSearch)

  const toggleRecentAssets = () => {
    setRecentAssetsLoaded(true)
    setShowRecentAssets(!showRecentAssets)
  }

  const toggleSearch = () => {
    removeDirections()
    setShowSearch(!showSearch)
  }

  const clearMapAndMarker = () => {
    clearMap()
    setShowSearch(false)
    addLocationMarker?.setMap(null)
    setAddLocationMarker(null)
  }

  return (
    <div data-cy="map-control-display">
      <MapControl
        map={map}
        toggleMapSearch={toggleSearch}
        clearMap={clearMapAndMarker}
        toggleRecentAssets={toggleRecentAssets}
        assetsRef={assetsRef}
        setAssetFilter={setAssetFilter}
        setLocationsFilter={setLocationsFilter}
        clearAssetFilter={clearAssetFilter}
        showRecentAssets={showRecentAssets}
        showSearch={showSearch}
        allowSearch={allowSearch}
        allowOptions={allowOptions}
        allowRecent={allowRecent}
      />
      {recentAssetsLoaded && (
        <RecentAssets
          visible={showRecentAssets}
          setActiveMarker={setActiveMarker}
          // toggleRecentAssets={toggleRecentAssets}
          assetsRef={assetsRef}
        />
      )}
      {showSearch && (
        <SearchMap
          map={map}
          marker={addLocationMarker}
          setMarker={setAddLocationMarker}
          // openGrid={openGrid}
          assetsRef={assetsRef}
          // setCoordinates={setCoordinates}
        />
      )}
      {showBoxSearch && (
        <BoxSearchDialog
          map={map}
          assetsRef={assetsRef}
          setAssetFilter={setAssetFilter}
          clearAssetFilter={clearAssetFilter}
        />
      )}
    </div>
  )
}
export default React.memo(MapControlDisplay)
