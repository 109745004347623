import {get} from 'lodash'
import {dashboardClick} from 'common/helpers'
import {numberFormatter} from 'common/helpersNumber'
import {formatDistanceToNow, isEqual} from 'date-fns'
import axios from 'axios'
import Swal from 'sweetalert2'

import {ANIMATION_DURATION_MILLISECONDS} from 'common/constants'

export const returnDCDValue = (data, field) => {
  let dataField = get(data, field?.FieldMapping?.replace(/>/g, '.', ''))
  if (dataField && field.UOM) {
    switch (field.type) {
      case 'number':
        dataField = numberFormatter(dataField, field?.precision || 0)
        break
    }
    return `${dataField} ${field.UOM} `
  } else if (dataField) {
    return dataField
  }
  return 'n/a'
}

const isCameraOnline = async asset => {
  try {
    return await axios.get('/camera/assetonline/' + asset.idAsset)
  } catch {
    return false
  }
}

export const editAsset = ({navigate, asset}) => {
  navigate(`/assets/${asset.idAsset}`, {state: {from: 'map'}})
}

export const addLocation = ({navigate, asset}) => {
  navigate(
    `/location?address=latlng:${asset?.Device?.Latitude},${asset?.Device?.Longitude}`,
  )
}

export const driverHistory = ({asset}) => {
  dashboardClick(asset.idAsset, 'driver')
}

export const openGetDirections = ({navigate, asset}) => {
  navigate(`/map/directions?address=${asset?.Device?.Last_Nearby_Address}`)
}

// export const checkCameraPermissions = ({user, ORoleType, userConfig}) => {
//   let showButtons = false
//   if (
//     user?.role === ORoleType.RestrictedAdministrator &&
//     userConfig.RestrictedAdminAccess
//   ) {
//     const allowedRoutes = userConfig.RestrictedAdminAccess.split(',')
//     if (allowedRoutes.includes('cameras')) {
//       showButtons = true
//     }
//   } else if (user?.role === ORoleType.Administrator) {
//     showButtons = true
//   }
//   return showButtons
// }

const wakeupCamera = asset => {
  return axios.post('/camera/wakeup/' + asset.idAsset)
}

const setCameraOnlineStatus = (setState, cache, asset) => {
  setState({cameraLoading: false})
  if (cache) {
    cache.cancelQueries(['cameraStatus', asset.idAsset])
    cache.setQueryData(['cameraStatus', asset.idAsset], 'online')
  }
}

export const onWakeUpCamera = async ({asset, setState, cache}) => {
  setState({cameraLoading: true})
  try {
    // Wait 2 seconds for the camera to wake up
    await wakeupCamera(asset)
    setTimeout(async () => {
      const onlineResponse = await isCameraOnline(asset)
      if (onlineResponse?.data === 'online') {
        setCameraOnlineStatus(setState, cache, asset)
      } else {
        //second try
        setTimeout(async () => {
          const onlineResponse = await isCameraOnline(asset)
          if (onlineResponse?.data === 'online') {
            setCameraOnlineStatus(setState, cache, asset)
          } else {
            //final try
            setTimeout(async () => {
              const onlineResponse = await isCameraOnline(asset)
              if (onlineResponse?.data === 'online') {
                setCameraOnlineStatus(setState, cache, asset)
              } else {
                throw new Error({message: 'CAMERA_STILL_OFFLINE'})
              }
            }, 4000)
          }
        }, 3000)
      }
    }, 2000)
  } catch (err) {
    console.error(err)
    if (err.message === 'CAMERA_STILL_OFFLINE') {
      Swal.fire(
        'The camera failed to come online. Please try again later.',
        '',
        'error',
      )
    } else {
      if (err.message === '')
        Swal.fire(
          'An unexpected error has occurred. Please try again later.',
          '',
          'error',
        )
    }
    setState({cameraStatus: 'offline'})
  }
}

// export const fetchAlertData = async ({setState, idAsset}) => {
//   const twentyFourHoursAgo = subDays(new Date(), 1)
//   const range = {startDate: twentyFourHoursAgo}
//   const dateString = getBeforeAfterStringForAPI(range)
//   const {data} = await Axios.get(`/assets/${idAsset}/alertevents?${dateString}`)
//   setState({alertData: data})
// }

export const returnTimeData = ({deviceTime, previousUpdate, setState}) => {
  let newState = {}
  try {
    const timeWithoutTimeZone = deviceTime.split('+')[0]
    const updatedTime = new Date(
      timeWithoutTimeZone.endsWith('Z')
        ? timeWithoutTimeZone
        : timeWithoutTimeZone + 'Z',
    )
    if (!previousUpdate.current) {
      previousUpdate.current = updatedTime
    }
    if (!isEqual(previousUpdate.current, updatedTime)) {
      previousUpdate.current = updatedTime

      newState.justUpdated = true
      setTimeout(
        () => setState({justUpdated: false}),
        ANIMATION_DURATION_MILLISECONDS,
      )
    }
    newState.timeFromNow = formatDistanceToNow(updatedTime)
    return newState
  } catch (error) {
    console.error('invalid time', error)
    return null
  }
}

export const renderTimeData = ({deviceTime, previousUpdate, setState}) => {
  if (deviceTime) {
    let newState = returnTimeData({deviceTime, previousUpdate, setState})
    if (newState) setState(newState)
  }
}
