import React from 'react'
import styled from 'styled-components/macro'
import {Button} from 'common/components'
import {ArrowsClockwise} from 'phosphor-react'
import {useHistoryTrailStore} from './hooks/useHistoryTrailStore'
import {ascendColors} from 'common/GlobalStyles'
import {OptionsType} from './EventFilter'
import {EventFilterType} from './hooks/historyTrailTypes'
const Wrapper = styled.div`
  position: absolute;
  background-color: white;
  z-index: 99;
  border: 1px solid lightgray;
  border-radius: 3px;
  margin-top: 45px;
  min-width: 250px;
  background: #ffffff;
  border: 1px solid #d2d2d7;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
`

const ListItem = styled.li<{
  isActive: boolean
  fontOnSecondary: string
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  list-style: none;
  cursor: pointer;
  padding: 10px 20px;
  background: ${p => (p.isActive ? 'var(--asc-platinum)' : 'white')};

  input[type='radio'] {
    margin-right: 10px;
    cursor: pointer;
  }
  input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid var(--asc-graphite);
  }

  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: var(--primary);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid var(--primary);
  }
  :hover {
    background-color: var(--asc-platinum);
    color: var(--asc-tertiary);
    input[type='radio']:after {
      background-color: var(--secondary);
    }

    .event-filter-badge {
      background: var(--secondary);
      color: ${p => p.fontOnSecondary};
    }
  }
  :first-child {
    margin-top: 15px;
  }
`
const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px 0px;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--primary);
    font-weight: bold;
    font-size: 13px;
    cursor: pointer;
    svg {
      margin-right: 5px;
    }
  }
`
const StyledButton = styled(Button)<{
  fontOnPrimary: string
  fontOnSecondary: string
}>`
  background: var(--secondary);
  color: ${p => p.fontOnSecondary};
  font-weight: bold;
  :hover {
    background: var(--primary);
    color: ${p => p.fontOnPrimary};
  }
`
const CardArrow = styled.div`
  border-top-width: 24px;
  position: absolute;
  left: 50%;
  top: -20px;
  .left {
    position: absolute;
    overflow: hidden;
    left: -6px;
    top: -1px;
    width: 16px;
    height: 21px;
    transform: scaleY(-1);
    div {
      position: absolute;
      left: 6px;
      background-color: rgb(255, 255, 255);
      transform: skewX(45deg);
      transform-origin: 0px 0px 0px;
      height: 24px;
      width: 10px;
      box-shadow: rgba(0, 0, 0, 0.6) 0px 1px 6px;
    }
  }
  .right {
    position: absolute;
    overflow: hidden;
    top: -1px;
    left: 10px;
    width: 16px;
    height: 21px;
    transform: scaleY(-1);
    div {
      position: absolute;
      left: 0px;
      background-color: rgb(255, 255, 255);
      transform: skewX(-45deg);
      transform-origin: 10px 0px 0px;
      height: 24px;
      width: 10px;
      box-shadow: rgba(0, 0, 0, 0.6) 0px 1px 6px;
    }
  }
`

const ResetFilterSection = styled.span`
  :hover {
    color: var(--secondary);
  }
`

type Props = {
  options: OptionsType[]
  setOpen: (open: boolean) => void
  closeDropdown: () => void
  map: google.maps.Map
}

const EventFilterDropDownCard = ({
  options = [],
  setOpen,
  closeDropdown,
  map,
}: Props) => {
  const eventFilter = useHistoryTrailStore(state => state.eventFilter)
  const setEventFilter = useHistoryTrailStore(state => state.setEventFilter)
  const onClick = (item: EventFilterType) => {
    setEventFilter(map, item)
    setOpen(false)
  }

  return (
    <Wrapper>
      <CardArrow>
        <div className="left">
          <div></div>
        </div>
        <div className="right">
          <div></div>
        </div>
      </CardArrow>
      <List>
        {options.map((item, i) => (
          <ListItem
            key={i}
            onClick={() => onClick(item.key as EventFilterType)}
            isActive={eventFilter === item.key}
            fontOnSecondary={ascendColors.fontOnSecondary}
          >
            <input type="radio" checked={eventFilter === item.key} readOnly />
            {item.text}
          </ListItem>
        ))}
      </List>
      <ButtonRow>
        <ResetFilterSection onClick={() => onClick('All')}>
          <ArrowsClockwise size={16} />
          Reset Filter
        </ResetFilterSection>
        <StyledButton
          onClick={closeDropdown}
          fontOnPrimary={ascendColors.fontOnPrimary}
          fontOnSecondary={ascendColors.fontOnSecondary}
        >
          DONE
        </StyledButton>
      </ButtonRow>
    </Wrapper>
  )
}

export default EventFilterDropDownCard
