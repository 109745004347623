import React from 'react'
import {
  RouteTimeTableColumn,
  RouteTimeTableRowContainer,
} from './routePlanTimeTableStyles'

type RouteTimeTableRowProps = {
  isPlanning: boolean
  isRouteDetail: boolean
  idRouteDispatchDriver?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowData: any[] //jsx
  rowClick?: (idSegment: string) => void
}
const RouteTimeTableRow: React.FC<RouteTimeTableRowProps> = ({
  isPlanning,
  isRouteDetail,
  idRouteDispatchDriver,
  rowData,
  rowClick,
}) => {
  return (
    <RouteTimeTableRowContainer
      isPlanning={isPlanning}
      isHeader={false}
      onClick={() => {
        rowClick && idRouteDispatchDriver
          ? rowClick(idRouteDispatchDriver)
          : undefined
      }}
      isRouteDetail={isRouteDetail}
      className="row"
    >
      {rowData.map((value, index) => (
        <RouteTimeTableColumn
          className={index === rowData.length - 1 ? 'chart' : ''}
          isDriver={index === 1}
          isTimeTable={index === rowData.length - 1}
          key={index}
          isRouteDetail={isRouteDetail}
        >
          {value}
        </RouteTimeTableColumn>
      ))}
    </RouteTimeTableRowContainer>
  )
}

export default RouteTimeTableRow
