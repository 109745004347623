import * as React from 'react'
import {RoutePlanType, Segment} from 'routingAndDispatch/types/typesModule'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components/macro'
import {ActionTypes} from 'common/types/typesModule'
import {Asset} from 'common/types/opening1Response'

interface MarkerProps {
  text: string
  lat: number
  lng: number
  segment: Segment
  plan: RoutePlanType | undefined
}

const Marker = styled.div`
  height: 30px;
  width: 30px;
  background: var(--primary);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  /* Center the content */
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  /* Border */
  border: 2px solid #d1d5db;
  border-radius: 0px 50% 50% 50%;

  /* Angle at the top */
  position: absolute;
  transform: translate(-50%, -100%) rotate(225deg);

  .circleText {
    transform: rotate(-225deg);
  }
`

export const MapMarker: React.FC<MarkerProps> = ({text, plan, segment}) => {
  const navigate = useNavigate()

  const passSegment = plan && {
    planStartDateTime: plan.planStartDateTime,
    planEndDateTime: plan.planEndDateTime,
    planName: plan.planName,
    planStatus: plan.planStatus,
    idPlan: plan.idPlan,
    segments: [segment],
  }

  return (
    <Marker
      data-cy="map-marker"
      onClick={() => {
        if (plan)
          navigate(
            `/routing/routes/${plan.idPlan}/${segment.idRouteDispatchDriver}`,
            {
              state: {route: passSegment},
            },
          )
      }}
    >
      <span className="circleText">{text}</span>
    </Marker>
  )
}

export type RouteDetailMapState = {
  showInfoWindow: boolean
  showDialog: boolean
  dialogTitle: string
  dialogText: string
  clickedAsset: Asset | null
  routeDrawnStopCount: number
  historyTrailDrawn: boolean
}

export const initialState: RouteDetailMapState = {
  showInfoWindow: false,
  showDialog: false,
  dialogTitle: '',
  dialogText: '',
  clickedAsset: null,
  routeDrawnStopCount: 0,
  historyTrailDrawn: false,
}

export const reducer = (state: RouteDetailMapState, action: ActionTypes) => {
  switch (action.type) {
    case 'setState': {
      return {
        ...state,
        ...action.data,
      }
    }
    default:
      console.error('unrecognized reducer action', action)
      return state
  }
}
