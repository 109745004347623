import React from 'react'
import styled from 'styled-components/macro'
import Swal from 'sweetalert2'
import axios from 'axios'
import landmarkStyles from 'common/landmarkStyles'
import states from 'common/states'
import countries from 'common/countries'
import {renderToString} from 'react-dom/server'
import {UserConfig} from 'common/useStore'
import {QueryClient} from '@tanstack/react-query'
import {LandmarkType} from './hooks/useLocations'

const ErrorList = styled.ul`
  list-style: none;
  padding: 0;
  border: 1px solid var(--grey-300);
  border-radius: 5px;
  margin-top: 20px;
  max-height: 250px;
  overflow-y: scroll;
`
const ErrorItem = styled.li`
  padding: 5px;
  border-bottom: 1px solid var(--grey-300);
  text-align: left;
  font-size: 14px;
`

const LocationErrorList = ({addresses}: {addresses: {Name: string}[]}) => (
  <div>
    <span>
      No locations added. Check the following locations for invalid or missing
      address data and try again.
    </span>
    <ErrorList>
      {addresses.map((address, index) => (
        <ErrorItem key={index}>{address.Name}</ErrorItem>
      ))}
    </ErrorList>
  </div>
)

type UploadFromTemplateProps = {
  event: any
  userConfig: UserConfig | null
  uploadFormRef: React.RefObject<HTMLFormElement>
  cache: QueryClient
  setLoading: (loading: boolean) => void
}

type ImportLocationNames = {
  'Location Name (required)': string
  'City (required)': string
  'State/Province (required)': string
  'Address Line 1 (required)': string
  'Address Line 2': string
  'Location Type': string
  Country: string
  Description: string
  'Unique ID': string
  'Zip/Postal Code': string
  'Contact Phone Number': string
  'Contact Name': string
  'Contact Email': string
}

export const uploadFromTemplate = ({
  event,
  userConfig,
  uploadFormRef,
  cache,
  setLoading,
}: UploadFromTemplateProps) => {
  var reader = new FileReader()
  const uploadForm = uploadFormRef.current

  if (event.target.files && event.target.files[0]) {
    const file = event.target.files[0]
    reader.readAsBinaryString(file)
  }

  reader.onload = async event => {
    const newLocationList: LandmarkType[] = []
    const data = event?.target ? event.target.result : null
    const XLSX = await import('common/SheetJSWriteWrapper')
    const workbook = XLSX.read(data, {type: 'binary'})
    let invalidLocationTypes = false
    const locationsFromTemplate: ImportLocationNames[] = XLSX.utils
      .sheet_to_json(workbook.Sheets['Bulk Location Import'], {
        header: 0,
        raw: false,
        // range: 'B1:K1004',
      })
      .slice(3) as ImportLocationNames[]

    if (
      locationsFromTemplate.length <= 1000 &&
      locationsFromTemplate.length > 0
    ) {
      if (
        locationsFromTemplate.every(
          location =>
            location['Location Name (required)'] &&
            location['City (required)'] &&
            location['State/Province (required)'] &&
            location['Address Line 1 (required)'],
        )
      ) {
        locationsFromTemplate.forEach(location => {
          if (
            location['Location Type'] &&
            !landmarkStyles.find(
              style => style.text === location['Location Type'],
            )
          ) {
            invalidLocationTypes = true
          }
          const idLandmarkStyle = location['Location Type']
            ? (landmarkStyles.find(
                style => style.text === location['Location Type'],
              )?.value as number)
            : null
          const landmarkRegion = location['State/Province (required)']
            ? states.find(
                state => state.text === location['State/Province (required)'],
              )?.value
            : ''
          const landmarkCountry = location['Country']
            ? countries.find(
                country => country.printable_name === location['Country'],
              )?.iso
            : ''
          const newLocation: LandmarkType = {
            Landmark_Name: location['Location Name (required)'],
            Landmark_Label: location['Description'] || null,
            Landmark_UUID: location['Unique ID'] || null,
            Landmark_Address_Line1: location['Address Line 1 (required)'] || '',
            Landmark_Address_Line2: location['Address Line 2'] || '',
            Landmark_City: location['City (required)'] || '',
            Landmark_Postal_Code: location['Zip/Postal Code'] || '',
            Landmark_Country: landmarkCountry,
            Landmark_Region: landmarkRegion,
            idLandmark_Style: idLandmarkStyle,
            Landmark_Style: '',
            IsActive: true,
            IsShared: false,
            Shared_idCustomer: null,
            Shared_idCustomerType: null,
            Latitude: null,
            Longitude: null,
            Landmark_Radius: 67,
            Landmark_Type: 'Circle',
            Landmark_Category: 1,
            ContactPhoneNumber: location['Contact Phone Number'],
            ContactName: location['Contact Name'],
            ContactEmail: location['Contact Email'],
          }
          newLocationList.push(newLocation)
        })
      } else {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `Please check required fields for all locations.`,
        })
      }
    } else if (locationsFromTemplate.length > 1000) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Import must be 1000 locations or less.`,
      })
    } else if (locationsFromTemplate.length === 0) {
      setLoading(false)
      Swal.fire({
        icon: 'info',
        title: 'No Locations Found.',
        text: 'Add locations to template and try again.',
      })
    }

    if (newLocationList.length) {
      let text = `<span>This action will create ${newLocationList.length} new locations. Would you like to continue?<span>`
      if (invalidLocationTypes) {
        text = `<span>This action will create ${newLocationList.length} new locations.<br /><br /><b>NOTE:</b> Some or all of the location types were invalid and will be blank.<br /><br/>Would you like to continue?<span>`
      }
      setLoading(false)
      Swal.fire({
        icon: 'info',
        title: 'Import Locations?',
        html: text,
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
      }).then(async res => {
        if (res.isConfirmed) {
          setLoading(true)
          try {
            const response = await axios.post('/LandmarkList', newLocationList)
            if (response.status === 200) {
              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: `Successfully added ${newLocationList.length} new locations.`,
              })
              cache.invalidateQueries(['locations'])
              setLoading(false)
            }
          } catch (error:
            | {response: {data: {BadAddresses: {name: string}}}}
            | any) {
            const badAddresses = error.response?.data?.BadAddresses
            const htmlString = renderToString(
              <LocationErrorList addresses={badAddresses} />,
            )
            setLoading(false)
            Swal.fire({
              icon: 'error',
              title: 'Import failed.',
              html: htmlString,
            })
          }
        }
      })
    }
    uploadForm?.reset()
  }
}
