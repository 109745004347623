import * as React from 'react'
import styled, {keyframes} from 'styled-components/macro'
import MapBackground from 'images/MapBackground.png'
import {XCircle} from 'phosphor-react'
import Heading from 'images/Heading.png'
import AppStore from 'images/app-store.png'
import GooglePlay from 'images/google-play.png'
import FadingWords from 'images/FadingWords.png'
import BottomCars from 'images/BottomCars.png'

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  width: 100vw;
  height: 100vh;
  align-items: flex-end;
  overflow: hidden;
`
const slideAnimation = keyframes`
 0% { height: 0;}
 100% { height: 60vh; }
`
const fadeAnimation = keyframes`
 0% { opacity: 0;}
 100% { opacity: 1; }
`
const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60vh;
  overflow-x: hidden;
  overflow-y: hidden;
  border: 1px solid #d2d2d7;
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 8px 8px 0px 0px;
  background: white;
  z-index: 1;
  justify-content: space-between;
  animation-name: ${slideAnimation};
  animation-duration: 1.5s;
`

const Background = styled.img`
  border-radius: 8px 8px 0px 0px;
  position: absolute;
  width: 100%;
  height: 59vh;
`

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  z-index: ${props => (props.zIndex ? props.zIndex : 1)};
  justify-content: center;
  background-image: ${props => (props.url ? `url(${props.url})` : 'none')};
  background-repeat: no-repeat;
  background-size: cover;
  height: ${props => (props.height ? props.height : 'inherit')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'default')};
  position: ${props => (props.position ? props.position : 'default')};
  bottom: 0;
`

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    max-width: 325px;
    width: 100%;
  }
`

const ButtonHolder = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: flex-end;
`

const AppLinks = styled.div`
  display: flex;
  height: 50%;
  justify-content: center;
  width: 100vw;
  gap: 10px;
  z-index: 1;
`

const AppLink = styled.a`
  height: fit-content;
`

const FadingWordsImageHolder = styled.div`
  width: 75%;
  margin-top: 10px;
`
const ImageHolder = styled.div`
  position: absolute;
  animation-name: ${fadeAnimation};
  animation-duration: 2s;
`

const GoToAppModal = ({hideModal}) => {
  return (
    <Wrapper>
      <Container>
        <Background src={MapBackground} />
        <Row>
          <Column>
            <ButtonHolder onClick={hideModal}>
              <XCircle weight="fill" color="var(--primary)" size={35} />
            </ButtonHolder>
            <img src={Heading} />
            <FadingWordsImageHolder>
              <img src={FadingWords} />
            </FadingWordsImageHolder>
          </Column>
        </Row>

        <Row
          alignItems={'flex-end'}
          zIndex={2}
          position={'absolute'}
          height={'47%'}
        >
          <ImageHolder>
            <img src={BottomCars} />
          </ImageHolder>

          <AppLinks>
            <AppLink href="https://apps.apple.com/us/app/ascend-fleet/id1619913662">
              <img src={AppStore} />
            </AppLink>
            <AppLink href="https://play.google.com/store/apps/details?id=com.ascendnativeapp">
              <img src={GooglePlay} />
            </AppLink>
          </AppLinks>
        </Row>
      </Container>
    </Wrapper>
  )
}
export default GoToAppModal
