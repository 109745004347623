import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'
import {CustomerNotificationType} from '../notificationsHelpers'

const getCustomerNotifications = async () => {
  const {data}: {data: CustomerNotificationType[]} = await Axios.get(
    `/rd/eta/templates/default?enabledStatus=true`,
  )

  const newData = data.map(notification => {
    notification.Tokens = notification.Tokens.map((token, index) => {
      const userConfig = useStore.getState().userConfig
      if (token.Text === 'COMPANY_NAME') {
        token.Preview = `${userConfig?.Customer_Name || 'Company'}`
      }
      return {
        key: index,
        value: index,
        Text: token.Text,
        Preview: token.Preview,
      }
    })
    return notification
  })

  return newData
}

export default function useCustomerNotications() {
  const user = useStore(state => state.user)
  return useQuery(['customerNotifications'], getCustomerNotifications, {
    enabled: Boolean(user?.token),
  })
}
