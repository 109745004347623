import * as React from 'react'
import {
  EditButton,
  NotificationCardBody,
  NotificationCardContainer,
  NotificationCardHeader,
} from './customerNotificationsStyles'
import Toggle from 'common/components/Toggle'
import NotificationModal from './NotificationModal'
import usePostCustomerTemplate from './hooks/usePostCustomerTemplate'

type NotificationCardProps = {
  idRDEtaDefaultTemplate: number
  NotificationName: string
  Enabled: boolean
  Description: string
}
const NotificationCard: React.FC<NotificationCardProps> = ({
  idRDEtaDefaultTemplate,
  NotificationName,
  Enabled,
  Description,
}) => {
  const [checked, setChecked] = React.useState(Enabled)
  const [showModal, setShowModal] = React.useState(false)
  const {mutateAsync: postCustomerEtaTemplate} = usePostCustomerTemplate()
  const onChange = () => {
    postCustomerEtaTemplate({
      idRDEtaDefaultTemplate,
      Enabled: !checked,
      NotificationName: NotificationName,
    })
    setChecked(!checked)
  }
  return (
    <>
      <NotificationCardContainer data-cy="notification-card">
        <NotificationCardHeader>
          <div>{NotificationName}</div>
          <Toggle
            onChange={onChange}
            checked={checked}
            customOn="ON"
            customOff="OFF"
          />
        </NotificationCardHeader>
        <NotificationCardBody>
          <div>{Description}</div>
          <EditButton
            disabled={!checked}
            onClick={() => (checked ? setShowModal(true) : undefined)}
            data-cy="edit-button"
          >
            Edit
          </EditButton>
        </NotificationCardBody>
      </NotificationCardContainer>

      <NotificationModal
        showModal={showModal}
        setShowModal={setShowModal}
        idRDEtaDefaultTemplate={idRDEtaDefaultTemplate}
      />
    </>
  )
}
export default NotificationCard
