import styled from 'styled-components/macro'
import {Column, Row, SearchLocationInput} from 'common/components'
import {Button} from 'semantic-ui-react'

export const Content = styled(Column)`
  /* margin-bottom: 100px; //for the footer */
`
export const Section = styled(Row)<{background?: string}>`
  gap: 20px;
  padding: 20px;
  border-top: 1px solid var(--asc-coolgray);
  background: ${p => (p.background ? p.background : 'transparent')};
  :first-child {
    border-top: none;
  }
`
export const StyledSearchLocationInput = styled(SearchLocationInput)`
  margin-bottom: 16px;
`

export const StyledColumn = styled(Column)<{flexGrow?: string; width?: string}>`
  flex-grow: ${p => (p.flexGrow ? p.flexGrow : '1')};
  width: ${p => (p.width ? p.width : 'auto')};
  gap: 10px;
  .field {
    margin-bottom: 0px !important;
  }
`

export const DurationTitleRow = styled(Row)`
  font-weight: bold;
  font-size: 0.928571em;
  gap: 5px;
`
export const StyledGroupButton = styled(Button.Group)`
  &&& {
    height: 30px;
    border-radius: 0px;
    .primary {
      background: var(--primary) !important;
      color: var(--secondary) !important;
    }
    button {
      font-weight: 700 !important;
      align-items: center;
      background: white;
      color: var(--primary) !important;
      border: 1px solid var(--asc-moss) !important;
      :first-child {
        border-right: none !important;
        border-radius: 5px 0px 0px 5px !important;
      }
      :last-child {
        border-radius: 0px 5px 5px 0px !important;
      }
      border-radius: 0px !important;
      display: flex;
      flex-direction: row;
      font-size: 13px !important;
      svg {
        margin-right: 3px;
      }
    }
  }
`

export const RequiredAsterisk = styled.span`
  ::after {
    content: '*';
    font-size: 0.9rem;
    color: var(--asc-vermilion);
  }
`
export const CoordinateRow = styled(Row)`
  gap: 20px;
`
export const RelativeContainer = styled.div`
  position: relative;
`
export const ContactRightColumn = styled(StyledColumn)`
  justify-content: space-between;
`

export const CheckboxRow = styled(Row)`
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  svg {
    :hover {
      fill: var(--secondary);
    }
  }
`
