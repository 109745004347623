import * as React from 'react'
import {useQueryClient} from '@tanstack/react-query'
import {useStore} from 'common/useStore'
import {useWebsocketStore} from '../useWebsocketStore'
import {startSocket, updateDevices, updateAlerts} from '../socketHelpers'
import useInterval from 'common/useInterval'
import {clearAllMarkersAndClusters} from 'map/mapHelpers'

const useSocket = () => {
  const cache = useQueryClient()
  const user = useStore(state => state.user)
  const userConfig = useStore(state => state.userConfig)
  const [intervalDelay, setIntervalDelay] = React.useState(10000)
  const socketRef = React.useRef(null)

  React.useEffect(() => {
    if (user?.token && !socketRef.current && userConfig) {
      // console.info('starting websocket')
      socketRef.current = startSocket(
        socketRef.current,
        user,
        cache,
        userConfig,
      )
    }
  }, [cache, user, userConfig])

  React.useEffect(() => {
    return () => {
      if (socketRef.current) {
        // console.info('disconnecting websocket from unload')
        useWebsocketStore.setState({
          socketListening: false,
        })
        socketRef.current.disconnect()
        socketRef.current = null
        //TODO: Is this necessary?
        clearAllMarkersAndClusters()
      }
    }
  }, [])

  const pendingDeviceUpdates = useWebsocketStore(
    state => state.pendingDeviceUpdates,
  )

  const pendingAlertUpdates = useWebsocketStore(
    state => state.pendingAlertUpdates,
  )

  useInterval(() => {
    //move pending device updates to map updates after interval
    if (pendingDeviceUpdates.length > 0) {
      const cachedAssets = cache.getQueryData(['opening1'])?.assets
      if (cachedAssets && cachedAssets.length > 0) {
        // console.info('applying device updates', pendingDeviceUpdates.length)
        updateDevices(pendingDeviceUpdates, cache)
      } else {
        // console.info('stopping websocket interval')
        setIntervalDelay(null)
      }
    }

    if (pendingAlertUpdates?.length > 0) {
      // console.info('updating alerts', pendingAlertUpdates?.length)
      //update numbers on assets
      updateAlerts(pendingAlertUpdates, cache)
    }

    useWebsocketStore.setState({
      pendingDeviceUpdates: [],
      pendingAlertUpdates: [],
      pendingMapUpdates: pendingDeviceUpdates,
    })
  }, intervalDelay)
}

export default useSocket
