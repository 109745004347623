import SearchInput from 'common/components/SearchInput'
import * as React from 'react'
import {getClosestAssets} from './findNearestAssetsHelpers'
import {infoWindowWithCreateLocation} from './infoWindowWithCreateLocation'
import {Asset} from 'common/types/opening1Response'

type SearchMapProps = {
  map: google.maps.Map
  // visible: boolean,
  marker: google.maps.Marker | null
  setMarker: (inp: google.maps.Marker) => void
  assetsRef: React.MutableRefObject<Asset[] | undefined>
}

const SearchMap = ({map, marker, setMarker, assetsRef}: SearchMapProps) => {
  const [displayValue, setDisplayValue] = React.useState('')
  const [infowindow, setInfowindow] =
    React.useState<google.maps.InfoWindow | null>(null)

  const createMarker = (latlng?: google.maps.LatLng) => {
    if (marker) {
      marker.setMap(null)
    }
    if (infowindow) {
      infowindow.close()
      setInfowindow(null)
    }

    if (latlng) {
      // setCoordinates(latln)
      const newInfowindow = new window.google.maps.InfoWindow()
      var currentMarker = new window.google.maps.Marker({
        position: latlng,
      })

      const {newInfowindow: updatedInfowindow, content} =
        infoWindowWithCreateLocation({
          latlng,
          onNearestAssets: () => {
            getClosestAssets({
              latlng,
              assets: assetsRef?.current || [],
              map,
            })
          },
          newInfowindow,
        })
      currentMarker.addListener('click', () => {
        updatedInfowindow.setContent(content)
        updatedInfowindow.open(map, currentMarker)
      })
      // To add the marker to the map, call setMap();
      currentMarker.setMap(map)
      map.setZoom(17)
      map.panTo(latlng)
      updatedInfowindow.setContent(content)
      updatedInfowindow.open({
        anchor: currentMarker,
        map,
      })
      setMarker(currentMarker)
      setInfowindow(updatedInfowindow)
    }
  }
  React.useEffect(() => {
    if (marker === null) {
      setDisplayValue('')
      if (infowindow) {
        infowindow.close()
        setInfowindow(null)
      }
    }
  }, [marker, infowindow])

  return (
    <SearchInput
      primary
      dataCy="search-map-input"
      onChangeText={setDisplayValue}
      onChange={createMarker}
      displayValue={displayValue}
      // visible={visible}
    />
  )
}
export default SearchMap
