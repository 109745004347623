import * as React from 'react'
import styled from 'styled-components/macro'
import {useParams} from 'react-router-dom'
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import GridTable from 'grid/GridTable'
import Map from 'map/Map'
import GetDirections from 'directions/GetDirections'
import useGridSchema from 'grid/hooks/useGridSchema'
import {useMapStore} from 'map/useMapStore'
import GridGlobalFilter from './GridGlobalFilter'
import GridTableButtons from './GridTableButtons'
import {
  // setHeight,
  buildColumns,
} from './gridMapContainerHelpers'
import {applyDistancesToAssets} from 'mapControl/findNearestAssetsHelpers'
import {useStore} from 'common/useStore'
import {HEADING_HEIGHT} from 'common/constants'
import useQueryParams from 'common/components/useQueryParams'
import ResizableTable from 'common/tables/ResizableTable/ResizableTable'
import {useGridFilterStore} from './useGridFilterStore'
import {Asset} from 'common/types/opening1Response'
import {ColumnDef} from '@tanstack/react-table'
import {FilterAssetsFromListProps} from 'opening/openingHelpers'
import {ActiveMarker} from 'common/types/typesModule'
import AddNewGroup from './AddNewGroup'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-areas:
    'topsection'
    'bottomsection';
  grid-template-rows: 0 1fr;

  #bottomsection {
    grid-area: bottomsection;
  }
`

const TopSection = styled.div`
  grid-area: topsection;
  overflow: hidden;
  z-index: 10;
  background: var(--asc-cultured);
  height: ${(p: {gridOpen: boolean}) => (p.gridOpen ? `100%` : '0')};
`
const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: bottomsection;
  height: 100%;
  margin-top: 0;
`

const initialState: State = {
  columns: [],
  shownAssets: [],
}

type State = {
  columns: ColumnDef<Asset, number>[]
  shownAssets: Asset[]
}

type Action = {type: 'setColumns'; data: ColumnDef<Asset, number>[]}
// | {type: 'setAssets'; data: {filteredAssets: Asset[], gridFilteredAssets: Asset}}

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'setColumns':
      return {...state, columns: action.data}
    default:
      return state
  }
}

type GridMapContainerProps = {
  allAssets: Asset[]
  filteredAssets: Asset[]
  activeMarker: Asset
  mapRef: React.MutableRefObject<
    | {
        map: google.maps.Map
      }
    | undefined
  >
  setAndFocusActiveMarker: (marker?: ActiveMarker) => void
  focusMap: () => void
  clearMap: () => void
  filterAssetsFromList: (props: FilterAssetsFromListProps) => void
  assetFilter: {
    label: string
    filteredAssetIDs: number[]
  }
  toggleFollow: () => void
  toggleStreetView: () => void
  clearAssetFilter: () => void
  setLocationsFilter: () => void
  gridFullscreen: boolean
  setGridFullscreen: (gridFullscreen: boolean) => void
  openingDispatch: (inp: {
    type: 'updateAssets'
    data: {assets: Asset[]}
  }) => void
}

const GridMapContainer = ({
  allAssets,
  filteredAssets,
  activeMarker,
  mapRef,
  setAndFocusActiveMarker,
  focusMap,
  clearMap,
  filterAssetsFromList,
  assetFilter,
  toggleFollow,
  toggleStreetView,
  clearAssetFilter,
  setLocationsFilter,
  gridFullscreen,
  setGridFullscreen,
  openingDispatch,
}: GridMapContainerProps) => {
  const {data: schema, status: gridStatus} = useGridSchema()
  const params = useParams()
  const showingNearestAssetDistances = useMapStore(
    state => state.showingNearestAssetDistances,
  )
  const nearByDistances = useMapStore(state => state.nearByDistances)
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const userConfig = useStore(state => state.userConfig)
  const gridOpen = useStore(state => state.gridOpen)
  const setAssetListOpen = useStore(state => state.setAssetListOpen)
  const setGridOpen = useStore(state => state.setGridOpen)
  const globalFilter = useGridFilterStore(state => state.globalFilter)
  const columnFilters = useGridFilterStore(state => state.columnFilters)

  React.useEffect(() => {
    if (state.columns?.length) {
      filterAssetsFromList({
        gridFilters: {
          filters: {columnFilters, globalFilter},
          columns: state.columns,
        },
      })
    }
    //TODO - fix this?
    //Adding filterAssetsFromList causes infinite loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnFilters, globalFilter])

  React.useEffect(() => {
    if (userConfig && schema && gridOpen) {
      const gridColumns = buildColumns({
        schema,
        showingNearestAssetDistances,
      })
      if (gridColumns?.length && gridOpen) {
        dispatch({type: 'setColumns', data: gridColumns})
      }
    }
  }, [schema, userConfig, showingNearestAssetDistances, gridOpen])

  React.useEffect(() => {
    const page = document.getElementById('grid-map-page')
    if (page) {
      if (gridOpen) {
        if (gridFullscreen) {
          page.style.gridTemplateRows = `1fr 0`
        } else {
          page.style.gridTemplateRows = `calc(50vh - 83px) 1fr`
        }
      } else {
        page.style.gridTemplateRows = `0 1fr`
      }
    }
  }, [gridFullscreen, gridOpen])

  let query = useQueryParams()

  React.useEffect(() => {
    if (
      showingNearestAssetDistances &&
      filteredAssets &&
      filteredAssets[0]?.FindNearestAssetDistance === undefined &&
      nearByDistances.length > 0
    ) {
      const assetsWithDistances = applyDistancesToAssets({
        filteredAssets,
        nearByDistances,
      })

      //Look for showing nearest column
      assetsWithDistances &&
        openingDispatch({
          type: 'updateAssets',
          data: {assets: assetsWithDistances},
        })
    }
  }, [
    filteredAssets,
    nearByDistances,
    openingDispatch,
    showingNearestAssetDistances,
  ])

  const closeGrid = () => {
    filterAssetsFromList({
      label: 'clearGridFilters',
    })

    setGridOpen(false)
  }

  const showAssetList = () => {
    closeGrid()
    setAssetListOpen(true)
  }

  const rowClick = (original: Asset) => {
    const changeActiveMarkerFromClick = () => {
      const newActiveMarker = {
        idDevice: original.idDevice,
        idAsset: original.idAsset,
        label: original.Asset_Label,
        lat: original.Device.Latitude,
        lng: original.Device.Longitude,
        following: true,
        heading: original.Device.Last_Heading,
      }
      setAndFocusActiveMarker(newActiveMarker)
    }
    if (original?.idAsset) {
      changeActiveMarkerFromClick()
    }
  }

  return (
    <Container id="grid-map-page" data-cy="grid">
      <TopSection id="topsection" gridOpen={gridOpen}>
        {gridOpen && (
          <>
            <ResizableTable
              totalRowCount={allAssets?.length}
              data={filteredAssets || []}
              columns={state.columns}
              status={gridStatus}
              // fixedRowHeight={45}
              title={'Assets'}
              testIdName={'assetgrid'}
              showSubHeader={false}
              rowClick={rowClick}
              pinnedColumns={['Asset_Label']}
              initSort={
                showingNearestAssetDistances
                  ? [{id: 'FindNearestAssetDistance', desc: false}]
                  : []
              }
              filters={{
                showColumnFilters: true,
              }}
              rowSelection={{
                setSelectedIDs: () => null,
                selectedIDs: activeMarker?.idAsset
                  ? [activeMarker.idAsset]
                  : [],
                idPropertyName: 'idAsset',
                dataTestId: 'assetgrid-selectassetrow',
              }}
              height={
                gridFullscreen
                  ? `calc(100vh - ${66 + HEADING_HEIGHT}px)`
                  : `calc(50vh - ${106 + HEADING_HEIGHT}px)`
              }
              showExportInHeader={true}
              rightHeaderContent={
                <>
                  {allAssets &&
                    filteredAssets &&
                    allAssets.length !== filteredAssets.length && (
                      <AddNewGroup
                        tableRows={filteredAssets}
                        allData={allAssets}
                      />
                    )}
                  <GridGlobalFilter
                    totalAssetCount={allAssets?.length || 0}
                    // filterAssetsFromList={filterAssetsFromList}
                    // columns={state.columns}
                  />
                  <GridTableButtons
                    showAssetList={showAssetList}
                    closeGrid={closeGrid}
                    setGridFullscreen={setGridFullscreen}
                    gridFullscreen={gridFullscreen}
                  />
                </>
              }
            />
          </>
        )}
      </TopSection>
      <BottomSection id="bottomsection">
        <Map
          focusMap={focusMap}
          mapRef={mapRef}
          setAndFocusActiveMarker={setAndFocusActiveMarker}
          clearMap={clearMap}
          setAssetFilter={filterAssetsFromList}
          assetFilter={assetFilter}
          toggleFollow={toggleFollow}
          toggleStreetView={toggleStreetView}
          clearAssetFilter={clearAssetFilter}
          setLocationsFilter={setLocationsFilter}
          filteredAssets={filteredAssets}
        />
        {params?.id === 'directions' && (
          <GetDirections
            map={mapRef?.current?.map}
            address={query.get('address')}
          />
        )}
      </BottomSection>
    </Container>
  )
}
export default React.memo(GridMapContainer)
