import {useQuery} from '@tanstack/react-query'
import axios from 'axios'

const getUserById = async idUser => {
  const {data} = await axios.get(`/users/${idUser}`)
  return data
}

export default function useUserDetail(idUser, shouldFetch = true) {
  return useQuery(['userDetail', idUser], () => getUserById(idUser), {
    enabled: idUser && shouldFetch ? true : false,
  })
}
