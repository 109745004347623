import * as React from 'react'
import {ActionTypes} from 'common/types/typesModule'
import {matchSorter} from 'match-sorter'
import {CollapsibleFilters} from 'routingAndDispatch/common/CollapsibleFilter'
import {RouteAttributeType} from 'routingAndDispatch/hooks/useRouteAttributes'
import {RouteDriversType} from 'routingAndDispatch/hooks/useRouteDrivers'
import {
  AddressInfo,
  AttributePill,
  DropdownOption,
} from '../driverSettingsStyles'
import {Truck} from 'phosphor-react'
import {getLocationFromId} from 'routingAndDispatch/Routes/Create/createRouteHelpers'
import {EllipsisText} from 'common/components'
import {RouteLocationsType} from 'routingAndDispatch/hooks/useRouteLocations'
import {ColumnDef} from '@tanstack/react-table'
type SortOptions =
  | 'DriverName A-Z'
  | 'DriverName Z-A'
  | 'Asset A-Z'
  | 'Asset Z-A'

type SortKeys = 'DriverNameAZ' | 'DriverNameZA' | 'AssetAZ' | 'AssetZA'

export interface DriversWithAttributes extends RouteDriversType {
  AssignedAttributes: string
}

export type State = {
  filterIsOpen: boolean
  filters: CollapsibleFilters[]
  filterValues: {name: string; value: string}[] | null
  allDrivers: DriversWithAttributes[]
  filteredDrivers: DriversWithAttributes[]
  searchValue: string
  sortBy: SortOptions
  renderDrivers: DriversWithAttributes[]
  attributes: RouteAttributeType[]
  exportData: string[][]
}

export const sortByOptions: {
  key: SortKeys
  text: string
  value: SortOptions
}[] = [
  {
    key: 'DriverNameAZ',
    text: 'Driver First Name: A-Z',
    value: 'DriverName A-Z',
  },
  {
    key: 'DriverNameZA',
    text: 'Driver First Name: Z-A',
    value: 'DriverName Z-A',
  },
  {key: 'AssetAZ', text: 'Assigned Asset: A-Z', value: 'Asset A-Z'},
  {key: 'AssetZA', text: 'Assigned Asset: Z-A', value: 'Asset Z-A'},
]
//just for prototyping

export const initialState: State = {
  filterIsOpen: false,
  filters: [],
  filterValues: null,
  allDrivers: [],
  filteredDrivers: [],
  searchValue: '',
  sortBy: 'DriverName A-Z',
  renderDrivers: [],
  attributes: [],
  exportData: [],
}
export const DRIVER_SEARCH_KEYS = [
  'AssignedAssetLabel',
  'FullName',
  'AssignedAttributes',
  'Status',
  'Dept',
  'startLocation.title',
  'startLocation.carddata.Address1',
  'startLocation.carddata.City',
  'startLocation.carddata.State',
  'endLocation.title',
  'endLocation.carddata.Address1',
  'endLocation.carddata.City',
  'endLocation.carddata.State',
]
export const getSortedDrivers = (
  drivers: DriversWithAttributes[],
  sortBy: SortOptions = 'DriverName A-Z',
) => {
  // const plans: MaintenanceStatusType[] = []
  // alertEvents?.forEach(event => {
  //   return plans.push(event)
  // })
  const driverList = drivers?.length ? [...drivers] : []

  if (sortBy === 'DriverName A-Z') {
    driverList.sort((a, b) => {
      const aName = a.FullName.trim().toLocaleLowerCase() || 'zzzzz'
      const bName = b.FullName.trim().toLocaleLowerCase() || 'zzzzz'
      return aName.localeCompare(bName)
    })
  } else if (sortBy === 'DriverName Z-A') {
    driverList.sort((a, b) => {
      const aName = a.FullName || ''
      const bName = b.FullName || ''
      return bName.localeCompare(aName)
    })
  } else if (sortBy === 'Asset A-Z') {
    driverList.sort((a, b) => {
      const aAsset = a.AssignedAssetLabel || 'zzzz' //keeps blanks at bottom
      const bAsset = b.AssignedAssetLabel || 'zzzz'
      return aAsset.localeCompare(bAsset)
    })
  } else if (sortBy === 'Asset Z-A') {
    driverList.sort((a, b) => {
      const aAsset = a.AssignedAssetLabel || '   ' //keeps blanks at bottom
      const bAsset = b.AssignedAssetLabel || '   '
      return bAsset.localeCompare(aAsset)
    })
  }

  return driverList
}

interface FilterElement {
  element: JSX.Element
  value: string
}
export const makeFilterValues = (values: string[] | FilterElement[] = []) => {
  const filterValues: {
    key: string
    value: string
    text: string | JSX.Element
  }[] = []

  const pushValue = (value: string | FilterElement) => {
    if (typeof value === 'string') {
      return filterValues.push({key: value, value: value, text: value})
    } else {
      return filterValues.push({
        key: value.value,
        value: value.value,
        text: value.element,
      })
    }
  }

  //keep display all at top
  if (values.length > 0) {
    pushValue(values[0])
  }
  //alphabetically sort rest
  const sortedValues = values.slice(1).sort((a, b) => {
    if (typeof a === 'string' && typeof b === 'string') {
      return a.localeCompare(b)
    } else if (typeof a === 'object' && typeof b === 'object') {
      return a.value.localeCompare(b.value)
    }
    return 0
  })

  sortedValues.forEach(value => pushValue(value))

  return filterValues
}

export const reducer = (state: State, action: ActionTypes) => {
  const getAttributeName = (id: string) => {
    const attribute = state.attributes.find(
      attribute => attribute.idAttribute === id,
    )
    return attribute ? attribute.Value : 'error'
  }

  switch (action.type) {
    case 'setState': {
      return {
        ...state,
        ...action.data,
      }
    }
    case 'setFilterValues': {
      const tempArrayFilterValues = state.filterValues
        ? [...state.filterValues]
        : []

      tempArrayFilterValues.forEach((filter, index) => {
        //clears stale filter value and updates to new
        if (filter.name === action.data.name) {
          tempArrayFilterValues.splice(index, 1)
        }
      })
      if (action.data.value !== '') {
        tempArrayFilterValues.push({
          name: action.data.name,
          value: action.data.value,
        })
      }
      let tempDriverArray = [...state.allDrivers]

      tempArrayFilterValues.forEach(value => {
        if (value.name === 'Assets') {
          tempDriverArray = tempDriverArray.filter(
            driver =>
              value.value === 'Display All' ||
              driver.AssignedAssetLabel === value.value,
          )
        }
        if (value.name === 'Driver Attributes') {
          tempDriverArray = tempDriverArray.filter(
            driver =>
              value.value === 'Display All' ||
              driver?.Attributes?.find(
                attribute =>
                  getAttributeName(attribute.idAttribute) === value.value,
              ),
          )
        }
        if (value.name === 'Driver Status') {
          tempDriverArray = tempDriverArray.filter(
            driver =>
              value.value === 'Display All' || driver?.Status === value.value,
          )
        }
        if (value.name === 'Linked UserID') {
          tempDriverArray = tempDriverArray.filter(
            driver =>
              value.value === 'Display All' ||
              (!!driver?.idUser && value.value === 'Linked Users') ||
              (!driver?.idUser && value.value === 'Not Linked Users'),
          )
        }
        if (value.name === 'Department') {
          tempDriverArray = tempDriverArray.filter(
            driver =>
              value.value === 'Display All' || driver.Dept === value.value,
          )
        }
      })

      const searchedArray = state.searchValue.length
        ? getSortedDrivers(
            matchSorter(tempDriverArray, state.searchValue.toLowerCase(), {
              threshold: matchSorter.rankings.CONTAINS,
              keys: DRIVER_SEARCH_KEYS,
            }),
            state.sortBy,
          )
        : getSortedDrivers(tempDriverArray, state.sortBy)

      return {
        ...state,
        filterValues:
          tempArrayFilterValues.length > 0 ? tempArrayFilterValues : null,
        filteredDrivers: getSortedDrivers(tempDriverArray, state.sortBy),
        renderDrivers: searchedArray,
      }
    }
    case 'setInitialLoad': {
      const drivers = action.data.drivers
      const locations = action.data.locations
      const tempAssetList: {element: JSX.Element; value: string}[] = [
        {
          element: (
            <DropdownOption>
              <Truck size={20} />
              <span>{'Display All'}</span>
            </DropdownOption>
          ),
          value: 'Display All',
        },
      ]
      const tempAttributeList: {element: JSX.Element; value: string}[] = [
        {
          element: <AttributePill>Display All</AttributePill>,
          value: 'Display All',
        },
      ]

      const tempLinkedList: string[] = [
        'Display All',
        'Linked Users',
        'Not Linked Users',
      ]

      const tempDriverStatusList = ['Display All']
      const tempDriverDeptList = ['Display All']

      drivers.forEach((driver: RouteDriversType) => {
        //Status filter values
        if (
          driver.Status &&
          !tempDriverStatusList.find(status => status === driver.Status)
        ) {
          tempDriverStatusList.push(driver.Status)
        }
        //Assets Filter Values
        if (
          driver.AssignedAssetLabel &&
          !tempAssetList.find(
            value => value.value === driver?.AssignedAssetLabel,
          )
        ) {
          tempAssetList.push({
            value: driver?.AssignedAssetLabel,
            element: (
              <DropdownOption>
                <Truck size={20} />
                <span>{driver?.AssignedAssetLabel}</span>
              </DropdownOption>
            ),
          })
        }
        //Department filter values
        if (
          driver.Dept &&
          !tempDriverDeptList.find(value => value === driver?.Dept)
        ) {
          tempDriverDeptList.push(driver?.Dept)
        }
        //Attributes filter values
        driver?.Attributes?.forEach(attribute => {
          if (
            !tempAttributeList.find(
              value => value.value === getAttributeName(attribute.idAttribute),
            )
          ) {
            tempAttributeList.push({
              element: (
                <AttributePill>
                  {getAttributeName(attribute.idAttribute)}
                </AttributePill>
              ),
              value: getAttributeName(attribute.idAttribute),
            })
          }
        })
      })

      tempAttributeList.sort((a, b) => {
        if (a.value < b.value) {
          return -1
        }
        if (a.value > b.value) {
          return 1
        }
        return 0
      })

      const filterOptions: CollapsibleFilters[] = [
        {
          titleText: 'Assets',
          filterId: 'Assets',
          options: makeFilterValues(tempAssetList),
        },
        {
          titleText: 'Driver Attributes',
          filterId: 'Driver Attributes',
          options: makeFilterValues(tempAttributeList),
        },

        {
          titleText: 'Driver Status',
          filterId: 'Driver Status',
          options: makeFilterValues(tempDriverStatusList),
        },
        {
          titleText: 'Linked UserID',
          filterId: 'Linked UserID',
          options: makeFilterValues(tempLinkedList),
        },
        {
          titleText: 'Department',
          filterId: 'Department',
          options: makeFilterValues(tempDriverDeptList),
        },
      ]
      const exportData = getDriverDSExportData(drivers, locations)
      return {
        ...state,
        filters: filterOptions,
        allDrivers: drivers,
        filteredDrivers: drivers,
        renderDrivers: drivers,
        exportData: exportData,
      }
    }

    case 'sortBy': {
      let tempArray = [...state.renderDrivers]
      const sortBy = sortByOptions.find(option => option.key === action.data)
      tempArray = getSortedDrivers(tempArray, sortBy?.value)

      return {
        ...state,
        renderDrivers: tempArray,
        filteredDrivers: tempArray,
        sortBy: sortBy?.value,
      }
    }
    default:
      break
  }
}
export const getAttributeName = (
  id: string,
  attributeData: RouteAttributeType[],
) => {
  const attribute = attributeData?.find(
    attribute => attribute.idAttribute === id,
  )
  return attribute ? attribute.Value : 'error'
}

export const address = (
  idLocation: string | null,
  locationList: RouteLocationsType[],
  type: string,
) => {
  const location = getLocationFromId(idLocation, locationList)
  return (
    <AddressInfo>
      <strong>{type === 'start' ? ' Start Point:' : ' End Point:'}</strong>
      <EllipsisText>{location?.title || '--'}</EllipsisText>
      {location && <EllipsisText>{location?.carddata?.Address1}</EllipsisText>}
      {location?.carddata.City && location?.carddata.State && (
        <EllipsisText>
          {location?.carddata.City}, {location?.carddata.State}
        </EllipsisText>
      )}
    </AddressInfo>
  )
}

export const driverDefaultSettingsColumns: ColumnDef<RouteDriversType>[] = [
  {
    header: 'Name',
    id: 'FullName',
    accessorKey: 'FullName',
  },
  {
    header: 'Asset',
    id: 'AssignedAssetLabel',
    accessorKey: 'AssignedAssetLabel',
  },
  {
    header: 'Department',
    id: 'Dept',
    accessorKey: 'Dept',
  },
  {
    header: 'Attributes',
    id: 'attributes',
    accessorKey: 'Attributes',
  },
  {
    header: 'Start Location',
    id: 'startLocation',
    accessorKey: 'startLocation',
  },
  {
    header: 'End Location',
    id: 'endLocation',
    accessorKey: 'endLocation',
  },
  {
    header: 'Linked To User',
    id: 'linkedToUser',
    accessorKey: 'linkedToUser',
  },
  {
    header: 'Status',
    id: 'status',
    accessorKey: 'status',
  },
]

export const getDriverDSExportData = (
  data: DriversWithAttributes[],
  locationList?: RouteLocationsType[],
) => {
  const newData: string[][] = []
  data?.forEach(d => {
    const {
      FullName,
      AssignedAssetLabel,
      Attributes,
      RouteStartLocationId,
      RouteEndLocationId,
      idUser,
      Status,
    } = d

    const startLocation = locationList
      ? getLocationFromId(RouteStartLocationId || null, locationList)
      : null

    const startAddressString = `${startLocation?.carddata?.Address1 || ''} ${
      startLocation?.carddata.City && startLocation?.carddata.State
        ? `${startLocation?.carddata.City}, ${startLocation?.carddata.State}`
        : ''
    }`

    const endLocation = locationList
      ? getLocationFromId(RouteEndLocationId || null, locationList)
      : null

    const endAddressString = `${endLocation?.carddata?.Address1 || ''} ${
      endLocation?.carddata.City && endLocation?.carddata.State
        ? `${endLocation?.carddata.City}, ${endLocation?.carddata.State}`
        : ''
    }`

    const attributesString = Attributes.map(item => item.Value).join(' ')

    newData.push([
      FullName || '',
      AssignedAssetLabel || '',
      attributesString || '',
      startAddressString,
      endAddressString,
      idUser ? 'True' : 'False',
      Status,
    ])
  })
  return newData
}
