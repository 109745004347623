import * as React from 'react'
import styled from 'styled-components/macro'
import {Spinner} from 'common/components'
import {ascendColors} from 'common/GlobalStyles'
import useAuthConfig from './hooks/useAuthConfig'

const Container = styled.div`
  display: flex;
  .lytx-rec-timeline {
    width: 1000px;
  }
`

const SurfsightRecordingTimeline = ({idDevice}: {idDevice: number}) => {
  const lytxTimelineRef = React.useRef<HTMLDivElement | null>(null)
  const {data: authConfigData, status} = useAuthConfig(idDevice)

  React.useEffect(() => {
    if (authConfigData) {
      const observer = new ResizeObserver(() => {
        document
          .getElementById('bottomEmptyElementToScrollTo')
          ?.scrollIntoView()
      })
      if (lytxTimelineRef.current) observer.observe(lytxTimelineRef.current)
    }
  }, [authConfigData])

  if (status === 'loading') {
    return <Spinner />
  } else if (status === 'error') {
    return (
      <div>
        There was an error loading the timeline, please try again later.
      </div>
    )
  }

  return (
    <Container>
      {authConfigData ? (
        <lytx-recording-timeline
          ref={lytxTimelineRef}
          class="lytx-rec-timeline"
          auth-token={authConfigData.authToken}
          organization-id={authConfigData.idOrganization}
          imei={authConfigData.imei}
          title-display={false}
          driver-name={false}
          description-display={false}
          primary-color={ascendColors.moss}
        ></lytx-recording-timeline>
      ) : null}
    </Container>
  )
}
export default SurfsightRecordingTimeline
