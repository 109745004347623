import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'
import {EditDeviceType} from './usePutDevice'

const getDevice = async (idDevice: number) => {
  const {data}: {data: EditDeviceType} = await Axios.get(
    `/management/devices/${idDevice}`,
  )
  return data
}

export default function useDeviceDetail(idDevice: number) {
  const user = useStore(state => state.user)
  return useQuery(['deviceDetail', idDevice], () => getDevice(idDevice), {
    enabled: Boolean(user?.token && idDevice),
  })
}
