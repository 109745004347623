import * as React from 'react'
import styled from 'styled-components/macro'
import {Range} from 'react-date-range'
import {compareAsc, format, parse} from 'date-fns'
import {matchSorter} from 'match-sorter'
import {
  CollapsibleFilters,
  FilterState,
} from 'routingAndDispatch/common/CollapsibleFilter'
import {DEFAULT_DRIVER_LOCATION} from 'routingAndDispatch/common/LocationsDropdown'
import {makeFilterValues} from 'routingAndDispatch/Setup/driverSettings/list/driverListSettingsHelpers'
import {OrderType} from 'routingAndDispatch/types/typesModule'
import {RouteDriversType} from 'routingAndDispatch/hooks/useRouteDrivers'
import {Truck} from 'phosphor-react'
import {RouteAttributeType} from 'routingAndDispatch/hooks/useRouteAttributes'
import {RouteLocationsType} from 'routingAndDispatch/hooks/useRouteLocations'
import {useStore} from 'common/useStore'
import {HOURS, MINS} from 'routingAndDispatch/common/commonHelpers'

const DropdownOption = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  svg {
    min-height: 20px;
    min-width: 20px;
    color: var(--asc-sonicsilver);
  }
`

export interface CreateRouteActionTypes {
  type:
    | 'setState'
    | 'setCleanState'
    | 'initialLoad'
    | 'setFilterValues'
    | 'setDriverFilterValues'
    | 'setOrdersFilterValues'
    | 'setDriverInitialLoad'
    | 'setOrdersInitialLoad'
    | 'searchOrders'
    | 'clearSearch'
    | 'setDurationState'
    | 'resetState'
    | 'clearFilters'
  //reducer data
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}

export enum Tab {
  'routePlan',
  'orders',
  'drivers',
  'approve',
}
export interface CreateRouteState {
  routeName: string
  isDirty: boolean
  isBalanced: boolean
  submitting: boolean
  dateRange: Range
  dateRangeError: string
  startLocation: string
  endLocation: string
  currentTab: Tab
  attributeList?: RouteAttributeType[]
  vehicleType: 'Heavy' | 'Light'
  serviceDuration: {hrs: number; mins: number}
  ordersFilterState: FilterState
  ordersSortState: [{id: string; value: string}] | []
  orders?: OrderType[]
  ordersToRender: OrderType[] | null
  filteredOrders: OrderType[] | null
  selectedOrders: string[]
  ordersSearchValue: string

  driverFilterState: FilterState
  driversSortState: [{id: string; value: string}] | []
  drivers?: RouteDriversType[]
  selectedDrivers: string[]
  driverSearchValue: string
  driversToRender?: RouteDriversType[]
  filteredDrivers?: RouteDriversType[]

  showErrorModal: boolean
  showConfirmModal: boolean

  idPlan?: number
  pageViewState: string
}

const makeDriverFilters = (
  drivers: RouteDriversType[],
  getAttributeName: (attributeID: string) => string,
) => {
  if (!drivers) return
  const tempAssetList: {element: JSX.Element; value: string}[] = [
    {
      element: (
        <DropdownOption>
          <Truck size={20} />
          <span>{'Display All'}</span>
        </DropdownOption>
      ),
      value: 'Display All',
    },
  ]
  const tempAttributeList: {element: JSX.Element; value: string}[] = [
    {
      element: <div>Display All</div>,
      value: 'Display All',
    },
  ]

  const tempDriverStatusList = ['Display All']

  const tempDriverDepartmentList = ['Display All']

  drivers.forEach((driver: RouteDriversType) => {
    //Status values
    if (
      driver.Status &&
      !tempDriverStatusList.find(status => status === driver.Status)
    ) {
      tempDriverStatusList.push(driver.Status)
    }
    //Asset Values
    if (
      driver.AssignedAssetLabel &&
      !tempAssetList.find(value => value.value === driver?.AssignedAssetLabel)
    ) {
      tempAssetList.push({
        value: driver?.AssignedAssetLabel,
        element: (
          <DropdownOption>
            <Truck size={20} />
            <span>{driver?.AssignedAssetLabel}</span>
          </DropdownOption>
        ),
      })
    }
    //Department Values
    if (
      driver.Dept &&
      !tempDriverDepartmentList.find(department => department === driver.Dept)
    ) {
      tempDriverDepartmentList.push(driver.Dept)
    }
    //Attribute Values
    driver?.Attributes?.forEach(attribute => {
      if (
        !tempAttributeList.find(
          value => value.value === getAttributeName(attribute.idAttribute),
        )
      ) {
        tempAttributeList.push({
          element: <div>{getAttributeName(attribute.idAttribute)}</div>,
          value: getAttributeName(attribute.idAttribute),
        })
      }
    })
  })

  const driverFilterOptions: CollapsibleFilters[] = [
    {
      titleText: 'Assets',
      filterId: 'Assets',
      options: makeFilterValues(tempAssetList),
    },
    {
      titleText: 'Driver Attributes',
      filterId: 'Driver Attributes',
      options: makeFilterValues(tempAttributeList),
    },
    {
      titleText: 'Driver Status',
      filterId: 'Driver Status',
      options: makeFilterValues(tempDriverStatusList),
    },
    {
      titleText: 'Driver Department',
      filterId: 'Driver Department',
      options: makeFilterValues(tempDriverDepartmentList),
    },
  ]
  return driverFilterOptions
}

export type CreateRouteReducerType = [
  state: CreateRouteState,
  dispatch: (action: CreateRouteActionTypes) => void,
]
export const ORDERS_SEARCH_KEYS = [
  'title',
  'stops.0.city',
  'stops.0.state',
  'stops.0.contact',
  'stops.0.companyName',
  'serviceType',
  'orderStatus',
  'description',
]

export const createRouteReducer: (
  state: CreateRouteState,
  action: CreateRouteActionTypes,
) => CreateRouteState = (state, action) => {
  const getAttributeName = (id: string) => {
    const attribute = state.attributeList?.find(
      attribute => attribute.idAttribute === id,
    )
    return attribute ? attribute.Value : 'error'
  }
  switch (action.type) {
    case 'setState': {
      return {
        ...state,
        ...action.data,
        isDirty: true,
      }
    }

    case 'setCleanState': {
      return {
        ...state,
        ...action.data,
      }
    }
    case 'initialLoad': {
      return {
        ...state,
        currentTab: Tab.drivers,
        ...action.data,
      }
    }
    case 'setDriverInitialLoad': {
      const driverFilterOptions = makeDriverFilters(
        action.data,
        getAttributeName,
      )
      return {
        ...state,
        driverFilterState: {
          ...state.driverFilterState,
          filters: driverFilterOptions,
        },
        drivers:
          state?.drivers && state.drivers.length > 0
            ? state.drivers
            : action.data,
        filteredDrivers: state.filteredDrivers
          ? action.data.filter(
              (driver: RouteDriversType) =>
                !!state.filteredDrivers?.find(
                  filteredDriver => filteredDriver.idDriver === driver.idDriver,
                ),
            )
          : action.data,
        driversToRender: state.driversToRender
          ? action.data.filter(
              (driver: RouteDriversType) =>
                !!state.driversToRender?.find(
                  filteredDriver => filteredDriver.idDriver === driver.idDriver,
                ),
            )
          : action.data,
      }
    }
    case 'resetState': {
      const driverFilterOptions = makeDriverFilters(
        action.data.drivers,
        getAttributeName,
      )

      return {
        ...state,
        ...action.data,
        driverFilterState: {
          ...action.data.driverFilterState,
          filters: driverFilterOptions,
        },
        isDirty: false,
      }
    }

    case 'setOrdersInitialLoad': {
      const tempOrdersStatusList: string[] = []
      const tempCityList: string[] = []
      const tempStateList: string[] = []
      const tempServiceTypeList: string[] = []
      const tempCompanyNameList: string[] = []
      const tempContactList: string[] = []
      const data = action.data.ordersData as OrderType[]

      // const planStartDate = new Date(state.dateRange.startDate || '')
      // const planEndDate = new Date(state.dateRange.endDate || '')

      // const filteredData = data.filter(order => {
      //   let allStopsInRange = true

      //   if (planStartDate && planEndDate && order.stops.length) {
      //     order.stops.forEach(stop => {
      //       if (!allStopsInRange) return

      //       const stopStart =
      //         stop?.ServiceStartDateTime || stop?.scheduledEarliestDate || ''
      //       const stopEnd =
      //         stop?.ServiceEndDateTime || stop?.scheduledLatestDate || ''

      //       const scheduledStartDate = new Date(stopStart)
      //       const scheduledEndDate = new Date(stopEnd)

      //       const isWithinTimeWindow =
      //         scheduledStartDate <= planEndDate &&
      //         scheduledEndDate >= planStartDate

      //       if (!isWithinTimeWindow) {
      //         allStopsInRange = false
      //       }
      //       return isWithinTimeWindow
      //     })
      //   }
      //   return allStopsInRange
      // })

      //filter when service date in plan time window, and only if new or null status
      data.forEach((order: OrderType) => {
        if (
          order?.orderStatus &&
          !tempOrdersStatusList.find(state => state === order.orderStatus)
        ) {
          tempOrdersStatusList.push(order.orderStatus)
        }

        if (
          order?.stops[0]?.state &&
          !tempStateList.find(state => state === order.stops[0].state)
        ) {
          tempStateList.push(order.stops[0].state)
        }

        if (
          order?.stops[0]?.city &&
          !tempCityList.find(city => city === order.stops[0].city)
        ) {
          tempCityList.push(order.stops[0].city)
        }

        if (
          order?.serviceType &&
          !tempServiceTypeList.find(type => type === order.serviceType)
        ) {
          tempServiceTypeList.push(order.serviceType)
        }
        if (
          order?.stops[0]?.companyName &&
          !tempCompanyNameList.find(
            companyName => companyName === order.stops[0].companyName,
          )
        ) {
          tempCompanyNameList.push(order.stops[0].companyName)
        }

        if (
          order?.stops[0]?.contact &&
          !tempContactList.find(contact => contact === order.stops[0].contact)
        ) {
          tempContactList.push(order.stops[0].contact)
        }
      })
      tempOrdersStatusList.sort()
      tempStateList.sort()
      tempCityList.sort()
      tempServiceTypeList.sort()
      tempCompanyNameList.sort()
      tempContactList.sort()
      const userDateFormat =
        useStore.getState().userConfig?.Date_Format || 'MM/dd/yyyy'
      const sortedOrders = data.sort((a: OrderType, b: OrderType) => {
        const dateA =
          a.scheduledDate && parse(a.scheduledDate, userDateFormat, new Date())
        const dateB =
          b.scheduledDate && parse(b.scheduledDate, userDateFormat, new Date())
        if (!dateA) return -1
        if (!dateB) return 1
        return compareAsc(dateA, dateB)
      })

      const newSelectedOrders: string[] = []
      state.selectedOrders.forEach(selected => {
        const foundOrder = sortedOrders.find(
          sorted => sorted.idOrder === selected,
        )
        if (foundOrder) {
          if (!newSelectedOrders.includes(foundOrder.idOrder)) {
            newSelectedOrders.push(foundOrder.idOrder)
          }
        }
      })

      const ordersFilterOptions: CollapsibleFilters[] = [
        {
          titleText: 'Status',
          filterId: 'Status',
          options: makeFilterValues(tempOrdersStatusList),
        },
        {
          titleText: 'Company Name',
          filterId: 'Company Name',
          options: makeFilterValues(tempCompanyNameList),
        },
        {
          titleText: 'Contact',
          filterId: 'Contact',
          options: makeFilterValues(tempContactList),
        },
        {
          titleText: 'City',
          filterId: 'City',
          options: makeFilterValues(tempCityList),
        },
        {
          titleText: 'State',
          filterId: 'State',
          options: makeFilterValues(tempStateList),
        },
        {
          titleText: 'Service Type',
          filterId: 'Service Type',
          options: makeFilterValues(tempServiceTypeList),
        },
      ]

      return {
        ...state,
        ordersFilterState: {
          ...state.ordersFilterState,
          filters: ordersFilterOptions,
        },
        orders: sortedOrders,
        filteredOrders: sortedOrders,
        ordersToRender: sortedOrders,
        selectedOrders: newSelectedOrders,
      }
    }
    case 'setDriverFilterValues': {
      const tempArrayFilterValues = state.driverFilterState.filterValues
        ? [...state.driverFilterState.filterValues]
        : []

      tempArrayFilterValues.forEach((filter, index) => {
        //clears stale filter value and updates to new
        if (filter.name === action.data.name) {
          tempArrayFilterValues.splice(index, 1)
        }
      })
      if (action.data.value !== '') {
        tempArrayFilterValues.push({
          name: action.data.name,
          value: action.data.value,
        })
      }
      let tempDriverArray = state.drivers ? [...state.drivers] : []

      tempArrayFilterValues.forEach(value => {
        if (value.name === 'Assets') {
          tempDriverArray = tempDriverArray.filter(
            driver =>
              value.value === 'Display All' ||
              driver.AssignedAssetLabel === value.value,
          )
        }
        if (value.name === 'Driver Attributes') {
          tempDriverArray = tempDriverArray.filter(
            driver =>
              value.value === 'Display All' ||
              driver?.Attributes?.find(
                attribute =>
                  getAttributeName(attribute.idAttribute) === value.value,
              ),
          )
        }

        if (value.name === 'Driver Status') {
          tempDriverArray = tempDriverArray.filter(
            driver =>
              value.value === 'Display All' || driver?.Status === value.value,
          )
        }
        if (value.name === 'Driver Department') {
          tempDriverArray = tempDriverArray.filter(
            driver =>
              value.value === 'Display All' || driver?.Dept === value.value,
          )
        }
      })
      const searchedArray = state.driverSearchValue.length
        ? matchSorter(tempDriverArray, state.driverSearchValue.toLowerCase(), {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ['AssignedAssetLabel', 'FullName', 'Dept'],
          })
        : tempDriverArray

      return {
        ...state,
        driverFilterState: {
          ...state.driverFilterState,
          filterValues:
            tempArrayFilterValues.length > 0 ? tempArrayFilterValues : null,
        },
        driversToRender: searchedArray,
        filteredDrivers: tempDriverArray,
      }
    }
    case 'setOrdersFilterValues': {
      const tempArrayFilterValues = state.ordersFilterState.filterValues
        ? [...state.ordersFilterState.filterValues]
        : []

      tempArrayFilterValues.forEach((filter, index) => {
        //clears stale filter value and updates to new
        if (filter.name === action.data.name) {
          tempArrayFilterValues.splice(index, 1)
        }
      })
      if (action.data.value !== '') {
        tempArrayFilterValues.push({
          name: action.data.name,
          value: action.data.value,
        })
      }

      let tempOrdersArray = state.orders ? [...state.orders] : []

      tempArrayFilterValues.forEach(value => {
        if (value.name === 'Status') {
          tempOrdersArray = tempOrdersArray.filter(
            order =>
              value.value === 'Display All' ||
              order.orderStatus === value.value,
          )
        }
        if (value.name === 'Company Name') {
          tempOrdersArray = tempOrdersArray.filter(
            order =>
              value.value === 'Display All' ||
              order?.stops[0]?.companyName === value.value,
          )
        }
        if (value.name === 'Contact') {
          tempOrdersArray = tempOrdersArray.filter(
            order =>
              value.value === 'Display All' ||
              order?.stops[0]?.contact === value.value,
          )
        }
        if (value.name === 'City') {
          tempOrdersArray = tempOrdersArray.filter(
            order =>
              value.value === 'Display All' ||
              order?.stops[0]?.city === value.value,
          )
        }
        if (value.name === 'State') {
          tempOrdersArray = tempOrdersArray.filter(
            order =>
              value.value === 'Display All' ||
              order?.stops[0]?.state === value.value,
          )
        }
        if (value.name === 'Service Type') {
          tempOrdersArray = tempOrdersArray.filter(
            order =>
              value.value === 'Display All' ||
              order?.serviceType === value.value,
          )
        }
      })
      const searchedArray = state.ordersSearchValue.length
        ? matchSorter(tempOrdersArray, state.ordersSearchValue.toLowerCase(), {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ORDERS_SEARCH_KEYS,
          })
        : tempOrdersArray

      return {
        ...state,
        ordersFilterState: {
          ...state.ordersFilterState,
          filterValues:
            tempArrayFilterValues.length > 0 ? tempArrayFilterValues : null,
        },
        ordersToRender: searchedArray,
        filteredOrders: tempOrdersArray,
      }
    }
    case 'searchOrders': {
      let tempOrdersArray = state.orders ? [...state.orders] : []

      const searchedArray = state.ordersSearchValue.length
        ? matchSorter(tempOrdersArray, state.ordersSearchValue.toLowerCase(), {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ORDERS_SEARCH_KEYS,
          })
        : tempOrdersArray

      return {
        ...state,
        ordersToRender: searchedArray,
        filteredOrders: searchedArray,
      }
    }
    case 'clearSearch': {
      return {
        ...state,
        ordersToRender: state.filteredOrders,
        ordersSearchValue: '',
      }
    }
    case 'clearFilters': {
      return {
        ...state,
        ordersToRender: action.data.orders,
        filteredOrders: action.data.orders,
        ordersFilterState: {
          ...state.ordersFilterState,
          filterValues: action.data.filterValues,
          filterIsOpen: false,
        },
      }
    }
    case 'setDurationState': {
      let newDurationState = {}
      const currentMinutes = state.serviceDuration?.mins || 0
      const currentHours = state.serviceDuration?.hrs || 0

      if (action.data.type === HOURS) {
        newDurationState = {
          [action.data.inputId]: {
            hrs: action.data.value,
            mins: currentMinutes,
          },
        }
      }
      if (action.data.type === MINS) {
        newDurationState = {
          [action.data.inputId]: {hrs: currentHours, mins: action.data.value},
        }
      }
      return {...state, ...newDurationState, isDirty: true}
    }
    default:
      state
  }
}
const today = format(new Date(), 'yyyy/MM/dd')
const initialStartTime = today + ' 8:00:00'
const initialStartDateTime = new Date(initialStartTime)
const initialEndTime = today + ' 17:00:00'
const initialEndDateTime = new Date(initialEndTime)

export const createRouteInitialState: CreateRouteState = {
  routeName: '',
  isBalanced: false,
  vehicleType: 'Light',
  showErrorModal: false,
  showConfirmModal: false,
  isDirty: false,
  submitting: false,
  startLocation: DEFAULT_DRIVER_LOCATION,
  endLocation: DEFAULT_DRIVER_LOCATION,
  dateRange: {
    startDate: initialStartDateTime,
    endDate: initialEndDateTime,
  },
  dateRangeError: '',
  currentTab: Tab.routePlan,
  attributeList: undefined,
  ordersFilterState: {
    filterIsOpen: false,
    filterValues: null,
  },
  ordersSortState: [],
  orders: undefined,
  selectedOrders: [],
  ordersToRender: null,
  filteredOrders: null,
  ordersSearchValue: '',

  driverFilterState: {
    filterIsOpen: false,
    filterValues: null,
  },
  driversSortState: [],
  drivers: undefined,
  selectedDrivers: [],
  driversToRender: undefined,
  filteredDrivers: undefined,
  driverSearchValue: '',
  pageViewState: 'split',
  serviceDuration: {hrs: 0, mins: 0},
}

export const getHoursAndMinutes = (timeInMinutes: number) => {
  let hoursAndMinutes = ''
  const hours = Math.floor(timeInMinutes / 60)
  const minutes = Math.floor(timeInMinutes) % 60
  if (hours > 0 && minutes > 0) {
    hoursAndMinutes += hours + ' hr : '
  }
  if (hours > 0 && minutes === 0) {
    hoursAndMinutes += hours + ' hr'
  }
  if (minutes > 0) {
    hoursAndMinutes += minutes.toString().padStart(2, '0') + ' min'
  }
  if (!hoursAndMinutes) {
    hoursAndMinutes += '< 1 min'
  }
  return hoursAndMinutes
}

export const getLocationFromId = (
  id: string | null,
  locationList: RouteLocationsType[],
) => {
  return locationList.filter(location => location._id === id)[0]
}
