import React, {useState, useEffect} from 'react'
import styled from 'styled-components/macro'

const Placeholder = styled.div`
  height: 100%;
  @keyframes shimmer {
    from {
      background-position: 200% 0;
    }
    to {
      background-position: -200% 0;
    }
  }

  animation: shimmer 2s linear infinite;
  background: linear-gradient(
    to right,
    transparent 0%,
    var(--asc-platinum) 25%,
    transparent 50%
  );
  background-size: 200% 100%;
`

const TransparentPlaceholder = styled.div`
  height: 100%;
  background: transparent;
`

const ShimmerPlaceholder = () => {
  const [showShimmer, setShowShimmer] = useState(true)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowShimmer(false) // After 10 seconds, hide shimmer
    }, 10000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return showShimmer ? <Placeholder /> : <TransparentPlaceholder />
}

export default ShimmerPlaceholder
