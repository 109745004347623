import {useMutation} from '@tanstack/react-query'
import axios from 'axios'
import Swal from 'sweetalert2'

const putAddress = async address => {
  const {data} = await axios.put(`/addresses/${address.idAddress}`, address)
  return data
}

export default function usePutAddress() {
  return useMutation(putAddress, {
    onError: (err, variables, previousValue) => {
      console.error(err, variables, previousValue)
      Swal.fire(
        'Error',
        'There was an error saving this user. Please check that the email address is not already in use and try again.',
        'error',
      )
    },
  })
}
