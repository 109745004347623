import * as React from 'react'
import {Body, Tab, TabRow} from './notificationStyles'
import {
  checkErrors,
  getLoadedTemplate,
  initialState,
  reducer,
} from './notificationsHelpers'
import useCustomerNotications from './hooks/useCustomerNotifications'
import {AlertModal, Spinner} from 'common/components'
import TabContent from './TabContent'
import useCustomerTemplate from './hooks/useCustomerTemplate'
import usePostCustomerTemplate from './hooks/usePostCustomerTemplate'
import Modal from 'common/Modal'

type NotificationModalProps = {
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  idRDEtaDefaultTemplate: number
}
const NotificationModal: React.FC<NotificationModalProps> = ({
  showModal,
  setShowModal,
  idRDEtaDefaultTemplate,
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const {data: customerNotifications} = useCustomerNotications()
  const {data: customerTemplate, status} = useCustomerTemplate({
    enabled: showModal,
    idRDEtaDefaultTemplate,
  })
  const {mutateAsync: postCustomerEtaTemplate} = usePostCustomerTemplate()

  React.useEffect(() => {
    if (customerNotifications && showModal && customerTemplate) {
      const selectedNotification = customerNotifications.find(
        notification =>
          notification.idRDEtaDefaultTemplate === idRDEtaDefaultTemplate,
      )
      if (selectedNotification) {
        const loadedTemplate = getLoadedTemplate({
          customerTemplate,
          customerNotificationDefaults: selectedNotification,
        })

        //text for dropdown component but given as Text from api response
        const insertOptions = selectedNotification.Tokens.map(token => {
          return {
            key: token.key,
            value: token.value,
            text: token.Text,
            preview: token.Preview,
          }
        })

        dispatch({
          type: 'setState',
          data: {
            template: loadedTemplate,
            originalTemplate: loadedTemplate,
            insertOptions: insertOptions,
          },
        })
      }
    }
  }, [
    showModal,
    customerNotifications,
    customerTemplate,
    idRDEtaDefaultTemplate,
  ])

  const handleInputChange = ({name, value}: {name: string; value: string}) => {
    let inputValue = value

    const errors = state.errors
    errors[name] = undefined
    dispatch({
      type: 'setState',
      data: {
        isDirty: true,
        template: {...state.template, [name]: inputValue},
        errors,
      },
    })
  }

  const onResetButton = (name: string) => {
    dispatch({
      type: 'setState',
      data: {
        template: {...state.template, [name]: state.originalTemplate[name]},
      },
    })
  }

  const onToggle = ({name, enabled}: {name: string; enabled: boolean}) => {
    dispatch({
      type: 'setState',
      data: {
        template: {...state.template, [name]: enabled},
        isDirty: true,
      },
    })
  }

  const onSave = () => {
    if (state.isDirty) {
      const errors = checkErrors({template: state.template})
      if (!errors.EmailBody && !errors.EmailSubject && !errors.SmsBody) {
        postCustomerEtaTemplate(state.template)
        setShowModal(false)
        dispatch({
          type: 'resetState',
        })
      }

      dispatch({
        type: 'setError',
        data: errors,
      })
    } else {
      setShowModal(false)
    }
  }

  const onDismiss = () => {
    if (state.isDirty) {
      dispatch({type: 'setState', data: {showDialog: true}})
    } else {
      setShowModal(false)
      dispatch({
        type: 'resetState',
      })
    }
  }
  return (
    <>
      {showModal && status === 'loading' && <Spinner />}
      {status === 'success' && (
        <Modal
          showDialog={showModal}
          title={`Edit ${state.template.NotificationName} Notifications`}
          onDismiss={onDismiss}
          renderFooter={true}
          onSave={onSave}
          width="1100px"
        >
          <Body>
            <TabRow>
              <Tab
                onClick={() => {
                  dispatch({type: 'setState', data: {currentTab: 'email'}})
                }}
                isSelected={state.currentTab === 'email'}
                key={'email'}
                data-cy="tab"
              >
                Email
              </Tab>
              <Tab
                onClick={() => {
                  dispatch({type: 'setState', data: {currentTab: 'text'}})
                }}
                isSelected={state.currentTab === 'text'}
                key={'text'}
                data-cy="tab"
              >
                Text Message
              </Tab>
            </TabRow>
            {state.currentTab === 'email' && (
              <TabContent
                name={'Email'}
                handleInputChange={handleInputChange}
                subject={state.template?.EmailSubject || ''}
                body={state.template?.EmailBody || ''}
                errors={state.errors}
                onResetButton={onResetButton}
                insertOptions={state.insertOptions}
                enabled={state.template.SendEmail}
                onToggle={(enabled: boolean) =>
                  onToggle({name: 'SendEmail', enabled})
                }
              />
            )}
            {state.currentTab === 'text' && (
              <TabContent
                name={'Sms'}
                handleInputChange={handleInputChange}
                subject={''}
                body={state.template?.SmsBody || ''}
                errors={state.errors}
                onResetButton={onResetButton}
                insertOptions={state.insertOptions}
                enabled={state.template.SendSms}
                onToggle={(enabled: boolean) =>
                  onToggle({name: 'SendSms', enabled})
                }
              />
            )}
          </Body>
        </Modal>
      )}
      <AlertModal
        showDialog={state.showDialog}
        onSave={() => {
          setShowModal(false)
          dispatch({type: 'resetState'})
        }}
        onDismiss={() => {
          dispatch({type: 'setState', data: {showDialog: false}})
        }}
        showDenyButton={true}
        title={`Cancel changes made to the ${state.template.NotificationName} notifications?`}
        type={'cancel'}
        text={'The changes you made will be discarded.'}
      />
    </>
  )
}
export default NotificationModal
