import axios from 'axios'
import {setAxiosAndUser} from 'common/setToken'
import Swal from 'sweetalert2'

export const checkCurrentPassword = state => {
  return state.currentPassword === state.savedPassword
}
export const validateCapital = state => {
  if (state.newPassword) {
    const input = state.newPassword
    let capital = 0
    for (let i = 0; i < input.length; i++) {
      if (/^[A-Z]/.test(input[i])) {
        capital++
      }
    }
    if (capital < 1) {
      return false
    } else if (capital > 0) {
      return true
    }
  }
}
export const validateNumbers = state => {
  if (state.newPassword) {
    const hasNumber = /\d/
    return hasNumber.test(state.newPassword)
  }
}
export const validateNoColon = state => {
  if (state.newPassword) {
    const hasColon = state.newPassword.match(':')
    return !(hasColon && hasColon.length)
  }
}
export const validateMatchingPassword = state => {
  return state.newPassword && state.confirmPassword
    ? state.newPassword === state.confirmPassword
    : false
}
export const validateLength = state => {
  const {newPassword} = state
  return newPassword && newPassword.length >= 8 && newPassword.length <= 32
}
export const initialChangePassword = {
  currentPassword: null,
  newPassword: null,
  matched: false,
  confirmPassword: null,
  showConfirmPassword: false,
  showNewPassword: false,
}
export const changePasswordReducer = (
  state = initialChangePassword,
  action,
) => {
  switch (action.type) {
    case 'setState': {
      return {
        ...state,
        ...action.data,
      }
    }
    case 'clearState': {
      return {...initialChangePassword}
    }
    default:
      return state
  }
}

export const submitNewPassword = async (userConfig, user, pwd, onDismiss) => {
  await axios.get(
    `users?a=setpassword&id=${userConfig.idUser}&p=${encodeURIComponent(pwd)}`,
  )
  const token = window.btoa(`${user.email}:${pwd}`)
  await setAxiosAndUser({token, email: user.email})
  onDismiss()
}
export const submitResetPassword = async (pwd, token, type) => {
  try {
    await axios.get(
      'users?a=setpassword&password=' +
        encodeURIComponent(pwd) +
        '&token=' +
        token,
    )

    Swal.fire(
      type === 'new' ? 'Creating new password' : 'Password reset',
      type === 'new'
        ? 'Password creation was a success.'
        : 'Password reset was a success.',
      'success',
    ).then(() => {
      window.location.href = `/login`
    })
  } catch (error) {
    Swal.fire(
      'Error',
      'There was an error resetting your password. Please check the link in the email and try again. If you continue to have problems, contact support',
      'error',
    ).then(() => {
      window.location.href = `/login`
    })
  }
}
