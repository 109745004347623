import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

const getAssetSummarySchema = async () => {
  const {data} = await Axios.get(`/schema?schematype=mobile_popup`)
  return data
}

const useAssetSummarySchema = () => {
  const user = useStore(state => state.user)
  return useQuery(['assetSummarySchema'], () => getAssetSummarySchema(), {
    enabled: Boolean(user.token),
    staleTime: 1000 * 60 * 5,
  })
}

export default useAssetSummarySchema
