import {library} from '@fortawesome/fontawesome-svg-core'
import {faArrowAltCircleRight} from '@fortawesome/free-regular-svg-icons'

import {
  faTimesCircle,
  faChartPie,
  faBook,
  faBars,
  faWindowClose,
  faStream,
  faHardHat,
  faGlobeAmericas,
  faIndustry,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faChevronDown,
  faChevronUp,
  faHourglassHalf,
  faPalette,
  faExternalLinkAlt,
  faServer,
  faAngleDown,
  faAngleUp,
  faArrowLeft,
  faInfoCircle,
  faArrowRight,
  faSignInAlt,
  faTachometerAlt,
  faTimes,
  faIdCard,
  faCaretSquareUp,
  faCaretSquareDown,
  faCaretUp,
  faCaretDown,
  faEdit,
  faPlus,
  faExclamationTriangle,
  faCaretRight,
  faAngleLeft,
  faAngleRight,
  faFilter,
  faSearch,
  faCalendarAlt,
  faSitemap,
  faClock,
  faTable,
  faSyncAlt,
  faPlay,
  faPause,
  faSun,
  faMoon,
  faWrench,
  faTools,
  faBurn,
  faWarehouse,
  faChartArea,
  faQuestionCircle,
  faDownload,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons'

export default function registerIcons() {
  library.add(
    faTimesCircle,
    faArrowAltCircleRight,
    faChartPie,
    faBook,
    faBars,
    faWindowClose,
    faStream,
    faHardHat,
    faGlobeAmericas,
    faIndustry,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faChevronDown,
    faChevronUp,
    faHourglassHalf,
    faPalette,
    faExternalLinkAlt,
    faServer,
    faAngleDown,
    faAngleUp,
    faArrowLeft,
    faInfoCircle,
    faArrowRight,
    faSignInAlt,
    faTachometerAlt,
    faTimes,
    faIdCard,
    faCaretSquareUp,
    faCaretSquareDown,
    faCaretUp,
    faCaretDown,
    faEdit,
    faPlus,
    faExclamationTriangle,
    faCaretRight,
    faAngleLeft,
    faAngleRight,
    faFilter,
    faSearch,
    faCalendarAlt,
    faSitemap,
    faClock,
    faTable,
    faSyncAlt,
    faPlay,
    faPause,
    faSun,
    faMoon,
    faWrench,
    faTools,
    faBurn,
    faWarehouse,
    faChartArea,
    faQuestionCircle,
    faDownload,
    faExclamationCircle,
  )
}
