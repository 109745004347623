const timezones = [
  {
    Moment_TZ_Name: 'US/Hawaii',
    TZ_Index: 2,
    TZ_Name: 'Hawaiian Standard Time',
    TZ_Description: '(GMT-10:00) US/HAWAII',
    Offset: -10.0,
    Display: 'HST',
    DisplayDST: 'HDT',
  },
  {
    Moment_TZ_Name: 'US/Alaska',
    TZ_Index: 3,
    TZ_Name: 'Alaskan Standard Time',
    TZ_Description: '(GMT-09:00) US/ALASKA',
    Offset: -9.0,
    Display: 'AKST',
    DisplayDST: 'AKDT',
  },
  {
    Moment_TZ_Name: 'US/Pacific',
    TZ_Index: 4,
    TZ_Name: 'Pacific Standard Time',
    TZ_Description: '(GMT-08:00) US/PACIFIC TIME (PST)',
    Offset: -8.0,
    Display: 'PST',
    DisplayDST: 'PDT',
  },
  {
    Moment_TZ_Name: 'US/Mountain',
    TZ_Index: 10,
    TZ_Name: 'Mountain Standard Time',
    TZ_Description: '(GMT-07:00) US/MOUNTAIN TIME (MST)',
    Offset: -7.0,
    Display: 'MST',
    DisplayDST: 'MDT',
  },
  {
    Moment_TZ_Name: 'US/Arizona',
    TZ_Index: 15,
    TZ_Name: 'US Mountain Standard Time',
    TZ_Description: '(GMT-07:00) US/ARIZONA',
    Offset: -7.0,
    Display: 'MST',
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: 'Canada/Saskatchewan',
    TZ_Index: 25,
    TZ_Name: 'Canada Central Standard Time',
    TZ_Description: '(GMT-06:00) Saskatchewan - Canada',
    Offset: -7.0,
    Display: 'CST',
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: 'US/Central',
    TZ_Index: 20,
    TZ_Name: 'Central Standard Time',
    TZ_Description: '(GMT-06:00) US/CENTRAL TIME (CST)',
    Offset: -6.0,
    Display: 'CST',
    DisplayDST: 'CDT',
  },
  {
    Moment_TZ_Name: 'America/Mexico_City',
    TZ_Index: 30,
    TZ_Name: 'Central Standard Time (Mexico)',
    TZ_Description: '(GMT-06:00) Guadalajara, Mexico City, Monterrey',
    Offset: -6.0,
    Display: 'CST',
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: 'US/Eastern',
    TZ_Index: 35,
    TZ_Name: 'Eastern Standard Time',
    TZ_Description: '(GMT-05:00) US/EASTERN TIME (EST)',
    Offset: -5.0,
    Display: 'EST',
    DisplayDST: 'EDT',
  },
  {
    Moment_TZ_Name: 'US/East-Indiana',
    TZ_Index: 40,
    TZ_Name: 'US Eastern Standard Time',
    TZ_Description: '(GMT-05:00) US/INDIANA',
    Offset: -5.0,
    Display: 'EST',
    DisplayDST: 'EDT',
  },
  {
    Moment_TZ_Name: 'Canada/Atlantic',
    TZ_Index: 50,
    TZ_Name: 'Atlantic Standard Time',
    TZ_Description: '(GMT-04:00) Atlantic Time - Canada',
    Offset: -4.0,
    Display: 'AST',
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: 'Canada/Newfoundland',
    TZ_Index: 60,
    TZ_Name: 'Newfoundland and Labrador Standard Time',
    TZ_Description: '(GMT-03:30) Newfoundland and Labrador - Canada',
    Offset: -3.5,
    Display: 'NDT',
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: 'America/Sao_Paulo',
    TZ_Index: 65,
    TZ_Name: 'E. South America Standard Time',
    TZ_Description: '(GMT-03:00) Brasilia',
    Offset: -3.0,
    Display: 'BRT',
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: 'Europe/London',
    TZ_Index: 85,
    TZ_Name: 'London Standard Time',
    TZ_Description: '(GMT+00:00) Dublin, Edinburgh, Lisbon, London',
    Offset: 0.0,
    Display: 'BST',
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: 'UTC',
    TZ_Index: 90,
    TZ_Name: 'UTC Standard Time',
    TZ_Description: '(GMT+00:00) UTC',
    Offset: 0.0,
    Display: 'GMT',
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: 'Europe/Budapest',
    TZ_Index: 95,
    TZ_Name: 'Central Europe Standard Time',
    TZ_Description:
      '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    Offset: 1.0,
    Display: 'CET',
    DisplayDST: 'CEST',
  },
  {
    Moment_TZ_Name: 'Europe/Warsaw',
    TZ_Index: 100,
    TZ_Name: 'Central European Standard Time',
    TZ_Description: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    Offset: 1.0,
    Display: 'CET',
    DisplayDST: 'CEST',
  },
  {
    Moment_TZ_Name: 'Europe/Paris',
    TZ_Index: 105,
    TZ_Name: 'Romance Standard Time',
    TZ_Description: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
    Offset: 1.0,
    Display: 'CET',
    DisplayDST: 'CEST',
  },
  {
    Moment_TZ_Name: 'Europe/Berlin',
    TZ_Index: 110,
    TZ_Name: 'W. Europe Standard Time',
    TZ_Description:
      '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    Offset: 1.0,
    Display: 'CET',
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: 'Europe/Bucharest',
    TZ_Index: 115,
    TZ_Name: 'E. Europe Standard Time',
    TZ_Description: '(GMT+02:00) Bucharest',
    Offset: 2.0,
    Display: 'EET',
    DisplayDST: 'EEST',
  },
  {
    Moment_TZ_Name: null,
    TZ_Index: 120,
    TZ_Name: 'Egypt Standard Time',
    TZ_Description: '(GMT+02:00) Cairo',
    Offset: 2.0,
    Display: 'EET',
    DisplayDST: 'EEST',
  },
  {
    Moment_TZ_Name: 'Europe/Athens',
    TZ_Index: 130,
    TZ_Name: 'GTB Standard Time',
    TZ_Description: '(GMT+02:00) Athens, Istanbul, Minsk',
    Offset: 2.0,
    Display: 'EET',
    DisplayDST: 'EEST',
  },
  {
    Moment_TZ_Name: 'Asia/Jerusalem',
    TZ_Index: 135,
    TZ_Name: 'Israel Standard Time',
    TZ_Description: '(GMT+02:00) Jerusalem',
    Offset: 2.0,
    Display: 'IST',
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: 'Europe/Moscow',
    TZ_Index: 145,
    TZ_Name: 'Russian Standard Time',
    TZ_Description: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
    Offset: 3.0,
    Display: 'MSK',
    DisplayDST: 'MSD',
  },
  {
    Moment_TZ_Name: 'Asia/Riyadh',
    TZ_Index: 150,
    TZ_Name: 'Arab Standard Time',
    TZ_Description: '(GMT+03:00) Kuwait, Riyadh',
    Offset: 3.0,
    Display: 'AST',
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: 'Asia/Baghdad',
    TZ_Index: 158,
    TZ_Name: 'Arabic Standard Time',
    TZ_Description: '(GMT+03:00) Baghdad',
    Offset: 3.0,
    Display: 'AST',
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: null,
    TZ_Index: 165,
    TZ_Name: 'Arabian Standard Time',
    TZ_Description: '(GMT+04:00) Abu Dhabi, Muscat',
    Offset: 4.0,
    Display: 'AST',
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: 'Asia/Kabul',
    TZ_Index: 175,
    TZ_Name: 'Afghanistan Standard Time',
    TZ_Description: '(GMT+04:30) Kabul',
    Offset: 4.5,
    Display: 'AFT',
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: null,
    TZ_Index: 185,
    TZ_Name: 'West Asia Standard Time',
    TZ_Description: '(GMT+05:00) Islamabad, Karachi, Tashkent',
    Offset: 5.0,
    Display: null,
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: 'Asia/Kolkata',
    TZ_Index: 190,
    TZ_Name: 'India Standard Time',
    TZ_Description: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    Offset: 5.5,
    Display: 'IST',
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: null,
    TZ_Index: 195,
    TZ_Name: 'Central Asia Standard Time',
    TZ_Description: '(GMT+06:00) Astana, Dhaka',
    Offset: 6.0,
    Display: null,
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: 'Asia/Bangkok',
    TZ_Index: 205,
    TZ_Name: 'SE Asia Standard Time',
    TZ_Description: '(GMT+07:00) Bangkok, Hanoi, Jakarta',
    Offset: 7.0,
    Display: 'ICT',
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: 'Asia/Shanghai',
    TZ_Index: 210,
    TZ_Name: 'China Standard Time',
    TZ_Description: '(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi',
    Offset: 8.0,
    Display: 'CST',
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: 'Asia/Seoul',
    TZ_Index: 230,
    TZ_Name: 'Korea Standard Time',
    TZ_Description: '(GMT+09:00) Seoul',
    Offset: 9.0,
    Display: 'KST',
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: 'Asia/Tokyo',
    TZ_Index: 235,
    TZ_Name: 'Tokyo Standard Time',
    TZ_Description: '(GMT+09:00) Osaka, Sapporo, Tokyo',
    Offset: 9.0,
    Display: 'JST',
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: 'Australia/Sydney',
    TZ_Index: 255,
    TZ_Name: 'AUS Eastern Standard Time',
    TZ_Description: '(GMT+10:00) Canberra, Melbourne, Sydney',
    Offset: 10.0,
    Display: 'AET',
    DisplayDST: 'AEST',
  },
  {
    Moment_TZ_Name: 'Australia/Darwin',
    TZ_Index: 245,
    TZ_Name: 'Australia/Darwin',
    TZ_Description: '(GMT+9:30) Australia/Darwin Northern Territory',
    Offset: 9.5,
    Display: 'ACT',
    DisplayDST: 'ACST',
  },
  {
    Moment_TZ_Name: 'Australia/Adelaide',
    TZ_Index: 250,
    TZ_Name: 'Australia/Adelaide',
    TZ_Description: '(GMT+9:30) Australia/Adelaide South Australia',
    Offset: 9.5,
    Display: null,
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: 'Australia/Brisbane',
    TZ_Index: 260,
    TZ_Name: 'Australia/Brisbane',
    TZ_Description: '(GMT+10:00) Australia/Brisbane',
    Offset: 10.0,
    Display: null,
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: 'Australia/Hobart',
    TZ_Index: 265,
    TZ_Name: 'Australia/Hobart',
    TZ_Description: '(GMT+10:00) Australia/Hobart Tasmania',
    Offset: 10.0,
    Display: null,
    DisplayDST: null,
  },
  {
    Moment_TZ_Name: 'Australia/Perth',
    TZ_Index: 225,
    TZ_Name: 'Australia/Perth',
    TZ_Description: '(GMT+8:00) Australia/Perth Western Australia',
    Offset: 8.0,
    Display: null,
    DisplayDST: null,
  },
]

export default timezones
