import axios from 'axios'
import {Spinner, Select, Row} from 'common/components'
import * as React from 'react'
import styled from 'styled-components/macro'
import Swal from 'sweetalert2'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 16px;
`

const Header = styled.div`
  display: flex;
  min-width: 250px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
`

const SelectionRow = styled(Row)`
  width: 100%;
  gap: 20px;
  select {
    width: max-content;
  }
`
const SurfSightLiveFeed = ({idDevice, setAssetLabel}) => {
  // const playerRef = React.useRef()
  const [authConfig, setAuthConfig] = React.useState()
  const [selectedChannel, setSelectedChannel] = React.useState(1)
  const [channels, setChannels] = React.useState([])
  const [cameras, setCameras] = React.useState([])
  const [selectedCamera, setSelectedCamera] = React.useState(null)

  React.useEffect(() => {
    const script = document.createElement('script')
    script.type = 'module'
    script.src = '/lytx/build/cloud-ui-components.esm.js'
    document.head.appendChild(script)
    return () => {
      document.head.removeChild(script)
    }
  }, [])

  React.useEffect(() => {
    const getCameras = async () => {
      try {
        const {data} = await axios.get('/camera/assetlist/online')
        const cameraList = data
          ?.map((item, index) => {
            return {
              value: item.idDevice,
              label: item.Asset_Label,
              index: index,
              idDevice_Type: item.idDevice_Type,
              asset: item,
              idDevice: item.idDevice,
            }
          })
          .filter(item => item.idDevice_Type === 35)

        const currentCamera = cameraList.find(
          camera => camera.idDevice === idDevice,
        )
        if (currentCamera) {
          setSelectedCamera(currentCamera)
        }

        setCameras(cameraList)
      } catch (error) {
        Swal.fire(
          'Error',
          'There was an error retrieving the cameras. Please check your connection and try again.',
          'error',
        )
      }
    }
    getCameras()
  }, [idDevice])

  React.useEffect(() => {
    const getAuth = async () => {
      try {
        const newConfig = await axios.get(
          `/camera/surfsight/authconfig/${
            selectedCamera?.idDevice || idDevice
          }`,
        )
        if (newConfig?.data) {
          setAuthConfig(newConfig.data)
        }
      } catch (error) {
        console.error(error)
      }
    }
    getAuth()
  }, [idDevice, selectedCamera])

  React.useEffect(() => {
    if (authConfig) {
      const surfsightAxios = axios.create({
        baseURL: 'https://api-prod.surfsight.net/v2/',
        headers: {
          Authorization: `Bearer ${authConfig.authToken}`,
        },
      })
      const getChannels = async () => {
        try {
          const channels = await surfsightAxios.get(
            `devices/${authConfig.imei}/cameras`,
          )
          if (channels?.data) {
            setChannels(channels?.data.data)
          }
        } catch (error) {
          console.error(error)
        }
      }
      getChannels()
    }
  }, [authConfig])

  // React.useEffect(() => {
  //   const element = playerRef.current
  //   const onComponentErrorHandler = event => {
  //     console.error(event)
  //   }
  //   if (element) {
  //     element.addEventListener('componentError', onComponentErrorHandler)
  //   }
  //   return () => {
  //     if (element) {
  //       element.removeEventListener('componentError', onComponentErrorHandler)
  //     }
  //   }
  // }, [playerRef])
  return !authConfig ? (
    <Spinner type="partial" />
  ) : (
    <Container>
      <lytx-live-video
        style={{width: '100%'}}
        auth-token={authConfig.authToken}
        organization-id={authConfig.idOrganization}
        imei={authConfig.imei}
        camera-id={selectedChannel}
        device-name={false}
        close-player={false}
      />
      <Header>
        <SelectionRow>
          <Select
            placeholder="-- Select Asset --"
            value={selectedCamera?.idDevice || idDevice}
            onChange={e => {
              const foundCamera = cameras.find(
                camera => camera.value === Number(e.currentTarget.value),
              )
              if (foundCamera) {
                setAssetLabel(foundCamera.label)
                setSelectedCamera(foundCamera)
                setSelectedChannel(1)
              }
            }}
          >
            {cameras.map(camera => {
              return (
                <option value={camera.value} key={camera.value}>
                  {camera.label}
                </option>
              )
            })}
          </Select>
          <Select
            value={selectedChannel}
            onChange={e => {
              const selectedChannel = e.currentTarget.value
              setSelectedChannel(Number(selectedChannel))
            }}
          >
            {channels.map(channel => {
              return (
                <option value={channel.cameraId} key={channel.cameraId}>
                  {channel.cameraId}
                </option>
              )
            })}
          </Select>
        </SelectionRow>
      </Header>
    </Container>
  )
}
export default SurfSightLiveFeed
