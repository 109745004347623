import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

export interface AssetsForDropdowns {
  idAsset: number
  Asset_Label: string
  idDevice?: number
  idDevice_Type?: number
}

const getAssets = async (useDriverList: boolean) => {
  if (useDriverList) {
    const {data}: {data: AssetsForDropdowns[]} = await Axios.get(
      'assets?a=getassetsfordriverassignment',
    )
    return data
  } else {
    const {data}: {data: AssetsForDropdowns[]} = await Axios.get(
      '/assets?a=getassetsforhtmlforms',
    )
    return data
  }
}

export default function useAssets({useDriverList} = {useDriverList: false}) {
  const user = useStore(state => state.user)
  return useQuery(['assetsForDropdown'], () => getAssets(useDriverList), {
    enabled: Boolean(user?.token),
    refetchOnWindowFocus: false,
  })
}
