import {ArrowLeft} from 'phosphor-react'
import styled from 'styled-components/macro'
import LocationsDropdown from '../../common/LocationsDropdown'
import {
  Column,
  MultiSelect,
  Row as BasicRow,
  DateRangePicker,
} from 'common/components'
import {Dropdown} from 'semantic-ui-react'
import SearchContainer from 'opening/SearchContainer'
import {QUERIES} from 'common/constants'
import {RouteAttributeType} from 'routingAndDispatch/hooks/useRouteAttributes'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
export const StyledDropdown = styled(Dropdown)`
  &&& {
    width: 330px;
  }
  &&& :focus {
    border-color: var(--asc-coolgray) !important;
  }
  > .visible.menu {
    max-height: 200px;
    border: var(--asc-coolgray);
  }
  &&& .ui.selection.active.dropdown {
    border-color: var(--asc-coolgray);
  }
`

export const Title = styled.span`
  font-weight: 700;
  font-size: 24px;
`
export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  width: 100%;
`
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  line-height: 100%;
  svg {
    cursor: pointer;
  }
`
export const Content = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

export const ContentContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: auto;
  overflow-y: hidden;
  flex-grow: 1;
`

export const Card = styled.div`
  background-color: #ffffff;
  border: solid 1px var(--asc-coolgray);
  border-radius: 20px;

  display: grid;
  min-width: fit-content;
  #styles {
    border-radius: 0px 0px 20px 20px;
  }
`
export const ShiftCard = styled.div`
  background-color: #ffffff;
  border: solid 1px var(--asc-coolgray);
  border-radius: 20px !important;
  min-width: fit-content;
  width: 80%;
  .header {
    border-radius: 20px 20px 0px 0px;
  }
  .table-container {
    border-radius: 20px;
  }
`
export const Row = styled.div`
  display: grid;
  grid-template-columns: 165px auto;
  border-top: solid 1px var(--asc-coolgray);
  padding: 0 25px;
  flex-wrap: wrap;
  height: 80px;
  & :first-of-type {
    border-top: none;
  }
`
export const StyledArrowLeft = styled(ArrowLeft)`
  cursor: pointer;
`

export const SettingName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: solid 1px var(--asc-coolgray);
  gap: 10px;
`
export const SettingField = styled.div`
  padding-left: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`
export const InfoPanel = styled.div`
  min-width: 350px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-left: solid 1px var(--asc-coolgray);
  overflow-y: auto;
`
export const PanelTitle = styled.span`
  font-weight: 600;
  font-size: 16px;
`
type ImageRowProps = {
  setMin?: boolean
}

export const ImageRow = styled.div<ImageRowProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap: 20px;
  svg {
    min-height: ${({setMin}) => (setMin ? '80px' : 'auto')};
    min-width: ${({setMin}) => (setMin ? '80px' : 'auto')};
  }
`
export const ImageContainer = styled.div`
  width: 50px;
  height: 50px;
`
export const PanelTopSection = styled.div`
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px var(--asc-coolgray);
  padding: 20px;
`
export const PanelList = styled.div``

export const PanelListPlaceHolder = styled.div`
  flex-grow: 1;
  max-height: 350px;
`
export const PanelRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 1px var(--asc-coolgray);
  padding: 15px;
  strong {
    white-space: nowrap;
  }
  & :nth-child(even) {
    background-color: var(--asc-cultured);
  }
`
export const BoldTitle = styled.span`
  font-weight: 700;
  font-size: 18px;
`
type ImageContentProps = {
  height?: string
  width?: string
}
export const ImageContent = styled.img<ImageContentProps>`
  display: block;
  height: ${({height}) => (height ? height : '80px')};
  width: ${({width}) => (width ? width : '80px')};
  border-radius: 50%;
  aspect-ratio: 1;
  object-fit: cover;
`
type StyledProps = {
  hasAsset: boolean
}
export const AssignedVehiclePanel = styled.div<StyledProps>`
  padding: 15px;
  border-bottom: ${({hasAsset}) => hasAsset && 'solid 1px var(--asc-coolgray)'};
`
export const AssetImage = styled.img`
  max-height: 130px;
  max-width: 220px;
  margin-top: 15px;
`
export const AssetImageDiv = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Wrapper = styled.div`
  padding: 20px 30px;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const AssignAssetButton = styled.div`
  border: solid 1px var(--primary);
  border-radius: 5px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  padding: 10px;
  margin: 15px 20px 10px 20px;
  letter-spacing: 0.5px;
  cursor: pointer;
  :hover {
    background-color: var(--asc-cultured);
  }
`

export const StyledLocationsDropdown = styled(LocationsDropdown)`
  width: 330px;
`
export const DropdownOption = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  svg {
    min-height: 20px;
    min-width: 20px;
    color: var(--asc-sonicsilver);
  }
`
export const AddRouteLocation = styled.div`
  color: var(--primary);
  font-weight: 700;
  cursor: pointer;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  align-items: center;
  white-space: nowrap;
  svg {
    min-width: 20px;
    min-height: 20px;
  }
  :hover {
    color: var(--secondary);
  }
`
export const StyledMultiSelect = styled(MultiSelect<RouteAttributeType>)`
  width: 330px;
  margin: 0px;
  div {
    font-weight: bold;
  }
`
export const Table = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 10px;
  overflow-y: auto;
`
export const DriverInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-start;
  height: 100%;
  padding: 20px 0;
`
export const NameColumn = styled.div`
  display: flex;
  flex-direction: column;
`
export const Name = styled.span`
  color: var(--primary);
  font-weight: 700;
  font-size: 16px;
`

export const PillWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 5px;
  max-height: 60px;
  overflow-y: auto; /* Use "auto" instead of "scroll" */

  /* Hide the scrollbar track (background) when there is no overflow */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Define the appearance of the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #888; /* You can adjust the color to match your UI */
    border-radius: 5px;
  }
`

export const AttributePill = styled.div`
  padding: 1px 20px;
  border-radius: 200px;
  background-color: var(--asc-gold);
  border: solid 1px var(--asc-gold);
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
`
export const IconRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  svg {
    min-height: 20px;
    min-width: 20px;
    color: var(--asc-sonicsilver);
  }
`

export const LightText = styled.span`
  color: var(--asc-sonicsilver);
  font-weight: 400;
  font-size: 14px;
`

export const StatusText = styled.span`
  color: var(--asc-sonicsilver);
  justify-self: right;
`

export const StyledSearchContainer = styled(SearchContainer)`
  min-width: 250px;
`
export const CardSection = styled.div`
  width: ${({width}: {width: string}) => width};
`
export const LinkedFlag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--asc-moss);
  gap: 5px;
  white-space: nowrap;
  svg {
    min-height: 16px;
    min-width: 16px;
  }
`

export const AddressInfo = styled(Column)`
  font-size: 13px;
`
export const ShiftRow = styled(BasicRow)`
  justify-content: space-between;
  gap: 35px;
  .field {
    width: 100%;
  }
  label {
    margin-bottom: 5px;
  }
`
export const ModalBody = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  #start-hour {
    border: 1px solid var(--asc-platinum) !important;
    border-right: none !important;
    :focus {
      border: 1px solid var(--secondary) !important;
      outline: 1px solid var(--asc-keylime) !important;
    }
  }
  #start-minute {
    border: 1px solid var(--asc-platinum) !important;
    border-left: none !important;
    :focus {
      border: 1px solid var(--secondary) !important;
      outline: 1px solid var(--asc-keylime) !important;
    }
  }
`
export const StyledDateRangePicker = styled(DateRangePicker)`
  &&& {
    #date-range-input-container {
      position: relative;

      @media ${QUERIES.tabletAndUp} {
        position: static !important;
      }
    }
    #date-range-picker-container {
      position: fixed;
      top: auto;
      left: auto;
    }
    #top-row {
      position: relative !important;
    }
  }
`
export const DatePickerGroup = styled(BasicRow)`
  #x-circle {
    overflow: initial !important;
    height: 100%;
    margin-top: 10px;
    cursor: pointer;
    :hover {
      fill: var(--secondary);
      color: var(--secondary);
    }
  }
`
export const ErrorMessage = styled.div`
  font-size: 13px;
  color: var(--asc-vermilion);
  font-weight: bold;
  margin: 5px 0px;
`
export const TimeToggleButtonPlaceHolder = styled.div`
  width: 50px;
  height: 40px;
  margin-left: 40px;
`
export const ShiftPickerRow = styled(BasicRow)`
  white-space: nowrap;
`

export const BreakCheckboxSection = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`
