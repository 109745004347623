import * as React from 'react'
import {
  Container,
  Header,
  NotificationsList,
} from './customerNotificationsStyles'
import NotificationCard from './NotificationCard'
import useCustomerNotications from './hooks/useCustomerNotifications'
import {Spinner} from 'common/components'

const CustomerNotifications = () => {
  const {data: customerNotifications, status} = useCustomerNotications()
  return (
    <>
      <Container>
        <Header>Customer Notifications</Header>
        <NotificationsList>
          {status === 'loading' && <Spinner />}
          {status === 'success' &&
            customerNotifications.map(notification => {
              return (
                <NotificationCard
                  key={notification.idRDEtaDefaultTemplate}
                  idRDEtaDefaultTemplate={notification.idRDEtaDefaultTemplate}
                  NotificationName={notification.NotificationName}
                  Enabled={notification.Enabled}
                  Description={notification.Description}
                />
              )
            })}
        </NotificationsList>
      </Container>
    </>
  )
}
export default CustomerNotifications
