import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'
import {SchemaType, ThresholdType} from 'account/customizeViews/SubmitPanel'

const getGridSchema = async () => {
  const getSchema: Promise<{
    data: SchemaType[]
  }> = Axios.get(`/schema?schematype=grid`)

  const getThreshold: Promise<{
    data: ThresholdType[]
  }> = Axios.get(`/customerthreshold`)
  const responses = await Promise.all([getSchema, getThreshold])
  let data: SchemaType[] = []
  if (responses[0]?.data && responses[1]?.data) {
    const schema = responses[0]
    const threshold = responses[1]
    schema.data.forEach(s => {
      let newItem = {...s}
      const tIndex = threshold.data.findIndex(
        t => t.idCustomerField === s.idCustomerField,
      )
      if (tIndex > -1) {
        newItem.threshold = threshold.data[tIndex]
      }
      data.push(newItem)
    })
  }
  return data
}

const useGridSchema = () => {
  const user = useStore(state => state.user)
  return useQuery(['gridSchema'], () => getGridSchema(), {
    enabled: Boolean(user.token),
    keepPreviousData: true,
  })
}

export default useGridSchema
