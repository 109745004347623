import {RequiredAsterisk} from 'common/components'
import * as React from 'react'
import styled from 'styled-components/macro'
import {Timer} from 'phosphor-react'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  border: solid 1px var(--asc-graphite);
  border-radius: 5px;
  padding: 5px 10px;
  align-items: center;
  width: fit-content;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  svg {
    margin-right: 5px;
  }
`
const StyledInput = styled.input`
  border: none !important;
  outline: none;
  width: 26px;
  height: 38px;
  line-height: 38px;
  :focus {
    box-shadow: none !important;
  }
`
const LabelText = styled.span`
  font-size: 13px;
  font-weight: 700;
  line-height: 38px;
  color: var(--asc-sonicsilver);
  cursor: default;
`
const Divider = styled.span`
  padding: 0 5px;
  line-height: 38px;
  font-size: 16px;
  font-weight: 700;
  color: var(--asc-sonicsilver);
`
const StyledLabel = styled.label`
  display: block;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  display: flex;
  margin-bottom: 5px;
`
interface Props {
  value: {hrs: number; mins: number} | undefined
  onHourChange: (hour: number) => void
  onMinuteChange: (minute: number) => void
  label?: string
  required?: boolean
  className?: string
  disabled?: boolean
}

const DurationInput: React.FC<Props> = ({
  value,
  onHourChange,
  onMinuteChange,
  label = '',
  required = false,
  className = '',
  disabled = false,
}) => {
  const handleHourChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(event.target.value))) return
    return onHourChange(Number(event.target.value))
  }
  const handleMinuteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(event.target.value))) return
    if (Number(event.target.value) === 60) {
      return onHourChange(1), onMinuteChange(0)
    }
    return onMinuteChange(Number(event.target.value))
  }
  const handleHourBlur = () => {
    let hour = value?.hrs || 0
    if (hour < 0) hour = 0
    if (hour === 0 && value?.mins === 0) {
      onHourChange(hour)
      onMinuteChange(1)
      return
    }
    if (hour > 99) hour = 99
    return onHourChange(hour)
  }
  const handleMinuteBlur = () => {
    let minute = value?.mins || 0
    if (minute < 0) minute = 0
    if (value?.hrs === 0 && minute === 0) {
      return onMinuteChange(1)
    }
    if (minute > 59) minute = 59
    return onMinuteChange(minute)
  }

  return (
    <div className={className}>
      {label && (
        <StyledLabel htmlFor={'duration-input'}>
          {label}
          {required && <RequiredAsterisk />}
        </StyledLabel>
      )}
      <Container id={'duration-input'} data-cy={'duration-input'}>
        <Timer />
        <StyledInput
          data-cy={'hour-input'}
          type="text"
          value={value?.hrs?.toString().padStart(2, '0') || '0'}
          onChange={handleHourChange}
          onBlur={handleHourBlur}
          disabled={disabled}
        />
        <LabelText>hr</LabelText>
        <Divider> : </Divider>
        <StyledInput
          data-cy={'minute-input'}
          type="text"
          value={value?.mins?.toString().padStart(2, '0') || '0'}
          onChange={handleMinuteChange}
          onBlur={handleMinuteBlur}
          disabled={disabled}
        />
        <LabelText>min</LabelText>
      </Container>
    </div>
  )
}
export default DurationInput
