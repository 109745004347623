import {cloneDeep} from 'lodash'

export const addAssetsToHierarchy = ({level2Customers, assets}) => {
  const l1Unassigned = assets.filter(asset => asset.HierarchyLevel === 1)
  const l1UnassignedAssetIDs = l1Unassigned.map(l => l.idAsset)
  const clonedL2 = cloneDeep(level2Customers)
  const hierarchyCustomers = {
    unassignedAssetIDs: l1UnassignedAssetIDs || [],
    checked: false,
    visible: true,
    CustomerList: clonedL2,
    unassignedVisible: true,
  }
  hierarchyCustomers?.CustomerList?.map(l2 => {
    l2.allAssetIDs = []
    l2.unassignedAssetIDs = []
    l2.checked = false
    l2.expanded = false
    l2.visible = true
    l2.unassignedChecked = false
    l2.unassignedVisible = true
    const l2HasChildren = l2.CustomerList && l2.CustomerList.length > 0
    assets.forEach(asset => {
      if (asset[`Hier_Level${l2.HierarchyLevel}_Id`] === l2.idCustomer) {
        l2.allAssetIDs.push(asset.idAsset)
        if (asset.HierarchyLevel === 2 && l2HasChildren) {
          l2.unassignedAssetIDs.push(asset.idAsset)
        }
      }
    })
    if (l2HasChildren) {
      l2.CustomerList.map(l3 => {
        l3.allAssetIDs = []
        l3.unassignedAssetIDs = []
        l3.unassignedChecked = false
        l3.checked = false
        l3.expanded = false
        l3.visible = true
        l3.unassignedVisible = true
        const l3HasChildren = l3.CustomerList && l3.CustomerList.length > 0
        assets.forEach(asset => {
          if (asset[`Hier_Level${l3.HierarchyLevel}_Id`] === l3.idCustomer) {
            l3.allAssetIDs.push(asset.idAsset)
            if (asset.HierarchyLevel === 3 && l3HasChildren) {
              l3.unassignedAssetIDs.push(asset.idAsset)
            }
          }
        })
        if (l3HasChildren) {
          l3.CustomerList.map(l4 => {
            l4.allAssetIDs = []
            l4.unassignedAssetIDs = []
            l4.checked = false
            l4.expanded = false
            l4.unassignedChecked = false
            l4.visible = true
            l4.unassignedVisible = true
            const l4HasChildren = l4.CustomerList && l4.CustomerList.length > 0
            assets.forEach(asset => {
              if (
                asset[`Hier_Level${l4.HierarchyLevel}_Id`] === l4.idCustomer
              ) {
                l4.allAssetIDs.push(asset.idAsset)
                if (asset.HierarchyLevel === 4) {
                  l4.unassignedAssetIDs.push(asset.idAsset)
                }
              }
            })
            if (l4HasChildren) {
              l4.CustomerList.map(l5 => {
                l5.allAssetIDs = []
                l5.checked = false
                l5.expanded = false
                l5.visible = true
                assets.forEach(asset => {
                  if (
                    asset[`Hier_Level${l5.HierarchyLevel}_Id`] === l5.idCustomer
                  ) {
                    l5.allAssetIDs.push(asset.idAsset)
                  }
                })
                return l5
              })
            }
            return l4
          })
        }
        return l3
      })
    }
    return l2
  })
  return hierarchyCustomers
}

export const getSelectedAssets = hierarchyCustomers => {
  const selectedAssets = []
  const clonedhierarchyCustomers = cloneDeep(hierarchyCustomers)
  if (clonedhierarchyCustomers) {
    if (clonedhierarchyCustomers.checked) {
      selectedAssets.push(...clonedhierarchyCustomers.unassignedAssetIDs)
    }
    clonedhierarchyCustomers.CustomerList.forEach(l2 => {
      if (l2.checked) {
        selectedAssets.push(...l2.allAssetIDs)
      } else {
        if (l2.unassignedChecked) {
          selectedAssets.push(...l2.unassignedAssetIDs)
        }
        if (l2.CustomerList) {
          l2.CustomerList.forEach(l3 => {
            if (l3.checked) {
              selectedAssets.push(...l3.allAssetIDs)
            } else {
              if (l3.unassignedChecked) {
                selectedAssets.push(...l3.unassignedAssetIDs)
              }
              if (l3.CustomerList) {
                l3.CustomerList.forEach(l4 => {
                  if (l4.checked) {
                    selectedAssets.push(...l4.allAssetIDs)
                  } else {
                    if (l4.unassignedChecked) {
                      selectedAssets.push(...l4.unassignedAssetIDs)
                    }
                    if (l4.CustomerList) {
                      l4.CustomerList.forEach(l5 => {
                        if (l5.checked) {
                          selectedAssets.push(...l5.allAssetIDs)
                        }
                      })
                    }
                  }
                })
              }
            }
          })
        }
      }
    })
  }
  return selectedAssets
}

export const onCheckedCalculation = (
  idCustomer,
  hierarchyCustomers,
  checked,
) => {
  const newHierarchyCustomers = cloneDeep(hierarchyCustomers)
  newHierarchyCustomers.CustomerList.map(l2 => {
    let l2CheckAll = l2.idCustomer === idCustomer
    if (l2CheckAll) {
      l2.checked = !checked
      l2.unassignedChecked = !checked
    }
    if (l2.CustomerList) {
      l2.CustomerList.map(l3 => {
        let l3CheckAll = l3.idCustomer === idCustomer || l2CheckAll
        if (l3CheckAll) {
          l3.checked = !checked
          l3.unassignedChecked = !checked
        }
        if (l3.CustomerList) {
          l3.CustomerList.map(l4 => {
            let l4CheckAll = l4.idCustomer === idCustomer || l3CheckAll
            if (l4CheckAll) {
              l4.checked = !checked
              l4.unassignedChecked = !checked
            }
            if (l4.CustomerList) {
              l4.CustomerList.map(l5 => {
                if (l5.idCustomer === idCustomer || l4CheckAll) {
                  l5.checked = !checked
                }
                return l5
              })
            }
            return l4
          })
        }
        return l3
      })
    }
    return l2
  })
  return newHierarchyCustomers
}

export const setUnassignedOrExpandedCheckbox = (
  idCustomer,
  hierarchyCustomers,
  checked,
  type = 'unassigned',
) => {
  const newHierarchyCustomers = cloneDeep(hierarchyCustomers)
  newHierarchyCustomers.CustomerList.map(l2 => {
    if (l2.idCustomer === idCustomer) {
      if (type === 'unassigned') l2.unassignedChecked = !checked
      if (type === 'expanded') l2.expanded = !checked
    } else {
      if (l2.CustomerList) {
        l2.CustomerList.map(l3 => {
          if (l3.idCustomer === idCustomer) {
            if (type === 'unassigned') l3.unassignedChecked = !checked
            if (type === 'expanded') l3.expanded = !checked
          } else {
            if (l3.CustomerList) {
              l3.CustomerList.map(l4 => {
                if (l4.idCustomer === idCustomer) {
                  if (type === 'unassigned') l4.unassignedChecked = !checked
                  if (type === 'expanded') l4.expanded = !checked
                }
                return l4
              })
            }
          }
          return l3
        })
      }
    }
    return l2
  })
  return newHierarchyCustomers
}

export const filterCustomers = ({searchValue, hierarchyCustomers}) => {
  const newHierarchyCustomers = cloneDeep(hierarchyCustomers)
  const unassignedMatch = !searchValue || 'unassigned'.includes(searchValue)
  newHierarchyCustomers.CustomerList.map(l2 => {
    if (searchValue) {
      l2.visible =
        l2.Customer_Name.toLowerCase().includes(searchValue) || !searchValue
    } else {
      l2.visible = true
      l2.expanded = false
    }
    l2.unassignedVisible = unassignedMatch
    if (l2.unassignedAssetIDs.length && unassignedMatch) {
      l2.visible = true
      l2.expanded = true
    }
    if (l2.CustomerList) {
      l2.CustomerList.map(l3 => {
        if (searchValue) {
          l3.visible = l3.Customer_Name.toLowerCase().includes(searchValue)
          if (l3.visible || (l3.unassignedAssetIDs.length && unassignedMatch)) {
            l2.visible = true
            l2.expanded = true
          }
        } else {
          l3.visible = true
          l3.expanded = false
        }
        l3.unassignedVisible = unassignedMatch
        if (l3.unassignedAssetIDs.length && unassignedMatch) {
          l3.visible = true
          l3.expanded = true
        }
        if (l3.CustomerList) {
          l3.CustomerList.map(l4 => {
            if (searchValue) {
              l4.visible = l4.Customer_Name.toLowerCase().includes(searchValue)
              if (
                l4.visible ||
                (l4.unassignedAssetIDs.length && unassignedMatch)
              ) {
                l2.visible = true
                l2.expanded = true
                l3.visible = true
                l3.expanded = true
              }
              l4.unassignedVisible = unassignedMatch
              if (l4.unassignedAssetIDs.length && unassignedMatch) {
                l4.visible = true
                l4.expanded = true
              }
              if (l4.CustomerList) {
                l4.CustomerList.map(l5 => {
                  if (searchValue) {
                    l5.visible =
                      l5.Customer_Name.toLowerCase().includes(searchValue)
                    if (l5.visible) {
                      l4.visible = true
                      l4.expanded = true
                      l3.visible = true
                      l3.expanded = true
                      l2.visible = true
                      l2.expanded = true
                    }
                  } else {
                    l5.visible = true
                    l5.expanded = false
                  }
                  return l5
                })
              }
            } else {
              l4.visible = true
              l4.expanded = false
            }
            return l4
          })
        }
        return l3
      })
    }
    return l2
  })

  return newHierarchyCustomers
}
