import * as React from 'react'
import styled from 'styled-components/macro'
import {cloneDeep} from 'lodash'
import {
  getSelectedAssets,
  onCheckedCalculation,
  setUnassignedOrExpandedCheckbox,
} from './hierarchyHelpers'
import HierarchyLine from './HierarchyLine'

const LevelsWrapper = styled.div`
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
`
const LevelSection = styled.div`
  /* display: ${p => (p.expanded === 'false' ? 'none' : 'block')};
  transition: height 1s ease-in-out; */
`
const Hierarchy = ({
  setAssetFilter,
  clearAssetFilter,
  setHierarchy,
  hierarchyCustomers,
  searchValue,
}) => {
  const onChecked = (idCustomer, checked, unassigned = false) => {
    let newHierarchyCustomers
    if (unassigned) {
      if (idCustomer === 0) {
        newHierarchyCustomers = cloneDeep(hierarchyCustomers)
        newHierarchyCustomers.checked = !checked
      } else {
        newHierarchyCustomers = setUnassignedOrExpandedCheckbox(
          idCustomer,
          hierarchyCustomers,
          checked,
          'unassigned',
        )
      }
    } else {
      newHierarchyCustomers = onCheckedCalculation(
        idCustomer,
        hierarchyCustomers,
        checked,
      )
    }
    setHierarchy(newHierarchyCustomers)

    const selectedAssetIDs = getSelectedAssets(newHierarchyCustomers)
    if (!selectedAssetIDs?.length) {
      clearAssetFilter('hierarchy')
    } else {
      setAssetFilter({filteredAssetIDs: selectedAssetIDs, label: 'Hierarchy'})
    }
  }

  const toggleExpansion = (idCustomer, expanded) => {
    const newHierarchyCustomers = setUnassignedOrExpandedCheckbox(
      idCustomer,
      hierarchyCustomers,
      expanded,
      'expanded',
    )
    setHierarchy(newHierarchyCustomers)
  }

  // React.useEffect(() => {
  //   // if (searchValue) {
  //   const newHierarchy = filterCustomers({
  //     searchValue: searchValue.toLowerCase(),
  //     hierarchyCustomers,
  //   })
  //   setHierarchy(newHierarchy)
  //   // } else {
  //   //   setHierarchy(newHierarchy)
  //   // }
  // }, [hierarchyCustomers, searchValue, setHierarchy])

  if (!hierarchyCustomers) return <></>
  return (
    <LevelsWrapper>
      {hierarchyCustomers.CustomerList?.map(l2 => (
        <LevelSection key={l2.idCustomer}>
          <HierarchyLine
            customer={l2}
            onChecked={onChecked}
            toggleExpansion={toggleExpansion}
            level={2}
            expanded={true}
          />
          <LevelSection>
            {l2.CustomerList &&
              l2.CustomerList.map(l3 => (
                <div key={l3.idCustomer}>
                  <HierarchyLine
                    customer={l3}
                    onChecked={onChecked}
                    toggleExpansion={toggleExpansion}
                    level={3}
                    expanded={l2.expanded}
                  />
                  <LevelSection>
                    {l3.CustomerList &&
                      l3.CustomerList.map(l4 => (
                        <div key={l4.idCustomer}>
                          <HierarchyLine
                            customer={l4}
                            onChecked={onChecked}
                            toggleExpansion={toggleExpansion}
                            level={4}
                            expanded={l3.expanded && l2.expanded}
                          />
                          <LevelSection>
                            {l4.CustomerList &&
                              l4.CustomerList.map(l5 => (
                                <div key={l5.idCustomer}>
                                  <HierarchyLine
                                    customer={l5}
                                    onChecked={onChecked}
                                    toggleExpansion={toggleExpansion}
                                    level={5}
                                    expanded={
                                      l4.expanded && l3.expanded && l2.expanded
                                    }
                                  />
                                </div>
                              ))}
                          </LevelSection>
                          {l4.unassignedAssetIDs?.length &&
                          l4.CustomerList?.length > 0 ? (
                            <HierarchyLine
                              customer={l4}
                              unassignedCount={l4.unassignedAssetIDs.length}
                              onChecked={onChecked}
                              level={5}
                              unassigned={true}
                              expanded={
                                l4.expanded && l3.expanded && l2.expanded
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      ))}
                    {l3.unassignedAssetIDs?.length &&
                    l3.CustomerList?.length > 0 ? (
                      <HierarchyLine
                        customer={l3}
                        unassignedCount={l3.unassignedAssetIDs.length}
                        onChecked={onChecked}
                        level={4}
                        unassigned={true}
                        expanded={l3.expanded && l2.expanded}
                      />
                    ) : (
                      <></>
                    )}
                  </LevelSection>
                </div>
              ))}
            {l2.unassignedAssetIDs?.length && l2.CustomerList?.length > 0 ? (
              <HierarchyLine
                customer={l2}
                unassignedCount={l2.unassignedAssetIDs.length}
                onChecked={onChecked}
                level={3}
                unassigned={true}
                expanded={l2.expanded}
              />
            ) : (
              <></>
            )}
          </LevelSection>
        </LevelSection>
      ))}
      {hierarchyCustomers?.unassignedAssetIDs.length ? (
        <HierarchyLine
          customer={{
            idCustomer: 0,
            unassignedChecked: hierarchyCustomers.checked,
            unassignedVisible:
              !searchValue || 'unassigned'.includes(searchValue),
          }}
          unassignedCount={hierarchyCustomers.unassignedAssetIDs.length}
          onChecked={onChecked}
          level={2}
          unassigned={true}
          expanded={true}
        />
      ) : (
        <></>
      )}
    </LevelsWrapper>
  )
}
export default Hierarchy
