import * as React from 'react'
import styled from 'styled-components/macro'
import {Rows, X, ArrowsOutSimple, ArrowsInSimple} from 'phosphor-react'
import Tooltip from 'common/components/Tooltip'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const IconHolder = styled.span`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 25px;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  flex-direction: column;
  cursor: pointer;
  background-color: ${(props: {active?: boolean}) =>
    props.active ? 'var(--primary)' : 'transparent'};
  svg {
    color: ${props => (props.active ? 'var(--secondary)' : 'var(--primary)')};
    fill: ${props => (props.active ? 'var(--secondary)' : 'var(--primary)')};
  }
  button:hover {
    background: transparent;
  }
  button {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: 25px;
    width: 30px;
    height: 30px;
    background: none !important;
    padding: 0;
    svg {
      fill: var(--primary);
    }
  }
  &&:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`
const IconContainer = styled.div`
  display: flex;
  padding: 7px 0 7px 0;
  background: transparent;
  width: 100%;
  align-self: center;
  text-align: right;
  justify-content: flex-end;
`

type GridTableButtonsProps = {
  showAssetList: () => void
  closeGrid: () => void
  setGridFullscreen: (gridFullScreen: boolean) => void
  gridFullscreen: boolean
}

const GridTableButtons = ({
  showAssetList,
  closeGrid,
  setGridFullscreen,
  gridFullscreen,
}: GridTableButtonsProps) => {
  return (
    <Container>
      <IconContainer>
        <Tooltip
          tooltip={gridFullscreen ? 'Shrink Grid' : 'Expand Grid'}
          position={'top center'}
          trigger={
            <IconHolder
              onClick={() => setGridFullscreen(!gridFullscreen)}
              data-testid={
                gridFullscreen ? 'assetgrid-shrink' : 'assetgrid-expand'
              }
            >
              {gridFullscreen ? (
                <ArrowsInSimple size={19} color={`var(--primary)`} />
              ) : (
                <ArrowsOutSimple size={19} color={`var(--primary)`} />
              )}
            </IconHolder>
          }
        />
        <Tooltip
          tooltip={'Open Asset List'}
          position={'top center'}
          trigger={
            <IconHolder
              onClick={showAssetList}
              data-testid="assetgrid-opencardlist"
            >
              <Rows size={19} color={`var(--primary)`} />
            </IconHolder>
          }
        />
        <Tooltip
          tooltip={'Close Grid'}
          position={'top center'}
          trigger={
            <IconHolder
              onClick={() => {
                setGridFullscreen(false)
                closeGrid()
              }}
              data-testid="assetgrid-close"
            >
              <X size={19} color={'var(--primary)'} />
            </IconHolder>
          }
        />
      </IconContainer>
    </Container>
  )
}
export default GridTableButtons
