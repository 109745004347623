import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

const getSensorData = async (idAsset: number, Sensor_Count: number) => {
  if (idAsset && Sensor_Count > 0) {
    const {data} = await Axios.get(`assets/${idAsset}/sensors`)
    return data
  } else {
    return {}
  }
}

export default function useSensorData(idAsset: number, Sensor_Count: number) {
  const user = useStore(state => state.user)
  return useQuery(
    ['sensorData', idAsset],
    () => getSensorData(idAsset, Sensor_Count),
    {
      enabled: Boolean(user?.token),
    },
  )
}
