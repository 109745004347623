import * as React from 'react'
import {AlertModal, ButtonDropDown, Spinner} from 'common/components'
import {Spacer, StopInfoMenu} from '../routeDetailStyles'
import useOnClickOutside from 'common/components/hooks/useOnClickOutside'
import {CaretDown, CaretUp} from 'phosphor-react'
import axios, {AxiosError} from 'axios'
import {useQueryClient} from '@tanstack/react-query'
import {showToast} from 'common/components/Toastr'
import {reducer} from 'routingAndDispatch/Stops/stopHelper'
import styled from 'styled-components/macro'
import {
  DropdownOption,
  ReducerType,
  StopCardDropdownProps,
  getOptions,
  getStopDate,
  initialState,
} from './stopCardDropdownHelpers'
import usePutTweakSegments from 'routingAndDispatch/RouteEditing/hooks/usePutTweakSegments'

const StyledButtonDropDown = styled(ButtonDropDown)`
  > button {
    background-color: ${({endRoute}: {endRoute: boolean}) =>
      endRoute && '#ffffff'};
    border-radius: 8px;
    & :hover {
      background-color: ${({endRoute}: {endRoute: boolean}) =>
        endRoute && '#ffffff'};
    }
  }
`

const StopCardDropdown: React.FC<StopCardDropdownProps> = ({
  idStop,
  idSegment,
  idPlan,
  stopStatus,
  stop,
  isCurrentStop,
  startRoute = false,
  endRoute = false,
  stopBeforeEndDate,
  refetch,
  planStartDateTime,
  routeDetailDispatch,
  otherStopsOnOrder,
  routeHasStarted = false,
}) => {
  const [state, dispatch]: ReducerType = React.useReducer(reducer, initialState)
  //const userConfig = useStore(state => state.userConfig)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const cache = useQueryClient()
  const {mutateAsync: tweakSegments} = usePutTweakSegments(true)
  const {stopStartDate, stopEndDate} = getStopDate({
    stopBeforeEndDate,
    planStartDateTime,
  })

  const handleDelete = async () => {
    dispatch({
      type: 'setState',
      data: {submitting: true, showDialogModal: false},
    })
    if (otherStopsOnOrder && otherStopsOnOrder.length > 0) {
      const requestBody = [
        {
          idSegment,
          newIdSegment: null,
          idOrder: stop.idOrder || '',
          idStop,
          scheduledEarliestDate: '',
          scheduledLatestDate: '',
        },
      ]
      otherStopsOnOrder.forEach(s => {
        requestBody.push({
          idSegment,
          newIdSegment: null,
          idOrder: s.idOrder || '',
          idStop: s.idStop || '',
          scheduledEarliestDate: '',
          scheduledLatestDate: '',
        })
      })
      await tweakSegments(requestBody)
      cache.invalidateQueries(['routePlan', idPlan])
      showToast('Stops were successfully deleted from this route!', 'success')
      refetch()
      dispatch({
        type: 'setState',
        data: {submitting: false},
      })
    } else {
      await axios
        .delete(`rd/segments/${idSegment}/stops/${idStop}`)
        .then(() => {
          cache.invalidateQueries(['routePlan', idPlan])
          showToast('Stop was successfully deleted from this route!', 'success')
        })
        .catch(err => {
          const error = err as AxiosError
          if (error?.response?.data.Message) {
            const regex = /"message":"([^"]+)"/
            const match = error.response.data.Message.match(regex)
            dispatch({type: 'setState', data: {errorMessage: match[1]}})
          }
          // setErrorMessage(error)
          dispatch({
            type: 'setState',
            data: {
              showDialogModal: true,
              modalType: 'error',
              modalTitle: `Alert!`,
              showDenyButton: false,
              selectedDropdownOption: null,
              modalText: state.errorMessage
                ? state.errorMessage
                : 'If the issue persists, contact customer service',
            },
          })
        })
        .finally(() => {
          refetch()
          dispatch({
            type: 'setState',
            data: {submitting: false, showDialogModal: false},
          })
        })
    }
  }

  const handleArrive = async () => {
    dispatch({type: 'setState', data: {submitting: true}})
    await axios
      .put(`rd/segments/${idSegment}/stops/${idStop}/arrive`)
      .then(() => {
        cache.invalidateQueries(['routePlan', idPlan])
        showToast('Driver arrived successfully!', 'success')
      })
      .catch(err => {
        const error = err as AxiosError
        if (error?.response?.data.Message) {
          const regex = /"message":"([^"]+)"/
          const match = error.response.data.Message.match(regex)
          dispatch({type: 'setState', data: {errorMessage: match[1]}})
        }
        // setErrorMessage(error)
        dispatch({
          type: 'setState',
          data: {
            showDialogModal: true,
            modalType: 'error',
            modalTitle: `Alert!`,
            showDenyButton: false,
            selectedDropdownOption: null,
            modalText: state.errorMessage
              ? state.errorMessage
              : 'If the issue persists, contact customer service',
          },
        })
      })
      .finally(() => {
        refetch()
        dispatch({
          type: 'setState',
          data: {
            submitting: false,
            showDialogModal: false,
          },
        })
      })
  }

  const handleDepart = async () => {
    dispatch({type: 'setState', data: {submitting: true}})
    await axios
      .put(`rd/segments/${idSegment}/stops/${idStop}/depart`)
      .then(() => {
        cache.invalidateQueries(['routePlan', idPlan])
        showToast('Driver departed successfully!', 'success')
      })
      .catch(err => {
        const error = err as AxiosError
        if (error?.response?.data.Message) {
          const regex = /"message":"([^"]+)"/
          const match = error.response.data.Message.match(regex)
          dispatch({type: 'setState', data: {errorMessage: match[1]}})
        }
        // setErrorMessage(error)
        dispatch({
          type: 'setState',
          data: {
            showDialogModal: true,
            modalType: 'error',
            modalTitle: `Alert!`,
            showDenyButton: false,
            selectedDropdownOption: null,
            modalText: state.errorMessage
              ? state.errorMessage
              : 'If the issue persists, contact customer service',
          },
        })
      })
      .finally(() => {
        refetch()
        dispatch({
          type: 'setState',
          data: {submitting: false, showDialogModal: false},
        })
      })
  }
  const handleOnMyWay = async () => {
    dispatch({type: 'setState', data: {submitting: true}})
    await axios
      .put(`rd/segments/${idSegment}/stops/${idStop}/onmyway`)
      .then(() => {
        cache.invalidateQueries(['routePlan', idPlan])
        showToast('The driver is on the way!', 'success')
      })
      .catch(err => {
        const error = err as AxiosError
        if (error?.response?.data.Message) {
          const regex = /"message":"([^"]+)"/
          const match = error.response.data.Message.match(regex)
          dispatch({type: 'setState', data: {errorMessage: match[1]}})
        }
        // setErrorMessage(error)
        dispatch({
          type: 'setState',
          data: {
            showDialogModal: true,
            modalType: 'error',
            modalTitle: `Alert!`,
            showDenyButton: false,
            selectedDropdownOption: null,
            modalText: state.errorMessage
              ? state.errorMessage
              : 'If the issue persists, contact customer service',
          },
        })
      })
      .finally(() => {
        refetch()
        dispatch({
          type: 'setState',
          data: {submitting: false, showDialogModal: false},
        })
      })
  }

  const handleStartRoute = async () => {
    dispatch({type: 'setState', data: {submitting: true}})
    await axios
      .put(`rd/segments/${idSegment}/stops/${idStop}/start`)
      .then(() => {
        cache.invalidateQueries(['routePlan', idPlan])
        showToast('The route has begun!', 'success')
      })
      .catch(err => {
        const error = err as AxiosError
        if (error?.response?.data.Message) {
          const regex = /"message":"([^"]+)"/
          const match = error.response.data.Message.match(regex)
          dispatch({type: 'setState', data: {errorMessage: match[1]}})
        }
        // setErrorMessage(error)
        dispatch({
          type: 'setState',
          data: {
            showDialogModal: true,
            modalType: 'error',
            modalTitle: `Alert!`,
            showDenyButton: false,
            selectedDropdownOption: null,
            modalText: state.errorMessage
              ? state.errorMessage
              : 'If the issue persists, contact customer service',
          },
        })
      })
      .finally(() => {
        refetch()
        dispatch({
          type: 'setState',
          data: {submitting: false, showDialogModal: false},
        })
      })
  }
  const handleEndRoute = async () => {
    dispatch({
      type: 'setState',
      data: {submitting: true, showDialogModal: false},
    })
    await axios
      .put(`rd/segments/${idSegment}/stops/${idStop}/end`)
      .then(() => {
        cache.invalidateQueries(['routePlan', idPlan])
        dispatch({
          type: 'setState',
          data: {submitting: false},
        })
        showToast('Driver has finished the route!', 'success')
      })
      .catch(err => {
        const error = err as AxiosError
        if (error?.response?.data.Message) {
          const regex = /"message":"([^"]+)"/
          const match = error.response.data.Message.match(regex)
          dispatch({type: 'setState', data: {errorMessage: match[1]}})
        }
        // setErrorMessage(error)
        dispatch({
          type: 'setState',
          data: {
            showDialogModal: true,
            modalType: 'error',
            modalTitle: `Alert!`,
            showDenyButton: false,
            selectedDropdownOption: null,
            modalText: state.errorMessage
              ? state.errorMessage
              : 'If the issue persists, contact customer service',
          },
        })
      })
      .finally(() => {
        refetch()
        dispatch({
          type: 'setState',
          data: {submitting: false, showEndRouteDialog: false},
        })
      })
  }

  const clickedOutside = () => {
    if (state.menuOpen) {
      dispatch({type: 'setState', data: {menuOpen: false}})
    }
  }
  useOnClickOutside(containerRef, clickedOutside)

  const dropdownClicked = (value: number) => {
    if (value === DropdownOption.SendNotification) {
      routeDetailDispatch({
        type: 'setState',
        data: {
          showNotificationSelectionModal: true,
          stop: state.stop,
        },
      })
    } else if (value === DropdownOption.DriverOnTheWay) {
      dispatch({
        type: 'setState',
        data: {
          showDialogModal: true,
          modalType: 'update',
          modalTitle: 'Just checking',
          showDenyButton: true,
          selectedDropdownOption: DropdownOption.DriverOnTheWay,
          modalText:
            'Are you sure you want to update the status of this stop to "In Transit"?',
        },
      })
    } else if (value === DropdownOption.Arrive) {
      if (isCurrentStop) {
        dispatch({
          type: 'setState',
          data: {
            showDialogModal: true,
            modalType: 'update',
            modalTitle: 'Just checking',
            showDenyButton: true,
            selectedDropdownOption: DropdownOption.Arrive,
            modalText:
              'Are you sure you want to update the status of this stop to "In Progress"?',
          },
        })
      } else
        dispatch({
          type: 'setState',
          data: {
            showDialogModal: true,
            modalType: 'update',
            modalTitle: 'Notice!',
            showDenyButton: true,
            selectedDropdownOption: DropdownOption.Arrive,
            modalText:
              'This was not the next scheduled stop. Do you still wish to proceed?',
          },
        })
    } else if (value === DropdownOption.Depart) {
      dispatch({
        type: 'setState',
        data: {
          showDialogModal: true,
          modalType: 'update',
          modalTitle: 'Just checking',
          showDenyButton: true,
          selectedDropdownOption: DropdownOption.Depart,
          modalText:
            'Are you sure you want to update the status of this stop to "Complete"?',
        },
      })
    } else if (value === DropdownOption.Delete) {
      if (otherStopsOnOrder && otherStopsOnOrder.length > 0) {
        dispatch({
          type: 'setState',
          data: {
            showDialogModal: true,
            modalType: 'delete',
            modalTitle: `Are you sure you want to delete this stop and its order from this route?`,
            showDenyButton: true,
            selectedDropdownOption: DropdownOption.Delete,
            modalText: `This will also delete ${
              otherStopsOnOrder.length
            } other stop${
              otherStopsOnOrder.length > 1 ? 's' : ''
            } on the order ${stop.orderName}. This action can't be undone.`,
          },
        })
      } else {
        dispatch({
          type: 'setState',
          data: {
            showDialogModal: true,
            modalType: 'delete',
            modalTitle: `Are you sure you want to delete this stop from this route?`,
            showDenyButton: true,
            selectedDropdownOption: DropdownOption.Delete,
            modalText: "This action can't be undone.",
          },
        })
      }
    } else if (value === DropdownOption.StartRoute) {
      dispatch({
        type: 'setState',
        data: {
          showDialogModal: true,
          modalType: 'update',
          modalTitle: 'Just checking',
          showDenyButton: true,
          selectedDropdownOption: DropdownOption.StartRoute,
          modalText: 'Are you sure you want to depart your starting point?',
        },
      })
    } else if (value === DropdownOption.EndRoute) {
      if (!isCurrentStop) {
        dispatch({
          type: 'setState',
          data: {
            showDialogModal: true,
            modalType: 'update',
            modalTitle: 'Just checking',
            showDenyButton: true,
            selectedDropdownOption: DropdownOption.EndRoute,
            modalText:
              'You have incomplete stops. Are you sure you wish to end this route?',
          },
        })
      } else {
        dispatch({
          type: 'setState',
          data: {
            showDialogModal: true,
            modalType: 'update',
            modalTitle: 'Just checking',
            showDenyButton: true,
            selectedDropdownOption: DropdownOption.EndRoute,
            modalText: 'Are you sure you want to complete this route?',
          },
        })
      }
    } else if (value === DropdownOption.AddStop) {
      routeDetailDispatch({type: 'setState', data: {showStopModal: true}})
      dispatch({
        type: 'setState',
        data: {
          showStopModal: true,
          stop: {
            marker: null,
            estimatedDuration: {hrs: 0, mins: 30},
            serviceDuration: 30,
            description: '',
            location: {lat: 0, lng: 0},
            companyId: '',
            companyName: '',
            formattedAddress: '',
            address: '',
            city: '',
            state: '',
            zipCode: '',
            country: '',
            phone: '',
            contact: '',
            email: '',
            UpdateReason: '',
            stopType: '',
            status: 'new',
            dateRangeError: '',
            dateRange: {
              startDate: stopStartDate,
              endDate: stopEndDate,
            },
          },
        },
      })
    }
  }

  React.useEffect(() => {
    if (stop) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const isDateValid = (date: any) =>
        typeof date === 'object' && !isNaN(date)

      const formattedStartDateTime =
        stop.ServiceStartDateTime && isDateValid(stop.ServiceStartDateTime)
          ? stop.ServiceStartDateTime.toISOString().replace('Z', '')
          : ''

      const formattedEndDateTime =
        stop.ServiceEndDateTime && isDateValid(stop.ServiceEndDateTime)
          ? stop.ServiceEndDateTime.toISOString().replace('Z', '')
          : ''

      dispatch({
        type: 'setState',
        data: {
          stop: {
            ...stop,
            scheduledEarliestDate: formattedStartDateTime,
            scheduledLatestDate: formattedEndDateTime,
            dateRange: {
              startDate: stop.ServiceStartDateTime,
              endDate: stop.ServiceEndDateTime,
            },
          },
        },
      })
    }
  }, [stop])

  const onSave = () => {
    if (state.selectedDropdownOption === DropdownOption.Arrive) {
      handleArrive()
    } else if (state.selectedDropdownOption === DropdownOption.Depart) {
      handleDepart()
    } else if (state.selectedDropdownOption === DropdownOption.Delete) {
      handleDelete()
    } else if (state.selectedDropdownOption === DropdownOption.StartRoute) {
      handleStartRoute()
    } else if (state.selectedDropdownOption === DropdownOption.EndRoute) {
      handleEndRoute()
    } else if (state.selectedDropdownOption === DropdownOption.DriverOnTheWay) {
      handleOnMyWay()
    } else {
      dispatch({
        type: 'setState',
        data: {showDialogModal: false, errorMessage: ''},
      })
    }
  }

  const onDismiss = () => {
    dispatch({
      type: 'setState',
      data: {showDialogModal: false, errorMessage: ''},
    })
  }

  return (
    <>
      {state.submitting && <Spinner />}
      {stopStatus.toLocaleLowerCase() === 'completed' ? (
        <Spacer />
      ) : (
        <>
          <StyledButtonDropDown
            endRoute={endRoute}
            text={
              <>
                <StopInfoMenu
                  menuOpen={state.menuOpen}
                  onClick={() => {
                    dispatch({
                      type: 'setState',
                      data: {menuOpen: !state.menuOpen},
                    })
                  }}
                  ref={containerRef}
                >
                  {state.menuOpen ? (
                    <CaretUp size={10} weight="fill" />
                  ) : (
                    <CaretDown size={10} weight="fill" />
                  )}
                </StopInfoMenu>
              </>
            }
            direction={'right'}
            options={getOptions({
              startRoute,
              endRoute,
              stopStatus,
              routeHasStarted,
            })}
            marginTop={'30px'}
            onChange={dropdownClicked}
            dataCy="stop-menu"
          />
          <AlertModal
            showDialog={state.showDialogModal}
            onSave={onSave}
            disabled={state.submitting}
            type={state.modalType}
            title={state.modalTitle}
            text={state.modalText}
            onDismiss={onDismiss}
            showDenyButton={state.showDenyButton}
          />
        </>
      )}
    </>
  )
}
export default StopCardDropdown
