import ExcelJS from 'exceljs'
import {saveAs} from 'file-saver'
import {ColumnDef} from '@tanstack/react-table'

type Props<TDataType, TSelectionIdType> = {
  columns: ColumnDef<TDataType, TSelectionIdType>[]
  data: unknown[][]
  fileName?: string
}

export const excelExport = async <TDataType, TSelectionIdType>({
  columns,
  data,
  fileName,
}: Props<TDataType, TSelectionIdType>) => {
  const workbook = new ExcelJS.Workbook()
  workbook.created = new Date()
  workbook.modified = new Date()
  const worksheet = workbook.addWorksheet()

  const headers = columns.map(c => {
    return {
      name: (c.header as string) || ' ',
      filterButton: true,
    }
  })

  worksheet.addTable({
    name: 'ExportTable',
    ref: 'A1',
    headerRow: true,
    totalsRow: false,
    style: {
      theme: 'TableStyleMedium2',
      showRowStripes: true,
    },
    columns: headers,
    rows: data,
  })

  worksheet.columns.forEach((column, index) => {
    const originalColumn = columns[index]
    if (originalColumn.meta?.excelWidth) {
      //excelwidth was provided use that
      column.width = originalColumn.meta?.excelWidth
    } else {
      //autofit width based on character count
      var dataMax = 0
      if (column?.eachCell) {
        column.eachCell({includeEmpty: true}, function (cell) {
          var columnLength = String(cell.value)?.length
          if (columnLength > dataMax) {
            dataMax = columnLength
          }
        })
        column.width = dataMax < 15 ? 15 : Math.min(dataMax, 100)
      }
    }
  })

  const buf = await workbook.xlsx.writeBuffer()

  saveAs(new Blob([buf]), `${fileName || 'Export'}.xlsx`)
}
