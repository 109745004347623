import {Column, Row} from 'common/components'
import styled from 'styled-components/macro'

export const Container = styled(Column)`
  width: 100%;
  height: calc(100vh - 140px);
  background: var(--asc-cultured);
`
export const Header = styled(Row)`
  font-size: 24px;
  font-weight: bold;
  padding: 25px;
`
export const NotificationsList = styled(Column)`
  padding: 25px;
  padding-top: 0px;
  gap: 20px;
`

export const NotificationCardContainer = styled.div`
  background-color: white;
  border: 1px solid var(--asc-coolgray);
  box-shadow: 4px 4px 12px 0px #00000026;
  border-radius: 20px;
  width: 100%;
  max-width: 1000px;
`

export const NotificationCardHeader = styled(Row)`
  padding: 20px;
  font-size: 18px;
  font-weight: bold;
  justify-content: space-between;
`

export const NotificationCardBody = styled(Row)`
  padding: 20px;
  padding-top: 0px;
  justify-content: space-between;
  gap: 64px;
`
type EditButtonProps = {
  disabled: boolean
}
export const EditButton = styled.div<EditButtonProps>`
  color: ${props =>
    props.disabled ? 'var(--asc-coolgray)' : 'var(--primary)'};
  font-weight: bold;
  font-size: 16px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  :hover {
    color: ${props =>
      props.disabled ? 'var(--asc-coolgray)' : 'var(--secondary)'};
  }
`
