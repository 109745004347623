import * as React from 'react'
import styled from 'styled-components/macro'
import MarkerImgIndicatorGo from 'images/map/marker_go_img.svg'
import MarkerImgIndicatorStop from 'images/map/marker_stop.svg'
import Alert from 'images/map/warning.svg'
import MaintDue from 'images/map/wrench.svg'

const Container = styled.div`
  position: relative;
  .hidden {
    display: none !important;
  }
`

const MarkerWhiteBorder = styled.div`
  cursor: ${p => (p.type === 'map' ? 'pointer' : 'inherit')};
  width: ${p => (p.type === 'map' ? '36px' : '36px')};
  height: ${p => (p.type === 'map' ? '36px' : '36px')};
  /* transform: translate3d(-50%, -50%, 0); */
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.5);
`

const MarkerColoredBorder = styled.div`
  width: ${p => (p.type === 'map' ? '30px' : '30px')};
  height: ${p => (p.type === 'map' ? '30px' : '30px')};
  border-radius: 50%;
  background: ${p => p.color};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  img {
    width: ${p => (p.type === 'map' ? '12px' : '12px')};
    height: ${p => (p.type === 'map' ? '12px' : '12px')};
  }
`

const MarkerInnerCircle = styled.div`
  width: ${p => (p.type === 'map' ? '20px' : '20px')};
  height: ${p => (p.type === 'map' ? '20px' : '20px')};
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  background-color: #fff;
  transform: translate3d(-50%, -50%, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  .hidden {
    display: none;
  }
`

const ArrowImg = styled.img`
  /* transform-origin: 50% 50%; */
  transition: rotate ease 0.8s;
  transform: rotate(${p => p.rotation}deg);
  width: 19px;
  height: 19px;
`

const StopIcon = styled.img`
  width: 10px;
  height: 10px;
`

const MarkerLabel = styled.div`
  background: #ffffff;
  border: 1px solid #949494;
  box-sizing: border-box;
  box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  justify-content: center;
  white-space: nowrap;
  position: absolute;
  top: 4px;
  left: 37px;
  padding: 4px 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
`

const Icon = styled.img`
  padding-left: 4px;
`

const Marker = (
  {
    point,
    type = 'map',
    onClick,
    showDriverName = '',
    showLabel = true,
    iconColor = '',
  },
  activeMarker,
) => {
  const {inMotion, heading, color, Asset_Label, alerts, maint, idAsset} =
    point?.properties || {}
  const thisMarkerIsActive = activeMarker?.idAsset === idAsset
  let classList = 'marker'
  if (thisMarkerIsActive) {
    if (activeMarker?.following) {
      classList = 'activeMarker following'
    } else {
      classList = 'activeMarker'
    }
  }
  return (
    <Container
      className={classList}
      id={`markerContainer-${idAsset}`}
      onClick={onClick}
      data-testid="map-asset-marker"
    >
      <MarkerWhiteBorder type={type}>
        <MarkerColoredBorder
          type={type}
          color={color || iconColor}
          data-cy="marker"
        ></MarkerColoredBorder>
        <MarkerInnerCircle type={type}>
          <ArrowImg
            rotation={inMotion && heading ? heading : 0}
            className={`marker-img-status ${!inMotion ? 'hidden' : ''}`}
            src={MarkerImgIndicatorGo}
          />
          <StopIcon
            className={`marker-img-stop ${inMotion ? 'hidden' : ''}`}
            src={MarkerImgIndicatorStop}
          />
        </MarkerInnerCircle>
      </MarkerWhiteBorder>
      {type === 'map' && showLabel && (Asset_Label || showDriverName) && (
        <>
          <MarkerLabel className={`marker-label`}>
            {showDriverName || Asset_Label}{' '}
            {alerts ? <Icon src={Alert} /> : <></>}
            {maint ? <Icon src={MaintDue} /> : <></>}
          </MarkerLabel>
        </>
      )}
    </Container>
  )
}
export default Marker
