import * as React from 'react'
import useOpening1 from 'opening/hooks/useOpening1'
import {getClosestAssets} from './findNearestAssetsHelpers'
import {useStore} from 'common/useStore'

type FindNearestAssetsPropsType = {
  map: google.maps.Map
  lat: number
  lng: number
  clearAssetFilter?: () => void
}

const FindNearestAssets = ({
  map,
  lat,
  lng,
  clearAssetFilter,
}: FindNearestAssetsPropsType) => {
  const setAssetListOpen = useStore(state => state.setAssetListOpen)
  const {data: opening1} = useOpening1()
  const assets = opening1?.assets
  const [latlng, setLatlng] = React.useState<google.maps.LatLng>()

  const findAssets = () => {
    if (clearAssetFilter) clearAssetFilter()
    if (latlng && assets) {
      getClosestAssets({
        latlng,
        assets,
        setAssetListOpen,
        map,
      })
    }
  }

  React.useEffect(() => {
    let newLatlng = null
    if (lat && lng) {
      newLatlng = new window.google.maps.LatLng(lat, lng)
      setLatlng(newLatlng)
    }
  }, [lat, lng])

  return (
    <div onClick={findAssets}>
      <span data-cy="find-nearest-assets">Find Nearest Assets</span>
    </div>
  )
}

export default FindNearestAssets
