export const initialAccountCustomizeViews = {
  changedColumns: {},
  activeIndex: 0,
  errors: {},
  isDirty: false,
}
export const accountCustomizeViewsReducer = (
  state = initialAccountCustomizeViews,
  action,
) => {
  switch (action.type) {
    case 'setState': {
      return {
        ...state,
        ...action.data,
      }
    }
    case 'setDirty': {
      return {
        ...state,
        isDirty: action.data,
      }
    }
    case 'initialColumns': {
      return {
        ...state,
        ...action.data,
        isDirty: false,
      }
    }
    case 'customHeader': {
      return {
        ...state,
        ...action.data.newColumns,
        isDirty: true,
        changedColumns: {
          ...state.changedColumns,
          [action.data.changedColumn]: true,
        },
      }
    }
    case 'setColumns': {
      return {
        ...state,
        changedColumns: {...state.changedColumns, [action.data.name]: true},
        [action.data.name]: action.data.result,
        isDirty: true,
      }
    }
    case 'clearState': {
      return {...initialAccountCustomizeViews}
    }
    default:
      console.error('Unrecognized action type', action)
      return state
  }
}

export const onDragEnd = ({result, name, dispatch, columns}) => {
  if (!result.destination) return
  const {source, destination} = result
  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId]
    const destColumn = columns[destination.droppableId]
    const sourceItems = [...sourceColumn.items]
    const destItems = [...destColumn.items]
    const [removed] = sourceItems.splice(source.index, 1)
    destItems.splice(destination.index, 0, removed)
    dispatch({
      type: 'setColumns',
      data: {
        name,
        result: {
          ...columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems,
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems,
          },
        },
      },
    })
  } else {
    const column = columns[source.droppableId]
    const copiedItems = [...column.items]
    const [removed] = copiedItems.splice(source.index, 1)
    copiedItems.splice(destination.index, 0, removed)
    dispatch({
      type: 'setColumns',
      data: {
        name,
        result: {
          ...columns,
          [source.droppableId]: {
            ...column,
            items: copiedItems,
          },
        },
      },
    })
  }
}
export const submitGrid = (columns, schemaname, schema) => {
  const submitColumns = columns[0].items.map(item => {
    return {
      FieldPresentationColumn: 0,
      FieldPresentationHeader: item.columnheader,
      FieldPresentationType: schemaname,
      idCustomerFieldPresentation: item.idSchema,
    }
  })
  // add back Asset Name since it can't be removed
  submitColumns.splice(0, 0, {
    FieldPresentationColumn: 0,
    FieldPresentationHeader: schema[0].columnheader,
    FieldPresentationType: schemaname,
    idCustomerFieldPresentation: schema[0].idSchema,
  })
  submitColumns.join()
  return submitColumns
}
export const submitPopup = (columns, schemaname, schema) => {
  const submitColumns = columns[0].items.map(item => {
    return {
      FieldPresentationColumn: 0,
      FieldPresentationHeader: item.columnheader,
      FieldPresentationType: schemaname,
      idCustomerFieldPresentation: item.idSchema,
    }
  })
  //add back removed items (removed because they can't be modified)
  const unmodifiedSchema = schema.slice(0, 5).map(item => {
    const returnItem = {
      FieldPresentationColumn: 0,
      FieldPresentationType: schemaname,
      FieldPresentationHeader: item.columnheader,
      idCustomerFieldPresentation: item.idSchema,
    }
    if (item.columnheader) {
      returnItem.FieldPresentationHeader = item.columnheader
    }
    return returnItem
  })

  return [...unmodifiedSchema, ...submitColumns]
}
export const submitDashboard = (columns, schemaname) => {
  const submitColumn0 = columns[0].items.map(item => {
    return {
      FieldPresentationColumn: 0,
      FieldPresentationHeader: item.columnheader,
      FieldPresentationType: schemaname,
      idCustomerFieldPresentation: item.idSchema,
    }
  })
  const submitColumn1 = columns[1].items.map(item => {
    return {
      FieldPresentationColumn: 1,
      FieldPresentationHeader: item.columnheader,
      FieldPresentationType: schemaname,
      idCustomerFieldPresentation: item.idSchema,
    }
  })
  return [...submitColumn0, ...submitColumn1]
}
export const onSubmit = async ({
  gridColumns,
  popupColumns,
  dashboardColumns,
  putCustomizeViews,
  changedColumns,
  data,
}) => {
  const submitObject = {}
  if (changedColumns.gridColumns) {
    submitObject.grid = submitGrid(gridColumns, 'grid', data.gridSchema)
  }
  if (changedColumns.popupColumns) {
    submitObject.mobile_popup = submitPopup(
      popupColumns,
      'mobile_popup',
      data.popupSchema,
    )
  }
  if (changedColumns.dashboardColumns) {
    submitObject.asset_detail = submitDashboard(
      dashboardColumns,
      'asset_detail',
    )
  }
  if (
    changedColumns.gridColumns ||
    changedColumns.popupColumns ||
    changedColumns.dashboardColumns
  ) {
    await putCustomizeViews(submitObject)
  }
}

const findAndChangeCustomHeader = ({columns, columnId, index, customName}) => {
  const changedColumn = columns[columnId]

  changedColumn.items.map((column, thisIndex) => {
    if (thisIndex === index) {
      column.FieldPresentationHeader = customName
      column.columnheader = customName
    }
    return column
  })
  columns[columnId] = changedColumn
  return columns
}

export const onCustomNameChangeApply = ({
  name,
  columns,
  columnId,
  index,
  customName,
  dispatch,
  setOpenIndex,
}) => {
  let newColumns
  let changedColumn
  if (name === 'Asset Grid') {
    changedColumn = 'gridColumns'
    columns.gridColumns = findAndChangeCustomHeader({
      columns: columns.gridColumns,
      columnId,
      index,
      customName,
    })
  } else if (name === 'Asset Pop-up') {
    changedColumn = 'popupColumns'
    columns.popupColumns = findAndChangeCustomHeader({
      columns: columns.popupColumns,
      columnId,
      index,
      customName,
    })
  } else {
    changedColumn = 'dashboardColumns'
    columns.dashboardColumns = findAndChangeCustomHeader({
      columns: columns.dashboardColumns,
      columnId,
      index,
      customName,
    })
  }

  dispatch({
    type: 'customHeader',
    data: {
      newColumns,
      changedColumn,
    },
  })
  setOpenIndex(null)
}

export const getPopUpColumn = schema => {
  const notToRender = [
    'Asset_Label',
    'Last_Heading_Cardinal',
    'In_Motion',
    'Asset_Icon_Full_Path',
    'Last_Nearby_Address',
    'Last_Location_DateTime_Local',
    'Last_Event',
  ]
  const filteredFieldVisibiltySchema = schema?.filter(
    item =>
      item.fieldVisibility &&
      notToRender.indexOf(item.deviceFieldName) === -1 &&
      item.columnheader !== 'Heading',
  )

  const availableSchema = schema?.filter(
    item =>
      !item.fieldVisibility &&
      notToRender.indexOf(item.deviceFieldName) === -1 &&
      item.columnheader !== 'Heading',
  )
  return {
    ['0']: {
      name: 'Asset Pop-up',
      items: filteredFieldVisibiltySchema || [],
    },
    ['1']: {
      name: 'Available Data Fields',
      items: availableSchema || [],
    },
  }
}
