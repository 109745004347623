import * as React from 'react'
import styled from 'styled-components/macro'

const AdjustIconHolder = styled.svg`
  width: 16px;
  height: 16px;
`

const Container = styled.div<{color: string}>`
  svg {
    fill: ${p => p.color};
  }
`

export const AdjustRoutesIcon = ({color}: {color: string}) => {
  return (
    <Container color={color}>
      <AdjustIconHolder xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <g clipPath="url(#clip0_327_550)">
          <path d="M17 21H5C4.73478 21 4.48043 21.1054 4.29289 21.2929C4.10536 21.4804 4 21.7348 4 22C4 22.2652 4.10536 22.5196 4.29289 22.7071C4.48043 22.8946 4.73478 23 5 23H17C17.2652 23 17.5196 22.8946 17.7071 22.7071C17.8946 22.5196 18 22.2652 18 22C18 21.7348 17.8946 21.4804 17.7071 21.2929C17.5196 21.1054 17.2652 21 17 21ZM5 14H15C15.2652 14 15.5196 13.8946 15.7071 13.7071C15.8946 13.5196 16 13.2652 16 13V11H27C27.2652 11 27.5196 10.8946 27.7071 10.7071C27.8946 10.5196 28 10.2652 28 10C28 9.73478 27.8946 9.48043 27.7071 9.29289C27.5196 9.10536 27.2652 9 27 9H16V7C16 6.73478 15.8946 6.48043 15.7071 6.29289C15.5196 6.10536 15.2652 6 15 6H5C4.73478 6 4.48043 6.10536 4.29289 6.29289C4.10536 6.48043 4 6.73478 4 7V13C4 13.2652 4.10536 13.5196 4.29289 13.7071C4.48043 13.8946 4.73478 14 5 14Z" />
          <rect x="20" y="18" width="8" height="8" rx="1" />
        </g>
        <defs>
          <clipPath id="clip0_327_550">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </AdjustIconHolder>
    </Container>
  )
}
