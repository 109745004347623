import {Button, Column, Row} from 'common/components'
import {LandmarkType} from 'locations/hooks/useLocations'
import * as React from 'react'
import styled from 'styled-components/macro'

const Container = styled(Row)<{background: string}>`
  width: 100%;
  min-height: 90px;
  background: ${p => p.background};
  border-top: 1px solid var(--asc-coolgray);
  padding: 10px 20px;
`
const LocationName = styled.div`
  color: var(--primary);
  font-weight: bold;
  font-size: 14px;
`
const Address = styled.div`
  color: black;
  font-size: 14px;
`
const ContactInfo = styled.div`
  color: var(--asc-sonicsilver);
`
const StyledColumn = styled(Column)<{width: string}>`
  width: ${p => p.width};
`
type LocationCardProps = {
  index: number
  location: LandmarkType
  selectLocation: (location: LandmarkType) => void
}
const LocationCard: React.FC<LocationCardProps> = ({
  index,
  location,
  selectLocation,
}) => {
  return (
    <>
      {location && (
        <Container
          background={index % 2 === 0 ? 'white' : 'var(--asc-cultured)'}
        >
          <StyledColumn width="50%">
            <LocationName>{location.Landmark_Name}</LocationName>
            <Address>
              {location.Landmark_Address_Line1 || location.Landmark_City
                ? `${location.Landmark_Address_Line1 || ''} ${
                    location.Landmark_City ? location.Landmark_City + ', ' : ''
                  }${location.Landmark_Region}`
                : `lat: ${location.Latitude}, lng: ${location.Longitude}`}
            </Address>
          </StyledColumn>
          <StyledColumn width="30%">
            <ContactInfo>{location.ContactName}</ContactInfo>
            <ContactInfo>{location.ContactEmail}</ContactInfo>
            <ContactInfo>{location.CustomerPhoneNumber}</ContactInfo>
          </StyledColumn>
          <StyledColumn width="20%">
            <Button onClick={() => selectLocation(location)}>CHOOSE</Button>
          </StyledColumn>
        </Container>
      )}
    </>
  )
}
export default LocationCard
