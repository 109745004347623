import Axios from 'axios'
import Swal from 'sweetalert2'
import {AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE} from 'common/constants'

type State = {
  loading: boolean
  logoFile: string
  newLogoFile: {
    name: string,
    type: string
  } | null
  newLogoFileURL: string
  idBranding: number
  logoFetched: boolean
}

export const initialState: State = {
  loading: true,
  logoFile: '',
  newLogoFile: null,
  newLogoFileURL: '',
  idBranding: 0,
  logoFetched: false,
}

type Action = {
  type:
    | 'setData'
    | 'setSelected'
    | 'setUserList'
    | 'setActiveSecondaryTab'
    | 'setLogo'
    | 'setNewLogo'
    | 'saveLogo'
    | 'cancelLogo'
    | 'error'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function reducer(state: any, action: Action) {
  switch (action.type) {
    case 'error':
      return { ...state, userListLoading: false, loading: false }
    case 'setLogo':
      return {
        ...state,
        newLogoFile: '',
        newLogoFileURL: '',
        idBranding: action.data.idBranding,
        logoFile: action.data && action.data.Logo ? `${AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE}/images/${action.data.Logo}` : '',
        logoFetched: true,
      }
    case 'setNewLogo':
      return { ...state, newLogoFileURL: action.data.fileURL, newLogoFile: action.data.file }
    case 'saveLogo':
      return { ...state, logoFile: state.newLogoFileURL, newLogoFile: '', newLogoFileURL: '' }
    case 'cancelLogo':
      return { ...state, newLogoFile: '', newLogoFileURL: '' }
  }
}

export const fetchLogo = async (id: number, type: 'customer' | 'dealer', dispatch: React.Dispatch<Action>) => {
  try {
    const response = await Axios.get(`/brandings/?a=get${type.toLowerCase()}&id=${String(id)}`)
    if (response && response.data) {
      dispatch({ type: 'setLogo', data: response.data })
    } else {
      dispatch({ type: 'setLogo', data: null })
    }
  } catch (e) {
    console.error(e)
    dispatch({ type: 'error', data: false })
  }
}

export const resizeImg = async (file: string): Promise<string> => {
  try {
    const img = new Image()
    img.src = file
    await new Promise((resolve) => (img.onload = resolve))
    const canvas = document.createElement('canvas')
    let ctx = canvas.getContext('2d')
    if (ctx) ctx.drawImage(img, 0, 0)
    const MAX_WIDTH = 250
    const MAX_HEIGHT = 50
    let width = img.naturalWidth
    let height = img.naturalHeight
    if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height
      height = MAX_HEIGHT
    }
  
    if (width > MAX_WIDTH) {
      width = MAX_WIDTH
      height *= MAX_WIDTH / width
    }
    canvas.width = width
    canvas.height = height
    ctx = canvas.getContext('2d')
    if (ctx) ctx.drawImage(img, 0, 0, width, height)
    return canvas.toDataURL()
  } catch (error) {
    console.error(error)
    return ''
  }

}

export const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>, dispatch: React.Dispatch<Action>) => {
  if (e.currentTarget.files && e.currentTarget.files[0]) {
    const file = e.currentTarget.files[0]
    if (file.size > 3000000) {
      Swal.fire({
        icon: 'error',
        title: 'File too large',
        text: 'File size is limited to 3MB',
      })
      return
    }
    const validImageTypes = ['image/gif', 'image/jpg', 'image/jpeg', 'image/png', 'image/bmp', 'image/svg+xml']
    if (!validImageTypes.includes(file.type)) {
      Swal.fire({
        icon: 'error',
        title: 'Incorrect file type',
        text:
          'File type is not supported. Valid extensions include: .gif, .jpeg, .png, .bmp and .svg. Please try again with a valid image file',
      })
      return
    }
    const reader = new FileReader()
    let baseString
    reader.onloadend = function () {
      baseString = reader.result
      if (baseString && typeof baseString === 'string') {
        //const resizedImage = await resizeImg(baseString)
        
        const data = {
          fileURL: baseString,
          file: file,
        }
        dispatch({ type: 'setNewLogo', data })
        if (e && e.currentTarget) {
          e.currentTarget.value = '' //clear file input so same file could be uploaded
        }
      }
    }
    reader.readAsDataURL(file)
  }
}

export const saveLogo = async (state: State, idCustomer: number | null, idDealer: number | null, dispatch: React.Dispatch<Action>) => {

  const saveObject = {
    file: state.newLogoFileURL,
    fileName: state?.newLogoFile?.name,
    fileType: state?.newLogoFile?.type,
    logoClickUrl: '',
    privacyPolicyUrl: '',
    dealersGlobalBrandingCustomerId: idCustomer || idDealer,
  }

  try {
    let brandingResponse
    if (state.idBranding !== undefined) {
      // if (state.idBranding === 0) {
        brandingResponse = await Axios.post('/v2/brandings', saveObject)
      // } else {
      //   brandingResponse = await Axios.put(`/v2/brandings/${state.idBranding}`, saveObject)
      // }
    }

    if (brandingResponse && brandingResponse.data) {
      const brandingID = brandingResponse.data.idBranding
      const url = idCustomer
        ? `/brandings?a=setcustomer&id=${idCustomer}&branding=${brandingID}`
        : `/brandings?a=setdealer&id=${idDealer}&branding=${brandingID}`
      await Axios.get(url)
      dispatch({ type: 'saveLogo' })
      Swal.fire({
        icon: 'info',
        title: 'Branding updated',
      })
    } else {
      throw new Error()
    }
  } catch (error) {
    console.error(error)
    Swal.fire({
      icon: 'error',
      title: 'Error updating branding',
      text: 'An unexpected error occurred.',
    })
  }
}
