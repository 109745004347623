import Axios from 'axios'
import {useMutation, useQueryClient} from '@tanstack/react-query'

const postGroup = async group => {
  const {data} = await Axios.post(`/groups`, group)
  return data
}

function usePostGroup() {
  const cache = useQueryClient()
  return useMutation(postGroup, {
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) => {
      console.error(err, variables, previousValue)
      cache.setQueryData(['groups'], previousValue)
    },
    // After success or failure, refetch the invitees query
    onSettled: () => {
      cache.invalidateQueries(['groups'])
      cache.invalidateQueries(['useGetUsersGroup'])
    },
  })
}

export default usePostGroup
