import {WEATHER_KEYS, QUERIES} from 'common/constants'
import {useStore} from 'common/useStore'
import React from 'react'
import styled from 'styled-components/macro'
import WeatherImage from 'images/weathericon.svg'
const RadarControlDiv = styled.div`
  display: none;
  align-content: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: white;
  @media ${QUERIES.tabletAndUp} {
    display: flex;
  }
`

export const initWeatherControl = map => {
  const userConfig = useStore.getState().userConfig
  const radarControlDiv = (
    <RadarControlDiv
      data-testid="weather-overlay"
      className="map-control map-weather-control"
      title="Weather"
      onClick={() => {
        if (map.overlayMapTypes.length) {
          map.overlayMapTypes.clear()
        } else {
          map.overlayMapTypes.push(radar)
          //clear weather after 1 minute
          setTimeout(() => {
            map.overlayMapTypes.clear()
          }, 60000)
        }
      }}
    >
      <img src={WeatherImage}></img>
    </RadarControlDiv>
  )
  const controlUI = document.createElement('div')
  controlUI.className = 'map-control map-weather-control'
  controlUI.title = 'Weather'
  //radarControlDiv.push(controlUI)
  // var weatherLayer = new google.maps.TrafficLayer();
  const radar = new window.google.maps.ImageMapType({
    getTileUrl: function (coord, zoom) {
      return [
        `https://maps.aerisapi.com/${WEATHER_KEYS.id}_${
          WEATHER_KEYS.secret
        }/radar,temperatures-text${
          userConfig.Temperature_UOM === '°C' ? '-metric' : ''
        }-dk:80:blend(overlay)/`,
        zoom,
        '/',
        coord.x,
        '/',
        coord.y,
        '/current.png',
      ].join('')
    },
    tileSize: new window.google.maps.Size(256, 256),
  })

  //controlUI.addEventListener('click', function () {})

  return radarControlDiv
}
