import * as React from 'react'
import {AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE} from 'common/constants'
import {CalendarBlank, CaretRight, Clock, Path} from 'phosphor-react'
import {useStore} from 'common/useStore'
import {
  AssetImageContent,
  AssetName,
  RouteColumn,
  ColumnWithGap,
  SummaryRow,
  DimGrayText,
  DriverInfo,
  DriverName,
  ImageColumn,
  ImageContent,
  LightRowRedText,
  LinkColumn,
  ProgressContainer,
  ProgressMeter,
  ProgressMeterContainer,
  ProgressStats,
  RegularText,
  RouteSummaryWrapper,
  RouteTitle,
  RowWithGap,
  // SilverText,
  SmallSilverText,
  SpaceBetween,
  StyledChatCircleText,
  OpenPanelUserCircle,
  SummaryColumn,
  TimeText,
  ThumbnailContent,
  RedText,
  ClosedPanelUserCircle,
} from './routeDetailStyles'
import {getHoursAndMinutes} from '../Create/createRouteHelpers'
import StatusBadge from 'routingAndDispatch/common/StatusBadge'
import {useNavigate} from 'react-router-dom'
import {
  convertToUsersDateFormat,
  convertToUsersTimeFormat,
} from 'common/helpersDateTime'
import {Segment} from 'routingAndDispatch/types/typesModule'
import {Tooltip} from 'common/components'
import {formatDateToStringForAPI, openWindowTab} from 'common/helpers'
import {isSameDay} from 'date-fns'
import {getStopStatusCounts} from 'routingAndDispatch/common/commonHelpers'
import CollapsiblePanel from 'routingAndDispatch/common/CollapsiblePanel'
import {numberWithCommas} from 'common/helpersNumber'
import {RoutePlanWithColor} from '../PlanReview/PlanReview'
interface RouteDetailProps {
  isPlanning: boolean
  showStopModal: boolean
  routeDetailData: RoutePlanWithColor | null
}

const SummaryPanel: React.FC<RouteDetailProps> = ({
  isPlanning,
  routeDetailData,
}) => {
  const navigate = useNavigate()
  const assetData = routeDetailData?.segments[0]?.Asset
  // const driverData = routeDetailData?.segments[0]?.Asset?.Driver

  const segment: Segment | undefined =
    routeDetailData?.segments?.filter(
      (segment: Segment) =>
        segment.idSegment === routeDetailData?.segments[0]?.idSegment,
    )[0] || routeDetailData?.segments[0]
  const userConfig = useStore(state => state.userConfig)

  const startTime = routeDetailData
    ? routeDetailData.planStartDateTime
    : new Date()

  const endTime = routeDetailData ? routeDetailData.planEndDateTime : new Date()

  const stopsWithoutEndpoints = segment?.stops?.filter(
    (stop, index) => index !== 0 && index !== segment?.stops?.length - 1,
  )
  const routeIsCompleted =
    segment?.stops[segment?.stops.length - 1]?.stopStatus?.toLowerCase() ===
    'completed'

  const isSame = startTime && endTime ? isSameDay(startTime, endTime) : false

  const renderProgressBar = () => {
    if (
      !isPlanning &&
      segment?.segmentStatus &&
      segment?.segmentStatus?.toLowerCase() !== 'completed'
    ) {
      return (
        <ProgressContainer data-cy={'summary-panel'}>
          <ProgressStats>
            <span>Progress</span>
            <div>
              <RowWithGap>
                {counts.completedStops} of{' '}
                {segment?.stops?.length - 2 >= 0
                  ? segment?.stops?.length - 2
                  : '0'}{' '}
                <RegularText> complete</RegularText>
              </RowWithGap>
            </div>
          </ProgressStats>
          <ProgressMeterContainer>
            <ProgressMeter
              complete={counts.completedStops || 0}
              total={stopsWithoutEndpoints?.length || 0}
            />
          </ProgressMeterContainer>
        </ProgressContainer>
      )
    }
  }

  const counts = getStopStatusCounts(
    routeDetailData?.planStartDateTime || new Date(),
    stopsWithoutEndpoints || [],
    routeDetailData?.planStatus,
  )

  const goToHistoryTrail = () => {
    if (startTime && endTime && assetData) {
      const startDateString = formatDateToStringForAPI(startTime)
      const endDateString = formatDateToStringForAPI(endTime)

      const queryString = `startDate=${startDateString}&endDate=${endDateString}`

      window.open(`/historytrail/${assetData.idAsset}?${queryString}`, '_blank')
    }
  }

  const status = isPlanning
    ? 'planning'
    : segment
    ? `${segment.segmentStatus?.toLowerCase()}${
        segment.missed && segment?.missed > 0 ? '-missed' : ''
      }`
    : ''
  return (
    <CollapsiblePanel
      titleText="Route Summary"
      titleIcon={{
        icon: <StatusBadge padding="2px 10px" status={status} />,
        renderWhenClosed: false,
      }}
      iconWhenClosed={<CaretRight size={16} color={'var(--primary)'} />}
      onLeft={true}
      defaultOpen={true}
      width={350}
      bottomIconWhenClosed={
        segment?.Driver_Icon_Full_Path ? (
          <>
            <ThumbnailContent
              data-cy="driver-detail-image"
              src={`${AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE}/${segment?.Driver_Icon_Full_Path}`}
            />
          </>
        ) : (
          <>
            <ClosedPanelUserCircle size={36} weight="thin" />
          </>
        )
      }
    >
      <RouteSummaryWrapper>
        <RouteTitle>{routeDetailData?.planName}</RouteTitle>
        <SpaceBetween>
          {isSame ? (
            <>
              <TimeText>
                <CalendarBlank size={18} />
                {String(
                  startTime &&
                    userConfig &&
                    convertToUsersDateFormat(startTime, userConfig),
                )}
              </TimeText>
              <TimeText>
                <Clock size={18} />
                {startTime &&
                  endTime &&
                  userConfig &&
                  convertToUsersTimeFormat(startTime, userConfig) +
                    ' — ' +
                    convertToUsersTimeFormat(endTime, userConfig)}
              </TimeText>{' '}
            </>
          ) : (
            <>
              <TimeText>
                {String(
                  startTime &&
                    userConfig &&
                    convertToUsersDateFormat(startTime, userConfig),
                )}
                {startTime &&
                  userConfig &&
                  ' ' + convertToUsersTimeFormat(startTime, userConfig)}
                {endTime &&
                  userConfig &&
                  ' - ' + convertToUsersDateFormat(endTime, userConfig)}
                {endTime &&
                  userConfig &&
                  ' ' + convertToUsersTimeFormat(endTime, userConfig)}
              </TimeText>
            </>
          )}
        </SpaceBetween>
        {renderProgressBar()}
        <SummaryColumn>
          <>
            {!isPlanning ? (
              <>
                <SummaryRow>
                  <DimGrayText>Total Stops</DimGrayText>
                  <span>{counts.totalStops}</span>
                </SummaryRow>
                <SummaryRow>
                  <DimGrayText>Completed Stops</DimGrayText>
                  <span>{counts.completedStops}</span>
                </SummaryRow>
                {!routeIsCompleted && (
                  <SummaryRow>
                    <DimGrayText>Pending Stops</DimGrayText>
                    <span>{counts.pendingStops}</span>
                  </SummaryRow>
                )}
                <SummaryRow>
                  <DimGrayText>On-Time Stops</DimGrayText>
                  <span>{counts.onTimeStops}</span>
                </SummaryRow>
                <SummaryRow>
                  <DimGrayText>Late Stops</DimGrayText>
                  <span>{counts.lateStops}</span>
                </SummaryRow>
                {segment?.missed ? (
                  <LightRowRedText>
                    <RedText>Missed Stops</RedText>
                    <span>{segment?.missed}</span>
                  </LightRowRedText>
                ) : (
                  <SummaryRow>
                    <DimGrayText>Missed Stops</DimGrayText>
                    <span>0</span>
                  </SummaryRow>
                )}
              </>
            ) : (
              <></>
            )}

            <SummaryRow>
              <DimGrayText>Estimated Distance</DimGrayText>
              <span>
                {segment?.info &&
                  numberWithCommas(
                    Math.floor(segment?.info?.totalDistance).toString(),
                  )}{' '}
                {userConfig?.Distance_UOM}
              </span>
            </SummaryRow>
            <SummaryRow>
              <DimGrayText>Estimated Travel Time</DimGrayText>
              <span>
                {segment?.info &&
                  getHoursAndMinutes(segment?.info?.drivingTime || 0)}
              </span>
            </SummaryRow>
            <SummaryRow>
              <DimGrayText>Estimated Service Time</DimGrayText>
              <span>
                {segment?.info &&
                  getHoursAndMinutes(segment?.info?.servingTime || 0)}
              </span>
            </SummaryRow>
            <SummaryRow>
              <DimGrayText>Estimated Total Time</DimGrayText>
              <span>
                {segment?.info &&
                  getHoursAndMinutes(segment?.info?.totalDuration || 0)}
              </span>
            </SummaryRow>
          </>
        </SummaryColumn>
        <DriverInfo>
          {segment?.Driver_Icon_Full_Path ? (
            <>
              <ImageContent
                data-cy="driver-detail-image"
                src={`${AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE}/${segment?.Driver_Icon_Full_Path}`}
              />
            </>
          ) : (
            <>
              <OpenPanelUserCircle size={66} weight="thin" />
            </>
          )}
          <SummaryRow>
            <ColumnWithGap>
              <RouteColumn>
                <DriverName>{segment?.driverName}</DriverName>
              </RouteColumn>
              {assetData && (
                <Tooltip
                  tooltip={'Open Asset Dashboard'}
                  trigger={
                    <LinkColumn
                      onClick={() =>
                        openWindowTab(
                          'asset_dashboard',
                          `/asset-dashboard/${assetData.idAsset}`,
                        )
                      }
                    >
                      <AssetName>{assetData?.Asset_Label}</AssetName>
                      <SmallSilverText>
                        {assetData?.Asset_Description}
                      </SmallSilverText>
                    </LinkColumn>
                  }
                  position={'top center'}
                />
              )}
            </ColumnWithGap>
            <ImageColumn>
              <RowWithGap gap="10px">
                {assetData && (
                  <Tooltip
                    tooltip={`View History Trail`}
                    trigger={<Path size={18} onClick={goToHistoryTrail} />}
                    position={'top center'}
                  />
                )}
                <Tooltip
                  tooltip={`Start Conversation`}
                  trigger={
                    <StyledChatCircleText
                      size={18}
                      onClick={() =>
                        navigate('/routing/messages', {
                          state: {
                            idUser: segment?.idUser,
                            from: `/routing/routes/${routeDetailData?.idPlan}/${routeDetailData?.idRouteDispatchDriver}`,
                            route: routeDetailData,
                          },
                        })
                      }
                    />
                  }
                  position={'top center'}
                />
              </RowWithGap>

              {assetData?.Asset_Icon_Full_Path ? (
                <>
                  <AssetImageContent
                    data-cy="driver-detail-image"
                    src={`${AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE}/${assetData?.Asset_Icon_Full_Path}`}
                  />
                </>
              ) : (
                <>{null}</>
              )}
            </ImageColumn>
          </SummaryRow>
        </DriverInfo>
      </RouteSummaryWrapper>
    </CollapsiblePanel>
  )
}

export default SummaryPanel
