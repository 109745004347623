import * as React from 'react'
import {useQueryClient} from '@tanstack/react-query'
import Axios from 'axios'
import styled from 'styled-components/macro'
import Toggle from 'common/components/Toggle'
import {CellContext} from '@tanstack/react-table'
import {ExistingLandmarkType} from './hooks/useLocations'

const updateIsActive = async (
  location: ExistingLandmarkType,
  checked: boolean,
) => {
  try {
    const {data} = await Axios.put(`/landmarks/${location.idLandmark}`, {
      IsActive: checked,
      Landmark_Category: location.Landmark_Category,
      idLandmark: location.idLandmark,
    })
    return data
  } catch (error) {
    console.error(error)
  }
}
const CheckboxActiveButton = styled.div`
  text-align: center;
`

const IsActiveCell = (row: CellContext<ExistingLandmarkType, unknown>) => {
  const location = row.row.original
  const cache = useQueryClient()
  const [isChecked, setIsChecked] = React.useState<boolean | null>(null)
  React.useEffect(() => {
    if (isChecked === null) {
      const checked = location ? location.IsActive : false
      setIsChecked(checked)
    }
  }, [location, isChecked])

  const updateActive = async (e: unknown, {checked}: {checked: boolean}) => {
    setIsChecked(Boolean(checked))
    await updateIsActive(location, checked)
    cache.invalidateQueries([`locationDetail-${location?.idLandmark}`])
    cache.removeQueries(['locations'])
  }
  return (
    <CheckboxActiveButton>
      <Toggle onChange={updateActive} checked={isChecked || false} />
    </CheckboxActiveButton>
  )
}

export default IsActiveCell
