import * as React from 'react'
import styled from 'styled-components/macro'

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: 20px;
  margin-top: 5px;
`

const Battery = styled.div`
  width: 19px;
  height: 15px;
  margin-top: 3px;
  border: 2px solid
    ${p =>
      p.percent < 11
        ? 'var(--asc-vermilion)'
        : p.percent < 35
        ? 'var(--asc-gold)'
        : 'var(--asc-moss)'};
  border-radius: 4px;
  position: relative;
  margin-left: 4px;
  margin-right: 7px;
  ::before {
    display: block;
    position: absolute;
    content: '';
    background-color: ${p =>
      p.percent < 11
        ? 'var(--asc-vermilion)'
        : p.percent < 35
        ? 'var(--asc-gold)'
        : 'var(--asc-moss)'};
    /* background-color: #000; */
    top: 1px;
    right: -5px;
    width: 2px;
    height: 8px;
    border-radius: 3px;
  }
  ::after {
    position: absolute;
    content: '';
    top: 1px;
    left: 1px;
    width: 10px;
    height: 9px;
    border: 1px solid
      ${p =>
        p.percent < 11
          ? 'var(--asc-vermilion)'
          : p.percent < 35
          ? 'var(--asc-gold)'
          : 'var(--asc-moss)'};
    background-color: ${p =>
      p.percent < 11
        ? 'var(--asc-vermilion)'
        : p.percent < 35
        ? 'var(--asc-gold)'
        : 'var(--asc-moss)'};
    width: ${p => (p.percent / 100) * 13}px;
  }
`
const Percentage = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
`

const BatteryMeter = ({percent}) => {
  return (
    <Container>
      <Battery percent={percent}></Battery>
      <Percentage>{`${percent}%`}</Percentage>
    </Container>
  )
}
export default BatteryMeter
