import * as React from 'react'
import {
  XCircle,
  Warning,
  VideoCamera,
  Cpu,
  Path,
  SquaresFour,
  DotsThreeCircle,
} from 'phosphor-react'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components/macro'

import {useStore} from 'common/useStore'
import useAssetSummarySchema from 'opening/hooks/useAssetSummarySchema'
import AssetTab from './AssetTab'
import DataTab from './DataTab'
import CommandsTab from './CommandsTab'
import AlertsTab from './AlertsTab'
import SensorsTab from './SensorsTab'
import BatteryMeter from './BatteryMeter'
import IconButton, {ActionTypes} from 'common/components/IconButton'
import QuickReportModal from 'common/components/QuickReportModal'

import FindNearestAssets from 'mapControl/FindNearestAssets'
import {
  useCheckUserRestriction,
  dashboardClick,
  checkAddDeleteEditAssetLowerLevels,
} from 'common/helpers'
import useAlertData from './hooks/useAlertData'
import useCameraStatusData from './hooks/useCameraStatusData'
import {useQueryClient} from '@tanstack/react-query'
import {
  openGetDirections,
  editAsset,
  addLocation,
  driverHistory,
  // checkCameraPermissions,
  onWakeUpCamera,
  // fetchAlertData,
  renderTimeData,
  returnTimeData,
} from './infoWindowHelpers'
import {
  InfoWindowContainer,
  CloseButton,
  InfoWindowArrow,
  Header,
  HeaderAssetLabel,
  HeaderLabel,
  SubHeader,
  TabContent,
  ButtonContainer,
  TimeStamp,
  TabAsset,
  BackIcon,
  VerticalBarSeparator,
  TimeAgo,
  LiveLook,
  HeaderDetails,
  SpinSpinnerGap,
  InfoWindowLabelRow,
  HeaderTitleAndButton,
  DesktopIconButton,
  ColorCorner,
  EquipmentID,
} from './infoWindowStyles'
import Pegman from 'images/Ascend-Pegman.svg'
import AssetUpdatedNotification from 'commandConsole/common/AssetUpdatedNotification'
import {UPDATE_INTERVAL_MILLISECONDS} from 'common/constants'
import CameraModal from './CameraModal'
import useSensorData from './hooks/useSensorData'
import useInterval from 'common/useInterval'
import {lookupColor} from 'map/mapHelpers'
import TripShare from 'tripshare/TripShareModal/TripShare'
import {convertToUsersDateAndTimeFormat} from 'common/helpersDateTime'
import {Asset} from 'common/types/opening1Response'
import {ActiveMarker} from 'common/types/typesModule'
import CameraEventRequestModal from 'cameras/CameraEventRequestModal'
import {ascendColors} from 'common/GlobalStyles'

const reducer = (
  state: InfoWindowStateType,
  action: {type: 'setState'; data: Partial<InfoWindowStateType>},
) => {
  switch (action.type) {
    case 'setState':
      return {...state, ...action.data}
    default:
      return state
  }
}

const AssetLabel = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export type ActiveTabType = 'asset' | 'data' | 'sensors' | 'alerts' | 'commands'

type InfoWindowStateType = {
  activeTab: ActiveTabType
  asset: Asset | null
  cameraLoading: boolean
  closeHover: boolean
  justUpdated: boolean
  timeFromNow: string
  showLiveView: boolean
  showTripModal: boolean
  showingQuickReportsModal: boolean
  showShareTripModal: boolean
  showConfirmModal: boolean
  showVideoRequest: boolean
}

const initialState: InfoWindowStateType = {
  activeTab: 'asset',
  asset: null,
  // cameraStatus: null,
  // alertData: '',
  // sensorData: '',
  cameraLoading: false,
  closeHover: false,
  justUpdated: false,
  timeFromNow: '',
  showLiveView: false,
  showTripModal: false,
  showingQuickReportsModal: false,
  showShareTripModal: false,
  showConfirmModal: false,
  showVideoRequest: false,
}

type InfoWindowProps = {
  //lat and lng needed for placement on map, not component
  lat: number
  lng: number
  closeInfoWindow: () => void
  activeMarker: ActiveMarker
  map: google.maps.Map
  toggleFollow: () => void
  toggleStreetView: () => void
  assets: Asset[]
  className?: string
  isMobile: boolean
  clearAssetFilter?: () => void
  left?: string
  bottom?: string
  fromRouteDetail?: boolean
}

// InfoWindow component
const InfoWindow = ({
  closeInfoWindow,
  activeMarker,
  map,
  toggleFollow,
  toggleStreetView,
  assets,
  className,
  isMobile,
  clearAssetFilter,
  left,
  bottom,
  fromRouteDetail = false,
}: InfoWindowProps) => {
  const userConfig = useStore(state => state.userConfig)
  const cache = useQueryClient()
  const {data: dcdSchema, status: dcdStatus} = useAssetSummarySchema()
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const {data: alertData} = useAlertData(activeMarker?.idAsset)
  const {data: cameraStatus, status: cameraStatusStatus} = useCameraStatusData(
    state.asset?.idAsset || 0,
    state.asset?.Device?.idDevice_Type || 0,
  )
  const {data: sensorData} = useSensorData(
    state.asset?.idAsset || 0,
    state.asset?.Sensor_Count || 0,
  )
  const canAddDeleteEditAssetLowerLevels = checkAddDeleteEditAssetLowerLevels()
  const setState = (data: Partial<InfoWindowStateType>) => {
    dispatch({type: 'setState', data})
  }

  const navigate = useNavigate()
  const showCameraButtons = useCheckUserRestriction('cameras')
  const previousUpdate = React.useRef(null)

  //update time from label regularly
  useInterval(() => {
    renderTimeData({
      deviceTime: state.asset?.Device.Last_Location_Time,
      previousUpdate,
      setState,
    })
  }, UPDATE_INTERVAL_MILLISECONDS)

  //watch for new asset or changes in current asset and update state
  React.useEffect(() => {
    if (assets && activeMarker?.idAsset) {
      const foundAsset = assets.find(
        asset => asset.idAsset === activeMarker.idAsset,
      )

      if (foundAsset) {
        let newData: Partial<InfoWindowStateType> = {}
        if (foundAsset.idAsset !== state.asset?.idAsset) {
          newData.activeTab = 'asset'
          previousUpdate.current = null
          newData.justUpdated = false
        }
        newData.asset = foundAsset
        //
        const timeData = returnTimeData({
          deviceTime: foundAsset?.Device.Last_Location_Time,
          previousUpdate,
          setState,
        })
        if (timeData) {
          newData = {...newData, ...timeData}
        }
        setState(newData)
      }
    }
  }, [activeMarker, assets, state.asset?.idAsset])

  const quickReport = (showModal: boolean) => {
    dispatch({
      type: 'setState',
      data: {
        showingQuickReportsModal: showModal,
      },
    })
  }

  const handleShowTripModal = (showModal: boolean) => {
    dispatch({
      type: 'setState',
      data: {showTripModal: showModal},
    })
  }

  let actions: ActionTypes[] = []

  if (userConfig?.UserRole?.idUserRole || 9 <= 1) {
    actions.push({
      value: 'AddLocation',
      text: 'Add Location',
      onClick: () => addLocation({navigate, asset: state.asset}),
      dataTestId: 'asset-bubble-addlocation',
    })
  }

  if (
    ((userConfig?.UserRole?.idUserRole &&
      userConfig?.UserRole?.idUserRole <= 1) ||
      userConfig?.RestrictedAdminAccess?.includes('cameras')) &&
    (state.asset?.Device?.idDevice_Type === 35 ||
      state.asset?.Device?.idDevice_Type === 11)
  ) {
    actions.push({
      value: 'RequestVideo',
      text: 'Request Video',
      onClick: () =>
        dispatch({
          type: 'setState',
          data: {showVideoRequest: true},
        }),
      dataTestId: 'asset-bubble-requestvideo',
    })
  }
  actions.push(
    {
      value: 'Details',
      text: 'View Details',
      onClick: () => setState({activeTab: 'data'}),
      dataTestId: 'asset-bubble-viewdetails',
    },
    {
      value: 'DriverHistory',
      text: 'Driver History',
      onClick: () => driverHistory({asset: state.asset}),
      dataTestId: 'asset-bubble-driverhistory',
    },
  )
  if (Boolean(userConfig?.UserRole?.idUserRole || 9 <= 1)) {
    actions.push({
      value: 'EditAsset',
      text: `${canAddDeleteEditAssetLowerLevels ? 'Edit' : 'View'} Asset`,
      onClick: () => editAsset({navigate, asset: state.asset}),
      dataTestId: 'asset-bubble-editasset',
    })
  }

  if (!fromRouteDetail) {
    actions.push(
      {
        value: 'FollowAsset',
        text: activeMarker.following ? 'Unfollow Asset' : 'Follow Asset',
        onClick: toggleFollow,
        dataTestId: activeMarker.following
          ? 'asset-bubble-unfollowasset'
          : 'asset-bubble-followasset',
      },
      {
        value: 'GetDirections',
        text: 'Get Directions',
        onClick: () => openGetDirections({navigate, asset: state.asset}),
        dataTestId: 'asset-bubble-getdirections',
      },
      {
        value: 'NearestAssets',
        text: 'Nearest Assets',
        dataTestId: 'asset-bubble-findneatestassets',
        content: (
          <FindNearestAssets
            map={map}
            lat={Number(state.asset?.Device.Latitude)}
            lng={Number(state.asset?.Device.Longitude)}
            clearAssetFilter={clearAssetFilter}
          />
        ),
      },
    )
  }

  actions.push(
    {
      value: 'QuickReport',
      text: 'Select Quick Report',
      onClick: quickReport,
      dataTestId: 'asset-bubble-selectquickreport',
    },
    {
      value: 'ShareTrip',
      text: 'Share Trip',
      onClick: handleShowTripModal,
      dataTestId: 'asset-bubble-sharetrip',
    },
  )

  // const timeFormat = userConfig?.Time_Format === '12HR' ? 'h:mm a' : 'H:mm a'
  // let localDateTime = state.asset?.Device.Last_Location_DateTime_Local
  //   ? new Date(state.asset?.Device.Last_Location_DateTime_Local)
  //   : null

  const tabNames = {
    alerts: 'Alerts',
    data: 'Details',
    sensors: 'Sensors',
    commands: 'Commands',
  }

  const equipmentIDShowing = Boolean(
    state.asset?.EquipmentID &&
      state.asset.EquipmentID !== state.asset.Asset_Label,
  )

  if (!state.asset) return <></>

  return (
    <InfoWindowContainer
      data-cy="infoWindow"
      onMouseDown={e => e.stopPropagation()}
      onMouseEnter={e => {
        e.stopPropagation()
        map?.setOptions && map.setOptions({scrollwheel: false})
      }}
      onMouseLeave={e => {
        e.stopPropagation()
        map?.setOptions && map.setOptions({scrollwheel: true})
      }}
      onDoubleClick={e => {
        e.stopPropagation()
      }}
      className={className}
      left={left}
      bottom={bottom}
    >
      <ColorCorner iconColor={lookupColor(state.asset.Icon_Color)} />
      <Header>
        <HeaderAssetLabel>
          <HeaderLabel $divHeight={equipmentIDShowing ? '100%' : '85%'}>
            <>
              {state.activeTab === 'asset' ? (
                <InfoWindowLabelRow>
                  <AssetLabel>{activeMarker?.label}</AssetLabel>
                  {state.justUpdated && <AssetUpdatedNotification />}
                </InfoWindowLabelRow>
              ) : (
                <HeaderTitleAndButton>
                  <BackIcon
                    size={20}
                    onClick={() => setState({activeTab: 'asset'})}
                  />
                  {tabNames[state.activeTab]}
                  <TabAsset>{activeMarker?.label}</TabAsset>
                </HeaderTitleAndButton>
              )}
            </>

            {equipmentIDShowing && (
              <EquipmentID>{state.asset.EquipmentID}</EquipmentID>
            )}
            <HeaderDetails>
              <TimeStamp tab={state.activeTab}>
                {state.asset?.Device.Last_Location_DateTime_Local
                  ? String(
                      convertToUsersDateAndTimeFormat(
                        new Date(
                          state.asset?.Device.Last_Location_DateTime_Local,
                        ),
                      ),
                    )
                  : ''}
                <VerticalBarSeparator>
                  {state.timeFromNow && ' | '}
                </VerticalBarSeparator>
                <TimeAgo>
                  {state?.timeFromNow && state.timeFromNow + ' ago'}
                </TimeAgo>
              </TimeStamp>
            </HeaderDetails>
          </HeaderLabel>
          <ButtonContainer>
            {state.asset?.Device?.Last_Battery_Percentage ? (
              <BatteryMeter
                percent={state.asset?.Device?.Last_Battery_Percentage}
              />
            ) : (
              <></>
            )}
          </ButtonContainer>
        </HeaderAssetLabel>
        {sensorData?.length && state.activeTab !== 'sensors' ? (
          <LiveLook onClick={() => setState({activeTab: 'sensors'})}>
            <Cpu weight="fill" size={15} />
            <span>Sensors</span>
          </LiveLook>
        ) : null}
        {/* Camera buttons */}
        {showCameraButtons && cameraStatusStatus === 'success' ? (
          <>
            {cameraStatus === 'standby' && (
              <LiveLook
                onClick={() =>
                  !state.cameraLoading &&
                  onWakeUpCamera({
                    asset: state.asset,
                    setState,
                    cache,
                  })
                }
                iconColor="var(--asc-gold)"
                fontOnSecondary={ascendColors.fontOnSecondary}
              >
                {state.cameraLoading ? (
                  <SpinSpinnerGap size={13} />
                ) : (
                  <VideoCamera weight="fill" size={15} />
                )}

                <span>{state.cameraLoading ? 'Loading' : 'Wake Up'}</span>
              </LiveLook>
            )}
            {cameraStatus === 'online' && (
              <LiveLook
                onClick={() => setState({showLiveView: true})}
                iconColor="var(--asc-sage)"
                fontOnSecondary={ascendColors.fontOnSecondary}
              >
                <VideoCamera weight="fill" size={15} />
                <span>Live View</span>
              </LiveLook>
            )}
            {cameraStatus === 'offline' && (
              <LiveLook onClick={() => setState({showLiveView: true})} disabled>
                <>
                  <VideoCamera
                    weight="fill"
                    size={15}
                    color={'var(--asc-sonicsilver)'}
                  />
                  <span>Offline</span>
                </>
              </LiveLook>
            )}
          </>
        ) : null}
        <CloseButton
          data-cy="info-window-close"
          data-testid="asset-bubble-close"
          onClick={() => {
            map?.setOptions && map?.setOptions({scrollwheel: true})
            closeInfoWindow()
          }}
          onMouseOver={() => setState({closeHover: true})}
          onMouseLeave={() => setState({closeHover: false})}
        >
          <XCircle size={30} weight={state.closeHover ? 'fill' : undefined} />
          {/* <Icon name="close" color="grey" /> */}
        </CloseButton>
      </Header>
      {state.activeTab === 'asset' && state.asset && (
        <SubHeader>
          <IconButton
            text="Alerts"
            Icon={Warning}
            fill={'fill'}
            onClick={() => {
              setState({activeTab: 'alerts'})
            }}
            color="var(--asc-vermilion)"
            hoverColor="var(--asc-lavender)"
            badgeContent={alertData?.length ? `${alertData.length}` : null}
            disabled={Boolean(!alertData?.length)}
            dataTestId="asset-bubble-alerts"
          />
          <IconButton
            text="Dashboard"
            Icon={SquaresFour}
            fill={'fill'}
            onClick={() => dashboardClick(activeMarker.idAsset, 'dashboard')}
            dataTestId="asset-bubble-dashboard"
          />

          <IconButton
            text="History Trail"
            Icon={Path}
            fill={'fill'}
            onClick={() => navigate(`/historytrail/${activeMarker.idAsset}`)}
            dataTestId="asset-bubble-historytrail"
          />
          <IconButton
            text="Street View"
            ImageIcon={Pegman}
            onClick={toggleStreetView}
            fill={'regular'}
            dataTestId="asset-bubble-streetview"
            // isImageIcon={true}
          />
          <DesktopIconButton
            text="More"
            iconSize={20}
            noIcon={true}
            Icon={DotsThreeCircle}
            options={actions}
            dataTestId="asset-bubble-more"
          />
        </SubHeader>
      )}
      <TabContent>
        {state.asset && state.activeTab === 'asset' ? (
          <AssetTab
            asset={state.asset}
            activeMarker={activeMarker}
            assetStatus={status}
            dcdStatus={dcdStatus}
            dcdSchema={dcdSchema}
            iconColor={lookupColor(state.asset.Icon_Color)}
          />
        ) : state.asset && state.activeTab === 'data' ? (
          <DataTab asset={state.asset} />
        ) : state.asset && state.activeTab === 'commands' ? (
          <CommandsTab asset={state.asset} map={map} />
        ) : state.asset && state.activeTab === 'alerts' ? (
          <AlertsTab
            alertData={alertData}
            userConfig={userConfig}
            isMobile={isMobile}
          />
        ) : state.asset && state.activeTab === 'sensors' ? (
          <SensorsTab sensorData={sensorData} />
        ) : (
          <></>
        )}
      </TabContent>
      {state.asset && (
        <QuickReportModal
          setShowingQuickReportsModal={quickReport}
          showingQuickReportsModal={state.showingQuickReportsModal}
          asset={state.asset}
        />
      )}
      {state.showTripModal && (
        <TripShare setShowTripModal={handleShowTripModal} asset={state.asset} />
      )}
      {state.showVideoRequest && (
        <CameraEventRequestModal
          closeModal={() =>
            dispatch({
              type: 'setState',
              data: {showVideoRequest: false},
            })
          }
          requestedCamera={{
            idAsset: state.asset.idAsset,
            idDevice: state.asset.Device.idDevice,
            idDevice_Type: state.asset.Device.idDevice_Type,
          }}
        />
      )}
      <InfoWindowArrow>
        <div className="left">
          <div></div>
        </div>
        <div className="right">
          <div></div>
        </div>
      </InfoWindowArrow>
      <CameraModal
        showLiveView={state.showLiveView}
        setState={setState}
        asset={state.asset}
      />
    </InfoWindowContainer>
  )
}

export default React.memo(InfoWindow)
