import {debounce} from 'lodash'
import * as React from 'react'
import {BREAKPOINTS} from './constants'

interface WindowSize {
  width: undefined | number
  height: undefined | number
  isMobile: boolean
}
const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState<WindowSize>({
    width: undefined,
    height: undefined,
    isMobile: false,
  })
  const isMounted = React.useRef(false)

  React.useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  React.useEffect(() => {
    const handleResize = debounce(
      () =>
        isMounted.current &&
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
          isMobile: window?.innerWidth <= BREAKPOINTS.tabletMax,
        }),
      200,
    )
    if (window && isMounted.current) {
      window.addEventListener('resize', handleResize)
      handleResize()
    }
    return () => {
      if (window) {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  return windowSize
}

export default useWindowSize
