type ServiceEtaState = {
  currentTab: string
  template: CustomerTemplateType | null
  errors: {
    EmailSubject: {message: string} | undefined
    EmailBody: {message: string} | undefined
    SmsBody: {message: string} | undefined
  }
  insertOptions: OptionType[] | null
  isDirty: boolean
  showDialog: boolean
  originalTemplate: CustomerTemplateType | null
}

type OptionType = {
  key: number
  Text: string
  value: number
  Preview: string
}

export type CustomerNotificationType = {
  idRDEtaDefaultTemplate: number
  NotificationName: string
  Category: string
  Description: string
  Enabled: boolean
  Tokens: OptionType[]
}

//used in both get and post hooks
// eslint-disable-next-line import/no-unused-modules
export type CustomerTemplateType = {
  idRDEtaDefaultTemplate: number
  Enabled: boolean
  NotificationName?: string
  EmailSubject?: string
  EmailBody?: string
  SmsBody?: string
  idRDEtaCustomerTemplate?: number
  idCustomer?: number
  AutoSend?: boolean
  SendEmail?: boolean
  SendSms?: boolean
}

type ReducerAction =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | {type: 'setState'; data: any}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | {type: 'setError'; data: any}
  | {type: 'resetState'}

export const getLoadedTemplate = ({
  customerTemplate,
  customerNotificationDefaults,
}: {
  customerTemplate: CustomerTemplateType
  customerNotificationDefaults: CustomerNotificationType
}) => {
  return {
    idRDEtaCustomerTemplate: customerTemplate?.idRDEtaCustomerTemplate || 0,
    idCustomer: customerTemplate?.idCustomer || 0,
    idRDEtaDefaultTemplate:
      customerTemplate?.idRDEtaDefaultTemplate ||
      customerNotificationDefaults.idRDEtaDefaultTemplate,
    NotificationName:
      customerTemplate?.NotificationName ||
      customerNotificationDefaults.NotificationName,
    Enabled: customerTemplate?.Enabled,
    AutoSend: customerTemplate?.AutoSend,
    SendEmail: customerTemplate?.SendEmail,
    EmailSubject: customerTemplate?.EmailSubject,
    EmailBody: customerTemplate?.EmailBody,
    SendSms: customerTemplate?.SendSms,
    SmsBody: customerTemplate?.SmsBody,
  }
}
export const initialState: ServiceEtaState = {
  currentTab: 'email',
  template: {
    idRDEtaCustomerTemplate: 0,
    idCustomer: 0,
    idRDEtaDefaultTemplate: 1,
    NotificationName: '',
    Enabled: true,
    AutoSend: true,
    SendEmail: true,
    EmailSubject: '',
    EmailBody: '',
    SendSms: true,
    SmsBody: '',
  },
  originalTemplate: null,
  insertOptions: null,
  errors: {EmailSubject: undefined, EmailBody: undefined, SmsBody: undefined},
  isDirty: false,
  showDialog: false,
}
export const reducer = (state: ServiceEtaState, action: ReducerAction) => {
  switch (action.type) {
    case 'setState':
      return {
        ...state,
        ...action.data,
      }
    case 'setError':
      return {
        ...state,
        errors: {...state.errors, ...action.data},
      }
    case 'resetState':
      return initialState
    default:
      return state
  }
}

export const checkErrors = ({template}: {template: CustomerTemplateType}) => {
  const errors = {
    EmailSubject:
      template.SendEmail && template.EmailSubject === ''
        ? {message: 'Must enter a subject'}
        : undefined,
    EmailBody:
      template.SendEmail && template.EmailBody === ''
        ? {message: 'Must enter a message'}
        : undefined,
    SmsBody:
      template.SendSms && template.SmsBody === ''
        ? {message: 'Must enter a message'}
        : undefined,
  }

  return errors
}
