import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

export interface ExistingLandmarkType extends LandmarkType {
  idLandmark: number
}

export interface LandmarkType {
  idLandmark?: number
  idLandmark_Style: number | null
  Landmark_Category: number
  Created_On?: string
  Created_By?: number | null
  Landmark_Name: string
  Landmark_Label: string | null
  Landmark_Type: 'Circle' | 'Polygon'
  Landmark_House_Nbr?: string
  Landmark_Address_Line1: string
  Landmark_Address_Line2: string
  Landmark_City: string
  Landmark_Region?: string
  Landmark_Postal_Code: string
  Landmark_Country?: string
  Landmark_Altitude?: number
  Landmark_Radius: number | null
  Start_Date?: string
  End_Date?: string
  Updated_By?: string
  Updated_On?: string
  IsActive: boolean
  ExternalID?: string
  IsShared: boolean
  Shared_idCustomer: number | null
  Landmark_UUID: string | null
  IsReadOnlyFromUI?: boolean | null
  CompanyName?: string
  CustomerPhoneNumber?: string | null
  ContactName: string
  ContactPhoneNumber: string
  ContactEmail?: string
  ServiceWindowStartTime?: string
  ServiceWindowEndTime?: string
  ServiceDuration?: number
  Landmark_Location_Text?: string
  Latitude: number | null
  Longitude: number | null
  Location_Text?: string
  Landmark_Style: string | null
  Created_By_UserName?: string
  Updated_By_UserName?: string
  CustomerType_Label?: string | null
  idCustomerType?: number | null
  Shared_idCustomerType?: number | null
  Shared_Customer_Name?: string | null
  AllowAdminToEdit?: boolean
}

const getLocations = async () => {
  const {data}: {data: ExistingLandmarkType[]} = await Axios.get(
    `/landmarks/?a=getlandmarksforhtmlformsbycategoryincludenotactive&category=1`,
  )
  data.sort((a, b) => {
    const x = a.Landmark_Name.toUpperCase()
    const y = b.Landmark_Name.toUpperCase()
    return x < y ? -1 : x > y ? 1 : 0
  })
  return data
}

const useLocations = () => {
  const user = useStore(state => state.user)
  return useQuery(['locations'], () => getLocations(), {
    enabled: Boolean(user?.token),
  })
}

export default useLocations
