import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

export interface UnassignedDevicesType {
  idDevice: number
  idDevice_Type: number
  Serial_Number_Displayed: string
  Event_Description?: string
  Device_Type: string
  EngineHoursSource: string
  OdometerSource: string
  SpeedSource: string
  SourceUIEnabled: boolean
  VSSOdometerSourceEnabled: boolean
  Created_On: string
  Last_Nearby_Address?: string
  Latitude?: number
  Longitude?: number
  Last_Location_Time?: string
  Last_Location_DateTime_Local?: string
  Last_Location_Time_Local?: string
  Last_Location_Date_Local?: string
  DeviceModel?: string
  SIMNBR?: string
}

const getUnassignedDevices = async () => {
  const {data}: {data: UnassignedDevicesType[]} = await Axios.get(
    '/devices/unassigned',
  )

  return data
}

export default function useUnassignedDevices() {
  const user = useStore(state => state.user)

  return useQuery(['unassignedDevices'], getUnassignedDevices, {
    enabled: Boolean(user?.token),
  })
}
