import React, {useEffect, useRef} from 'react'
import styled from 'styled-components/macro'
import {Form, Input} from 'semantic-ui-react'
import InputErrorMessage from './InputErrorMessage'
import {StyledLabel} from './StyledComponents'
import {ErrorType} from 'common/types'

type componentFormType = {
  street_number?: string
  route?: string
  locality?: string
  administrative_area_level_1?: string
  country?: string
  postal_code?: string
}

var componentForm: componentFormType = {
  street_number: 'short_name',
  route: 'long_name',
  locality: 'short_name',
  administrative_area_level_1: 'short_name',
  country: 'short_name',
  postal_code: 'short_name',
}

const AutoCompleteInput = styled(Form.Field)`
  display: flex;
  flex-direction: ${props => props.flexdirection};
  align-items: ${props => props.alignitems};
  width: ${props => props.widthpercent}%;
  label {
    span {
      color: var(--asc-vermilion);
      margin: 0px 3px;
    }
  }
  div {
    width: 100%;
  }
  input {
    padding: 5px;
    width: 100%;
  }
  #input-error-message {
    position: relative !important;
    bottom: -1px;
    width: fit-content;
  }
`

/*
  automatically fill the address in location detail  and saved object location information  for the location modal.
  */

export const createAddressObject = (place: google.maps.places.PlaceResult) => {
  var obj: Partial<componentFormType> = {}

  if (place.address_components) {
    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i]
        .types[0] as keyof componentFormType
      if (componentForm[addressType]) {
        //collect all the address object from reverse geocode
        const addressValue = place.address_components[i]
        const component = componentForm[
          addressType
        ] as keyof typeof addressValue
        var val = addressValue[component] as string
        obj[addressType] = val
      }
    }
  }
  //assign the full address to the location object
  return obj
}

export const changeAddressObjectToSTAGEFormat = (
  address: componentFormType,
) => {
  // const
  let streetAddress
  //check if the location object consist of address number and street name put both to the input
  //otherwise just give the street number for the location address line 1
  if (address.street_number && address.route) {
    //get the full address
    streetAddress = address.street_number + ' ' + address.route
  } else if (address.street_number && !address.route) {
    //only street number
    streetAddress = address.street_number
  } else if (!address.street_number && address.route) {
    //only route address
    streetAddress = address.route
  }
  //apply all the address fields
  const Landmark_Address_Line1 = streetAddress ? streetAddress : ''
  const Landmark_Region = address.administrative_area_level_1
    ? address.administrative_area_level_1
    : null
  const Landmark_City = address.locality ? address.locality : null
  const Landmark_Country = address.country ? address.country : 'US'
  const Landmark_Postal_Code = address.postal_code ? address.postal_code : ''
  return {
    Landmark_Address_Line1,
    Landmark_Region,
    Landmark_City,
    Landmark_Country,
    Landmark_Postal_Code,
  }
}

type Props = {
  updateAddress: (data: SearchLocationInputDispatchedDataType | null) => void
  setFormattedAddress: (formattedAddress: string) => void
  formattedAddress: string
  width?: number
  flexdirection?: string
  alignitems?: string
  className?: string
  errors?: ErrorType
  showTitle?: boolean
}

export type SearchLocationInputDispatchedDataType = {
  formattedAddress: string
  address: {
    Latitude: number | null
    Longitude: number | null
    Landmark_Address_Line1: string
    Landmark_Region: string | undefined
    Landmark_City: string
    Landmark_Country: string
    Landmark_Postal_Code: string
  }
  marker?: {center: google.maps.LatLng}
}

const SearchLocationInput = ({
  updateAddress,
  setFormattedAddress,
  formattedAddress,
  width,
  flexdirection,
  alignitems,
  className = '',
  errors,
  showTitle = true,
}: Props) => {
  const autoCompleteInputRef = useRef<HTMLInputElement | null>(null)
  const initialized = useRef<boolean>(false)
  const [addressIsSelected, setIsSelected] = React.useState(false)
  const [inputChanged, setInputChanged] = React.useState(false)

  useEffect(() => {
    const createAutocomplete = () => {
      //on init - create google autocomplete and set reference - class is added when autocomplete is created
      if (
        !autoCompleteInputRef.current ||
        !updateAddress ||
        initialized.current
      )
        return
      initialized.current = true
      const autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteInputRef.current,
        {types: ['geocode', 'establishment']},
      )
      if (autoComplete) {
        autoComplete.setFields([
          'address_components',
          'formatted_address',
          'geometry',
        ])
        autoComplete.addListener('place_changed', () => {
          const addressObject = autoComplete.getPlace()
          if (!addressObject) return
          setIsSelected(true)
          setInputChanged(false)

          const formattedAddressObject = createAddressObject(addressObject)
          const tempAddress = changeAddressObjectToSTAGEFormat(
            formattedAddressObject,
          )
          const address = {
            ...tempAddress,
            Latitude: addressObject?.geometry?.location?.lat() || null,
            Longitude: addressObject?.geometry?.location?.lng() || null,
            Landmark_City: tempAddress.Landmark_City || '',
            Landmark_Region: tempAddress.Landmark_Region || '',
          }
          updateAddress({
            address,
            formattedAddress: addressObject?.formatted_address || '',
            marker: {
              center: addressObject.geometry?.location as google.maps.LatLng,
            },
          })
        })
      }
    }
    createAutocomplete()
  }, [updateAddress])

  const handleBlur = () => {
    if (addressIsSelected && !inputChanged) return
    if (!formattedAddress || !addressIsSelected || inputChanged) {
      setIsSelected(false)
      setInputChanged(false)
      return updateAddress(null)
    }
  }

  return (
    <AutoCompleteInput
      widthpercent={width}
      flexdirection={flexdirection}
      alignitems={alignitems}
      className={className}
    >
      {showTitle && (
        <StyledLabel htmlFor="location-search">
          Address<span>*</span>
        </StyledLabel>
      )}
      <Input
        data-cy="location-search"
        aria-label="location-input"
        onChange={event => {
          setInputChanged(true)
          setFormattedAddress(event.target.value)
        }}
        placeholder=""
        value={formattedAddress || ''}
      >
        <input
          onBlur={handleBlur}
          ref={autoCompleteInputRef}
          data-cy="location-search-input"
        />
      </Input>
      {errors && errors['formattedAddress']?.message ? (
        <InputErrorMessage message={errors['formattedAddress']?.message} />
      ) : null}
    </AutoCompleteInput>
  )
}

export default SearchLocationInput
