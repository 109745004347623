import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

export type RouteSettingsType = {
  idRouteDispatchDefault: number
  idCustomer: number
  StartTime: string //xx:xx
  StartAMPM: string
  EndTime: string //xx:xx
  EndAMPM: string
  StartLocation: string
  EndLocation: string
  ServiceDuration: number //minutes
  Created_On: string //iso date
  Created_By: number //ascend Id
  Updated_On: string //iso date
  Updated_By: number //ascend Id
  BalancedWorkload: boolean
  VehicleType: 'Heavy' | 'Light'
}

const getRouteDefaultSettings = async () => {
  const {data}: {data: RouteSettingsType} = await Axios.get('/rd/plans/default')
  return data
}

export default function useGetRouteDefaultSettings() {
  const user = useStore(state => state.user)
  return useQuery(['routeDefaultSettings'], getRouteDefaultSettings, {
    enabled: Boolean(user?.token),
  })
}
