import * as React from 'react'
import styled from 'styled-components/macro'
import {useNavigate} from 'react-router-dom'
import {useCheckUserRestriction} from 'common/helpers'
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  div {
    font-weight: bold;
  }
`
const AddButton = styled.button`
  background: var(--secondary);
  color: var(--primary);
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  text-decoration: none;
  border-radius: 0.285714rem;
  font-weight: bold;
  cursor: pointer;
  :hover {
    color: var(--secondary);
    background: var(--primary);
  }
`

const AddAssetButton = () => {
  const navigate = useNavigate()
  const showButton = useCheckUserRestriction('assets')

  return (
    <ButtonContainer>
      {showButton ? (
        <AddButton onClick={() => navigate('/asset')}>ADD ASSET</AddButton>
      ) : (
        <div>No Assets Available</div>
      )}
    </ButtonContainer>
  )
}

export default AddAssetButton
