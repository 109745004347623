import * as React from 'react'
import {XCircle} from 'phosphor-react'
import {CloseButton} from './infoWindowStyles'
import {useStore} from 'common/useStore'
import styled from 'styled-components/macro'
import {parseISO, format} from 'date-fns'
import {Button} from 'semantic-ui-react'
import {useNavigate} from 'react-router-dom'

const InfoWindowContainer = styled.div`
  position: absolute;
  left: -233px;
  bottom: 70px;
  width: 467px;
  height: 263px;
  background-color: white;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  display: flex;
  flex-direction: column;
  cursor: initial;
  border-radius: 8px;
  font-family: 'Open Sans';
`

const Title = styled.div`
  font-size: 18px;
  span {
    font-weight: bold;
  }
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d2d2d7;
  padding: 15px 20px;
  height: 75px;
`
const InfoWindowArrow = styled.div`
  border-top-width: 24px;
  position: absolute;
  left: 140px;
  bottom: 0px;
  .left {
    position: absolute;
    overflow: hidden;
    left: 100px;
    bottom: -30px;
    width: 16px;
    height: 30px;
    div {
      position: absolute;
      left: 6px;
      background-color: rgb(255, 255, 255);
      transform: skewX(22.6deg);
      transform-origin: 0px 0px 0px;
      height: 24px;
      width: 10px;
      box-shadow: rgba(0, 0, 0, 0.6) 0px 1px 6px;
    }
  }
  .right {
    position: absolute;
    overflow: hidden;
    bottom: -30px;
    left: 116px;
    width: 16px;
    height: 30px;
    div {
      position: absolute;
      left: 0px;
      background-color: rgb(255, 255, 255);
      transform: skewX(-22.6deg);
      transform-origin: 10px 0px 0px;
      height: 24px;
      width: 10px;
      box-shadow: rgba(0, 0, 0, 0.6) 0px 1px 6px;
    }
  }
`

const Body = styled.div`
  width: 100%;
  padding: 20px;
  span {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-content: center;
    justify-content: center;
    height: 50px;
    font-size: 13px;
  }
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 5px;
`

const Label = styled.div`
  font-weight: bold;
  font-size: 14px;
  width: 25%;
`
const Value = styled.div`
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 75%;
`

const StyledButton = styled(Button)`
  &&& {
    padding: 10px 40px;
    background: var(--secondary);
    color: var(--primary);
    :hover {
      color: var(--secondary);
      background: var(--primary);
    }
  }
`
// InfoWindow component
const UnassignedInfoWindow = ({closeInfoWindow, device}) => {
  const navigate = useNavigate()
  const [closeHover, setCloseHover] = React.useState(false)
  const userConfig = useStore(state => state.userConfig)

  const timeFormat = userConfig?.Time_Format === '12HR' ? 'h:mm a' : 'H:mm a'
  // let localDateTime = device?.Last_Location_DateTime_Local
  //   ? new Date(device.Last_Location_DateTime_Local)
  //   : null
  let createdOn = ''

  if (device?.Created_On) {
    createdOn = format(
      parseISO(device?.Created_On),
      `${userConfig.Date_Format} ${timeFormat}`,
    )
  }
  if (!device) return <></>

  return (
    <InfoWindowContainer data-cy="infoWindow">
      <Header>
        <Title>
          <span>Serial #: {device.Serial_Number_Displayed}</span>{' '}
          {` (${device?.Device_Type})`}
        </Title>
        <CloseButton
          data-cy="asset-summary-close"
          onClick={() => {
            closeInfoWindow()
          }}
          onMouseOver={() => setCloseHover(true)}
          onMouseLeave={() => setCloseHover(false)}
        >
          <XCircle size={30} weight={closeHover ? 'fill' : null} />
          {/* <Icon name="close" color="grey" /> */}
        </CloseButton>
      </Header>
      <Body>
        <Row>
          <Label>Created On:</Label>
          <Value>{createdOn}</Value>
        </Row>
        <Row>
          <Label>Last Report:</Label>
          <Value>
            {(device?.Last_Location_Date_Local &&
              `${device?.Last_Location_Date_Local} ${device?.Last_Location_Time_Local}`) ||
              `---`}
          </Value>
        </Row>
        <Row>
          <Label>Address:</Label>
          <Value>{device?.Last_Nearby_Address}</Value>
        </Row>
        <Row>
          <Label>Lat & Long:</Label>
          <Value>
            {' '}
            {(device?.Latitude && `${device?.Latitude} ${device?.Longitude}`) ||
              `---`}
          </Value>
        </Row>
        <span>
          <StyledButton
            onClick={() => {
              navigate(`/asset?idDevice=${device.idDevice}`)
            }}
          >
            ASSIGN DEVICE TO AN ASSET
          </StyledButton>
        </span>
      </Body>
      <InfoWindowArrow>
        <div className="left">
          <div></div>
        </div>
        <div className="right">
          <div></div>
        </div>
      </InfoWindowArrow>
    </InfoWindowContainer>
  )
}

export default React.memo(UnassignedInfoWindow)
