import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'
import {DeviceType} from 'management/types/types'

const getDeviceTypes = async () => {
  const {data}: {data: DeviceType[]} = await Axios.get(`/devicetypes`)
  data.sort((a, b) => {
    if (a.Device_Description < b.Device_Description) return -1
    if (a.Device_Description > b.Device_Description) return 1
    return 0
  })
  data.map((d: DeviceType) => {
    return {
      ...d,
      text: d.Device_Description,
      value: d.idDevice_Type,
      key: d.idDevice_Type,
    }
  })
  return data
}

const useDeviceTypes = () => {
  const user = useStore(state => state.user)
  return useQuery(['devicetypes'], () => getDeviceTypes(), {
    enabled: Boolean(user.token),
  })
}

export default useDeviceTypes
