import Axios from 'axios'
import {useMutation} from '@tanstack/react-query'
import {showToast} from 'common/components/Toastr'

// Use Cases to know what we're doing with each item in the array:
// - Both idSegment & newIdSegment are null OR idSegment & newIdSegment have the same value: then we are only updating the stop service date times on the order/stop
// - idSegment has value & newIdSegment is null: then we are converting order to unassigned
// - idSegment is null & newIdSegment has value: then we are adding an unassigned order to the newIdSegment
// - Both idSegment & newIdSegment have different values: then delete order from idSegment and add it to newIdSegment
export type RouteChange = {
  idSegment: string | null //null if coming from unassigned
  newIdSegment: string | null //can be same as idSegment, null if unassigning the order
  idOrder: string
  idStop: string
  scheduledEarliestDate: string //Both ServiceStart and ServiceEnd should be null if we are only moving the order to a new segment without having to change the dates.
  scheduledLatestDate: string
}

type StopData = {
  idOrder: string
  idStop: string
  scheduledEarliestDate: string
  scheduledLatestDate: string
}

type ApiObject = {
  idSegment: string | null
  newIdSegment: string | null
  stops: StopData[]
}

const tweakSegments = async (routeChanges: RouteChange[]) => {
  const segments: ApiObject[] = []

  routeChanges.forEach(routeChange => {
    const idSegmentComboIndex = segments.findIndex(
      segment =>
        segment.idSegment === routeChange.idSegment &&
        segment.newIdSegment === routeChange.newIdSegment,
    )
    if (idSegmentComboIndex > -1) {
      segments[idSegmentComboIndex].stops.push({
        idOrder: routeChange.idOrder,
        idStop: routeChange.idStop,
        scheduledEarliestDate: routeChange.scheduledEarliestDate,
        scheduledLatestDate: routeChange.scheduledLatestDate,
      })
    } else {
      segments.push({
        idSegment: routeChange.idSegment,
        newIdSegment: routeChange.newIdSegment,
        stops: [
          {
            idOrder: routeChange.idOrder,
            idStop: routeChange.idStop,
            scheduledEarliestDate: routeChange.scheduledEarliestDate,
            scheduledLatestDate: routeChange.scheduledLatestDate,
          },
        ],
      })
    }
  })

  await Axios.put(`rd/segments/tweak`, {
    segments,
  })
}

const usePutTweakSegments = (fromRouteDetail?: boolean) => {
  return useMutation(tweakSegments, {
    onSuccess: () => {
      if (!fromRouteDetail)
        showToast('Successfully updated the routes', 'success')
    },
    onError: () => {
      showToast('An error occurred updating the routes', 'error')
    },
  })
}

export default usePutTweakSegments
