import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

const getManagementUsers = async () => {
  const {data} = await Axios.get(`/management/users`)
  return data
}

const useManagementUsers = () => {
  const user = useStore(state => state.user)
  return useQuery(['managementUsers'], () => getManagementUsers(), {
    enabled: Boolean(user.token),
  });
}

export default useManagementUsers
