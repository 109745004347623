import React, {useState} from 'react'
import styled from 'styled-components/macro'
import Modal from '../assets/Modal'
import {StyledInputTextArea} from './formElements/styledFormComponents'
// import {deviceSwapTypes} from './managementHelpers'
import Axios from 'axios'
import Swal from 'sweetalert2'
import Spinner from 'common/components/Spinner'
// import {TextAreaWithLabel} from 'common/components'
import {Dropdown as SemanticDropdown, DropdownProps} from 'semantic-ui-react'
import {EditDeviceType} from './TemedaAdmin/Devices/hooks/usePutDevice'

// import Typeahead from './formElements/Typeahead'

const ButtonContainer = styled.div`
  border-top: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-top: 10px;
`
const Button = styled.button`
  margin: 10px 5px 10px 0px;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const Input = styled.input`
  margin-bottom: 12px;
  padding: 5px;
`
const Label = styled.label`
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 5px;
`
const Note = styled.span`
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
`
const Dropdown = styled(SemanticDropdown)`
  &&& {
    margin-bottom: 10px;
    border: 1px solid var(--grey-500) !important;
    color: var(--grey-900);
    .default.text {
      color: var(--grey-600);
    }
  }
`
type Props = {
  idDevice: number | undefined
  hideModal: () => void
  clearModal: () => void
  onSwapSuccess?: () => void
  onSwapFailed?: () => void
  srcDevice: EditDeviceType | null
  hotSwapDevices: EditDeviceType[] | undefined
  selectedIdCustomer: number
}

type DeviceSwap = {
  swapWithIdDevice: number | null | undefined
  notes: string
  currentIdDevice: number | undefined
  currentDeviceIdCustomer?: number
  swapType: number
}

const swapTypeStringMap: {[key: number]: string} = {
  1: 'RMA',
  2: 'Tech Upgrade',
  3: 'Dealer Discretion',
}

function DeviceSwapModal(props: Props) {
  const {hideModal, clearModal, srcDevice, hotSwapDevices, selectedIdCustomer} =
    props
  const [swapWithIdDevice, setSwapWithIdDevice] = useState<
    number | null | undefined
  >(null)
  const [loading, setLoading] = useState(false)
  const [notes, setNotes] = useState('')
  const [swapType, setSwapType] = useState<number>()

  const postHotSwap = async (swap: DeviceSwap) => {
    try {
      const response = await Axios.post('/devices/swap', swap)
      if (response && response.status === 200) {
        clearModal()
        Swal.fire({
          icon: 'success',
          title: 'Device swap successful!',
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error swapping device.',
          text: `Please try again.`,
        })
      }
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: 'Error swapping device.',
        text: `Please try again.`,
      })
    }
    setLoading(false)
  }

  function onSwapClicked() {
    setLoading(true)
    if (swapType !== undefined) {
      const submitSwap = {
        swapWithIdDevice,
        notes,
        currentIdDevice: srcDevice?.idDevice,
        currentDeviceIdCustomer: selectedIdCustomer,
        swapType: swapType,
      }
      postHotSwap(submitSwap)
    }
  }

  const onSwapWithChanged = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    {value}: DropdownProps,
  ) => {
    const device = hotSwapDevices?.find(
      d => d.Serial_Number_Displayed === value,
    )
    if (device) {
      if (device.idDevice) {
        setSwapWithIdDevice(device.idDevice)
      }
      if (device.DeviceSwapType) {
        setSwapType(device.DeviceSwapType)
      }
    } else {
      setSwapWithIdDevice(null)
      setSwapType(undefined)
    }
  }

  return (
    <Modal title="Swap Device" onHide={hideModal}>
      {!loading ? (
        <Container>
          <Label>Swap Type:</Label>
          <Input
            value={srcDevice && swapType ? swapTypeStringMap[swapType] : ''}
            disabled
          />
          <Label>Serial Number:</Label>
          <Input value={srcDevice?.Serial_Number_Displayed} disabled />
          {(swapType === 2 || swapType === 1) && (
            <Note>
              <strong>Note: </strong>
              {`${srcDevice?.Serial_Number_Displayed} will be deactivated & removed from the account`}
            </Note>
          )}
          <Label>Swap with (New Serial Number):</Label>
          <Dropdown
            placeholder="Select or type serial number"
            search
            selection
            clearable
            selectOnBlur={false}
            onChange={onSwapWithChanged}
            // value={state?.asset?.idDevice}
            options={
              hotSwapDevices
                ? hotSwapDevices.map(d => ({
                    text: d.Serial_Number_Displayed,
                    value: d.Serial_Number_Displayed,
                  }))
                : []
            }
            data-cy="unassigned-device-select"
            name={name}
          />
          {/* <Typeahead
            onChange={onSwapWithChanged}
            label="Swap with (New Serial Number):"
            name="swapWithIdDevice"
            options={
              hotSwapDevices
                ? hotSwapDevices.map(d => d.Serial_Number_Displayed)
                : []
            }
          /> */}
          <Label>Notes</Label>
          <StyledInputTextArea
            value={notes}
            rows={7}
            onChange={e => setNotes(e.currentTarget.value)}
          />
          <ButtonContainer>
            <Button
              onClick={onSwapClicked}
              disabled={loading || !swapWithIdDevice}
            >
              Swap
            </Button>
          </ButtonContainer>
        </Container>
      ) : (
        <Spinner />
      )}
    </Modal>
  )
}

export default DeviceSwapModal
