import React from 'react'
import {Route, Routes} from 'react-router-dom'
import Error from 'common/Error'
const Orders = React.lazy(() => import('./Orders/Orders'))

const RoutesPage = React.lazy(() => import('./Routes/RoutesPage'))
const Setup = React.lazy(() => import('./Setup/Setup'))
const RoutePlans = React.lazy(() => import('./Routes/View/PlansList'))
const CreateRoute = React.lazy(() => import('./Routes/Create/CreateRoute'))
const RouteSettings = React.lazy(
  () => import('./Setup/routeSettings/RouteSettings'),
)
const RouteLocationsList = React.lazy(
  () => import('./Setup/routeSettings/Route Locations/RouteLocationsList'),
)
const ServiceTypesList = React.lazy(
  () => import('./Setup/routeSettings/Service Types/ServiceTypesList'),
)
const DriverSettings = React.lazy(
  () => import('./Setup/driverSettings/list/DriverListSettings'),
)
const DriverAttributesList = React.lazy(
  () => import('./Setup/driverSettings/Driver Attributes/DriverAttributesList'),
)
const ImportSettings = React.lazy(
  () => import('./Setup/routeSettings/ImportSettings'),
)
const EditDriverSettings = React.lazy(
  () => import('./Setup/driverSettings/detail/DriverDetailSettings'),
)
const OrderDetail = React.lazy(() => import('./Orders/Order/OrderDetail'))
const OrdersImport = React.lazy(() => import('./Orders/Import'))
const OrdersList = React.lazy(() => import('./Orders/List/OrdersList'))
const Conversations = React.lazy(() => import('./conversations/Conversations'))

const RoutePlanReview = React.lazy(
  () => import('./Routes/PlanReview/PlanReviewContainer'),
)

const RouteEditing = React.lazy(() => import('./RouteEditing/RouteEditingPage'))

import FourOhFour from 'common/FourOhFour'
import RouteDetail from './Routes/Route Detail/RouteDetail'
import LocationList from 'locations/LocationList'
import CustomerNotifications from './Setup/routeSettings/Customer Notifications/CustomerNotifications'

export default function RoutingRoutes() {
  return (
    <Routes>
      <Route errorElement={<Error />}>
        <Route path={`orders`} element={<Orders />} />
        <Route
          path={`orders/locations`}
          element={<LocationList isRouteOptimization={true} />}
        />
        <Route path={`orders/list`} element={<OrdersList />} />
        <Route path={`orders/order`} element={<OrderDetail />} />
        <Route path={`orders/import`} element={<OrdersImport />} />
        <Route path={`orders/:id`} element={<OrderDetail />} />

        <Route path={`setup/route-settings`} element={<RouteSettings />} />
        <Route
          path={`setup/route-locations`}
          element={<RouteLocationsList />}
        />
        <Route path={`setup/service-types`} element={<ServiceTypesList />} />
        <Route path={`setup/import-settings`} element={<ImportSettings />} />
        <Route path={`setup/driver-settings`} element={<DriverSettings />} />
        <Route
          path={`setup/customer-notifications`}
          element={<CustomerNotifications />}
        />
        <Route
          path={`setup/driver-attributes`}
          element={<DriverAttributesList />}
        />
        <Route
          path={`/setup/edit-driver-settings/:id`}
          element={<EditDriverSettings />}
        />
        <Route path={`setup`} element={<Setup />} />

        <Route path={`routes/create-route/:id?`} element={<CreateRoute />} />
        <Route path={`routes`} element={<RoutesPage />} />
        <Route path={`routes/list`} element={<RoutePlans />} />
        <Route path={`routes/:id`} element={<RoutePlanReview />} />
        <Route
          path={`routes/:idPlan/:idRouteDispatchDriver`}
          element={<RouteDetail />}
        />

        <Route path={'messages'} element={<Conversations />} />
        <Route path={'messages/:id'} element={<Conversations />} />
        <Route path={'editing'} element={<RouteEditing />} />
        <Route path="*" element={<FourOhFour />} />
      </Route>
    </Routes>
  )
}
