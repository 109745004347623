import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'
import {getBeforeAfterStringForAPI} from 'common/helpersDateTime'
import {subDays} from 'date-fns'

const getAlertData = async (idAsset: number) => {
  if (idAsset) {
    const twentyFourHoursAgo = subDays(new Date(), 1)
    const range = {startDate: twentyFourHoursAgo}
    const dateString = getBeforeAfterStringForAPI(range)
    const {data} = await Axios.get(
      `/assets/${idAsset}/alertevents?${dateString}`,
    )
    return data
  } else {
    return []
  }
}

export default function useAlertData(idAsset: number) {
  const user = useStore(state => state.user)
  return useQuery(['alertData', idAsset], () => getAlertData(idAsset), {
    enabled: Boolean(user?.token),
  })
}
