import ExcelJS from 'exceljs'
import {saveAs} from 'file-saver'

export const saveAsCSV = async (columns, data, fileName) => {
  const workbook = new ExcelJS.Workbook()
  workbook.created = new Date()
  workbook.modified = new Date()
  const worksheet = workbook.addWorksheet()

  const headers = columns.map(c => c.exportHeader || c.exportValue)

  worksheet.addRow(headers)
  worksheet.addRows(data)

  const buf = await workbook.csv.writeBuffer()

  saveAs(new Blob([buf]), `${fileName || 'Export'}.csv`)
}
