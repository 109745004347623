import * as React from 'react'
import styled from 'styled-components/macro'
import {StyledLabel} from 'common/components/StyledComponents'
import {Popup} from 'semantic-ui-react'
import {Question} from 'phosphor-react'
import {RequiredAsterisk} from './InputWithLabel'
const ToolTipTrigger = styled.span`
  padding: 0 5px;
`
const LabeledWithHelp = ({
  label,
  tooltip,
  htmlFor,
  required = false,
  noMargin = false,
}) => {
  return (
    <StyledLabel htmlFor={htmlFor} noMargin={noMargin}>
      <span>
        {label}
        {required && <RequiredAsterisk />}
      </span>
      {tooltip && (
        <Popup
          content={tooltip}
          on="hover"
          trigger={
            <ToolTipTrigger>
              <Question size={15} />
            </ToolTipTrigger>
          }
        />
      )}
    </StyledLabel>
  )
}

export default LabeledWithHelp
