/* eslint-disable max-lines */
import GoogleMapReact from 'google-map-react'
import {unstable_batchedUpdates} from 'react-dom' //https://docs.pmnd.rs/zustand/guides/event-handler-in-pre-react-18
import {RoutePlanType, Segment} from 'routingAndDispatch/types/typesModule'
import {
  BottomArrow,
  MarkerContainer,
  MarkerContainerForArrow,
  StopText,
  StyledChatTeardropForMap,
  SvgRow,
} from './routeDetailStyles'
import {House, FlagCheckered} from 'phosphor-react'
import React from 'react'
import {
  getStartingBounds,
  useHistoryTrailStore,
} from 'historyTrail/hooks/useHistoryTrailStore'
import {useStore} from 'common/useStore'
import {format, isSameDay} from 'date-fns'
import {
  StopReducerAction,
  StopState,
  ViewableStop,
} from 'routingAndDispatch/Stops/stopHelper'
import {HistoryTrailPoint} from 'historyTrail/hooks/historyTrailTypes'

import {getIcon, plotMarkers, getType} from 'historyTrail/plotMarkers'
import {RoutePlanWithColor} from '../PlanReview/PlanReview'
import {useRouteDetailStore} from './hooks/useRouteDetailStore'
import {ColumnDef} from '@tanstack/react-table'

export const createMapOptions = (maps: GoogleMapReact.Maps) => {
  return {
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.DEFAULT,
      position: maps.ControlPosition.BOTTOM_RIGHT,
      mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE],
    },
    tilt: 0,
    rotateControl: false,
    mapTypeControl: true,
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
    },
    streetViewControl: true,
    streetViewControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
    },
    fullscreenControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
    },
  }
}

export const getSegmentLatLngs = (segment: Segment) => {
  const lastStop = segment.stops[segment.stops.length - 1]
  const startStop = {
    lat: segment.stops[0]?.location.lat,
    lng: segment.stops[0]?.location.lng,
  }
  const endStop = {
    lat: lastStop?.location.lat,
    lng: lastStop?.location.lng,
  }
  const waypoints: google.maps.DirectionsWaypoint[] = []
  segment.stops?.forEach((stop, index) => {
    if (
      stop.location.lat &&
      stop.location.lng &&
      index !== 0 &&
      index !== segment.stops.length - 1 &&
      stop.location.lat !== segment.stops[index - 1].location.lat &&
      stop.location.lat !== segment.stops[index - 1].location.lng
    ) {
      waypoints.push({
        location: {lat: stop.location.lat, lng: stop.location.lng},
        stopover: false,
      })
    }
  })
  return {
    start: startStop,
    end: endStop,
    waypoints,
  }
}

interface MarkerProps {
  text: string
  lat: number
  lng: number
  className?: string
  markerColors: {
    backgroundColor: string
    color: string
    borderColor: string
  }
}
export const StopMarker: React.FC<MarkerProps> = ({
  text,
  className,
  markerColors,
}) => {
  return (
    <MarkerContainer className={className}>
      <StyledChatTeardropForMap
        weight={'duotone'}
        size={35}
        data-cy="map-marker-stop"
        backgroundcolor={markerColors.backgroundColor}
        color={markerColors.borderColor}
      />
      <StopText color={markerColors.color}>{text}</StopText>
    </MarkerContainer>
  )
}

export type DateRangeType = {
  startDate: Date | ''
  endDate: Date | ''
}

export const getSegmentDateRange = (
  plan: RoutePlanType | null,
): DateRangeType => {
  if (plan?.segments && plan.segments[0]) {
    const segment = plan.segments[0]
    const stopLength = segment?.stops.length > 0
    const lastCard = stopLength ? segment.stops.length - 1 : 0

    // Convert arrival and departure to Date() object
    //Use earlier of plan start or actual departure time
    const firstStopDate =
      segment.stops[0]?.DepartureDate &&
      new Date(segment.stops[0].DepartureDate)
    const planStartDate = plan.planStartDateTime
    let startDate =
      firstStopDate && firstStopDate < planStartDate
        ? firstStopDate
        : planStartDate

    //check if there is an actual arrival date at last stop and if so use that, otherwise use scheduled service start on last stop
    const dateTimeActualArrivalDate = segment.stops[lastCard]?.ArrivalDate
      ? new Date(segment.stops[lastCard].ArrivalDate + '')
      : ''

    const endDate =
      dateTimeActualArrivalDate &&
      dateTimeActualArrivalDate > plan.planEndDateTime
        ? dateTimeActualArrivalDate
        : plan.planEndDateTime

    return {
      startDate: startDate,
      endDate: endDate,
    }
  } else {
    return {startDate: '', endDate: ''}
  }
}

// export const MissedStopMarker: React.FC<MarkerProps> = ({text}) => {
//   return (
//     <MarkerContainer>
//       <StyledChatTeardropForMap
//         color={'#ffffff'}
//         weight={'duotone'}
//         size={35}
//         data-cy="map-marker-stop"
//       />
//       <StopText className="circleText">{text}</StopText>
//     </MarkerContainer>
//   )
// }

export const SameEndpointMarker: React.FC<MarkerProps> = ({
  text,
  className,
  markerColors,
}) => {
  return (
    <MarkerContainer className={className}>
      <MarkerContainerForArrow
        data-cy="map-marker-same-endpoint"
        color={markerColors.borderColor}
      >
        <span>{text}</span>
        <SvgRow>
          <House size={18} weight={'fill'} color={'var(--asc-moss)'} />
          <FlagCheckered size={18} weight={'fill'} color={'#000000'} />
        </SvgRow>
      </MarkerContainerForArrow>
      <BottomArrow color={markerColors.borderColor}>
        <div className="left">
          <div></div>
        </div>
        <div className="right">
          <div></div>
        </div>
      </BottomArrow>
    </MarkerContainer>
  )
}
export const StartMarker: React.FC<MarkerProps> = ({text, markerColors}) => {
  return (
    <MarkerContainer>
      <MarkerContainerForArrow
        color={markerColors.borderColor}
        data-cy="map-marker-start"
      >
        <span>{text}</span>
        <House size={18} weight={'fill'} color={'var(--asc-moss)'} />
      </MarkerContainerForArrow>
      <BottomArrow color={markerColors.borderColor}>
        <div className="left">
          <div></div>
        </div>
        <div className="right">
          <div></div>
        </div>
      </BottomArrow>
    </MarkerContainer>
  )
}
export const EndMarker: React.FC<MarkerProps> = ({text, markerColors}) => {
  return (
    <MarkerContainer>
      <MarkerContainerForArrow
        color={markerColors.borderColor}
        data-cy="map-marker-end"
      >
        <span>{text}</span>
        <FlagCheckered size={18} weight={'fill'} color={'#000000'} />
      </MarkerContainerForArrow>
      <BottomArrow color={markerColors.borderColor}>
        <div className="left">
          <div></div>
        </div>
        <div className="right">
          <div></div>
        </div>
      </BottomArrow>
    </MarkerContainer>
  )
}

// export const onMapLoad = (
//   map: google.maps.Map,
//   maps: google.maps.Map,
//   setMap: (map: google.maps.Map) => void,
//   setMaps: (maps: google.maps.Map) => void,
//   // mapRef?: React.MutableRefObject<{map: google.maps.Map} | undefined>,
// ) => {
//   // if (mapRef && !mapRef?.current) {
//   //   mapRef.current = {map}
//   // }

// }

//TODO - fix these anys
export const drawHistoryTrail = ({
  data,
  map,
  bounds,
}: {
  //don't know the data for history trail. todo
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
  map: google.maps.Map
  bounds: google.maps.LatLngBounds
}): google.maps.LatLngBounds => {
  if (map) {
    const setPoints = useHistoryTrailStore.getState().setPoints
    if (data?.length) {
      bounds = getStartingBounds(data)
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const points = data.map((point: any) => {
      const type = getType(point)
      const newPoint = {...point, ...type}
      const icon = getIcon(window.google.maps, newPoint)
      newPoint.icon = icon
      return newPoint
    })
    plotMarkers(points, map, window.google.maps)
    setPoints(points)
  }
  return bounds
}

interface State extends StopState {
  columns: ColumnDef<Segment>[]
  title: string
  serviceType: string
  description: string
  idPlan: string
  renderStartDate?: Date
  renderEndDate?: Date
  historyTrailPoints?: HistoryTrailPoint[]
  routePlanDriverSegment: RoutePlanWithColor | null
  dateToView: Date
  showNotificationModal: boolean
  selectedNotification: number | null
  showNotificationSelectionModal: boolean
  updatedStopStatus: boolean
  submitting: boolean
}

export type ReducerType = [
  state: State,
  dispatch: (action: StopReducerAction) => void,
]

export const reducer = (state: State, action: StopReducerAction) => {
  switch (action.type) {
    case 'setState': {
      if (typeof action.data.formattedAddress === 'string') {
        return {
          ...state,
          stop: {
            ...state.stop,
            formattedAddress: action.data.formattedAddress,
          },
          isDirty: true,
        }
      }
      return {
        ...state,
        ...action.data,
      }
    }
    case 'setCleanState': {
      return {
        ...state,
        ...action.data,
      }
    }
    case 'setCleanStopState': {
      return {
        ...state,
        stop: {...state.stop, ...action.data},
      }
    }
    case 'updateAddress': {
      const lat = action.data.marker?.center.lat() || 0
      const long = action.data.marker?.center.lng() || 0
      const errors = state.errors
      if (errors['formattedAddress']) {
        errors['formattedAddress'].message = ''
      }
      return {
        ...state,
        stop: {
          ...state.stop,
          formattedAddress: action.data.formattedAddress,
          address: action.data.address.Landmark_Address_Line1,
          city: action.data.address.Landmark_City,
          state: action.data.address.Landmark_Region,
          zipCode: action.data.address.Landmark_Postal_Code,
          marker: action.data.marker,
          location: {lat: lat, lng: long},
        },
        isDirty: true,
        errors,
      }
    }
    case 'setErrors': {
      const errors = {
        formattedAddress: {message: ''},
        scheduledDate: {message: ''},
        contact: {message: ''},
        phone: {message: ''},
        email: {message: ''},
        serviceWindow: {message: ''},
        latitude: {message: ''},
        longitude: {message: ''},
        title: {message: ''},
      }
      //Empty required fields
      if (
        action.data.formattedAddress === '' &&
        state.showAddressOrCoordinates === 'address'
      ) {
        errors['formattedAddress'].message = 'Missing Address'
      }
      if (action.data.idSegment && action.data.title === '') {
        errors['title'].message = 'Missing Title'
      }
      if (
        action.data.latitude === '' &&
        state.showAddressOrCoordinates === 'coordinates'
      ) {
        errors['latitude'].message = `Missing Latitude`
      }
      if (
        action.data.longitude === '' &&
        state.showAddressOrCoordinates === 'coordinates'
      ) {
        errors['longitude'].message = `Missing Longitude`
      }
      if (action.data.scheduledDate === '') {
        errors['scheduledDate'].message = 'Missing Date'
      }
      if (action.data.contact === '') {
        errors['contact'].message = 'Missing Contact'
      }
      if (action.data.phone.phone === '' || !action.data.phone.valid) {
        errors['phone'].message =
          action.data.phone.phone === ''
            ? 'Missing Phone Number'
            : 'Invalid Phone Number'
      }
      if (action.data.email.email === '' || !action.data.email.valid) {
        errors['email'].message =
          action.data.email.email === '' ? 'Missing Email' : 'Invalid Email'
      }
      if (
        action.data.startTime.minute === 0 &&
        action.data.startTime.hour === 0
      ) {
        errors['serviceWindow'].message = 'Invalid Start Time'
      }

      return {
        ...state,
        errors,
      }
    }
    case 'setTimeState': {
      const newState = {[action.data.inputId]: action.data.value}
      return {
        ...state,
        stop: {
          ...state.stop,
          ...newState,
        },
        isDirty: true,
      }
    }
    case 'setDurationState': {
      let newDurationState = {}
      const currentMinutes = state?.stop?.estimatedDuration?.mins || 0
      const currentHours = state?.stop?.estimatedDuration?.hrs || 0

      if (action.data.type === 'hours') {
        newDurationState = {
          hrs: action.data.value,
          mins: currentMinutes,
        }
      }
      if (action.data.type === 'mins') {
        newDurationState = {
          hrs: currentHours,
          mins: action.data.value,
        }
      }

      const estimatedDurationInSeconds =
        currentHours * 3600 + currentMinutes * 60

      return {
        ...state,
        stop: {
          ...state.stop,
          estimatedDuration: newDurationState,
          serviceDuration: estimatedDurationInSeconds,
        },
        isDirty: true,
      }
    }
    case 'toggleAddressCoordinates':
      return {
        ...state,
        showAddressOrCoordinates: action.data,
      }
    case 'loadServiceTypes': {
      const options = action.data.map((serviceType: string, index: number) => {
        return {
          key: index,
          value: serviceType,
          text: serviceType,
        }
      })
      return {
        ...state,
        availableServiceTypes: options,
      }
    }
    case 'resetErrors': {
      const errors = state.errors
      //Empty required fields
      if (action.data.formattedAddress) {
        errors['formattedAddress'].message = ''
      }
      if (action.data.scheduledDate) {
        errors['scheduledDate'].message = ''
      }
      if (action.data.contact) {
        errors['contact'].message = ''
      }
      if (action.data.phone) {
        errors['phone'].message = ''
      }
      if (action.data.email) {
        errors['email'].message = ''
      }
      if (action.data.serviceWindow) {
        errors['serviceWindow'].message = ''
      }
      if (action.data.latitude) {
        errors['latitude'].message = ''
      }
      if (action.data.longitude) {
        errors['longitude'].message = ''
      }
      return {
        ...state,
        errors,
      }
    }
    default:
      console.error('Action Type not recognized: ', action)
      return {
        ...state,
      }
  }
}

const userConfig = useStore.getState().userConfig
const dateFormat = userConfig?.Date_Format || 'MM/dd/yyyy'
const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)
const tomorrowFormatted = format(tomorrow, dateFormat)
const initialStartTime = tomorrowFormatted + ' 8:00:00'
const initialStartDateTime = new Date(initialStartTime)
const initialEndTime = tomorrowFormatted + ' 17:00:00'
const initialEndDateTime = new Date(initialEndTime)

export const initialStop: ViewableStop = {
  idStop: '',
  estimatedDuration: {hrs: 0, mins: 0},
  dateRange: {
    startDate: initialStartDateTime,
    endDate: initialEndDateTime,
  },
  dateRangeError: '',
  formattedAddress: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  stopType: 'Service',
  location: {lat: 0, lng: 0},
  serviceDuration: null,
}
export const initialState: State = {
  columns: [],
  showStopModal: false,
  isDirty: false,
  currentStopLoaded: false,
  showAddressOrCoordinates: 'address',
  routePlanDriverSegment: null,
  errors: {},
  stop: initialStop,
  title: '',
  serviceType: '',
  description: '',
  idPlan: '',
  historyTrailPoints: [],
  dateToView: initialStartDateTime,
  showNotificationModal: false,
  updatedStopStatus: false,
  submitting: false,
  selectedNotification: null,
  showNotificationSelectionModal: false,
}

export const getDates = (route: RoutePlanType) => {
  const dates: Date[] = []
  if (!route.segments) return dates
  route?.segments?.forEach(segment => {
    segment.stops.forEach(stop => {
      if (stop.ServiceStartDateTime) {
        const isDuplicateDate = dates.find(day => {
          return stop.ServiceStartDateTime
            ? isSameDay(day, new Date(stop.ServiceStartDateTime))
            : false
        })

        if (!isDuplicateDate) {
          dates.push(new Date(stop.ServiceStartDateTime))
        }
      }
    })
  })
  if (!dates.find(day => isSameDay(day, new Date(route.planStartDateTime)))) {
    dates.push(new Date(route.planStartDateTime))
  }
  if (!dates.find(day => isSameDay(day, new Date(route.planEndDateTime)))) {
    dates.push(new Date(route.planEndDateTime))
  }

  dates.sort((a, b) => a.getTime() - b.getTime())
  return dates
}

type RenderRoutesProps = {
  id: string
  points: {
    start: {
      lat: number
      lng: number
    }
    end: {
      lat: number
      lng: number
    }
    waypoints: google.maps.DirectionsWaypoint[]
  }
  color: string
  bounds: google.maps.LatLngBounds | null
  type?: string
}

export const removeAllRoutes = () => {
  const existingDirections = useRouteDetailStore.getState().directionsRendered
  existingDirections.forEach(d => {
    unstable_batchedUpdates(() => {
      useRouteDetailStore.getState().removeDirection(d.id)
    })
  })
}

export const renderRoutes = ({
  id,
  points,
  color,
  bounds,
  type,
}: RenderRoutesProps) => {
  const map = useRouteDetailStore.getState().map
  const origin = points.start
  const destination = points.end
  const waypoints = points.waypoints
  const rendererOptions = {
    preserveViewport: true,
    map: map,
    suppressMarkers: true,
    draggable: false,
    polylineOptions: {
      strokeColor: (color || '#5f3b0c') + 'bf', //bf is hex code opacity value of 75%
      strokeWeight: 5,
    },
  }
  const existingDirections = useRouteDetailStore.getState().directionsRendered
  const foundDirections = existingDirections.find(d => d.id === id)
  if (foundDirections && type !== 'add') {
    //remove route was clicked
    useRouteDetailStore.getState().removeDirection(id)

    return null
  } else if (!foundDirections) {
    const directionsService = new google.maps.DirectionsService()
    // const directionsRenderer = new google.maps.DirectionsRenderer(rendererOptions)
    // directionsRenderer.setMap(map)

    // Divide route to several parts because max stop limit is 20 (18 waypoints + 1 origin + 1 destination)
    const routeSections: google.maps.DirectionsWaypoint[][] = [[]]

    routeSections[0] = [
      {location: origin, stopover: false} as google.maps.DirectionsWaypoint,
      ...waypoints.slice(0, 22),
    ]
    if (waypoints.length > 21) {
      routeSections[1] = waypoints.slice(21, 43)
    }
    if (waypoints.length > 43) {
      routeSections[2] = waypoints.slice(42, 64)
    }
    if (waypoints.length > 64) {
      routeSections[3] = waypoints.slice(63, 83)
    }
    if (waypoints.length > 83) {
      routeSections[4] = waypoints.slice(82, 101)
    }
    routeSections[routeSections.length - 1].push({
      location: destination,
      stopover: false,
    } as google.maps.DirectionsWaypoint)
    // waypointsIncludingEndpoints.length < 100 &&
    //   waypointsIncludingEndpoints.forEach((waypoint, index) => {
    //     const partIndex = Math.floor(index / 20)
    //     if ((index + 1) % 20 === 0) {
    //       routeSections.push([])
    //     }
    //     routeSections[partIndex].push(waypoint)
    //   })

    routeSections.forEach(section => {
      // Waypoints does not include first station (origin) and last station (destination)
      const middleWaypoints: google.maps.DirectionsWaypoint[] = []
      section.forEach((waypoint, index) => {
        if (index === 0 || index === section.length - 1) return
        middleWaypoints.push({location: waypoint.location, stopover: false})
      })

      const serviceOptions: google.maps.DirectionsRequest = {
        origin: section[0]?.location || {},
        destination: section[section.length - 1]?.location || {},
        waypoints: middleWaypoints,
        travelMode: google.maps.TravelMode.DRIVING,
      }
      // Send request

      serviceOptions.origin &&
        serviceOptions.destination &&
        directionsService.route(serviceOptions, (result, status) => {
          if (status === google.maps.DirectionsStatus.OK && result !== null) {
            const directionsToDisplay = new google.maps.DirectionsRenderer(
              rendererOptions,
            )
            directionsToDisplay.setDirections(result)

            useRouteDetailStore
              .getState()
              .addDirection({id, direction: directionsToDisplay})

            const routeBounds =
              directionsToDisplay &&
              directionsToDisplay.getDirections()?.routes[0].bounds
            if (routeBounds && bounds) {
              bounds.extend(routeBounds.getNorthEast())
              bounds.extend(routeBounds.getSouthWest())
            }
          } else {
            console.error(`error fetching directions ${result}`)
          }
        })
    })

    if (bounds) {
      bounds.extend(origin)
      bounds.extend(destination)
      waypoints.forEach(
        point =>
          point.location && bounds.extend(point.location as google.maps.LatLng),
      )
    }
    return bounds
  } else {
    return bounds
  }
}

type TimeAndDistanceProps = {
  points: {
    start: {
      lat: number
      lng: number
    }
    end: {
      lat: number
      lng: number
    }
  }
  unitSystem: google.maps.UnitSystem
}

export const getTravelTimeAndDistance = ({
  points,
  unitSystem,
}: TimeAndDistanceProps) => {
  const origin = points.start
  const destination = points.end

  const directionsService = new google.maps.DirectionsService()

  const routeSection: google.maps.DirectionsWaypoint[] = [
    {location: origin, stopover: false},
    {location: destination, stopover: false},
  ]

  const serviceOptions: google.maps.DirectionsRequest = {
    origin: routeSection[0].location || {},
    destination: routeSection[1]?.location || {},
    travelMode: google.maps.TravelMode.DRIVING,
    unitSystem,
  }

  // Send request
  return (
    serviceOptions.origin &&
    serviceOptions.destination &&
    directionsService.route(serviceOptions, (result, status) => {
      if (status === google.maps.DirectionsStatus.OK && result !== null) {
        // https://docs.pmnd.rs/zustand/guides/event-handler-in-pre-react-18

        return result
      } else {
        console.error(`error fetching directions ${result}`)
      }
    })
  )
}
