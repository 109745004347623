/* eslint-disable no-console */
import {
  HubConnectionBuilder,
  LogLevel,
  HubConnectionState,
  HubConnection,
} from '@microsoft/signalr'
import {useStore} from 'common/useStore'
import {TEMEDA_URI} from 'common/constants'
import {useConversationsSocketStore} from './useConversationsSocketStore'
import {QueryClient} from '@tanstack/react-query'
import Swal from 'sweetalert2'
import {netAPIInstance} from 'common/components/netAPIAxios'
import {subMinutes} from 'date-fns'

const startConnection = async (connection: HubConnection) => {
  try {
    await connection.start()
    console.log('SignalR Connected.')
  } catch (err) {
    console.log(err)
    console.log('trying again')
    setTimeout(start, 5000)
  }
}

const start = async (queryClientInstance: QueryClient) => {
  const unreadMessages = useConversationsSocketStore.getState().unreadMessages
  const connection = useConversationsSocketStore.getState().connection
  const setUnreadMessages =
    useConversationsSocketStore.getState().setUnreadMessages
  const setNewWebsocketMessage =
    useConversationsSocketStore.getState().setNewWebsocketMessage
  // const token = localStorage.getItem('token')
  const url = TEMEDA_URI + 'netapi/chatHub'
  const user = useStore.getState().user
  const qs = `Basic ${user?.token ? user.token : ''}`
  try {
    connection && connection.stop()
    const thisConnection = new HubConnectionBuilder()
      .withUrl(url, {
        headers: {
          Authorization: qs,
        },
        withCredentials: false,
      })
      .configureLogging(LogLevel.Information)
      .build()

    startConnection(thisConnection)

    thisConnection.on(
      'BroadcastMessageToUsers',
      (name: string, message: string, users: string) => {
        console.info('BroadcastMessageToUsers', {
          name,
          message: JSON.parse(message),
          users,
        })
        const messageObject = JSON.parse(message)
        if (name === 'messageAdd') {
          const currentUnreadMessages = [...unreadMessages]
          const messageId = messageObject.idConversation
          const messageName = messageObject.senderDisplayName
          const messageText = messageObject.text
          useConversationsSocketStore.setState({
            mostRecentDateTime: messageObject.createdOn,
            lastCheckTimestamp: new Date(),
          })

          if (!currentUnreadMessages.includes(messageId)) {
            currentUnreadMessages.push(messageId)
            setUnreadMessages(currentUnreadMessages)
            setNewWebsocketMessage(messageName, messageText, messageId)
            queryClientInstance.invalidateQueries([
              'conversations',
              messageId.toString(),
            ])
          }
          queryClientInstance.invalidateQueries([
            'conversations',
            messageId.toString(),
          ])
          queryClientInstance.invalidateQueries(['conversationsList'])
        } else if (name === 'planProcessingComplete') {
          queryClientInstance.invalidateQueries(['routePlanList'])
          const errorMessage = messageObject.errorMessage
          const planName = messageObject.planName || ''
          // console.log('here', errorMessage)
          if (errorMessage) {
            Swal.fire({
              icon: 'error',
              title: 'Error processing plan',
              text: `There was an error processing the ${planName} plan. The error was "${errorMessage}"`,
            })
          } else {
            Swal.fire(
              'Route Plan processing complete',
              `Processing for the ${planName} plan is now complete. The plan can now be viewed in the route plan list.`,
              'success',
            )
          }
        } else if (name === 'planConfirmComplete') {
          queryClientInstance.invalidateQueries(['routePlanList'])
          const planName = messageObject.planName || ''
          Swal.fire(
            'Dispatching complete',
            `Dispatching for the ${planName} plan is now complete. Routes are now available for drivers and can be viewed in the route plan list.`,
            'success',
          )
        }
      },
    )
    thisConnection.onclose(async (error: Error | undefined) => {
      console.error('Connection closed', error, thisConnection.connectionId)
      thisConnection.onclose = () => null
      useConversationsSocketStore.setState({
        connection: null,
      })
      await start(queryClientInstance)
    })
    const newMessagesFound = await checkForNewMessages()

    if (newMessagesFound) {
      useConversationsSocketStore.setState({messagesWhileAway: true})
    }
    useConversationsSocketStore.setState({
      connection: thisConnection,
    })
    return thisConnection
  } catch (error) {
    console.error(error)
  }
}

const checkForNewMessages = async () => {
  if (!window.location.pathname.includes('conversations')) {
    const lastCheckTimestamp =
      useConversationsSocketStore.getState().lastCheckTimestamp
    if (
      !lastCheckTimestamp ||
      lastCheckTimestamp.getTime() < subMinutes(new Date(), 1).getTime()
    ) {
      useConversationsSocketStore.setState({lastCheckTimestamp: new Date()})
      const {data} = await netAPIInstance.get(
        `conversations?includeDetails=false`,
      )
      const mostRecentDateTime =
        useConversationsSocketStore.getState().mostRecentDateTime
      if (
        data &&
        data.unreadMessageCount > 0 &&
        (!mostRecentDateTime ||
          new Date(mostRecentDateTime).getTime() <
            new Date(data.mostRecentDateTime).getTime())
      ) {
        useConversationsSocketStore.setState({
          mostRecentDateTime: data.mostRecentDateTime,
        })
        return true
      }
    }
  }
  return false
}

let timerRunning = false
const timerCheckForMessages = () => {
  if (!timerRunning && navigator.onLine) {
    setInterval(async () => {
      const connection = useConversationsSocketStore.getState().connection
      if (connection?.state !== HubConnectionState.Connected) {
        const newMessagesFound = await checkForNewMessages()
        if (newMessagesFound) {
          useConversationsSocketStore.setState({messagesWhileAway: true})
        }
      }
    }, 300000)
    timerRunning = true
  }
}

export const startSocket = async (queryClientInstance: QueryClient) => {
  const connection = useConversationsSocketStore.getState().connection
  connection && connection.stop()
  await start(queryClientInstance)

  // subscribe to visibility change events

  timerCheckForMessages()

  // console.log('adding listener')
  // const checkVisibility = async () => {
  //   // fires when user switches tabs, apps, goes to homescreen, etc.
  //   if (document.visibilityState === 'hidden' && connection?.state) {
  //     console.info('stopping signalR')
  //     stopSocket()
  //   }

  //   // fires when app transitions from prerender, user returns to the app / tab.
  //   if (
  //     (document.visibilityState === 'visible' && !connection?.state) ||
  //     connection?.state !== HubConnectionState.Connected
  //   ) {
  //     console.log('starting')
  //     if (connection) connection.stop()
  //     let newConnection = await start(queryClientInstance)
  //     useConversationsSocketStore.setState({
  //       connection: newConnection || null,
  //     })
  //   }
  // }
  // document.removeEventListener('visibilitychange', checkVisibility)
  // document.addEventListener('visibilitychange', checkVisibility)

  return connection
}
export const stopSocket = () => {
  const connection = useConversationsSocketStore.getState().connection
  if (connection) connection.stop()
  useConversationsSocketStore.setState({
    connection: null,
  })
}
