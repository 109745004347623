import Swal from 'sweetalert2'
import axios from 'axios'
import {format} from 'date-fns'

const numberField = value =>
  value ? Number(value.replace(/[^/.\d]/, '')) : null

export const uploadFromTemplate = async ({
  event,
  userConfig,
  uploadFormRef,
  cache,
}) => {
  var reader = new FileReader()
  const uploadForm = uploadFormRef.current

  const XLSX = await import('common/SheetJSWriteWrapper')

  if (event.target.files && event.target.files[0]) {
    const file = event.target.files[0]
    reader.readAsBinaryString(file)
  }

  const incomingAssets = []
  reader.onload = function (event) {
    const data = event.target.result
    const workbook = XLSX.read(data, {type: 'binary'})
    const assetsFromTemplate = XLSX.utils
      .sheet_to_json(workbook.Sheets['Basic Asset Import'], {
        header: 0,
        raw: false,
      })
      .slice(5)

    assetsFromTemplate.forEach(a => {
      if (a['info']) {
        delete a['info']
      }
      if (a['GPS Device Serial # (required)'] && a['Asset Name (required)']) {
        incomingAssets.push(a)
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `Please check required fields for all assets.`,
        })
        uploadForm.reset()
      }

      if (incomingAssets.length) {
        var newAssetList = []
        incomingAssets.forEach(asset => {
          const submitAsset = {
            idAsset_Type: 2,
            Device: {
              Serial_Number_Displayed: asset['GPS Device Serial # (required)'],
            },
            Asset_Label: asset['Asset Name (required)'],
            Asset_Description: asset['Asset Description'] || null,
            Asset_TZ: userConfig.TimeZone,
            User_Specified_Odometer:
              numberField(asset['Odometer (miles or km)']) || null,
            User_Specified_EngineHours:
              numberField(asset['Engine Hours']) || null,
            Make: asset['Make'] || null,
            Model: asset['Model'] || null,
            Year: numberField(asset['Year']) || null,
            Vin: asset['Vin'] || null,
            LicensePlate: asset['License Plate'] || null,
            EquipmentID: asset['Equipment ID'] || null,
            EquipmentType: asset['Equipment Type'] || null,
            EquipmentSubType: asset['Equipment SubType'] || null,
            BatteryTypeVoltage: asset['Battery Voltage']
              ? asset['Battery Voltage'].toLowerCase().replace('v', '')
              : null,
            BusinessUnit: asset['Business Unit'] || null,
            Division: asset['Division'] || null,
            Department: asset['Department'] || null,
            Region: asset['Region'] || null,
            District: asset['District'] || null,
            AssignedTo: asset['Assigned To'] || null,
            LocationInfo: asset['Location Info'] || null,
            Status: asset['Status'] || null,
            FuelType: asset['Fuel Type'] || null,
            ProcurementOption: asset['Rent/Own/Lease'] || null,
            ProcurementInfo: asset['Rent/Own/Lease Details'] || null,
            InitialCost: numberField(asset['Initial Cost']) || null,
            ExternalID: asset['External ID'] || null,
            GPSInstallDate: asset['GPS Install Date']
              ? format(new Date(asset['GPS Install Date']), 'yyyy-MM-dd')
              : null,
            GPSInstallNotes: asset['GPS Install Notes'] || null,
            BillingRatePerHour:
              numberField(asset['Billing Rate (Per Hour)']) || null,
            AcquiredDate: asset['Acquired Date']
              ? format(new Date(asset['Acquired Date']), 'yyyy-MM-dd')
              : null,
            VehicleClass: asset['Vehicle Class'] || null,
          }
          newAssetList.push(submitAsset)
        })

        Swal.fire({
          icon: 'info',
          title: 'Import Assets?',
          text: `This action will create ${newAssetList.length} new ${
            newAssetList.length === 1 ? 'asset' : 'assets'
          }. Would you like to continue?`,
          showDenyButton: true,
          confirmButtonText: `Yes`,
          denyButtonText: `No`,
        }).then(res => {
          if (res.isConfirmed) {
            const requests = []
            newAssetList.forEach(asset => {
              requests.push(axios.post('./assets', asset))
            })
            Promise.allSettled(requests).then(responses => {
              const newAssets = responses.filter(
                response => response.status === 'fulfilled',
              )
              if (newAssets.length) {
                if (newAssets.length === responses.length) {
                  Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: `Successfully added ${newAssets.length} new ${
                      newAssetList.length === 1 ? 'asset' : 'assets'
                    }.`,
                  })
                  uploadForm.reset()
                } else {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Warning',
                    text: `Successfully added ${newAssets.length} new ${
                      newAssets.length > 1 ? 'assets' : 'asset'
                    }!
                        ${responses.length - newAssets.length} ${
                      responses.length - newAssets.length > 1
                        ? 'assets'
                        : 'asset'
                    } failed, please verify entries and try again.`,
                  })
                }
                cache.invalidateQueries(['assetsList'])
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: `An error occured. Please try again.`,
                })
              }
            })
            uploadForm.reset()
          } else {
            uploadForm.reset()
          }
        })
      }
    })
  }
}
