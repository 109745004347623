import {useMutation, useQueryClient} from '@tanstack/react-query'
import Axios from 'axios'
import {formatISO} from 'date-fns'
import {OrderType} from 'routingAndDispatch/types/typesModule'

type BulkPostOrdersData = {
  idLocations: number[]
  earliestDate?: Date
  latestDate?: Date
  serviceDuration: number
  description?: string
  serviceType?: string
  orderType: 'Singlestop' | 'Multistop'
  selectedLocationsType: 'Pickup' | 'Delivery'
  Multistop_idLocation?: number
}

const bulkPostOrders = async (orderObject: BulkPostOrdersData) => {
  const earliestDate =
    orderObject.earliestDate &&
    formatISO(orderObject.earliestDate).substring(0, 19)
  const latestDate =
    orderObject.latestDate && formatISO(orderObject.latestDate).substring(0, 19)
  const {data} = await Axios.post(`rd/orders/bulkByLocation`, {
    ...orderObject,
    earliestDate,
    latestDate,
  })
  return data
}

export default function useBulkPostOrdersByLocation() {
  const cache = useQueryClient()
  return useMutation(bulkPostOrders, {
    onSuccess: data => {
      // Snapshot the previous value
      const previousValue: OrderType[] | undefined = cache.getQueryData([
        'orders',
      ])
      const updatedList = previousValue
        ? [...data, ...previousValue]
        : [...data]
      cache.setQueryData(['orders'], updatedList)
    },
    onError: (err, variables) => {
      // Snapshot the previous value
      const previousValue: OrderType[] | undefined = cache.getQueryData([
        'orders',
      ])
      console.error(err, variables, previousValue)
      cache.setQueryData(['orders'], previousValue)
    },
    onSettled: () => {
      cache.invalidateQueries(['orders'])
    },
  })
}
