import * as React from 'react'
import styled from 'styled-components/macro'
import {DropdownArrow, Checkbox} from 'common/components'

const LevelLine = styled.div`
  display: flex;
  visibility: ${p => (p.expanded && p.visible ? 'visible' : 'hidden')};
  height: ${p => (p.expanded && p.visible ? '65px' : '0')};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: height 100ms linear 0ms, visibility 0 linear 100ms;
  border-bottom: ${p => (p.visible && p.expanded ? '1px' : '0')} solid
    var(--table-borders);
  padding-left: ${p => Math.max(0, (p.level - 2) * 20)}px;
  &:hover {
    ${p =>
      !p.disabled
        ? `background: #f0f6e1;
            input {
              border: 1px solid var(--asc-moss);
              box-sizing: border-box;
              box-shadow: 0px 0px 6px 1px var(--secondary);
              border-radius: 5px;
            }`
        : ''};
  }
`

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1em;
  font-weight: 700;
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};
  justify-content: space-between;
  margin-left: 10px;
  span {
    margin-left: 10px;
  }
`

const HierarchyLine = ({
  customer,
  onChecked,
  toggleExpansion,
  unassigned = false,
  unassignedCount = 0,
  level,
  expanded,
}) => {
  let visible = customer.visible
  if (unassigned) {
    visible = customer.unassignedVisible
  }

  return (
    <LevelLine
      level={level}
      visible={visible}
      expanded={expanded}
      disabled={!customer.AssetCount && !unassigned}
    >
      <StyledLabel disabled={!customer.AssetCount && !unassigned}>
        <Checkbox
          dataCy={'hierarchy-select'}
          onChange={() =>
            onChecked(
              customer.idCustomer,
              unassigned ? customer.unassignedChecked : customer.checked,
              unassigned,
            )
          }
          selected={
            (unassigned ? customer.unassignedChecked : customer.checked) ||
            false
          }
          disabled={!customer.AssetCount && !unassigned}
        />
        {/* <StyledCheckbox
          type="checkbox"
          data-cy="hierarchy-select"
          onChange={() =>
            onChecked(
              customer.idCustomer,
              unassigned ? customer.unassignedChecked : customer.checked,
              unassigned,
            )
          }
          checked={
            (unassigned ? customer.unassignedChecked : customer.checked) ||
            false
          }
          disabled={!customer.AssetCount && !unassigned}
        /> */}
        <span>
          {unassigned ? 'Unassigned' : customer.Customer_Name} (
          {unassigned ? unassignedCount : customer.AssetCount})
        </span>
      </StyledLabel>
      {!unassigned && customer.CustomerList?.length > 0 && (
        <DropdownArrow
          collapsed={customer.expanded ? 'no' : 'yes'}
          data-cy="hierarchy-expand"
          onClick={() =>
            toggleExpansion(customer.idCustomer, customer.expanded)
          }
        />
      )}
    </LevelLine>
  )
}
export default HierarchyLine
