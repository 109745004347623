import * as React from 'react'
import ListHeader from './ListHeader'
import SearchContainer from './SearchContainer'

const OpeningFilterPanelHeader = ({
  tabSelected,
  setTabSelected,
  setSearchOpen,
  filteredAssets,
  clearFunction,
  handleSearch,
  searchValue,
  assetFilter,
  selectedGroups,
  selectedProjects,
  selectedLocations,
  selectedZones,
  onAssetsFilter,
  assetsStatus,
  unassignedDevices,
}) => {
  const [showSearch, setShowSearch] = React.useState(false)

  return (
    <React.Fragment>
      <ListHeader
        tabSelected={tabSelected}
        setTabSelected={tab => {
          setTabSelected(tab)
        }}
        filteredAssets={filteredAssets}
        setShowSearch={() => {
          setShowSearch(!showSearch)
          setSearchOpen(!showSearch)
        }}
        showSearch={showSearch}
        selectedGroups={selectedGroups}
        selectedProjects={selectedProjects}
        selectedLocations={selectedLocations}
        selectedZones={selectedZones}
        assetsStatus={assetsStatus}
        onAssetsFilter={onAssetsFilter}
        unassignedDevices={unassignedDevices}
      />
      <SearchContainer
        searchType={tabSelected}
        clearFunction={clearFunction}
        handleSearch={handleSearch}
        searchValue={searchValue}
        showSearch={showSearch}
        assetFilter={assetFilter}
        onAssetsFilter={onAssetsFilter}
      />
    </React.Fragment>
  )
}
export default OpeningFilterPanelHeader
