//Used to remove unneeded UI properties to send to API
/* eslint-disable @typescript-eslint/no-unused-vars */
import Axios from 'axios'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {showToast} from 'common/components/Toastr'
import {StopTypeType} from 'routingAndDispatch/Stops/stopHelper'

export type PostOrderToSegmentType = {
  idOrder: string
  title: string
  description?: string | null
  serviceType?: string
  stops: NewStop[]
  createdAt: string
  totalStops: string
  orderStatus: string
  idSegment: string
  idPlan: string
  idRouteDispatchDriver: string
}
interface NewStop {
  idStop: string
  scheduledEarliestDate?: string
  scheduledLatestDate?: string
  description: string
  location?: {
    lat?: number
    lng?: number
  }
  companyName: string
  address?: string
  city?: string
  state?: string
  zipCode?: string
  country?: string | null
  phone: string
  contact: string
  email: string
  serviceDuration: number
  UpdateReason: string
  stopType: StopTypeType
}

const postOrder = async (order: PostOrderToSegmentType) => {
  const {idSegment, idPlan, ...rest} = order

  //removing UI properties
  const orderToSend = rest

  const {data} = await Axios.post(
    `rd/segments/${idSegment}/stops/1`,
    orderToSend,
  )
  return data
}

const usePostStopToSegment = () => {
  const cache = useQueryClient()
  return useMutation(postOrder, {
    onSuccess: () => {
      showToast(`Successfully added stop.`, 'success')
    },
    onError: (err, variables, previousValue) => {
      console.error(err, variables, previousValue)
      cache.setQueryData(['routePlanList'], previousValue)
    },
    // After success or failure, refetch the invitees query
    onSettled: (data, error, variables) => {
      cache.invalidateQueries(['routePlanList'])
      cache.invalidateQueries(['orders'])
      cache.invalidateQueries(['routePlan', variables.idPlan])
      cache.invalidateQueries(['routeDetail', variables.idRouteDispatchDriver])
    },
  })
}

export default usePostStopToSegment
