import * as React from 'react'
import styled from 'styled-components/macro'
import {Form, Input} from 'semantic-ui-react'
import {Row, StyledLabel} from './StyledComponents'
import LabeledWithHelp from 'common/components/LabelWithHelp'
import InputErrorMessage from './InputErrorMessage'
import {valueChanged} from './inputWithLabelHelpers'
import {CheckCircle, XCircle} from 'phosphor-react'

type Props = {
  name: string
  label: string
  disabled?: boolean
  suffix?: string
  onSuffixClick?: () => unknown
  suffixIcon?: string
  tooltip?: string
  type?: 'text' | 'number' | 'password'
  decimals?: number
  inputProps?: Record<string, unknown>
  prefix?: string
  prefixIcon?: string | React.ReactNode
  subLabel?: string | React.ReactNode
  errors?: {
    [key: string]: {type?: string; message: string}
  }
  width?: string | number
  required?: boolean
  value: string | number | undefined | null
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  valueIsValid?: boolean
  className?: string
  autocomplete?: boolean
  integrateIcon?: boolean
  shouldValidate?: boolean
  noCommas?: boolean
  maxCharacters?: number
  inputDescription?: string | React.ReactElement
}
interface LabelProps {
  integrateIcon?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledInput = styled(({integrateIcon, ...props}) => <Input {...props} />)`
  &&& {
    ${props => props.integrateIcon && 'border: 1px solid var(--asc-graphite);'}
    ${props => props.integrateIcon && 'border-radius: 5px;'}
    input {
      border: 1px solid var(--asc-coolgray);
      border-right-color: var(--asc-coolgray) !important;
      display: flex;
      flex: 1 0;
      width: 0;
      border-radius: ${({
        prefix,
        suffix,
      }: {
        prefix: string
        suffix: boolean
      }) => {
        if (!prefix && !suffix) return '5px !important;'
        if (!prefix && suffix) return '5px 0 0 5px !important;'
        if (prefix && !suffix) return '0 5px 5px 0 !important;'
        if (prefix && suffix) return '0 !important;'
      }};
    }
    input:focus {
      outline: none;
      border: 1px solid var(--tertiary);
      border-right-color: var(--tertiary) !important;
      box-shadow: 0px 0px 6px 1px var(--asc-coolgray) !important;
    }
    &&& input:disabled {
      opacity: 1;
      background-color: var(--grey-100) !important;
    }
    &&.ui.label {
      line-height: 14px;
    }
  }
`

const InnerInput = styled.input<LabelProps>`
  &&& {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    height: 38px;
    padding: 0 12px;
    ${props => props.integrateIcon && 'border: none !important'};
    border-color: var(--asc-coolgray);
    &:disabled {
      color: black;
      opacity: 0.8 !important;
    }
  }
`

const SubLabel = styled.div`
  padding-bottom: 5px;
  font-size: smaller;
  font-weight: normal;
  margin-top: -8px;
`
export const StyledField = styled(Form.Field)`
  &&& {
    display: flex;
    flex-direction: column;
    width: ${({$width}: {$width: string | undefined}) => $width || '100%'};
    position: relative;
    min-width: 120px;
    display: flex;
    margin-bottom: 16px;
  }
`

const StyledCheck = styled.div`
  position: absolute;
  bottom: 10px;
  left: calc(100% - 32px);
`

export const RequiredAsterisk = styled.span`
  ::after {
    margin-left: 3px;
    content: '*';
    font-size: 0.9rem;
    color: var(--asc-vermilion);
  }
`

const PrefixLabel = styled.label<LabelProps>`
  &&& {
    display: flex;
    border-radius: 5px 0 0 5px;
    padding: 10px 13px;
    background-color: ${props =>
      props.integrateIcon ? '#ffffff' : 'var(--asc-platinum)'};
    font-weight: bold;
    font-size: 14px;
    height: 38px;
    margin: 0;
    svg {
      max-height: 14px;
      max-width: 14px;
    }
  }
`

const SuffixLabel = styled.label`
  &&& {
    display: flex;
    border-radius: 0 5px 5px 0;
    padding: 10px 13px;
    background-color: var(--asc-platinum);
    font-weight: bold;
    font-size: 14px;
    height: 38px;
    margin: 0;
    svg {
      max-height: 14px;
      max-width: 14px;
    }
  }
`
const LabelRow = styled(Row)`
  justify-content: space-between;
`

const InputDescription = styled.div`
  padding-top: 10px;
  display: flex;
  color: var(--asc-sonicsilver);
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`

const CharacterCount = styled.div`
  font-size: 12px;
  color: var(--asc-coolgray);
`
const InputWithLabel = ({
  name,
  label,
  disabled = false,
  suffix = '',
  suffixIcon,
  tooltip,
  value,
  onChange,
  inputProps = {},
  prefix = '',
  prefixIcon,
  errors,
  width,
  subLabel,
  type = 'text',
  decimals = 0,
  onSuffixClick,
  required = false,
  onBlur,
  valueIsValid,
  className = '',
  autocomplete = true,
  integrateIcon = false,
  shouldValidate = false,
  noCommas = false,
  maxCharacters = undefined,
  inputDescription = undefined,
}: Props) => {
  const [inputDisplayValue, setInputDisplayValue] = React.useState('')
  const prevValue = React.useRef<string | number>()
  const [localErrors, setLocalErrors] = React.useState<
    | {
        [key: string]: {type?: string; message: string}
      }[]
    | []
  >([])
  const [isFocused, setIsFocused] = React.useState(false)

  React.useEffect(() => {
    //loading from props (initially and changed externally)
    if (prevValue.current !== value) {
      if (type === 'number') {
        const fakeEvent = {target: {value: String(value)}}
        const {newDisplayValue, returnValue} = valueChanged({
          e: fakeEvent,
          prevValue: prevValue.current !== undefined ? prevValue.current : '',
          decimals,
          noCommas,
        })
        if (newDisplayValue !== 'previousValue') {
          setInputDisplayValue(newDisplayValue)
        }
        prevValue.current = returnValue || undefined
      } else {
        setInputDisplayValue(String(value))
        prevValue.current = value === null || value === undefined ? '' : value
      }
    }
  }, [value, type, decimals, noCommas])

  const inputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    if (maxCharacters && inputValue.length > maxCharacters) {
      const croppedValue = inputValue.slice(0, maxCharacters)
      setInputDisplayValue(croppedValue)
      prevValue.current = croppedValue
      e.target.value = croppedValue
      onChange(e)
      // if (localErrors.length) return
      // const tempArray = [...localErrors]
      // tempArray.push({
      //   overflow: {
      //     message:
      //       (label ? label : 'This input') +
      //       ' must be less than ' +
      //       maxCharacters +
      //       ' characters.',
      //   },
      // })

      // return setLocalErrors(tempArray)
    }

    setLocalErrors([])

    if (
      type !== 'number' &&
      (!maxCharacters || e.target.value.length <= maxCharacters)
    ) {
      setInputDisplayValue(e.currentTarget.value)
      prevValue.current = e.currentTarget.value
      onChange(e)
    } else {
      const {newDisplayValue, returnValue} = valueChanged({
        e,
        prevValue: prevValue.current || '',
        decimals,
        noCommas,
      })
      if (
        newDisplayValue !== 'previousValue' &&
        (!maxCharacters || e.target.value.length <= maxCharacters)
      ) {
        setInputDisplayValue(newDisplayValue)
        if (returnValue !== prevValue.current) {
          //change value at parent

          prevValue.current = String(returnValue) || undefined
          e.target.value = String(returnValue)
          onChange(e)
        }
      }

      if (e.currentTarget.value === '-') {
        setInputDisplayValue(e.currentTarget.value)
        onChange(e)
      }
    }
  }

  return (
    <StyledField className={className} $width={width}>
      <LabelRow>
        <div>
          {tooltip ? (
            <LabeledWithHelp
              required={required}
              htmlFor={name}
              label={label}
              tooltip={tooltip}
            />
          ) : (
            label && (
              <StyledLabel htmlFor={name}>
                {label}
                {required && <RequiredAsterisk />}
              </StyledLabel>
            )
          )}
        </div>
        <div>
          {maxCharacters && isFocused && (
            <CharacterCount>{`${
              value?.toString().length
            }/${maxCharacters}`}</CharacterCount>
          )}
        </div>
      </LabelRow>
      {subLabel && <SubLabel>{subLabel}</SubLabel>}
      <StyledInput
        labelPosition={suffix || suffixIcon ? 'right' : 'left'}
        prefix={prefix}
        suffix={suffix}
        integrateIcon={integrateIcon}
      >
        {prefix && <PrefixLabel prefix="true">{prefix}</PrefixLabel>}

        {prefixIcon && (
          <PrefixLabel prefix="true" integrateIcon={integrateIcon}>
            {prefixIcon}
          </PrefixLabel>
        )}
        <InnerInput
          id={name}
          name={name}
          disabled={disabled}
          value={inputDisplayValue}
          onChange={inputChanged}
          onBlur={e => {
            setIsFocused(false)
            onBlur && onBlur(e)
          }}
          onFocus={() => setIsFocused(true)}
          data-lpignore="true"
          data-1p-ignore="true"
          type={type === 'password' ? 'password' : 'text'}
          {...inputProps}
          integrateIcon={integrateIcon}
          autoComplete={(!autocomplete).toString() && 'one-time-code'} //one-time-code will prevent autofill in chrome.
        />

        {suffix && <SuffixLabel onClick={onSuffixClick}>{suffix}</SuffixLabel>}
        {suffixIcon && <SuffixLabel>{suffixIcon}</SuffixLabel>}
      </StyledInput>
      {inputDescription && (
        <InputDescription>{inputDescription}</InputDescription>
      )}
      {valueIsValid ? (
        <StyledCheck>
          <CheckCircle
            size={20}
            weight="fill"
            color="var(--tertiary)"
            data-cy="valid-check"
          />
        </StyledCheck>
      ) : (
        shouldValidate &&
        !valueIsValid && (
          <StyledCheck>
            <XCircle
              size={20}
              weight="fill"
              color="var(--asc-vermilion)"
              data-cy="invalid-check"
            />
          </StyledCheck>
        )
      )}
      {(errors || localErrors.length > 0) && (
        <InputErrorMessage
          data-cy={'input-error-message'}
          message={
            errors
              ? errors[name]?.message
              : localErrors.length > 0
              ? localErrors[0].overflow?.message
              : ''
          }
        />
      )}
    </StyledField>
  )
}
export default InputWithLabel
