import {Card, Divider} from 'semantic-ui-react'
import {InputWithLabel} from 'common/components'
import InputDropdownOptional from './InputDropdownOptional.tsx'
import React from 'react'
import states from 'common/states'
import countries from 'common/countries'
import styled from 'styled-components/macro'
const formattedCountries = countries.map((country, i) => ({
  key: i,
  value: country.iso,
  flag:
    country.iso.toLowerCase() !== 'aq' // No flag for Antarctica :()
      ? country.iso.toLowerCase()
      : null,
  text: country.printable_name,
}))
const StyledInputContainer = styled.div`
  margin-bottom: 8px;
`
const AddressInformation = ({
  address = {},
  dispatch,
  includeAddress2 = true,
}) => {
  const onInputChange = e => {
    let value = e?.currentTarget?.value
    const name = e?.currentTarget?.name

    if (name) {
      dispatch({
        type: 'updateAddress',
        data: {
          [name]: value,
        },
      })
    }
  }

  const onDropdownChange = ({name, value}) => {
    if (name) {
      dispatch({
        type: 'updateAddress',
        data: {
          [name]: value,
        },
      })
    }
  }

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>Address Information</Card.Header>
        <Divider />
        <Card.Description>
          <StyledInputContainer>
            <InputWithLabel
              name="Address_Line1"
              label="Address"
              inputProps={{
                value: address?.Address_Line1 || '',
                onChange: onInputChange,
              }}
            />
          </StyledInputContainer>
          <StyledInputContainer>
            {includeAddress2 && (
              <InputWithLabel
                name="Address_Line2"
                label="Address Line 2"
                inputProps={{
                  value: address?.Address_Line2 || '',
                  onChange: onInputChange,
                }}
              />
            )}
          </StyledInputContainer>
          <StyledInputContainer>
            <InputWithLabel
              name="Locality"
              label="City"
              inputProps={{
                value: address?.Locality || '',
                onChange: onInputChange,
              }}
            />
          </StyledInputContainer>
          <StyledInputContainer>
            <InputDropdownOptional
              options={states}
              onChange={onDropdownChange}
              value={address?.Region || null}
              name="Region"
              label="State/Province"
              search={true}
            />
          </StyledInputContainer>
          <StyledInputContainer>
            <InputWithLabel
              name="Postal_Code"
              label="Zip/Postal Code"
              inputProps={{
                value: address?.Postal_Code || '',
                onChange: onInputChange,
              }}
            />
          </StyledInputContainer>

          <InputDropdownOptional
            options={formattedCountries}
            onChange={onDropdownChange}
            value={address?.isoCountryCode || null}
            name="isoCountryCode"
            label="Country"
            search={true}
          />
        </Card.Description>
      </Card.Content>
    </Card>
  )
}

export default AddressInformation
