import styled from 'styled-components/macro'
import {Column, Row} from 'common/components'

export const Body = styled.div`
  padding: 20px;
`
export const TabRow = styled(Row)`
  width: 100%;
  border-bottom: 1px solid var(--asc-coolgray);
  margin-bottom: 20px;
`
interface TabProps {
  isSelected: boolean
}

export const Tab = styled.div<TabProps>`
  min-width: 250px;
  cursor: pointer;
  font-weight: 700;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  gap: 5px;
  border-bottom: ${props =>
    props.isSelected ? 'solid 5px var(--secondary)' : 'solid 5px transparent'};
  :hover {
    background-color: var(--asc-cultured);
    background-color: ${props =>
      props.isSelected && 'var(--asc-selectedgreen)'};
  }
`

export const TabContentContainer = styled(Column)`
  width: 100%;
  gap: 10px;
`

export const ButtonsRow = styled(Row)`
  width: 100%;
  justify-content: space-between;
`

export const Button = styled(Row)`
  align-items: center;
  color: var(--primary);
  font-weight: bold;
  font-size: 13px;
  gap: 5px;
  padding: 5px;
  border: 1px solid transparent;
  cursor: pointer;
  :hover {
    color: var(--secondary);
    svg {
      fill: var(--secondary);
    }
  }
`

export const StyledRow = styled(Row)`
  gap: 50px;
  .field {
    margin-bottom: 0px !important;
  }
`
export const StyledColumn = styled(Column)`
  gap: 10px;
  min-width: 50%;
`
export const PreviewContainer = styled(Column)`
  background: var(--asc-cultured);
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  gap: 20px;
  overflow-y: auto;
`

export const PreviewTitle = styled.div`
  color: var(--asc-sonicsilver);
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
`

export const PreviewText = styled.div`
  font-size: 14px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
`
export const PreviewContentTitle = styled(PreviewText)`
  border-bottom: 1px solid var(--asc-coolgray);
  padding-top: 0px;
  padding-bottom: 20px;
  min-height: 43px;
`

export const LabelRow = styled(Row)`
  justify-content: space-between;
  margin-bottom: 10px;
  span {
    font-weight: bold;
    font-size: 14px;
  }
`

export const CharacterCount = styled.div`
  font-size: 12px;
  color: var(--asc-coolgray);
`

export const ContentHeaderRow = styled(Row)`
  justify-content: space-between;
`

export const ToggleGroup = styled(Row)`
  width: fit-content;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: 1px solid var(--asc-coolgray);
  border-radius: 5px;
  background: white;
  position: relative;
  white-space: nowrap;
`

export const InputContainer = styled(Column)`
  position: relative;
`

export const SendButtonInput = styled(Row)`
  position: relative;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`
export const ErrorMessage = styled.span`
  color: var(--error-text) !important;
  font-weight: bold;
  font-size: 0.85714286rem;
`
