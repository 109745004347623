import React, {useState} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import styled from 'styled-components/macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Error from 'common/Error'
import {Device} from 'common/types/opening1Response'
import {User, Customer, Dealer} from '../types/types'
import {CustomerType} from 'management/hooks/useCustomers'
import DevicesTable from './TemedaAdmin/Devices/DevicesTable'
import DeviceSwapTable from './DeviceSwapTable'
import Branding from './Branding'

const NavTabBottomContainer = React.lazy(
  () => import('./NavTabBottomContainer'),
)

const UsersBottomContainer = React.lazy(
  () => import('./TemedaAdmin/Users/UsersBottomContainer'),
)

const ApiUsersBottomContainer = React.lazy(
  () => import('./TemedaAdmin/ApiUsers/ApiUsersBottomContainer'),
)

// const DeviceHistoryTable = React.lazy(() => import('./DeviceHistoryTable'))

const UsersTable = React.lazy(() => import('./UsersTable'))
const CustomersTable = React.lazy(() => import('./CustomersTable'))
const DealersTable = React.lazy(() => import('./DealersTable'))

//const DeviceHistoryTable = lazy(() => import('./DeviceHistoryTable'))

const Icon = styled(FontAwesomeIcon)`
  font-size: calc(14 / 14 * 1rem);
  color: #333;
  margin: -0.1em 0.5em 0 0;
`

const StyledButton = styled.button`
  padding: 15px !important;
  cursor: pointer;
  border-radius: 3px;
  margin: 0 10px !important;
  background-color: #e0e1e2;
  border: none;
  &:hover {
    color: white;
    background-color: #43494e;
  }
  :focus {
    outline: none;
  }
`

const MainContainer = styled.div`
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  > div {
    flex: 1;
  }
`

const FULL_HEIGHT = 'calc(100vh - 194px)'
export const HALF_HEIGHT = 'calc(50vh - 103px)'

type Props = {
  data: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any
    loading: boolean
    selected: CustomerType
    allUsers: User[]
    filteredUsers: User[]
    devices: Device[]
    hotSwapDevices: Device[]
    deviceTypes: Device[]
    customers: Customer[]
    dealers: Dealer[]
    activeSubTab: string
    logoFile: string
    newLogoFile: string
    newLogoFileURL: string
    idBranding: number
    creds: {
      auth: string
      userName: string
      userCompany: string
      dateFormat: string
      timezoneName: string
    }
  }
  customerClicked: (row: CustomerType) => void
  dealerClicked: (row: CustomerType) => void
  clearSelected: () => void
}

const ManagementRoutes = (props: Props) => {
  const {filteredUsers, allUsers} = props.data

  const [_showUnlinkedApiUsers, setShowUnlinkedApiUsers] = useState(false)

  const UnassociatedUsersButton = () => (
    <StyledButton onClick={() => setShowUnlinkedApiUsers(true)}>
      <Icon icon="info-circle"></Icon> Unlinked Users
    </StyledButton>
  )

  return (
    <MainContainer>
      <Routes>
        <Route errorElement={<Error />}>
          <Route
            path={`users`}
            element={
              <UsersTable
                users={allUsers}
                selected={props.data.selected}
                height={FULL_HEIGHT}
                title="All Users"
              />
            }
          />
          <Route
            path={`customers/devices`}
            element={
              <>
                <CustomersTable
                  selected={props.data.selected}
                  customerClicked={props.customerClicked}
                  height={HALF_HEIGHT}
                />
                <DevicesTable selected={props.data.selected} />
              </>
            }
          />

          <Route
            path={`customers/device-swap`}
            element={
              <>
                <CustomersTable
                  selected={props.data.selected as CustomerType}
                  customerClicked={props.customerClicked}
                  height={HALF_HEIGHT}
                />
                <DeviceSwapTable selected={props.data.selected} />
              </>
            }
          />
          <Route
            path={`customers/*`}
            element={
              <>
                <CustomersTable
                  selected={props.data.selected as CustomerType}
                  customerClicked={props.customerClicked}
                  height={HALF_HEIGHT}
                />
                <NavTabBottomContainer
                  selected={props.data.selected}
                  users={filteredUsers}
                />
              </>
            }
          />

          <Route
            path={`dealers/*`}
            element={
              <>
                <DealersTable
                  selected={props.data.selected}
                  dealerClicked={props.dealerClicked}
                  height={HALF_HEIGHT}
                />
                <NavTabBottomContainer
                  selected={props.data.selected}
                  users={filteredUsers}
                />
              </>
            }
          />

          {/* <Route
            path={`support/notreporting`}
            element={<DeviceHistoryTable />}
          /> */}

          <Route
            path={`support/devices`}
            element={
              <>
                <CustomersTable
                  selected={props.data.selected}
                  customerClicked={props.customerClicked}
                  height={HALF_HEIGHT}
                />
                <DevicesTable selected={props.data.selected} />
              </>
            }
          />
          {/* <Route
            path={`support/devices/edit/:id`}
            element={
              <>
                <CustomersTable
                  selected={props.data.selected}
                  customerClicked={props.customerClicked}
                  height={HALF_HEIGHT}
                />
                <DevicesTable selected={props.data.selected} admin={true} />
              </>
            }
          /> */}
          <Route
            path={`support/customeradmin`}
            element={
              <>
                <CustomersTable
                  selected={props.data.selected}
                  customerClicked={props.customerClicked}
                  height={FULL_HEIGHT}
                  forCustomersTable={true}
                  clearSelected={props.clearSelected}
                />
              </>
            }
          />

          <Route
            path={`support/useradmin`}
            element={
              <>
                <CustomersTable
                  selected={props.data.selected}
                  customerClicked={props.customerClicked}
                  height={HALF_HEIGHT}
                />

                <UsersBottomContainer
                  users={filteredUsers}
                  selected={props.data.selected}
                />
              </>
            }
          />

          <Route
            path={`support/apiusers`}
            element={
              <>
                <CustomersTable
                  selected={props.data.selected}
                  customerClicked={props.customerClicked}
                  height={HALF_HEIGHT}
                />
                <UnassociatedUsersButton />

                <ApiUsersBottomContainer
                  selected={props.data.selected}
                  customerClicked={props.customerClicked}
                  isUnlinkedApiUsersDisplayed={_showUnlinkedApiUsers}
                  onHideUnlinkedApiUsers={() => setShowUnlinkedApiUsers(false)}
                />
              </>
            }
          />
          <Route
            path={`support/branding`}
            element={
              <>
                <CustomersTable
                  selected={props.data.selected as CustomerType}
                  customerClicked={props.customerClicked}
                  height={HALF_HEIGHT}
                />
                <Branding selected={props.data.selected} />
              </>
            }
          />
          <Route
            path={`support/device-swap`}
            element={
              <>
                <CustomersTable
                  selected={props.data.selected as CustomerType}
                  customerClicked={props.customerClicked}
                  height={HALF_HEIGHT}
                />
                <DeviceSwapTable selected={props.data.selected} />
              </>
            }
          />
          <Route
            path={`support`}
            element={<Navigate to={`/management/support/customeradmin`} />}
          />
          <Route path={``} element={<Navigate to={`users`} />} />
        </Route>
      </Routes>
    </MainContainer>
  )
}

export default ManagementRoutes
