import React from 'react'
import styled from 'styled-components/macro'
import {CaretDown, Check} from 'phosphor-react'
import Button from 'common/components/Button'
import useOnClickOutside from 'common/components/hooks/useOnClickOutside'

const Wrapper = styled.div`
  position: relative;
  width: auto;
  display: flex;
`
const StyledButton = styled(Button)`
  margin-left: 5px;
  background: transparent;
  color: var(--primary);
  font-weight: bold;
  display: flex;
  flex-direction: row;
  padding: 0px;
  align-items: center;
  svg {
    margin-right: 5px;
  }
  :hover {
    background: transparent;
    color: var(--secondary);
  }
`
const AltButton = styled(Button)`
  margin-left: 5px;
  background: transparent;
  color: var(--primary);
  font-weight: bold;
  display: flex;
  flex-direction: row;
  padding: 0px;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid var(--primary);
  border-radius: 5px;
  svg {
    margin-right: 5px;
  }
  :hover {
    background: var(--asc-selectedgreen);
    border: 1px solid var(--primary);
  }
`

const SortButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
  div {
    button {
      div {
        margin-right: 5px;
      }
    }
  }
`

const ItemWrapper = styled.div`
  position: absolute;
  ${p => p.direction}: 0;
  background-color: white;
  z-index: 99;
  border: 1px solid lightgray;
  border-radius: 3px;
  margin-top: ${p => (p.marginTop ? p.marginTop : '0px')};
`

const List = styled.ul`
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  padding: 10px;
  list-style: none;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
  padding-right: 25px;

  :hover {
    background-color: #e7e7e7;
    color: rgba(0, 0, 0, 0.9);
  }
`
const IconHolder = styled.div`
  width: 25px;
`

const SortByButton = ({
  onChange,
  sortByOptions,
  altButton = false,
  defaultSortedBy,
  className = '',
}) => {
  const [open, setOpen] = React.useState(false)
  const [selectedOption, setSelectedOption] = React.useState(
    defaultSortedBy ? defaultSortedBy : '',
  )
  const drop = React.useRef(null)
  const clickedOutside = () => {
    if (open) {
      setOpen(false)
    }
  }
  useOnClickOutside(drop, clickedOutside)

  const onClick = item => {
    onChange(item.key)
    setSelectedOption(item.value)
    setOpen(false)
  }

  return (
    <SortButton className={className}>
      <Wrapper className="dropdown" ref={drop}>
        {!altButton ? (
          <StyledButton
            data-cy={'sort-by'}
            onClick={() => setOpen(open => !open)}
            active={open}
          >
            <React.Fragment>
              <div>Sort By</div>
              <CaretDown size={17} />
            </React.Fragment>
          </StyledButton>
        ) : (
          <AltButton
            data-cy={'sort-by'}
            onClick={() => setOpen(open => !open)}
            active={open}
          >
            <React.Fragment>
              <div>Sort By</div>
              <CaretDown size={17} />
            </React.Fragment>
          </AltButton>
        )}
        {open && (
          <ItemWrapper direction={'right'} marginTop={'25px'}>
            <List>
              {sortByOptions.map((item, i) => (
                <ListItem
                  data-cy={item.dataCy}
                  key={i}
                  onClick={() => {
                    onClick(item)
                  }}
                >
                  <IconHolder>
                    {item.value === selectedOption && <Check />}
                  </IconHolder>
                  {item.text}
                </ListItem>
              ))}
            </List>
          </ItemWrapper>
        )}
      </Wrapper>
    </SortButton>
  )
}
export default SortByButton
