import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
`

export const Content = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
type RowProps = {
  alignItems?: string
  justifyContent?: string
  gap?: string
  padding?: string
  background?: string
  marginTop?: string
}
// export const HeaderRow = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   width: 100%;
// `

const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  background: ${props => props.background || ''};
  align-items: ${props => props.alignItems || ''};
  justify-content: ${props => props.justifyContent || ''};
  gap: ${props => (props.gap ? props.gap : '')};
  padding: ${props => (props.padding ? props.padding : '')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '')};
`

export const Badge = styled(Row)`
  align-items: center;
  min-width: 35px;
  min-height: 1em;
  padding: 0.37em;
  line-height: 1em;
  justify-content: center;
  border-radius: 500rem;
  background-color: var(--secondary);
  box-shadow: 1px 1px 1px var(--asc-coolgray);
  color: black;
  font-weight: bold;
  margin-left: ${({marginLeft}: {marginLeft?: string}) =>
    marginLeft ? marginLeft : '15px'};
  margin-top: 3px;
`
// export const BackButton = styled.div`
//   cursor: pointer;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   color: var(--primary);
//   svg {
//     margin-right: 5px;
//   }
//   :hover {
//     color: var(--secondary);
//   }
// `
// export const TitleBadgeGroup = styled(Row)`
//   align-items: center;
// `
export const PlanNameRender = styled.span`
  /* white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
  font-weight: bold;
`
export const ButtonContainer = styled.div`
  margin-left: 10px;
`

export const BasicCell = styled.div`
  width: 100%;
  text-align: right;
`

export const MissedCell = styled(BasicCell)`
  color: var(--asc-vermilion);
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: right;
  align-items: center;
  gap: 5px;
  svg {
    min-width: 16px;
    min-height: 16px;
  }
`

export const AdjustPlanIconRow = styled(Row)`
  align-items: center;
  gap: 5px;
  color: var(--primary);
  margin-right: 10px;
  cursor: pointer;
  :hover {
    color: var(--secondary);
    svg {
      fill: var(--secondary) !important;
    }
  }
`
