import {sortArrayOfObjectsByStringValue} from 'common/helpers'
import {AlertEvents} from 'common/types/alertTypes'
import {Asset} from 'common/types/opening1Response'
import {isBefore, parseISO, subDays} from 'date-fns'
import {cloneDeep} from 'lodash'

export type GutStateType = {
  assetCount: number
  inactive: {
    count: number
    color: 'green' | 'red' | 'yellow'
    assets: number[]
  }
  running: {
    count: number
    color: 'green' | 'red' | 'yellow'
    assets: number[]
  }
  moving: {
    count: number
    color: 'green' | 'red' | 'yellow'
    assets: number[]
  }
  stopped: {
    count: number
    color: 'green' | 'red' | 'yellow'
    assets: number[]
  }
  due: {
    count: number
    color: 'green' | 'red' | 'yellow'
    assets: number[]
  }
  overdue: {
    count: number
    color: 'green' | 'red' | 'yellow'
    assets: number[]
  }
}

export const initialGutAssetState: GutStateType = {
  assetCount: 0,

  inactive: {
    count: 0,
    color: 'green',
    assets: [],
  },
  running: {
    count: 0,
    color: 'green',
    assets: [],
  },
  moving: {
    count: 0,
    color: 'green',
    assets: [],
  },
  stopped: {
    count: 0,
    color: 'green',
    assets: [],
  },
  due: {
    count: 0,
    color: 'green',
    assets: [],
  },
  overdue: {
    count: 0,
    color: 'green',
    assets: [],
  },
}

export const calcGutAssetValues = (assets: Asset[]) => {
  // ALERTS - One per asset/alert type (assets can be repeated if different alert types)
  // INACTIVE - Last motion time more than 24 hours ago (can also be running or in motion if we don't have points)
  // RUNNING - Engine is running (can also be in motion)
  // MOVING - Asset In_Motion
  // STOPPED - Asset not In_Motion (can also be running or inactive?)
  // DUE - Assets with plans due (asset can be repeated in overdue)
  // OVERDUE - Assets with plans overdue (asset can be repeated in due)
  if (assets?.length) {
    const newCounts = cloneDeep(initialGutAssetState)
    newCounts.assetCount = assets.length
    assets.forEach(asset => {
      if (asset?.Device?.In_Motion) {
        newCounts.moving.count++
        newCounts.moving.assets.push(asset.idAsset)
      } else {
        newCounts.stopped.count++
        newCounts.stopped.assets.push(asset.idAsset)
        newCounts.stopped.color = 'red'
      }
      if (asset?.Device?.Is_Running) {
        newCounts.running.count++
        newCounts.running.assets.push(asset.idAsset)
        newCounts.running.color = 'yellow'
      }

      const twentyFourHoursAgo = subDays(new Date(), 1)
      const lastMotionTime = asset?.Device?.Last_Motion_Time
      if (lastMotionTime) {
        const lastMotionTimeDateObject = parseISO(String(lastMotionTime))
        const olderThan24Hours = isBefore(
          lastMotionTimeDateObject,
          twentyFourHoursAgo,
        )
        if (olderThan24Hours) {
          newCounts.inactive.count++
          newCounts.inactive.assets.push(asset.idAsset)
        }
      }

      if (asset.MaintDueCount) {
        newCounts.due.count++
        newCounts.due.assets.push(asset.idAsset)
        newCounts.due.color = 'yellow'
      }
      if (asset.MaintOverdueCount) {
        newCounts.overdue.count++
        newCounts.overdue.assets.push(asset.idAsset)
        newCounts.overdue.color = 'red'
      }
    })

    if (newCounts.inactive.count > assets.length * 0.1) {
      newCounts.inactive.color = 'red'
    }
    return newCounts
  } else {
    return initialGutAssetState
  }
}

const alertsDataTestIds: {[key: number]: string} = {
  23: 'offhours',
  28: 'ignitiononenginerunning',
  24: 'movementstarted',
  25: 'movementstopped',
  26: 'noactivity',
  27: 'extendedstop',
  40: 'towdetected',
  2000: 'camerashockpanicharshdriving',
  2007: 'camerasevereshock',
  2008: 'cameradistracteddriver',
  2011: 'cameradriverunbelted',
  2012: 'cameracellphoneusage',
  2018: 'cameraobstruction',
  2020: 'cameranosignal',
  2021: 'cameraaiphoneunbelteddistract',
  1: 'topspeed',
  8: 'speedlimit',
  506: 'erraticdriving',
  5: 'harshacceleration',
  4: 'harshbraking',
  3: 'harshmaneuver',
  2003: 'panicbuttonpressed',
  500: 'enginerpm',
  501: 'enginecoolanttemp',
  502: 'batteryvoltagelow',
  503: 'batteryvoltagehigh',
  504: 'fuellow',
  515: 'deflow',
  6: 'idleoccurence',
  7: 'idlelimit',
  259: 'fuelfillup',
  518: 'fuelcardviolation',
  20: 'gpsbatterypercentlow',
  21: 'gpsbatteryvoltagelow',
  22: 'gpstemplow',
  19: 'gpstemphigh',
  509: 'gpsnotreporting',
  39: 'poweron',
  976: 'powerloss',
  10: 'locationentry',
  11: 'locationexit',
  9: 'locationinout',
  12: 'locationstop',
  512: 'locationtimeexceededinside',
  513: 'locationtimeexceededoutside',
  13: 'zoneentry',
  14: 'zoneexit',
  15: 'zonestop',
  16: 'maintenancedueoverdue',
  17: 'maintenance',
  18: 'maintenanceoverdue',
  505: 'checkengine',
  514: 'inspectionfailed',
  516: 'inspectionmonitor',
  519: 'inspectionproximityviolation',
  3000: 'latearrival',
  3001: 'missedstop',
  507: 'sensoractive',
  510: 'tempsensorhigh',
  511: 'tempsensorlow',
  517: 'input1active',
}

export const calcGutAlertValues = (alerts: AlertEvents[]) => {
  // ALERTS - One per asset/alert type (assets can be repeated if different alert types)

  const alertsByType: {
    type: string
    count: number
    assets: number[]
    dataTestId: string
  }[] = []
  let alertCount = 0
  if (alerts) {
    alerts.forEach(alert => {
      const alertIndex = alertsByType.findIndex(
        a => a.type === alert.Alert_Type,
      )
      if (alertIndex > -1) {
        if (!alertsByType[alertIndex].assets.includes(alert.idAsset)) {
          alertsByType[alertIndex].count++
          alertsByType[alertIndex].assets.push(alert.idAsset)
          alertCount++
        }
      } else {
        alertsByType.push({
          type: alert.Alert_Type,
          count: 1,
          assets: [alert.idAsset],
          dataTestId: `alert-${alertsDataTestIds[alert.idAlert]}`,
        })
        alertCount++
      }
    })
  }

  const sortedAlertsByType = sortArrayOfObjectsByStringValue(
    alertsByType,
    'type',
  )
  const newAlerts = {
    count: alertCount,
    color: alertCount > 0 ? 'red' : 'green',
    types: sortedAlertsByType,
  }
  return newAlerts
}
