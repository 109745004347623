import {isBefore} from 'date-fns'
import {ShiftTimePickerType} from 'routingAndDispatch/Setup/routeSettings/routeSettingsHelper'
import {StopType} from 'routingAndDispatch/types/typesModule'
export const HOURS = 'hours'
export const MINS = 'mins'

export const getHoursMinutes = (time: number | null) => {
  if (!time) {
    return {hrs: 0, mins: 0}
  }
  const hours = Math.floor(time / 60)
  const minutes = time % 60
  return {hrs: hours, mins: minutes}
}

export const getTotalMinutesFromTime = ({
  hrs: hours,
  mins: minutes,
}: {
  hrs: number
  mins: number
}) => {
  return hours * 60 + minutes
}

export const getShiftTimeFromDate: (date: Date) => ShiftTimePickerType = (
  date: Date,
) => {
  const rawHour = date.getHours()
  const hour: number = rawHour % 12
  const minute: number = date.getMinutes()
  const ampm: 'AM' | 'PM' = Number(rawHour) <= 12 ? 'AM' : 'PM'
  return {ampm, hour, minute}
}

export const getStringFromAMPMHoursMinutes = (
  timeObject: ShiftTimePickerType | string,
) => {
  let timeString = ''

  if (
    typeof timeObject !== 'string' &&
    !isNaN(timeObject?.hour) &&
    !isNaN(timeObject.minute)
  ) {
    const hours = timeObject.hour > 12 ? timeObject.hour - 12 : timeObject.hour

    const minutes = timeObject.minute
    timeString =
      hours.toString().padStart(2, '0') +
      ':' +
      minutes.toString().padStart(2, '0') +
      ':00'
  } else if (typeof timeObject === 'string') {
    const time = timeObject.split('T')[1]
    const hours =
      Number(time.split(':')[0]) === 24 || Number(time.split(':')[0]) === 12
        ? Number(time.split(':')[0]) - 12
        : Number(time.split(':')[0])

    const minutes = time.split(':')[1]
    timeString = `${timeObject.split('T')[0]}T${
      hours.toString().padStart(2, '0') +
      ':' +
      minutes.toString().padStart(2, '0') +
      ':00'
    }`
  }
  return timeString
}

//completed stops, the number of stops with the status complete
//pending stops, a plan has not been started or completed, anything that isn't completed or arrived is pending
//late stops, if plan not started or completed, if the arrival date (when the stop actually arrives) is after the service start date time (the scheduled scheduledServiceDate when creating) it is considered late
//    if the plan is completed, make sure stop is not missed, if not calculate above
//ontime stops, if plan hasn't happened yet or the arrival date is before the scheduled

export const getStopStatusCounts = (
  date: Date,
  stops: StopType[],
  planStatus?: string,
) => {
  const totalStops = stops?.length || 0
  const completedStops =
    stops?.filter(stop => stop.stopStatus?.toLowerCase() === 'completed')
      ?.length || 0
  const pendingStops =
    planStatus?.toLowerCase() !== 'completed' ? totalStops - completedStops : 0
  let onTimeStops = 0
  let lateStops = 0

  stops &&
    stops?.forEach(stop => {
      const now = new Date()

      if (planStatus?.toLowerCase() !== 'completed') {
        //if the plan date is in the past and not yet complete, and the stops has not been arrived, it is late. if it hasn't happened yet, the stops are on time
        if (stop.isLate) {
          lateStops++
        } else if (isBefore(now, date)) {
          onTimeStops++
        }
        //else if the stop has been arrived, check if arrival is before the scheduled date
        else if (stop.ArrivalDate && stop.ArrivalDate !== '') {
          const arrivalDate = new Date(stop.ArrivalDate)
          if (
            stop.ServiceStartDateTime &&
            arrivalDate > stop.ServiceStartDateTime
          ) {
            lateStops++
          } else if (
            stop.ServiceStartDateTime &&
            isBefore(arrivalDate, stop.ServiceStartDateTime)
          ) {
            onTimeStops++
          }
        }
      } else {
        //if the plan is completed, do not count missed as late

        if (
          stop.ArrivalDate &&
          stop.ArrivalDate !== '' &&
          stop.stopStatus?.toLowerCase() !== 'missed'
        ) {
          const arrivalDate = new Date(stop.ArrivalDate)
          if (
            stop.ServiceStartDateTime &&
            arrivalDate > stop.ServiceStartDateTime
          ) {
            lateStops++
          } else if (
            stop.ServiceStartDateTime &&
            arrivalDate < stop.ServiceStartDateTime
          ) {
            onTimeStops++
          }
        }
      }
    })

  return {totalStops, completedStops, pendingStops, lateStops, onTimeStops}
}
