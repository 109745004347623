import {differenceInSeconds, parseISO} from 'date-fns'
import {HistoryTrailPoints, HistoryTrailPoint} from './hooks/historyTrailTypes'
import {AlertEvents} from 'common/types/alertTypes'
import {SensorType} from 'sensors/hooks/useSensors'

export interface Feature {
  type: 'Feature'
  geometry: {type: string; coordinates: [number, number]}
  properties: {
    CameraThumbnailUrl?: string
    id: number
    idEvent_Code: number
    idRaw_Data: number
    Event_Type: string
    Event_Timestamp: string
    Event_Timestamp_Local: string
    Nearby_Address: string
    Odometer: number | undefined
    Last_Heading: number
    Last_Heading_Cardinal: string
    Last_Speed: number
    In_Motion: boolean
    stopLength: string
    CurrentDriver: string
    validCoordinates?: boolean
    visible?: boolean
    Alerts?: AlertEvents[]
    SensorEvent?: SensorType[]
    CameraMediaEventIds?: number[]
    color?: string
    title?: string
    icon?: {
      url: string
    }
  }
}
export function createGeoJson(events: HistoryTrailPoints) {
  var convertedJSON: {type: 'FeatureCollection'; features: Feature[]} = {
    type: 'FeatureCollection',
    features: [],
  }
  const eventsWithStopTime = calculateStopTimes(events)
  eventsWithStopTime?.forEach((thisEvent: HistoryTrailPoint, index: number) => {
    const coordinates = [thisEvent.Longitude, thisEvent.Latitude]
    if (
      thisEvent.Longitude &&
      thisEvent.Latitude &&
      coordinates[0] !== 0 &&
      coordinates[1] !== 0
    ) {
      var feature: Feature = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [thisEvent.Longitude, thisEvent.Latitude],
        },
        properties: {
          id: index + 1,
          idEvent_Code: thisEvent.idEvent_Code,
          idRaw_Data: thisEvent.idRaw_Data,
          Event_Type: thisEvent.Event_Type,
          Event_Timestamp: thisEvent.Event_Timestamp,
          Event_Timestamp_Local: thisEvent.Event_Timestamp_Local,
          Nearby_Address: thisEvent.Nearby_Address,
          Odometer: thisEvent.Engine_Odometer || thisEvent.GPS_Odometer,
          Last_Heading: thisEvent.Last_Heading,
          Last_Heading_Cardinal: thisEvent.Last_Heading_Cardinal,
          Last_Speed: thisEvent.Last_Speed,
          In_Motion: thisEvent.In_Motion,
          stopLength: thisEvent.stopLength || '',
          Alerts: thisEvent.Alerts,
          SensorEvent: thisEvent.SensorEvent,
          CameraMediaEventIds: thisEvent.CameraMediaEventIds,
          CurrentDriver: thisEvent.CurrentDriver,
          validCoordinates: thisEvent.validCoordinates,
          visible: thisEvent.visible,
          color: thisEvent.color,
          title: thisEvent.title,
          icon: thisEvent.icon,
          CameraThumbnailUrl: thisEvent.CameraThumbnailUrl,
        },
      }
      convertedJSON.features.push(feature)
    }
  })
  return convertedJSON
}

export function secondsToHMSS(input: number) {
  var hours = Math.floor(input / 60 / 60)
  var minutes = Math.floor(input / 60) % 60
  var seconds = input % 60
  return (
    hours +
    ':' +
    (minutes < 10 ? '0' : '') +
    minutes +
    ':' +
    (seconds < 10 ? '0' : '') +
    seconds
  )
}

function calculateStopTimes(events: HistoryTrailPoints) {
  var inMotion = false
  var eventCount = events?.length

  events?.forEach(point => {
    var pointDateTime = parseISO(point.Event_Timestamp + 'Z')
    var stopLength: number | undefined
    let nextMotion: HistoryTrailPoint | undefined
    if ((!point.In_Motion || !point.Engine_Running) && inMotion) {
      //this point is not in motion and we were in motion before then record stop
      inMotion = false
      stopLength = 0
      if (!point.stopLength) {
        //check that stop time wasn't already calculated (subsequent filters)
        // if (index === eventCount - 1) {
        //   //this is the last entry - calculate stop time to now
        //   stopLength = Math.floor(
        //     differenceInSeconds(new Date(), pointDateTime),
        //   )
        //   // stopLength = Math.floor(moment.utc().diff(moment.utc(timeux)) / 1000);
        // } else {
        //now find the next entry where we are in motion
        const futureEvents = events.filter(o => {
          return o.idRaw_Data > point.idRaw_Data
        })
        nextMotion = futureEvents.find(e => e.In_Motion)
        if (nextMotion) {
          const nextMotionDate = parseISO(nextMotion.Event_Timestamp + 'Z')
          stopLength = Math.floor(
            differenceInSeconds(nextMotionDate, pointDateTime),
          )
          // stopLength = moment(nextMotion.Event_Timestamp).diff(moment(timeux)) / 1000;
        }
        // }
      }
      if (stopLength > 0) {
        stopLength = Math.round(stopLength) //round off decimals - some camera events were giving fractions
        if (nextMotion) {
          //put stop time on last stop event not first one
          var nextMotionIndex = events.findIndex(event => {
            return event.idRaw_Data === nextMotion?.idRaw_Data
          })
          events[nextMotionIndex - 1].stopLength = secondsToHMSS(stopLength)
        } else {
          //last event
          events[eventCount - 1].stopLength = secondsToHMSS(stopLength)
        }
      }
    } else {
      if (point.In_Motion && !inMotion) {
        //we are now in motion and weren't before
        inMotion = true
      }
    }
  })
  return events
}
