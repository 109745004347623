import * as React from 'react'
import {Card, Radio, Divider} from 'semantic-ui-react'
import styled from 'styled-components/macro'
import {postFeedback} from './feedbackHelper'
import {InputWithLabel} from 'common/components'
import TextAreaWithLabel from 'common/components/TextAreaWithLabel'
import Modal from 'common/Modal'

const ModalBody = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-height: 700px;
  max-widht: 500px;
  && .radio {
    padding: 10px;
  }
`
const Message = styled.div`
  font-size: 16px;
`
const MessageSent = styled.div`
  font-size: 20px;
  text-align: center;
  padding: 40px 20px;
`
const FeedBack = ({onDismiss}) => {
  const [feedback, setFeedback] = React.useState({
    Comments: '',
    FeedbackType: null,
    Subject: '',
  })
  const [submited, setSubmitted] = React.useState(false)
  const onSave = async () => {
    await postFeedback(feedback)
    setSubmitted(true)
  }

  return (
    <Modal
      showDialog={true}
      title={'Send Feedback'}
      width={'600px'}
      renderFooter={submited ? false : true}
      disabled={!feedback?.Subject}
      onDismiss={onDismiss}
      onSave={onSave}
      submitButtonText="Submit"
    >
      {submited ? (
        <ModalBody>
          <MessageSent>
            We have successfully received your feedback. Thank you.
          </MessageSent>
        </ModalBody>
      ) : (
        <ModalBody>
          <Radio
            label="I like something."
            onClick={() => setFeedback({...feedback, FeedbackType: 'positive'})}
            checked={feedback.FeedbackType === 'positive'}
          />
          <Radio
            label="I don't like something."
            onClick={() => setFeedback({...feedback, FeedbackType: 'negative'})}
            checked={feedback.FeedbackType === 'negative'}
          />
          <Radio
            label="I have a suggestion."
            onClick={() =>
              setFeedback({...feedback, FeedbackType: 'suggestion'})
            }
            checked={feedback.FeedbackType === 'suggestion'}
          />
          {feedback.FeedbackType && (
            <Card fluid>
              <Card.Content>
                <Card.Header>
                  <Message>
                    {feedback.FeedbackType === 'positive' &&
                      `That's great! Let us know what we're doing right, and we'll
                  make sure we stay on the right track.`}
                    {feedback.FeedbackType === 'negative' &&
                      `We're sorry to hear that! If you think it’s something that we can fix right away, contact customer support and they’ll get right on it. \nOtherwise, please let us know how we can improve.`}
                    {feedback.FeedbackType === 'suggestion' &&
                      `We’re always trying to improve! Let us know your thoughts, and we’ll take it under consideration`}
                  </Message>
                </Card.Header>
                <Divider />
                <Card.Description>
                  <InputWithLabel
                    // errors={state.errors}
                    inputProps={{
                      value: feedback?.Subject || '',
                      onChange: event => {
                        setFeedback({
                          ...feedback,
                          Subject: event.target.value,
                        })
                      },
                    }}
                    label="Subject"
                    name="Subject"
                  />

                  <TextAreaWithLabel
                    rows={4}
                    defaultValue={feedback?.Comments || ''}
                    setValue={(name, value) => {
                      setFeedback({...feedback, [name]: value})
                    }}
                    label="Comments"
                    name="Comments"
                  />
                </Card.Description>
              </Card.Content>
            </Card>
          )}
        </ModalBody>
      )}
    </Modal>
  )
}
export default FeedBack
