import * as React from 'react'
import {matchSorter} from 'match-sorter'
import styled from 'styled-components/macro'
import useOpening4 from 'opening/hooks/useOpening4'
import ProjectCard from './ProjectCard'

const ListContainer = styled.div`
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
`

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const ProjectList = ({
  setAssetFilter,
  clearAssetFilter,
  selectedProjects = [],
  searchValue,
}) => {
  const {data: opening4} = useOpening4()
  const [filteredProjects, setFilteredProjects] = React.useState([])
  const [allProjects, setAllProjects] = React.useState([])

  React.useEffect(() => {
    const projects = opening4?.projects || []
    const allSorted = [...projects].sort((a, b) =>
      a.Group_Name.localeCompare(b.Group_Name),
    )
    setFilteredProjects(allSorted)
    setAllProjects(allSorted)
  }, [opening4])

  React.useEffect(() => {
    if (searchValue) {
      const newFilteredProjects = matchSorter(allProjects, searchValue, {
        keys: ['Group_Name'],
        threshold: matchSorter.rankings.CONTAINS,
      })
      setFilteredProjects(newFilteredProjects)
    } else {
      setFilteredProjects(allProjects)
    }
  }, [allProjects, searchValue])

  const toggleProject = e => {
    const projectID = Number.isInteger(e)
      ? e
      : Number(e.currentTarget.dataset.projectid)
    let newSelectedProjects

    if (selectedProjects.includes(projectID)) {
      newSelectedProjects = selectedProjects.filter(
        project => project !== projectID,
      )
    } else {
      newSelectedProjects = [...selectedProjects, projectID]
    }
    if (newSelectedProjects.length === 0) {
      clearAssetFilter()
    } else {
      const assetsSelected = []
      const selectedLocations = []
      newSelectedProjects.forEach(id => {
        const project = filteredProjects.find(p => p.idGroup === id)

        if (project?.Assets && project.Assets.length > 0) {
          project.Assets.forEach(idAsset => {
            if (!assetsSelected.includes(idAsset)) {
              assetsSelected.push(idAsset)
            }
          })
        }
        if (project?.Locations && project.Locations.length > 0) {
          project.Locations.forEach(idLocation => {
            if (!selectedLocations.includes(idLocation)) {
              selectedLocations.push(idLocation)
            }
          })
        }
      })

      setAssetFilter({
        filteredAssetIDs: assetsSelected,
        label: 'Projects',
        projectIDs: newSelectedProjects,
        selectedLocations: selectedLocations,
      })
    }
  }

  return (
    <ListContainer>
      <List>
        {filteredProjects.map(project => (
          <ProjectCard
            key={project.idGroup}
            project={project}
            selectedProjects={selectedProjects}
            selected={selectedProjects.includes(project.idGroup)}
            toggleProject={toggleProject}
          ></ProjectCard>
        ))}
      </List>
    </ListContainer>
  )
}
export default ProjectList
