import styled from 'styled-components/macro'
export const DirectionsMapContainer = styled.div`
  height: calc(100vh - 100px);
  max-height: 95vh;
  width: 300px;
  display: flex;
  flex-direction: row;
  flex: 1;
  position: absolute;
  top: 90px;
  right: 80px;
  background-color: white;
`
export const DirectionsInputsPanel = styled.div`
  position: relative;
  width: 400px;
  padding: 0 10px;
`
export const IconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  background-color: ${props => (props.disabled ? '#ccc' : '#4285f4')};
  flex: 1;
`
export const IconButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-direction: row;
`
export const ReverseIcon = styled.div`
  background: url(//maps.gstatic.com/tactile/directions/omnibox/directions-1x-20150929.png);
  background-size: 96px 216px;
  width: 24px;
  height: 24px;
  background-position: 171px -27px;
  cursor: pointer;
  margin: 10px 5px 5px 0px;
`
export const TurnRightIcon = styled(ReverseIcon)`
  background-position: 168px -73px;
`
export const DirectionPanel = styled.div`
  max-height: 75vh;
  overflow-y: scroll;
`
export const CollapseIcon = styled.div`
  justify-content: end;
  display: flex;
  margin: 10px 5px 0px 0px;
  cursor: pointer;
  svg {
    color: var(--primary);
  }
  &:hover {
    svg {
      fill: var(--secondary);
    }
  }
`
