import * as React from 'react'
import {RoutePlanType} from 'routingAndDispatch/types/typesModule'
import {useStore} from 'common/useStore'
import {Spinner, BreadCrumbs, Crumb} from 'common/components'
import {
  AddStopButton,
  ColumnHeader,
  Container,
  ContentWrapper,
  ErrorMessage,
  Heading,
  LoaderContainer,
  RouteMapColumn,
  RouteTimeTableRow,
  StopCardContainer,
  StyledArrowLeft,
  StyledTitleWrapper,
  Title,
} from './routeDetailStyles'
import {ArrowLeft, PlusCircle} from 'phosphor-react'
import StopCardPanel from './Stops Panel/StopCardPanel'
import RouteDetailMap from './RouteDetailMap'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import SummaryPanel from './SummaryPanel'
import {
  ReducerType,
  initialState,
  reducer,
  getSegmentDateRange,
  DateRangeType,
  getDates,
  initialStop,
} from './routeDetailHelpers'
import {AssetWithColor} from '../PlanReview/planReviewHelper'
import useHistoryTrailPoints from 'historyTrail/hooks/useHistoryTrailPoints'
import RouteTimeTable from 'routingAndDispatch/common/RouteTimeTable/RouteTimeTable'
import {format, isSameDay, parse} from 'date-fns'
import {Row, Typography} from '../routesStyles'
import {PlanReviewStyledDropdownOptional} from 'routingAndDispatch/common/RouteTimeTable/routePlanTimeTableStyles'
import {
  getDateOptions,
  getTimeRange,
} from 'routingAndDispatch/common/RouteTimeTable/routePlanTimeTableHelpers'
import SendNotificationModal from './Stops Panel/SendNotifications/SendNotificationModal'
import useRouteDetailByDriver from './hooks/useRouteDetailByDriver'
import usePostSendNotification from './Stops Panel/SendNotifications/hooks/usePostSendNotification'
import AddStopFromDetailModal from './Stops Panel/AddStopFromDetail/AddStopFromDetailModal'
import {Loader} from 'semantic-ui-react'
import NotificationSelectionModal from './Stops Panel/SendNotifications/NotificationSelectionModal'
import {AdjustPlanIconRow} from '../View/routePlansStyles'
import {AdjustRoutesIcon} from 'routingAndDispatch/common/AdjustRoutesIcon'
import {useRouteEditingStore} from 'routingAndDispatch/RouteEditing/useRouteEditingStore'

const RouteDetail = () => {
  const {idPlan, idRouteDispatchDriver} = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const setBreadcrumbs = useStore(state => state.setBreadcrumbs)
  const userConfig = useStore(state => state.userConfig)
  const setDateSelected = useRouteEditingStore(state => state.setDateSelected)

  const [state, dispatch]: ReducerType = React.useReducer(reducer, initialState)
  const {
    data: routeDetailData,
    status: routeDetailStatus,
    refetch,
    isRefetching,
  } = useRouteDetailByDriver(
    idPlan,
    idRouteDispatchDriver,
    state.showStopModal || false,
  )
  const {mutateAsync: postSendNotification} = usePostSendNotification()

  React.useEffect(() => {
    if (routeDetailData) {
      dispatch({
        type: 'setState',
        data: {routePlanDriverSegment: routeDetailData},
      })
    }
  }, [routeDetailData])

  const dateRange: DateRangeType =
    state.routePlanDriverSegment?.segments?.length &&
    state.routePlanDriverSegment.planStatus !== 'Planning'
      ? getSegmentDateRange(state.routePlanDriverSegment as RoutePlanType)
      : {startDate: '', endDate: ''}

  const {data: historyTrailPoints, refetch: refetchHistoryTrail} =
    useHistoryTrailPoints(
      state.routePlanDriverSegment?.segments[0].Asset
        ? state.routePlanDriverSegment?.segments[0].Asset.idAsset
        : null,
      dateRange ? dateRange.startDate : '',
      dateRange ? dateRange.endDate : '',
    )

  const scrollToIndex: number | undefined = location.state?.scrollToIndex

  const isPlanning =
    (state.routePlanDriverSegment?.planStatus &&
      state.routePlanDriverSegment?.planStatus.toLowerCase() === 'planning') ||
    false

  const segment =
    (state.routePlanDriverSegment?.segments &&
      state.routePlanDriverSegment?.segments[0]) ||
    null

  const segmentStatus = segment?.segmentStatus

  const showHistoryButtons =
    segmentStatus === 'Completed' || segmentStatus === 'InProgress'

  const isMultiDay =
    state.routePlanDriverSegment &&
    !isSameDay(
      state.routePlanDriverSegment.planStartDateTime,
      state.routePlanDriverSegment.planEndDateTime,
    )

  React.useEffect(() => {
    setBreadcrumbs(
      <BreadCrumbs>
        <Crumb href="/map">Home</Crumb>
        <Crumb>Route Optimization</Crumb>
        <Crumb href="/routing/routes">Routes</Crumb>
        <Crumb href="/routing/routes/list">Plans</Crumb>
        <Crumb href={`/routing/routes/${state.routePlanDriverSegment?.idPlan}`}>
          {state.routePlanDriverSegment?.planName}
        </Crumb>
        <Crumb>Route Detail</Crumb>
      </BreadCrumbs>,
    )
  }, [
    setBreadcrumbs,
    state.routePlanDriverSegment?.idPlan,
    state.routePlanDriverSegment?.planName,
  ])

  const handleDateChange = (value: string) => {
    const day = parse(
      value,
      userConfig?.Date_Format || 'MM/dd/yyyy',
      new Date(),
    )

    const segmentInfo = getTimeRange(
      state.routePlanDriverSegment as RoutePlanType,
      day,
    )
    dispatch({
      type: 'setState',
      data: {
        renderStartDate: segmentInfo.earliestTime,
        renderEndDate: segmentInfo.latestTime,
        dateToView: day,
      },
    })
    //scroll route time time to beginning when date change
    const routeTimeTable = document.getElementsByClassName('timeTable')[0]
    routeTimeTable.scrollLeft = 0
    routeTimeTable.scrollTop = 0
  }

  const onSendNotification = (body: {
    sendEmail: boolean
    emailAddresses: string
    ccAddresses: string
    emailSubject: string
    emailBody: string
    sendSms: boolean
    phoneNumbers: string
    smsBody: string
    idOrder: string
  }) => {
    if (idPlan && (body.sendEmail || body.sendSms)) {
      postSendNotification({idPlan: Number(idPlan), body})
    }
    //should send notification here
    dispatch({
      type: 'setCleanState',
      data: {showNotificationModal: false, selectedNotification: null},
    })
  }

  const onRefetch = () => {
    refetch()
    refetchHistoryTrail()
  }
  React.useEffect(() => {
    if (isMultiDay && state.routePlanDriverSegment) {
      dispatch({
        type: 'setState',
        data: {dateToView: state.routePlanDriverSegment.planStartDateTime},
      })
    }
  }, [isMultiDay, state.routePlanDriverSegment])

  return (
    <Container>
      {routeDetailStatus === 'error' ? (
        <span>
          There was an error loading this page. Please try again or contact
          customer service.
        </span>
      ) : routeDetailStatus !== 'success' && !isPlanning ? (
        <Spinner />
      ) : (
        <>
          {state.submitting && <Spinner />}
          <StyledTitleWrapper>
            <Title>
              <StyledArrowLeft
                data-cy={'back-button'}
                onClick={() =>
                  navigate(
                    state.routePlanDriverSegment
                      ? `/routing/routes/${state.routePlanDriverSegment.idPlan}`
                      : `/routing/routes/list`,
                  )
                }
              >
                <ArrowLeft size={25} color={'var(--primary)'} />
              </StyledArrowLeft>
              Route Details
              {isRefetching && (
                <LoaderContainer>
                  <Loader active inline size="tiny" />
                  Retrieving Latest Data
                </LoaderContainer>
              )}
            </Title>
            <Row>
              {routeDetailStatus === 'success' &&
                state.routePlanDriverSegment &&
                !isPlanning && (
                  <AdjustPlanIconRow
                    onClick={() => {
                      if (state.routePlanDriverSegment?.planStartDateTime) {
                        setDateSelected(
                          new Date(
                            state.routePlanDriverSegment?.planStartDateTime,
                          ),
                        )
                      }
                      navigate('/routing/editing/')
                    }}
                  >
                    <AdjustRoutesIcon color="var(--primary)" />
                    <Typography fontSize={13}>ADJUST ROUTE PLAN</Typography>
                  </AdjustPlanIconRow>
                )}
              {(segmentStatus === 'InProgress' ||
                segmentStatus === 'Dispatched') && (
                <AddStopButton
                  onClick={() =>
                    dispatch({
                      type: 'setCleanState',
                      data: {showStopModal: true},
                    })
                  }
                >
                  <PlusCircle weight="fill" className="button-icon" size={16} />
                  Add Stop
                </AddStopButton>
              )}
            </Row>
          </StyledTitleWrapper>
          <ContentWrapper>
            <SummaryPanel
              routeDetailData={state.routePlanDriverSegment}
              isPlanning={isPlanning}
              showStopModal={Boolean(state.showStopModal)}
            />
            <RouteMapColumn>
              <ColumnHeader>
                <Heading>Route Map</Heading>{' '}
                {isMultiDay &&
                  state.dateToView &&
                  state.routePlanDriverSegment && (
                    <Row
                      alignItems="center"
                      justifyContent="flex-end"
                      gap="5px"
                    >
                      <span>Displaying stops for: </span>
                      <PlanReviewStyledDropdownOptional
                        name={'Day to View'}
                        label={'Day to View'}
                        clearable={false}
                        required={false}
                        placeholder="--Select a date--"
                        options={getDateOptions(
                          userConfig,
                          getDates(
                            state.routePlanDriverSegment as RoutePlanType,
                          ),
                        )}
                        onChange={({value}: {value: string}) =>
                          handleDateChange(value)
                        }
                        value={format(
                          state.dateToView || new Date(),
                          userConfig?.Date_Format || 'MM/dd/yyyy',
                        )}
                        errors={{}}
                      />
                    </Row>
                  )}
              </ColumnHeader>

              {state.routePlanDriverSegment?.segments?.length && (
                <>
                  <RouteTimeTableRow>
                    <RouteTimeTable
                      routeData={state.routePlanDriverSegment}
                      showNowTimeLine={
                        state.routePlanDriverSegment.planStatus.toLowerCase() !==
                        'planning'
                      }
                      isRouteDetail={true}
                      dateToView={
                        isMultiDay
                          ? state.dateToView
                          : state.routePlanDriverSegment.planStartDateTime
                      }
                    />
                  </RouteTimeTableRow>
                  <RouteDetailMap
                    segmentArray={state.routePlanDriverSegment.segments}
                    historyTrailPoints={historyTrailPoints || []}
                    assignedAssets={
                      segment && segment.Asset
                        ? [segment.Asset as AssetWithColor]
                        : []
                    }
                    detail={true}
                    // mapRef={mapRef}
                  />
                </>
              )}
            </RouteMapColumn>
            {state.routePlanDriverSegment ? (
              <StopCardPanel
                refetch={onRefetch}
                historyTrailPoints={historyTrailPoints}
                routeDetailDispatch={dispatch} //needed to prop drill this down to the stop modal to set the state for show stop modal in order to disable the api call while it is open
                showHistoryButtons={showHistoryButtons}
                scrollToIndex={scrollToIndex}
                routePlan={state.routePlanDriverSegment as RoutePlanType} //need this to pass route to order detail to return back to route detail since we don't load the route detail with an api/id params
              />
            ) : (
              routeDetailStatus === 'success' &&
              !state.routePlanDriverSegment && (
                <StopCardContainer>
                  <ErrorMessage>
                    An issue has occured loading your route, please try again
                    and contact customer service if the issue persists.
                  </ErrorMessage>
                </StopCardContainer>
              )
            )}
          </ContentWrapper>
        </>
      )}
      <AddStopFromDetailModal
        showStopModal={state.showStopModal || false}
        toggleShowStopModal={() =>
          dispatch({
            type: 'setCleanState',
            data: {showStopModal: !state.showStopModal},
          })
        }
        idSegment={state.routePlanDriverSegment?.segments[0].idSegment || ''}
        idPlan={idPlan || ''}
        idRouteDispatchDriver={idRouteDispatchDriver || ''}
        planStartDate={
          state.routePlanDriverSegment?.planStartDateTime || new Date()
        }
        planEndDate={
          state.routePlanDriverSegment?.planEndDateTime || new Date()
        }
        setSubmitting={submitting =>
          dispatch({type: 'setCleanState', data: {submitting: submitting}})
        }
      />
      <NotificationSelectionModal
        showNotificationSelectionModal={state.showNotificationSelectionModal}
        setShowNotificationsModal={showModal =>
          dispatch({
            type: 'setState',
            data: {showNotificationSelectionModal: showModal},
          })
        }
        setSelectedNotification={selectedNotification =>
          dispatch({
            type: 'setState',
            data: {selectedNotification: selectedNotification},
          })
        }
      />
      <SendNotificationModal
        showNotificationModal={state.selectedNotification !== null}
        onDismiss={() => {
          dispatch({
            type: 'setState',
            data: {
              showNotificationModal: false,
              stop: initialStop,
              selectedNotification: null,
            },
          })
        }}
        stop={state.stop}
        onSave={onSendNotification}
        idSegment={segment ? segment.idSegment : null}
        selectedNotification={state.selectedNotification}
      />
    </Container>
  )
}

export default RouteDetail
