import * as React from 'react'
import styled from 'styled-components/macro'
import EventFilterDropDown from './EventFilterDropdown'
import {useHistoryTrailStore} from './hooks/useHistoryTrailStore'
import {ascendColors} from 'common/GlobalStyles'

export type OptionsType = {
  key: string
  origText?: string
  value: string
  text: React.JSX.Element
}

const Wrapper = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 2;
`

const ItemBadge = styled.div<{
  eventFilter: boolean
  fontOnSecondary: string
  customSecondary: string
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 1em;
  padding: 4px 7px;
  line-height: 1em;
  justify-content: center;
  border-radius: 500rem;
  background-color: ${props =>
    props.eventFilter ? props.customSecondary : 'var(--asc-sonicsilver)'};
  font-weight: bold;
  color: ${props =>
    props.eventFilter ? props.fontOnSecondary : 'var(--asc-cultured)'};
`
const FilterText = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  div {
    margin-left: 20px;
  }
`
const options = [
  {
    key: 'All',
    origText: 'All Events',
    value: 'All',
  },
  {
    key: 'Motion',
    origText: 'Motion Events',
    value: 'Motion',
  },
  {
    key: 'Stop',
    origText: 'Stop Events',
    value: 'Stop',
  },
  {
    key: 'Alerts',
    origText: 'Alert Events',
    value: 'Alerts',
  },
  {
    key: 'Camera',
    origText: 'Camera Events',
    value: 'Camera',
  },
  {
    key: 'Driver Safety',
    origText: 'Driver Safety Events',
    value: 'Driver Safety',
  },
]

export const driverSafetyEventCodes = {
  26: 'Harsh Brake',
  27: 'Harsh Acceleration',
  28: 'Harsh Maneuver',
  2008: 'Distracted Driver',
  2012: 'Cell Phone Use',
  2011: 'Driver Unbelted',
}

const EventFilter = ({map}: {map: google.maps.Map}) => {
  const totalCount = useHistoryTrailStore(state => state.totalCount)
  const points = useHistoryTrailStore(state => state.points)
  const dateRangePoints = useHistoryTrailStore(state => state.dateRangePoints)
  const eventFilter = useHistoryTrailStore(state => state.eventFilter)

  const [availableFilters, setAvailableFilters] = React.useState<OptionsType[]>(
    [],
  )
  React.useEffect(() => {
    const optionCounts = [{title: 'All', count: totalCount}]
    const currentPoints =
      totalCount === points.length ? points : dateRangePoints
    currentPoints.forEach(point => {
      const foundOptionIndex = optionCounts.findIndex(
        o => o.title === point.title,
      )
      if (foundOptionIndex === -1) {
        optionCounts.push({title: point.title || '', count: 1})
      } else {
        const updatedOption = optionCounts[foundOptionIndex]
        updatedOption.count++
      }
      if (point.Alerts) {
        const foundAlertIndex = optionCounts.findIndex(
          o => o.title === 'Alerts',
        )
        if (foundAlertIndex === -1) {
          optionCounts.push({title: 'Alerts', count: 1})
        } else {
          const updatedOption = optionCounts[foundAlertIndex]
          updatedOption.count++
        }
      }
      if (
        driverSafetyEventCodes[
          point.idEvent_Code as keyof typeof driverSafetyEventCodes
        ]
      ) {
        const foundDriverSafetyIndex = optionCounts.findIndex(
          o => o.title === 'Driver Safety',
        )
        if (foundDriverSafetyIndex === -1) {
          optionCounts.push({title: 'Driver Safety', count: 1})
        } else {
          const updatedOption = optionCounts[foundDriverSafetyIndex]
          updatedOption.count++
        }
      }
    })

    const newAvailable: OptionsType[] = []
    optionCounts.forEach(opt => {
      const foundOption: OptionsType = options.find(
        o => o.key === opt.title,
      ) as OptionsType
      if (foundOption) {
        const copiedOption = Object.assign({}, foundOption)
        copiedOption.text = (
          <FilterText>
            {copiedOption.origText}
            <ItemBadge
              customSecondary={ascendColors.customSecondary}
              fontOnSecondary={ascendColors.fontOnSecondary}
              eventFilter={eventFilter === opt.title}
              className="event-filter-badge"
            >
              {new Intl.NumberFormat().format(opt.count)}
            </ItemBadge>
          </FilterText>
        )
        delete copiedOption.origText
        newAvailable.push(copiedOption)
      }
    })
    setAvailableFilters(newAvailable)
  }, [dateRangePoints, eventFilter, points, totalCount])

  return (
    <Wrapper>
      <EventFilterDropDown options={availableFilters} map={map} />
    </Wrapper>
  )
}
export default React.memo(EventFilter)
