import React from 'react'
import styled from 'styled-components/macro'
import {useStore} from 'common/useStore'

const SpeedometerContainer = styled.div`
  position: relative;
  width: 330px;
  font-size: 16px;
  margin: 10px 0;
  /* transform: scale(0.8); */
`
const SVGComponent = styled.svg`
  &&& {
    margin: 0;
    padding: 0;
    overflow: visible;
  }
`
const Scale = styled.g`
  stroke: #2d3142;
`
const Outline = styled.path`
  fill: #bfc0c0;
`
const Fill = styled.path`
  fill: #4f5d75;
`
const Needle = styled.polygon`
  fill: #ef8354;
`
const OutputContainer = styled.div`
  width: 60px;
  height: 30px;
  background-color: #bfc0c0;
  /*background-color: $output-color;*/
  border-radius: 60px 60px 0 0;
  position: absolute;
  top: 135px;
  left: 135px;

  .output-text {
    line-height: 17px;
    position: relative;
    top: -35px;
    left: 0px;
    text-align: center;
    font-weight: bold;
    white-space: nowrap;
  }
`

const rad = Math.PI / 180
const NS = 'http://www.w3.org/2000/svg'
const offset = 50 //radius of donut
const cy = 158

const OutputText = styled.span``
const Speedometer = ({speedValue = 0}) => {
  const userConfig = useStore(state => state.userConfig)
  React.useEffect(() => {
    var svg = document.querySelector('.speedometer .typeRange')
    var output = document.querySelector('.speedometer .output')
    var outline = document.querySelector('.speedometer .outline')
    var fill = document.querySelector('.speedometer .fill')
    var needle = document.querySelector('.speedometer .needle')
    var scale = document.querySelector('.speedometer .scale')

    var W = parseInt(
      window.getComputedStyle(svg, null).getPropertyValue('width'),
    )

    var cx = Math.floor(W / 2)

    var r1 = cx - offset
    var delta = Math.floor(r1 / 4)
    var r2 = r1 - delta
    var x2 = offset,
      y2 = cy

    function drawScale() {
      var sr1 = r1 + 5 //outer tick mark length
      var sr2 = r2 - 5 //inner tick mark length
      var srTx = r1 + 15 //number distance from donut
      var srTy = r1 + 15
      clearRect(scale)
      var n = 0
      for (var sa = -180; sa <= 0; sa += 18) {
        var sx1 = cx + sr1 * Math.cos(sa * rad)
        var sy1 = cy + sr1 * Math.sin(sa * rad)
        var sx2 = cx + sr2 * Math.cos(sa * rad)
        var sy2 = cy + sr2 * Math.sin(sa * rad)
        var sxT = cx + srTx * Math.cos(sa * rad) - 9
        var syT = cy + srTy * Math.sin(sa * rad)

        var scaleLine = document.createElementNS(NS, 'line')
        var scaleLineObj = {
          class: 'scale',
          x1: sx1,
          y1: sy1,
          x2: sx2,
          y2: sy2,
        }
        setSVGAttributes(scaleLine, scaleLineObj)

        scale.appendChild(scaleLine)

        var scaleText = document.createElementNS(NS, 'text')
        var scaleTextObj = {
          x: sxT,
          y: syT,
        }
        setSVGAttributes(scaleText, scaleTextObj)
        scaleText.textContent = n * 10
        scale.appendChild(scaleText)

        n++
      }
    }

    function drawInput(cx, cy, r1, offset, delta, a) {
      var d1 = getD1(cx, cy, r1, offset, delta)
      var d2 = getD2(cx, cy, r1, offset, delta, a)

      drawScale()

      outline.setAttribute('d', d1)
      fill.setAttribute('d', d2)

      drawNeedle(cx, cy, r1, a)
    }

    function updateInput(p, cx, cy, r1, offset, delta) {
      var x = p.x
      var y = p.y
      var lx = cx - x
      var ly = cy - y

      var a = Math.atan2(ly, lx) / rad - 180

      drawInput(cx, cy, r1, offset, delta, a)
    }

    function getD1(cx, cy, r1, offset, delta) {
      var x1 = cx + r1,
        y1 = cy
      var x2 = offset,
        y2 = cy
      var r2 = r1 - delta
      var x3 = x1 - delta,
        y3 = cy
      var d1 = `M ${x1}, ${y1} A${r1},${r1} 0 0 0 ${x2},${y2} H${
        offset + delta
      } A${r2},${r2} 0 0 1 ${x3},${y3} z`
      return d1
    }

    function getD2(cx, cy, r1, offset, delta, a) {
      a *= rad
      var r2 = r1 - delta
      var x4 = cx + r1 * Math.cos(a)
      var y4 = cy + r1 * Math.sin(a)
      var x5 = cx + r2 * Math.cos(a)
      var y5 = cy + r2 * Math.sin(a)

      var d2 = `M ${x4}, ${y4} A${r1},${r1} 0 0 0 ${x2},${y2} H${
        offset + delta
      } A${r2},${r2} 0 0 1 ${x5},${y5} z`
      return d2
    }

    function drawNeedle(cx, cy, r1, a) {
      var nx1 = cx + 5 * Math.cos((a - 90) * rad)
      var ny1 = cy + 5 * Math.sin((a - 90) * rad)

      var nx2 = cx + (r1 + 15) * Math.cos(a * rad)
      var ny2 = cy + (r1 + 15) * Math.sin(a * rad)

      var nx3 = cx + 5 * Math.cos((a + 90) * rad)
      var ny3 = cy + 5 * Math.sin((a + 90) * rad)

      var points =
        nx1 + ',' + ny1 + ' ' + nx2 + ',' + ny2 + ' ' + nx3 + ',' + ny3
      needle.setAttribute('points', points)
    }

    // helpers
    function clearRect(node) {
      while (node.firstChild) {
        node.removeChild(node.firstChild)
      }
    }

    function setSVGAttributes(elmt, oAtt) {
      for (var prop in oAtt) {
        elmt.setAttribute(prop, oAtt[prop])
      }
    }
    var newSpeed = Math.round(speedValue * 10) / 10
    newSpeed > 100 ? 100 : newSpeed
    var pa = speedValue * 1.8 - 180
    var p = {}
    p.x = cx + r1 * Math.cos(pa * rad)
    p.y = cy + r1 * Math.sin(pa * rad)
    updateInput(p, cx, cy, r1, offset, delta)
    output.innerHTML = `<span class="output-text">${newSpeed.toFixed(1)} ${
      userConfig.Speed_UOM
    } </span>`
  }, [userConfig.Speed_UOM, speedValue])

  return (
    <SpeedometerContainer className="speedometer" data-cy="speedometer">
      <SVGComponent className="typeRange" height="100%" width="100%">
        <Scale className="scale"></Scale>
        <Outline className="outline" d=""></Outline>
        <Fill className="fill" d=""></Fill>
        <Needle className="needle" points=""></Needle>
      </SVGComponent>
      <OutputContainer className="output">
        <OutputText className="output-text"></OutputText>
      </OutputContainer>
    </SpeedometerContainer>
  )
}

export default Speedometer
