import * as React from 'react'
import {Checkbox} from 'semantic-ui-react'
import Axios from 'axios'
import {
  MapControlContainer,
  MapControlButton,
  MobileMapControlContainer,
  DropdownMenu,
  DropdownMenuItem,
} from './MapControlStyles'
// import {drawLocations} from 'historyTrail/locationFeatureHelpers'
import {toggleBoxSearch} from './boxSearchHelper'
import {useMapStore} from 'map/useMapStore'
import {
  MagnifyingGlass,
  CaretDoubleRight,
  CaretDoubleLeft,
  BoundingBox,
  MapPinLine,
  ClockCounterClockwise,
  ArrowsClockwise,
  FadersHorizontal,
} from 'phosphor-react'
import Tooltip from 'common/components/Tooltip'
import {Asset} from 'common/types/opening1Response'
import {SetAssetFilterProps} from 'opening/openingHelpers'
import {LocationInputType} from 'routingAndDispatch/common/AddressCoordinatesToggle'
import {ascendColors} from 'common/GlobalStyles'

type MapControlProps = {
  map: google.maps.Map
  toggleMapSearch: () => void
  clearMap: () => void
  toggleRecentAssets: () => void
  assetsRef: React.MutableRefObject<Asset[] | undefined>
  setAssetFilter: (inp: SetAssetFilterProps) => void
  clearAssetFilter: () => void
  setLocationsFilter: (inp: LocationInputType[]) => void
  showRecentAssets: boolean
  showSearch: boolean
  allowSearch: boolean
  allowOptions: boolean
  allowRecent: boolean
}

const MapControl = ({
  map,
  toggleMapSearch,
  clearMap,
  toggleRecentAssets,
  assetsRef,
  setAssetFilter,
  clearAssetFilter,
  setLocationsFilter,
  showRecentAssets,
  showSearch,
  allowSearch = true,
  allowOptions = true,
  allowRecent = true,
}: MapControlProps) => {
  const [showToolbar, setShowToolbar] = React.useState(true)
  const [showLocations, setShowLocations] = React.useState(false)
  const showBoxSearch = useMapStore(state => state.showBoxSearch)
  const showClusters = useMapStore(state => state.showClusters)
  const showAssetLabels = useMapStore(state => state.showAssetLabels)
  const showDropdown = useMapStore(state => state.showDropdown)
  const toggleClusters = () => {
    useMapStore.setState({
      showClusters: !showClusters,
    })
  }
  const toggleAssetsLabels = () => {
    useMapStore.setState({
      showAssetLabels: !showAssetLabels,
    })
  }
  const toggleDropdown = () => {
    useMapStore.setState({
      showDropdown: !showDropdown,
    })
    if (showRecentAssets) {
      toggleRecentAssets()
    }
    if (showSearch) {
      toggleMapSearch()
    }
  }

  const toggleLocations = async (map: google.maps.Map) => {
    const newShowLocations = !showLocations
    if (map) {
      if (newShowLocations) {
        const bounds = map.getBounds()
        if (bounds) {
          const NE = bounds.getNorthEast()
          const SW = bounds.getSouthWest()
          const NELat = NE.lat()
          const NELng = NE.lng()
          const SWLat = SW.lat()
          const SWLng = SW.lng()
          try {
            const {data: locationsInView} = await Axios.get(
              `landmarks/viewport?lat1=${NELat}&long1=${SWLng}&lat2=${SWLat}&long2=${NELng}`,
            )
            setLocationsFilter(locationsInView)
          } catch (error) {
            console.error('error getting locations', error)
          }
        }
      } else {
        setLocationsFilter([])
      }
    }
    setShowLocations(!showLocations)
  }

  return (
    <>
      <MapControlContainer
        data-cy="map-control"
        hasOnlyOneButton={!showToolbar}
        onClick={() => useMapStore.setState({showAssetMarkers: true})}
      >
        {showToolbar ? (
          <React.Fragment>
            {allowOptions && (
              <MapControlButton
                fontOnPrimary={ascendColors.fontOnPrimary}
                fontOnSecondary={ascendColors.fontOnSecondary}
                onClick={toggleDropdown}
                isActive={showDropdown}
                data-testid="map-toolbar-mapoptions"
              >
                <Tooltip
                  tooltip={'Map Options'}
                  position={'top center'}
                  trigger={<FadersHorizontal size={20} />}
                />
                {showDropdown && (
                  <DropdownMenu>
                    <DropdownMenuItem
                      onClick={toggleAssetsLabels}
                      data-testid="map-toolbar-mapoptions-showassetnames"
                    >
                      <Checkbox
                        checked={showAssetLabels}
                        label={'Show Asset Names'}
                      />
                    </DropdownMenuItem>
                    {assetsRef?.current && assetsRef.current.length < 1000 && (
                      <DropdownMenuItem
                        onClick={toggleClusters}
                        data-testid="map-toolbar-mapoptions-showmapclusters"
                      >
                        <Checkbox
                          checked={showClusters}
                          label={'Show Map Clusters'}
                        />
                      </DropdownMenuItem>
                    )}
                  </DropdownMenu>
                )}
              </MapControlButton>
            )}
            <MapControlButton
              onClick={clearMap}
              title="Clear all filters and reset the map."
              data-testid="map-toolbar-resetmap"
              fontOnPrimary={ascendColors.fontOnPrimary}
              fontOnSecondary={ascendColors.fontOnSecondary}
            >
              <Tooltip
                tooltip={'Reset Map'}
                position={'top center'}
                trigger={<ArrowsClockwise size={20} />}
              />
            </MapControlButton>
            {allowRecent && (
              <MapControlButton
                onClick={() => {
                  toggleRecentAssets()
                  if (showDropdown) {
                    useMapStore.setState({
                      showDropdown: !showDropdown,
                    })
                  }
                  if (showSearch) {
                    toggleMapSearch()
                  }
                }}
                title="View your recently selected Assets."
                isActive={showRecentAssets}
                data-testid="map-toolbar-recentlyviewed"
                fontOnPrimary={ascendColors.fontOnPrimary}
                fontOnSecondary={ascendColors.fontOnSecondary}
              >
                <Tooltip
                  tooltip={'Recently Viewed Assets'}
                  position={'top center'}
                  trigger={<ClockCounterClockwise size={20} />}
                />
              </MapControlButton>
            )}
            <MapControlButton
              isActive={showLocations}
              title="Show locations within the visible map view."
              onClick={() => toggleLocations(map)}
              data-testid="map-toolbar-showhidelocations"
              fontOnPrimary={ascendColors.fontOnPrimary}
              fontOnSecondary={ascendColors.fontOnSecondary}
            >
              <Tooltip
                tooltip={showLocations ? 'Hide Locations' : 'Show Locations'}
                position={'top center'}
                trigger={<MapPinLine size={20} />}
              />
            </MapControlButton>
            <MapControlButton
              isActive={showBoxSearch}
              onClick={() => {
                toggleBoxSearch({
                  map,
                  assetsRef,
                  setAssetFilter,
                  clearAssetFilter,
                })
              }}
              data-cy="box-search-control"
              title="Draw a box on the map to select Assets and zoom in."
              data-testid="map-toolbar-boxsearch"
              fontOnPrimary={ascendColors.fontOnPrimary}
              fontOnSecondary={ascendColors.fontOnSecondary}
            >
              <Tooltip
                tooltip={showBoxSearch ? 'Cancel Box Search' : 'Box Search'}
                position={'top center'}
                trigger={<BoundingBox size={20} />}
              />
            </MapControlButton>
            {allowSearch && (
              <MapControlButton
                isActive={showSearch}
                onClick={() => {
                  toggleMapSearch()
                  if (showDropdown) {
                    useMapStore.setState({
                      showDropdown: !showDropdown,
                    })
                  }
                  if (showRecentAssets) {
                    toggleRecentAssets()
                  }
                }}
                data-cy="search-map-control"
                title="Enter an address or place to find it on the map."
                data-testid="map-toolbar-searchmap"
                fontOnPrimary={ascendColors.fontOnPrimary}
                fontOnSecondary={ascendColors.fontOnSecondary}
              >
                <Tooltip
                  tooltip={'Search Map'}
                  position={'top center'}
                  trigger={<MagnifyingGlass size={20} />}
                />
              </MapControlButton>
            )}{' '}
          </React.Fragment>
        ) : (
          <></>
        )}
        <MapControlButton
          data-cy="toggle-map-control"
          onClick={() => setShowToolbar(!showToolbar)}
          data-testid={`map-toolbar-${
            showToolbar ? 'map-toolbar-collapse' : 'map-toolbar-display'
          }`}
          fontOnPrimary={ascendColors.fontOnPrimary}
          fontOnSecondary={ascendColors.fontOnSecondary}
        >
          {showToolbar ? (
            <CaretDoubleRight size={20} />
          ) : (
            <Tooltip
              tooltip={'Display Map Toolbar'}
              position={'left center'}
              trigger={<CaretDoubleLeft size={20} />}
            />
          )}
        </MapControlButton>
      </MapControlContainer>
      <MobileMapControlContainer data-cy="map-control">
        <MapControlButton
          onClick={clearMap}
          title="Clear all filters and reset the map."
          fontOnPrimary={ascendColors.fontOnPrimary}
          fontOnSecondary={ascendColors.fontOnSecondary}
        >
          <Tooltip
            tooltip={'Reset Map'}
            position={'top center'}
            trigger={<ArrowsClockwise size={20} />}
          />
        </MapControlButton>
      </MobileMapControlContainer>
    </>
  )
}
export default MapControl
