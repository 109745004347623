import {useQuery} from '@tanstack/react-query'
import axios from 'axios'
import {useStore} from 'common/useStore'
import {getAvailableCameras} from 'common/components/liveLookHelper'

export type CameraOnlineType = {
  idAsset: number
  Asset_Label: string
  Asset_Description: string | null
  idDevice: number
  Serial_Number_Displayed: string
  idDevice_Type: number
  CurrentDriver: string | null
  CurrentIdDriver: number | null
  Last_Communication: string
  AvailableChannels?: string
  DeviceModelIDDV?: string
}

const getCamerasOnline = async () => {
  const {data}: {data: CameraOnlineType[]} = await axios.get(
    '/camera/assetlist/online',
  )
  const availableCameras = getAvailableCameras(data)
  return availableCameras
}

const useCamerasOnline = () => {
  const user = useStore(state => state.user)
  return useQuery(['camerasOnline'], () => getCamerasOnline(), {
    enabled: Boolean(user?.token),
  })
}

export default useCamerasOnline
