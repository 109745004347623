import * as React from 'react'
import styled from 'styled-components/macro'

const Container = styled.div`
  width: fit-content;
  display: flex;
  white-space: normal;
  background: #fff !important;
  border: 1px solid var(--error-border) !important;
  color: var(--error-text) !important;
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  margin-top: 5px;
  padding: 0.5833em 0.833em;
  text-transform: none;
  font-size: 0.85714286rem;
  font-weight: 700;
  border-radius: 0.28571429rem;
  position: absolute;
  bottom: -35px;
  left: 0;
  opacity: 90% !important;
  z-index: 1;
  ::before {
    border-width: 1px 0 0 1px;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    top: -1px;
    left: 50%;
    position: absolute;
    content: '';
    background-image: none;
    z-index: 2;
    width: 0.6666em;
    height: 0.6666em;
    background-color: inherit;
    border-style: solid;
    border-color: inherit;
  }
`

const InputErrorMessage = ({message}: {message: string | undefined}) => {
  if (!message) return null
  return (
    <Container
      role="alert"
      data-cy={'input-error-message'}
      id="input-error-message"
    >
      {message}
    </Container>
  )
}
export default InputErrorMessage
