import {useStore} from 'common/useStore'
import * as React from 'react'
import {Button} from 'semantic-ui-react'
import styled from 'styled-components/macro'
import ReactDOMServer from 'react-dom/server'

const InfoWindowContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  .label {
    width: 150px;
  }
  .button {
    margin: 2px 0;
  }
`
export const infoWindowWithCreateLocation = ({
  latlng,
  onNearestAssets,
  newInfowindow,
}) => {
  const userConfig = useStore.getState().userConfig
  const splitAdminOptions = userConfig?.RestrictedAdminAccess
    ? userConfig.RestrictedAdminAccess.split(',')
    : []

  let InfoWindowContent = (
    <InfoWindowContainer>
      <Button id="findNearest" icon="magnify" label="Find nearest Assets" />
    </InfoWindowContainer>
  )
  if (
    userConfig?.Role_Id === 1 ||
    (userConfig?.Role_Id === 11 &&
      splitAdminOptions.indexOf('locations') !== -1)
  ) {
    InfoWindowContent = (
      <InfoWindowContainer>
        <Button id="add-location" icon="marker" label="Add location" />
        <Button id="findNearest" icon="magnify" label="Find nearest Assets" />
      </InfoWindowContainer>
    )
  }

  const content = ReactDOMServer.renderToString(InfoWindowContent)

  window.google.maps.event.addListener(newInfowindow, 'domready', () => {
    document
      .getElementById('add-location')
      ?.addEventListener(
        'click',
        () =>
          (window.location.href = `/location?address=latlng:${latlng.lat()},${latlng.lng()}`),
      )
    document
      .getElementById('findNearest')
      ?.addEventListener('click', onNearestAssets)
  })
  return {newInfowindow, content}
}
