import React, { ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'

const ModalRoot = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20000;
  justify-content: center;
  align-items: center;
`

const ModalContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  min-height: 50vh;
  max-height: 80vh;
  width: 30vw;
  display: flex;
  flex-direction: column;
`

const ModalContainerWide = styled(ModalContainer)`
  width: 60vw !important;
`

const ModalHeader = styled.div`
  background-color: #eee;
  border-radius: 5px 5px 0px 0px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  font-size: calc(18 / 14 * 1rem);
`

const ModalContent = styled.div`
  flex: 1;
  display: flex;
  min-height: 0;
  justify-content: center;
`

const ModalContentInner = styled.div`
  padding-top: 10px;
  width: 80%;
  display: flex;
  flex-direction: column;
`

const ModalContainerInnerWide = styled(ModalContentInner)`
  width: 100% !important;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  margin-right: 10px;
`

const ClickableIcon = styled(Icon)`
  cursor: pointer;
`

type Props = {
  title: string
  onHide?: () => void
  children: ReactNode
  wide?: boolean
}

function Modal(props: Props) {
  const { onHide, title, wide } = props

  return (
    <ModalRoot data-cy="modal">
      {wide ? (
        <ModalContainerWide>
          <ModalHeader>
            <span>{title}</span>
            <ClickableIcon data-cy="close-icon" onClick={onHide ? () => onHide() : undefined} icon="times" />
          </ModalHeader>
          <ModalContent>
            <ModalContainerInnerWide>{props.children}</ModalContainerInnerWide>
          </ModalContent>
        </ModalContainerWide>
      ) : (
        <ModalContainer>
          <ModalHeader>
            <span>{title}</span>
            <ClickableIcon onClick={onHide ? () => onHide() : undefined} icon="times" />
          </ModalHeader>
          <ModalContent>
            <ModalContentInner>{props.children}</ModalContentInner>
          </ModalContent>
        </ModalContainer>
      )}
    </ModalRoot>
  )
}

export default Modal
