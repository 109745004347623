import React from 'react'
import {Tooltip, Checkbox} from 'common/components'
import {useStore} from 'common/useStore'
import {CaretDown, CaretUp} from 'phosphor-react'
import {
  ActionButton,
  ActionToggle,
  ButtonContainer,
  ButtonsSection,
  Controls,
  SelectAllRow,
  SelectionActionButton,
  StyledDropdown,
} from './resizableTableStyles'
import {Dropdown} from 'semantic-ui-react'

type Props<T> = {
  data: unknown[]
  toggleSelectAll?: (selectedId: T | undefined, selectAll: boolean) => void
  selectedItemsTotal?: number
  selectionActionButtons: SelectionActionButton[]
  disabled: boolean
  showChecked?: boolean
  // onDelete?: () => void
  // onModify?: () => void
}

const SelectAllButton = <T,>({
  data,
  toggleSelectAll,
  selectedItemsTotal,
  selectionActionButtons,
  disabled = false,
  showChecked = false,
}: Props<T>) => {
  const userConfig = useStore(state => state.userConfig)
  const [open, setOpen] = React.useState(false)

  return (
    <ButtonsSection>
      {(userConfig?.UserRole?.idUserRole === 0 ||
        userConfig?.UserRole?.idUserRole === 1) && (
        <SelectAllRow>
          <Tooltip
            tooltip={
              selectedItemsTotal === data.length ? 'Unselect All' : 'Select All'
            }
            position={'top center'}
            trigger={
              <Checkbox
                dataCy={'select-all'}
                onChange={(selectAll: boolean) =>
                  toggleSelectAll?.(undefined, selectAll)
                }
                disabled={false}
                color={undefined}
                selected={
                  showChecked ||
                  (selectedItemsTotal === data.length &&
                    selectedItemsTotal !== 0)
                }
              />
            }
          />
          <Controls>
            <StyledDropdown
              direction="left"
              open={open}
              onClose={() => setOpen(false)}
              disabled={disabled}
              trigger={
                <ButtonContainer>
                  <ActionButton
                    noOptions={selectionActionButtons.length === 1}
                    onClick={() => selectionActionButtons[0].actionFunction()}
                    data-cy="main-button"
                  >
                    {selectionActionButtons[0]?.icon &&
                      selectionActionButtons[0].icon}
                    {selectionActionButtons[0].text}
                  </ActionButton>
                  {selectionActionButtons.length > 1 && (
                    <ActionToggle
                      onClick={() => setOpen(!open)}
                      data-cy="toggle"
                    >
                      {open ? (
                        <CaretUp weight="fill" />
                      ) : (
                        <CaretDown weight="fill" />
                      )}
                    </ActionToggle>
                  )}
                </ButtonContainer>
              }
            >
              <Dropdown.Menu data-cy="dropdown-menu">
                {selectionActionButtons.map((action, index) => {
                  if (index === 0) return null
                  return (
                    <Dropdown.Item
                      key={action.text}
                      text={action.text}
                      onClick={action.actionFunction}
                      data-cy={action.text + '-dropdown-button'}
                    />
                  )
                })}
              </Dropdown.Menu>
            </StyledDropdown>
          </Controls>
        </SelectAllRow>
      )}
    </ButtonsSection>
  )
}

export default SelectAllButton
