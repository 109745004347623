import axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'
import {format, sub} from 'date-fns'

type AvailableDatesType = {
  [key: string]: boolean
}

const getAvailableDatesForDevice = async (
  idDevice: number | undefined,
): Promise<AvailableDatesType[]> => {
  const oneYearAgo = format(sub(new Date(), {years: 1}), 'yyyy-MM-dd')
  const formattedNow = format(new Date(), 'yyyy-MM-dd')
  const {data} = await axios.get(
    `/camera/recordingavailability/${idDevice}?StartDate=${oneYearAgo}&EndDate=${formattedNow}`,
  )
  return data
}

export default function useAvailableRecordingDates(
  idDevice: number | undefined,
) {
  const user = useStore(state => state.user)
  return useQuery(
    ['availableRecordingDates', idDevice],
    () => getAvailableDatesForDevice(idDevice),
    {
      enabled: Boolean(user?.token && idDevice),
    },
  )
}
