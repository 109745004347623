import styled from 'styled-components/macro'

type Props = {
  active: boolean
  fontOnSecondary: string
  fontOnPrimary: string
}

export const ButtonAction = styled.button<Props>`
  background-color: ${({active}) => (active ? 'var(--primary)' : 'white')};
  color: ${({active, fontOnPrimary}) =>
    active ? fontOnPrimary : 'var(--primary)'};
  height: 40px;
  padding: 16px 12px 16px 0px;
  width: fit-content;
  white-space: nowrap;
  display: flex;
  align-items: center;
  &:hover {
    background-color: var(--secondary);
    color: ${p => p.fontOnSecondary};
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

export const ButtonInput = styled.input<{
  fontOnPrimary: string
  fontOnSecondary: string
}>`
  appearance: none;
  margin: 0;
  font: inherit;
  color: currentcolor;
  width: 32px;
  height: 32px;
  border: 1px solid var(--asc-graphite);
  border-radius: 50%;
  display: grid;
  place-content: center;
  transform: scale(0.5);
  cursor: pointer;
  :checked {
    border: 1px solid var(--secondary);
    background-color: var(--primary);
  }
  ::before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 32px 32px var(--secondary);
  }
  :checked::before {
    transform: scale(1);
  }
  &:hover {
    background-color: var(--primary);
    border: 1px solid ${p => p.fontOnPrimary};
    color: ${p => p.fontOnSecondary};
  }
  &:disabled {
    border: 1px solid white;
  }
`
