import * as React from 'react'
import styled from 'styled-components/macro'
import {getExportFileBlob} from 'common/tables/tableHelpers'
import Button from 'common/components/Button'
import {DownloadSimple} from 'phosphor-react'
import {Asset} from 'common/types/opening1Response'
import {HistoryTrailPoint} from './hooks/historyTrailTypes'
import {ColumnDef} from '@tanstack/react-table'
import {convertToUsersDateAndTimeFormat} from 'common/helpersDateTime'

const Container = styled.div`
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
  flex-direction: row;
  height: 100%;
  padding: 5px;
  width: 25%;
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    border-radius: 2px;
    background-color: transparent;
    padding: 10px;
    color: var(--primary);
    font-weight: bold;
    font-size: 13px;
    :hover {
      background-color: transparent;
      color: var(--secondary);
    }
    svg {
      min-width: 16px;
    }
  }
`

const ExportIconLabel = styled.span`
  margin-left: 5px;
`

const assetColumns: ColumnDef<HistoryTrailPoint>[] = [
  {
    header: 'Name',
    accessorKey: 'Asset_Label',
  },
  {
    header: 'Event Type',
    accessorKey: 'Event_Type',
  },
  {
    header: 'Date/Time',
    accessorKey: 'Event_Timestamp_Local',
    cell: instance => {
      return convertToUsersDateAndTimeFormat(
        new Date(instance.row.original.Event_Timestamp_Local),
      )
    },
  },
  {
    header: 'Location/Address',
    accessorKey: 'Nearby_Address',
  },
  {
    header: 'Driver',
    accessorKey: 'CurrentDriver',
  },
  {
    header: 'Direction',
    accessorKey: 'Last_Heading_Cardinal',
  },
  {
    header: 'Speed',
    accessorKey: 'Last_Speed',
  },
  {
    header: 'Lat',
    accessorKey: 'Latitude',
  },
  {
    header: 'Long',
    accessorKey: 'Longitude',
  },
  {
    header: 'idAsset',
    accessorKey: 'idAsset',
  },
]

const getPickedData = (data: HistoryTrailPoint[], assetInfo: Asset) => {
  const newData: (string | number)[][] = []
  data?.forEach(d => {
    const {
      Event_Type,
      Event_Timestamp_Local,
      Nearby_Address,
      CurrentDriver,
      Last_Heading_Cardinal,
      Last_Speed,
      Latitude,
      Longitude,
    } = d
    newData.push([
      assetInfo.Asset_Label,
      Event_Type,
      Event_Timestamp_Local,
      Nearby_Address,
      CurrentDriver,
      Last_Heading_Cardinal,
      Last_Speed,
      Latitude,
      Longitude,
      assetInfo.idAsset,
    ])
  })
  return newData
}

/*to use:
columns send like usual;[{header, accessor, id}]...[{header:var1, accessor:var1, id:var1},{header:var2, accessor:var2, id:var2}]
data will be a corresponding array of only the value, no object/property naming
[[object1Var1Value,object1Var2Value],[object2Var1Value,object2Var2Value]]
*/

type ExportButtonProps = {
  data: HistoryTrailPoint[]
  assetInfo?: Asset
  fileName?: string
}

const ExportButton = ({
  data,
  assetInfo,
  fileName = 'History Trail',
}: ExportButtonProps) => {
  const exportData = () => {
    const convertedData: (string | number)[][] =
      (assetInfo && getPickedData(data, assetInfo)) || []
    getExportFileBlob({
      columns: assetColumns as ColumnDef<unknown>[],
      data: convertedData,
      fileType: 'xlsx',
      fileName: fileName,
    })
  }

  return (
    <Container>
      <Button onClick={exportData}>
        <DownloadSimple size={16} />
        <ExportIconLabel>EXPORT</ExportIconLabel>
      </Button>
    </Container>
  )
}
export default ExportButton
