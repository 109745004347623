import {Checkbox} from 'common/components'
import * as React from 'react'
import styled from 'styled-components/macro'

const CheckboxCellContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  min-height: 18px;
  &&& {
    input {
      height: 16px;
    }
  }
`

interface Props<TIdType = string> {
  id: TIdType
  idKey: string
  //can pass any data to the component
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[]
  selectedItemIds?: TIdType[]
  toggleSelect: (id: TIdType, selectAll?: boolean) => void
  selectAll?: boolean
  disabled?: boolean
  color?: string
}
function CheckboxCell<TIdType>({
  id,
  data,
  selectedItemIds,
  toggleSelect,
  idKey,
  disabled = false,
  color = undefined,
  selectAll = false,
}: Props<TIdType>) {
  const isSelected = (selectedItemIds: TIdType[], id?: TIdType) => {
    if (selectAll) {
      return true
    } else if (!selectAll && id && selectedItemIds?.indexOf(id) > -1) {
      return true
    } else {
      return false
    }
  }

  const item =
    data &&
    data.find(dataItem => {
      return dataItem[idKey] === id
    })

  if (!selectAll && !item) return <></>

  const itemId = item ? item[idKey] : ''
  return (
    <CheckboxCellContainer>
      <Checkbox
        selected={
          selectAll
            ? selectedItemIds?.length === data.length
            : isSelected(selectedItemIds || [], itemId) || false
        }
        dataCy={selectAll ? 'select-all' : itemId}
        onChange={() => toggleSelect(itemId, selectAll)}
        disabled={disabled}
        color={color}
      />
    </CheckboxCellContainer>
  )
}
export default CheckboxCell
