import * as React from 'react'
import styled from 'styled-components/macro'
import {AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE} from 'common/constants'
import {CellContext} from '@tanstack/react-table'
import {Asset} from 'common/types/opening1Response'

const AssetImage = styled.img`
  max-height: 24px;
  max-width: 50px;
`

const AssetImageDiv = styled.div`
  width: 50px;
  height: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AssetImageCell = (instance: CellContext<Asset, unknown>) => {
  let value = instance.getValue()
  if (value) {
    return (
      <AssetImageDiv>
        <AssetImage src={`${AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE}/${value}`} />
      </AssetImageDiv>
    )
  }
  return null
}

export default AssetImageCell
