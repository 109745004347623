import Axios from 'axios'

export const returnLevels = async idCustomer => {
  const options = []
  const {data} = await Axios.get(`/userroles/levels/${idCustomer}`)
  data.forEach((l, i) => {
    const newOption = {
      key: i,
      value: l.idCustomerType,
      text: l.CustomerType_Label,
    }
    options.push(newOption)
  })
  return options
}

export const returnAccounts = async (idCustomer, selectedIdCustomerType) => {
  const options = []
  const url = `/customers/parent/${idCustomer}/childtype/${selectedIdCustomerType}/list`
  const {data} = await Axios.get(url)
  data.forEach((l, i) => {
    const newOption = {
      key: i,
      value: l.idCustomer,
      text: l.Customer_Name,
    }
    options.push(newOption)
  })
  return options
}
