import styled, {keyframes} from 'styled-components/macro'
import {QUERIES} from 'common/constants'
import InfoWindow from './infoWindow/InfoWindow'

const radiateAnimation = keyframes`
 0% {  border-color: rgba(119,135,55, 1);  }
 50% {border-color: rgba(119,135,55,.1) }
 100%{border-color: rgba(119,135,55, 1);}
`

export const MapContainer = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  width: 100%;
  border-bottom: ${(props: {showBorder?: boolean}) =>
    props.showBorder ? '1px solid var(--asc-coolgray)' : 'none'};
  .location-marker-label {
    background-color: var(--asc-gold);
    font-weight: bold;
    text-align: center;
    border: 1px solid var(--asc-cultured);
    border-radius: 10px;
    white-space: nowrap;
    padding: 5px;
    font-size: 14px;
    color: black;
  }
  .zone-marker-label {
    font-family: 'Lucida Grande', 'Arial', sans-serif;
    background-color: #00620075;
    color: #fff;
    font-weight: bold;
    text-align: center;
    border: 1px solid var(--asc-cultured);
    border-radius: 2px;
    white-space: nowrap;
    padding: 3px;
    font-size: 0.9rem;
  }
  .activeMarker {
    transform: scale(1.1, 1.1);
    z-index: 9;
  }
  .map-weather-control {
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin: 0 10px 10px 10px;
  }
  .following {
    cursor: pointer;
    width: 54px;
    height: 54px;
    transform: scale(1.1, 1.1) translate3d(-8px, -8px, 0);
    border-radius: 50%;
    background-color: 'transparent';
    box-shadow: 'none';
    border: 9px solid rgba(119, 135, 55, 1);
    animation-name: ${radiateAnimation};
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  div[role='menubar'] {
    bottom: 14px !important;
    right: 60px !important;
  }
`

export const MobileInfoWindow =
  InfoWindow &&
  styled(InfoWindow)`
    @media ${QUERIES.tabletAndUp} {
      display: none;
    }
  `

export const DesktopInfoWindow =
  InfoWindow &&
  styled(InfoWindow)`
    display: none;
    @media ${QUERIES.tabletAndUp} {
      display: block;
    }
  `

export const ClusterContainer = styled.div`
  cursor: pointer;
  position: relative;
`

export const ClusterMarkerImg = styled.svg<{$outerSize: number}>`
  width: ${p => p.$outerSize}px;
  height: ${p => p.$outerSize}px;
  position: fixed;
  transform: translate(-50%, -50%);
  filter: drop-shadow(1px 4px 12px rgba(0, 0, 0, 0.5));
`

export const ClusterCount = styled.div`
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  color: #333333;
  font-size: ${(p: {count: number}) => (p.count > 999 ? '12px' : '13px')};
  font-family: 'Open Sans', Arial, sans-serif;
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  line-height: 13px;
`

export const ClusterLabel = styled.div`
  background: #ffffff;
  border: 1px solid #949494;
  box-sizing: border-box;
  box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  justify-content: center;
  white-space: nowrap;
  position: absolute;
  left: 22px;
  top: -12px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  img:not(:first-child) {
    padding-left: 4px;
  }
`
