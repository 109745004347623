import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

const getCarriers = async () => {
  const {data} = await Axios.get(`/carriers`)
  return data
}

const useCarriers = () => {
  const user = useStore(state => state.user)
  return useQuery(['carriers'], () => getCarriers(), {
    enabled: Boolean(user.token),
  });
}

export default useCarriers
