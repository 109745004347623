import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'
import {useMapStore} from 'map/useMapStore'
import {calcGutAlertValues} from 'gut/gutHelpers'

const getAlertSummary = async () => {
  const {data} = await Axios.get('/alertevents?summary=true')
  if (data) {
    const newCounts = calcGutAlertValues(data)
    useMapStore.setState({
      gutAlertData: newCounts,
    })
  }
  return data
}

export default function useAlertEventSummary() {
  const user = useStore(state => state.user)
  return useQuery(['alertevents?summary=true'], getAlertSummary, {
    enabled: Boolean(user?.token),
    staleTime: 1000 * 60 * 10,
    cacheTime: 1000 * 60 * 10,
  });
}
