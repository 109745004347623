import {EditDeviceType} from './hooks/usePutDevice'
import {SemanticInputType} from 'common/components/InputDropdownOptional'

type ErrorType = {
  [key: string]: {type?: string; message: string}
}
type DeviceFormState = {
  device: Partial<EditDeviceType>
  errors: ErrorType
}
type ActionTypes =
  | {type: 'setState'; data: Partial<DeviceFormState>}
  | {type: 'updateDevice'; data: Partial<EditDeviceType>}

const initialDevice: Partial<EditDeviceType> = {
  idDevice_Type: undefined,
  idCustomer: undefined,
  idCarrier: undefined,
  Serial_Number_Displayed: '',
  IMEI: '',
}

export const initialState = {
  device: initialDevice,
  errors: {},
}

const requiredFields: ErrorType = {
  idDevice_Type: {message: 'Device type is required'},
  idCarrier: {message: 'Carrier is required'},
  Serial_Number_Displayed: {message: 'Serial Number is required'},
}

export const errorCheck = (
  state: DeviceFormState,
  data?: Partial<EditDeviceType>,
) => {
  const errors: ErrorType = state.errors
  const dataToCheck = data ? data : state.device

  Object.keys(dataToCheck).forEach((key: string) => {
    if (key in dataToCheck) {
      const editDeviceTypeKey = key as keyof EditDeviceType
      if (requiredFields[editDeviceTypeKey]) {
        if (
          !dataToCheck[editDeviceTypeKey] &&
          dataToCheck[editDeviceTypeKey] !== 0
        ) {
          errors[key] = requiredFields[key]
        } else {
          delete errors[key]
        }
      }
    }
  })
  return errors
}

export const reducer = (state: DeviceFormState, action: ActionTypes) => {
  switch (action.type) {
    case 'setState':
      return {...state, ...action.data}
    case 'updateDevice':
      const errors = errorCheck(state, action.data)
      return {
        ...state,
        device: {...state.device, ...action.data},
        errors: {...state.errors, ...errors},
      }
    default:
      return state
  }
}

export const onChangeInput = ({
  e,
  dispatch,
}: {
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  dispatch: React.Dispatch<ActionTypes>
}) => {
  let value = e?.currentTarget?.value
  const name = e?.currentTarget?.name

  switch (name) {
    default:
      if (name) {
        dispatch({
          type: 'updateDevice',
          data: {
            [name]: value,
          },
        })
      }
      break
  }
}

export const onDropdownChange = ({
  name,
  value,
  dispatch,
}: {
  name: string
  value: SemanticInputType
  dispatch: React.Dispatch<ActionTypes>
}) => {
  if (name) {
    dispatch({
      type: 'updateDevice',
      data: {
        [name]: value,
      },
    })
  }
}
