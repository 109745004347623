import {useQueryClient, useMutation} from '@tanstack/react-query'
import axios from 'axios'
import {AddDeviceType} from './usePutDevice'

const postDevice = async (device: AddDeviceType) => {
  const {data} = await axios.post(`/devices`, device)
  return data
}

export default function usePostDevice() {
  const cache = useQueryClient()
  return useMutation(postDevice, {
    onError: (err, variables, previousValue) => {
      console.error(err, variables, previousValue)
    },
    // After success or failure, refetch the devices query
    onSettled: () => {
      cache.invalidateQueries([`devices`])
    },
  })
}
