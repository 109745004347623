import {UserCircle, XCircle} from 'phosphor-react'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components/macro'
import {useConversationsSocketStore} from 'common/webSockets/useConversationsSocketStore'
import {useQueryClient} from '@tanstack/react-query'

const Banner = styled.div`
  position: fixed;
  top: 20px;
  right: 10px;
  padding: 30px;
  align-items: center;
  z-index: 1;
  flex-direction: row;
  height: 100px; /* set the height to 100 pixels */
  width: 400px; /* set the width to 200 pixels */
  padding: 0px;
  cursor: pointer;
`

const BannerContent = styled.div`
  background-color: var(--secondary);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Photo = styled.div`
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  margin-right: 10px;
`

const TextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: 10px;
`

const NameRow = styled.div`
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
`

const NameText = styled.div`
  color: var(--asc-black);
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 0px;
`

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`

const MessageRow = styled.div`
  margin-top: 0px;
  display: flex;
`

const MessageText = styled.div`
  color: var(--asc-black);
  font-weight: 400;
  font-size: 16px;
  margin-top: 0px;
  display: flex;
`

const ConversationToast = () => {
  const [showToast, setShowToast] = React.useState(false)
  const setMostRecentMessageInfo = useConversationsSocketStore(
    state => state.setNewWebsocketMessage,
  )
  const mostRecentMessageInfo = useConversationsSocketStore(
    state => state.newWebsocketMessage,
  )
  const messagesWhileAway = useConversationsSocketStore(
    state => state.messagesWhileAway,
  )
  const cache = useQueryClient()

  const navigate = useNavigate()

  React.useEffect(() => {
    const path = location.pathname
    const searchPath = `/messages/${mostRecentMessageInfo.idConversation}`
    if (mostRecentMessageInfo.name !== '' && !path.endsWith(searchPath)) {
      setShowToast(true)
    } else {
      setShowToast(false)
    }
  }, [mostRecentMessageInfo, showToast])

  const clear = () => {
    setMostRecentMessageInfo('', '', 0)
    useConversationsSocketStore.setState({messagesWhileAway: false})
  }

  return showToast || messagesWhileAway ? (
    <Banner
      onClick={() => {
        clear()
        cache.invalidateQueries([
          'conversationList',
          ['conversations', mostRecentMessageInfo.idConversation],
        ])
        navigate(
          `/routing/messages/${mostRecentMessageInfo.idConversation || ''}`,
        )
      }}
    >
      <BannerContent>
        <Photo>
          <UserCircle size={50} />
        </Photo>
        <TextContainer>
          <NameRow>
            <NameText>
              {messagesWhileAway ? 'New Messages' : mostRecentMessageInfo.name}
            </NameText>
            <CloseButton
              onClick={event => {
                event.stopPropagation()
                clear()
              }}
            >
              <XCircle size={30} weight="light" />
            </CloseButton>
          </NameRow>
          <MessageRow>
            {messagesWhileAway ? (
              <MessageText>There are new messages available</MessageText>
            ) : (
              <MessageText>{`${mostRecentMessageInfo.message
                .replace(/\uFFFC/, '')
                .substring(0, 70)}...`}</MessageText>
            )}
          </MessageRow>
        </TextContainer>
      </BannerContent>
    </Banner>
  ) : (
    <></>
  )
}

export default ConversationToast
