import * as React from 'react'
import styled from 'styled-components/macro'
import {ALERT_EVENT_COLORS} from 'common/GlobalStyles'
import {useMapStore} from 'map/useMapStore'

const Container = styled.li`
  display: flex;
  border-bottom: 1px solid var(--asc-coolgray);
  cursor: pointer;
`
const LineItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-weight: ${({selected}) => (selected ? 700 : 400)};
  font-size: 14px;
  line-height: 22px;
  padding: 15px 20px;
  background-color: ${({selected}) =>
    selected ? 'var(--asc-lavender)' : 'white'};
  span {
    display: flex;
    font-size: 14px;
    line-height: 22px;
    color: ${({selected}) => (selected ? 'black' : ' var(--asc-sonicsilver)')};
  }
  div {
    max-width: 255px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  svg {
    fill: ${({color}) => color};
  }
  :hover {
    background-color: var(--asc-lavender);
  }
`

const AlertItem = ({
  label,
  item,
  setAssetFilter,
  assetFilter,
  alertSeverity,
}) => {
  const onClick = () => {
    useMapStore.setState({showAssetMarkers: true})
    if (label === assetFilter?.label) {
      setAssetFilter({label: '', filteredAssetIDs: null})
    } else {
      setAssetFilter({label, filteredAssetIDs: item.assets})
    }
  }
  return (
    <Container onClick={onClick} data-testid={item.dataTestId}>
      <LineItem
        selected={assetFilter?.label === item.type}
        color={ALERT_EVENT_COLORS[alertSeverity]}
        severity={alertSeverity}
      >
        <div>{label}</div>
        <span>{item.count}</span>
      </LineItem>
    </Container>
  )
}
export default AlertItem
