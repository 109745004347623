import * as React from 'react'
import styled from 'styled-components/macro'
import {dashboardClick} from 'common/helpers'
import {Warning, Wrench} from 'phosphor-react'

const Container = styled.div`
  display: flex;
  cursor: pointer;
  padding: 4px;
  border-radius: 5px;
  border: 1px solid
    ${p =>
      p.icon === 'alert' ? 'rgba(210, 67, 65,0.5)' : 'rgba(118, 118, 118,0.5)'};
  :hover {
    background-color: ${p =>
      p.icon === 'alert' ? 'var(--asc-vermilion)' : 'var(--asc-sonicsilver)'};
    span {
      color: white;
    }

    svg {
      fill: white;
      color: white;
    }
  }
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  span {
    display: inline-block;
    min-width: 10px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    color: ${p =>
      p.icon === 'alert' ? 'var(--asc-vermilion)' : 'var(--asc-sonicsilver)'};
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
  }
`

const IconBadge = ({count, icon, idAsset, dataTestId}) => {
  const onClick = () => dashboardClick(idAsset, icon)
  return (
    <Container onClick={onClick} icon={icon} data-testid={dataTestId}>
      <IconContainer>
        {icon === 'alert' ? (
          <Warning size={16} weight={'fill'} color={'var(--asc-vermilion)'} />
        ) : (
          <Wrench size={16} weight={'fill'} color={'var(--asc-sonicsilver)'} />
        )}
      </IconContainer>
      <Count icon={icon}>
        <span>{count}</span>
      </Count>
    </Container>
  )
}
export default IconBadge
