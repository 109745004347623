import Axios from 'axios'
import {useMutation} from '@tanstack/react-query'
import {showToast} from 'common/components/Toastr'

interface PostSendNotificationParams {
  idPlan: number
  body: {
    sendEmail: boolean
    emailAddresses: string
    emailSubject: string
    emailBody: string
    sendSms: boolean
    phoneNumbers: string
    smsBody: string
    idOrder: string
  }
}

const postSendNotification = async ({
  idPlan,
  body,
}: PostSendNotificationParams) => {
  const {data} = await Axios.post(
    `rd/eta/customer/notification/${idPlan}/Send`,
    body,
  )
  return data
}

const usePostSendNotification = () => {
  return useMutation(postSendNotification, {
    onError: (err, variables, previousValue) => {
      console.error(err, variables, previousValue)
      showToast('Something went wrong while sending the notification!', 'error')
    },
    onSuccess(data, variables) {
      let message = ''
      if (variables.body.sendEmail && variables.body.sendSms) {
        message = 'Successfully sent the email and text notifications!'
      } else if (variables.body.sendEmail) {
        message = 'Successfully sent the email notification!'
      } else if (variables.body.sendSms) {
        message = 'Successfully sent the text notification!'
      }

      showToast(message, 'success')
    },
  })
}

export default usePostSendNotification
