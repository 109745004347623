import * as React from 'react'
import styled from 'styled-components/macro'

const Arrow = styled.span`
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  position: relative;
  margin: 0 10px;
  cursor: pointer;
  span {
    top: 0.5rem;
    position: absolute;
    width: 1rem;
    height: 0.3rem;
    background-color: #b2b2b2;
    display: inline-block;
    transition: all 0.2s ease;
  }
  span:first-of-type {
    left: 0;
    transform: rotate(${p => (p.collapsed === 'yes' ? '45deg' : '-45deg')});
  }
  span:last-of-type {
    right: -1px;
    transform: rotate(${p => (p.collapsed === 'yes' ? '-45deg' : '45deg')});
  }
`

const DropdownArrow = ({collapsed, ...rest}) => {
  return (
    <Arrow collapsed={collapsed} {...rest}>
      <span></span>
      <span></span>
    </Arrow>
  )
}
export default DropdownArrow
