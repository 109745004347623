import * as React from 'react'
import styled from 'styled-components/macro'
import {VideoCamera} from 'phosphor-react'
import {CameraStatusType} from './hooks/useCameraList'
interface StatusBadgeProps {
  status: CameraStatusType | null
}

const StatusBadge: React.FC<StatusBadgeProps> = ({status}) => {
  const statusOptions = [
    {
      value: 'online',
      text: 'Online',
      color: 'var(--asc-moss)',
    },
    {
      value: 'standby',
      text: 'Standby',
      color: 'var(--asc-gold)',
    },
    {
      value: 'offline',
      text: 'Off',
      color: 'var(--asc-jet)',
    },
    {
      value: 'Not Found',
      text: 'Not Found',
      color: 'var(--asc-vermilion)',
    },
  ]
  const statusOption = statusOptions.find(s => s.value === status)
  if (!statusOption) return null
  return (
    <BadgeContainer data-cy="status-badge" color={statusOption?.color || ''}>
      <VideoCamera
        weight={status === 'offline' ? 'light' : 'fill'}
        size={15}
        color={statusOption.color}
      />
      {statusOption?.text}
    </BadgeContainer>
  )
}
export default StatusBadge

const BadgeContainer = styled.div`
  display: flex;
  flex-direction: 'row';
  align-items: center;
  width: fit-content;
  padding: 3px 10px;
  justify-content: left;
  border-radius: 5px;
  border: 1px solid
    ${p =>
      p.color === 'var(--asc-jet)' || p.color === 'var(--asc-vermilion)'
        ? 'var(--asc-coolgray)'
        : 'var(--primary)'};
  font-weight: bold;
  white-space: nowrap;
  gap: 5px;
  font-size: 13px;
  color: var(--asc-subduedmoss);
  text-transform: uppercase;
`
