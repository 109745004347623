/* eslint-disable max-lines */
import * as React from 'react'
import styled, {keyframes} from 'styled-components/macro'
import {
  CaretCircleDown,
  CaretCircleUp,
  ChatCircleText,
  ChatTeardrop,
  UserCircle,
} from 'phosphor-react'
import {QUERIES} from 'common/constants'
import {ALERT_EVENT_COLORS} from 'common/GlobalStyles'
import StatusBadge from 'routingAndDispatch/common/StatusBadge'
import {Column, EllipsisText, InfoPillContainer, Row} from 'common/components'

type StatusProps = {
  isPending?: boolean
  isInProgress?: boolean
  isCompleted?: boolean
  planStatus?: string
  isSelected?: boolean
  isInTransit?: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 46px) !important;
`
export const StopCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  position: relative;
`
export const DateHeader = styled.div`
  color: var(--asc-brown);
  background-color: var(--asc-stone);
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 0;
  width: 100%;
  height: 40px;
  padding-left: 60px;
  z-index: 1;
  font-weight: 700;
  gap: 5px;
  line-height: 16px;
`
export const StopCardDateHeader = styled.div`
  color: var(--asc-brown);
  background-color: var(--asc-stone);
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 62px;
  width: 100%;
  height: 40px;
  padding-left: 60px;
  font-weight: 700;
  gap: 5px;
  line-height: 16px;
`
export const ScrollContainer = styled.div<{showHistoryButtons?: boolean}>`
  height: 100%;
  overflow-y: auto;
  width: 100%;
  background-color: var(--asc-cultured);
`
export const RowWithGap = styled(Row)`
  gap: ${({gap}: {gap?: string}) => (gap ? gap : 'default')};
`
export const TimeRow = styled(RowWithGap)`
  width: 100%;
  justify-content: space-between;
  margin-bottom: ${({marginBottom}: {marginBottom?: string}) =>
    marginBottom ? marginBottom : 'default'};
`

export const StyledInfoPillContainer = styled(InfoPillContainer)`
  justify-content: flex-end;
  align-items: flex-end;
`
export const ReadMoreOrLess = styled.div`
  font-size: 14px;
  color: var(--primary);
  font-weight: bold;
  cursor: pointer;
`
export const MapContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  div[role='menubar'] {
    bottom: 14px !important;
    right: 60px !important;
  }
`
export const DistanceText = styled.span`
  color: var(--asc-jet);
  line-height: 100%;
  display: flex;
  align-items: center;
`

export const EstimateTextContainer = styled.div`
  display: grid;
  grid-template-columns: 41px 1fr;
  padding-left: 23px;
`
export const RouteColumn = styled(Column)`
  gap: 3px;
  svg {
    min-height: 20px;
    min-width: 20px;
  }
`
export const LinkColumn = styled(RouteColumn)`
  cursor: pointer;
`

export const ColumnWithGap = styled(RouteColumn)`
  width: 145px;
  gap: 5px;
`
export const SummaryColumn = styled(Column)`
  overflow: auto;
  margin-bottom: 10px;
  svg {
    cursor: pointer;
  }
  div:nth-child(odd) {
    background-color: var(--asc-cultured);
  }
`

export const ImageColumn = styled(RouteColumn)`
  align-items: flex-end;
  gap: 10px;
  svg {
    cursor: pointer !important;
  }
`
export const SpaceBetween = styled(RowWithGap)`
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`

export const StyledTitleWrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
  @media ${QUERIES.tabletAndUp} {
    grid-template-columns: 1fr 6fr 1fr;
  }
`
export const ColumnHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 20px;
  background-color: var(--asc-cultured);
`
export const StyledChatCircleText = styled(ChatCircleText)`
  cursor: pointer;
`
export const Title = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 100%;
  div {
    margin-right: 20px;
  }
`
export const Heading = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
`

export const AddStopButton = styled.button`
  background-color: var(--asc-lime);
  color: var(--primary);
  border: none;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  :hover {
    background-color: var(--primary);
    color: var(--asc-lime);
  }
`

export const StyledStatusBadge = styled(StatusBadge)`
  width: fit-content;
  margin: 0;
`
export const Counter = styled.div`
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--asc-lime);
  color: black;
  font-weight: 700;
  font-size: 13px;
  padding: 0px 10px;
`
type EndPointProps = {
  isEnd?: boolean
}
export const EndPoint = styled.div<EndPointProps>`
  display: flex;
  flex-direction: row;
  background-color: ${props =>
    props.isEnd ? 'var(--asc-dimgray)' : 'var(--asc-platinum)'};
  border: solid 1px
    ${props => (props.isEnd ? 'var(--asc-dimgray)' : 'var(--asc-coolgray)')};
  color: ${props => (props.isEnd ? '#ffffff' : '#000000')};
  margin: 5px;
  padding-bottom: ${props => (props.isEnd ? '10px' : '0')};
  min-height: 74px;
`
export const HomeColumn = styled(RouteColumn)`
  padding: 17px;
`

export const StopColumn = styled(RouteColumn)`
  width: 54px;
  align-items: center;
  position: relative;
`
export const CardColumn = styled(RouteColumn)`
  padding: 15px 0 10px 0;
  width: 225px;
  gap: 5px;
  flex-grow: 1;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
export const EndPointColumn = styled(RouteColumn)`
  padding-top: 15px;
  width: 100%;
`
export const StopCardMenuColumn = styled(RouteColumn)`
  margin-top: 15px;
  .button-dropdown {
    margin-right: 15px;
  }
`
export const DropdownColumn = styled(RouteColumn)`
  align-items: flex-end;
  flex: 1;
  padding-right: 15px;
  justify-content: center;
  color: black;
`
export const Spacer = styled.div`
  width: 25px;
  padding-right: 15px;
`
export const Address = styled.span<StopNumberProps>`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.isMissed && 'var(--asc-vermilion)'};
`
export const TitleLink = styled.span<StopNumberProps>`
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  cursor: pointer;
  color: ${props =>
    props.isMissed ? 'var(--asc-vermilion)' : 'var(--primary)'};
  :hover {
    color: var(--asc-moss);
    text-decoration: underline;
  }
`
export const StyledCaretCircleUp = styled(CaretCircleUp)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`
export const StyledCaretCircleDown = styled(CaretCircleDown)`
  cursor: pointer;
  top: 10px;
  right: 10px;
  position: absolute;
`
export const OrderDescription = styled.span<{height: string}>`
  color: var(--asc-sonicsilver);
  font-size: 14px;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  white-space: break-spaces !important;
  height: ${props => props.height};
`
export const DashedLineGreen = styled.div`
  border: dashed 1px var(--asc-moss);
  width: 1px;
  height: 100%;
`

export const DashedLineGray = styled.div`
  border: dashed 1px var(--asc-coolgray);
  width: 1px;
  height: 100%;
`
export const DriverName = styled.span`
  font-weight: 600;
  font-size: 18px;
  color: var(--primary);
`
export const AssetName = styled.span`
  font-weight: 700;
  font-size: 13px;
  color: var(--primary);
`

export const ContentWrapper = styled.div`
  display: flex;
  background-color: #ffffff;
  border-top: solid 1px var(--asc-coolgray);
  height: 100%;
  overflow-y: auto;
`

export const RouteSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0 0 0;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
`
export const StopSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`
export const RouteTitle = styled.span`
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  padding-left: 20px;
`
export const BoldableText = styled.span<{isBold: boolean}>`
  display: flex;
  flex-direction: row;
  font-weight: ${props => (props.isBold ? '700' : '400')};
`
export const TimeText = styled.span`
  font-size: 14px;
  color: ${({color}: {color?: string; gap?: string; isOnSameDay?: boolean}) =>
    color ? color : 'var(--asc-brown)'};
  display: flex;
  flex-direction: row;
  align-items: ${({
    isOnSameDay,
  }: {
    color?: string
    gap?: string
    isOnSameDay?: boolean
  }) => (isOnSameDay === false ? 'flex-start' : 'center')};
  line-height: 100%;
  gap: ${({gap}: {color?: string; gap?: string; isOnSameDay?: boolean}) =>
    gap ? gap : '0px'};
`
export const TimeColumn = styled(Column)`
  width: 50%;
  gap: 5px;
`
export const DifferentDayColumn = styled(Column)`
  gap: 5px;
  white-space: nowrap;
`
export const ExpandableBox = styled.div<StatusProps>`
  border: solid 1px
    ${props => (props.isInProgress ? 'var(--asc-moss)' : 'var(--asc-coolgray)')};
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px;
  background-color: ${props =>
    props.isInProgress ? 'var(--asc-selectedgreen)' : 'var(--asc-cultured)'};
`
export const PlaceHolderContainer = styled.div`
  margin-right: 20px;
`
export const CardRowDouble = styled.div`
  display: grid;
  grid-template-columns: 20px 110px 20px 1fr;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  width: 310px;

  svg {
    min-height: 16px;
    min-width: 16px;
  }
`
export const CardRowSingle = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  width: 310px;
  svg {
    min-height: 16px;
    min-width: 16px;
  }
`
export const TruckIconHolder = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  width: 50px;
  align-content: center;
  left: -10px;
`
export const Bold = styled.span`
  font-weight: 600;
  font-size: 14px;
`
export const BoldPrimary = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: var(--primary);
`
const SilverText = styled(EllipsisText)`
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--asc-sonicsilver);
  svg {
    min-height: 18px;
    min-width: 18px;
  }
`

export const SmallSilverText = styled(SilverText)`
  font-size: 13px;
`
export const SummaryRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  align-items: center;
  padding: 10px 20px;
`

export const LightRowRedText = styled(SummaryRow)`
  color: ${ALERT_EVENT_COLORS.Critical};
`

export const DimGrayText = styled.span`
  font-weight: 700;
  font-size: 14px;
  color: var(--asc-dimgray);
`
export const RedText = styled.span`
  font-weight: 700;
  font-size: ${({fontSize}: {fontSize?: string}) =>
    fontSize ? fontSize : '14px'};
  color: ${ALERT_EVENT_COLORS.Critical};
`

export const DriverInfo = styled.div`
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  border-top: solid 1px var(--asc-coolgray);
  position: relative;
  background-color: #ffffff;
  bottom: 0;
  width: 100%;
  margin-bottom: 15px;
  flex-grow: 1;
  max-height: 166px;
`

export const StyledChatTeardrop = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({isInProgress, isMissed, isCompleted, isInTransit, status, ...props}) => (
    <ChatTeardrop {...props} />
  ),
)`
  position: absolute;
  transform: rotate(-45deg);
  top: 11px;
  > path:first-of-type {
    fill: ${props =>
      props.isMissed
        ? 'var(--asc-vermilion)'
        : props.isInProgress
        ? 'var(--asc-lime)'
        : props.isCompleted
        ? 'var(--asc-moss)'
        : props.isInTransit
        ? 'var(--asc-gold)'
        : 'var(--asc-platinum)'};
    opacity: 1;
  }
`

export const StyledChatTeardropForMap = styled(ChatTeardrop)`
  position: absolute;
  transform: translateZ(0) translate(-50%, -100%) rotate(-45deg);
  min-width: 35px;
  min-height: 35px;
  > path:first-of-type {
    fill: ${({backgroundcolor}: {backgroundcolor?: string}) => backgroundcolor};
    opacity: 1;
  }
`

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
  gap: 8px;
`
export const ProgressStats = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
`
export const ProgressMeterContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: var(--asc-stone);
  height: 10px;
  border-radius: 200px;
`
interface MeterProps {
  complete: number
  total: number
}
export const ProgressMeter = styled.div<MeterProps>`
  position: absolute;
  height: 100%;
  left: 0;
  width: ${props => (props.complete / props.total) * 100 || 0}%;
  border-radius: 200px;
  background-color: var(--asc-moss);
`
export const RegularText = styled.div`
  font-weight: 400;
  font-size: 14px;
  padding-left: 3px;
`

export const StopText = styled.span`
  position: absolute;
  transform: translateZ(0) translate(-50%, -155%);
  color: ${({color}: {color: string}) => color};
  font-weight: 700;
  font-size: 14px;
`
interface StopNumberProps {
  isMissed?: boolean
  isFinished?: boolean
}
export const StopNumber = styled.span<StopNumberProps>`
  color: ${props => (props.isFinished ? 'white' : 'black')};
  position: absolute;
  top: 18px;
  font-weight: 700;
  font-size: 14px;
`
export const ImageContent = styled.img`
  display: block;
  height: 66px;
  width: 66px;
  border-radius: 50%;
  aspect-ratio: 1;
  object-fit: cover;
  position: absolute;
  top: -33px;
  left: 22px;
`
export const ThumbnailContent = styled.img`
  display: block;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  aspect-ratio: 1;
  object-fit: cover;
  position: absolute;
  top: -50px;
  left: 9px;
`
export const Detail = styled.span`
  display: block;
  font-weight: 600;
  font-size: 14px;
`
export const AssetImageContent = styled.img`
  max-width: 100px;
  max-height: 100px;
`
export const OpenPanelUserCircle = styled(UserCircle)`
  position: absolute;
  top: -33px;
  left: 22px;
  background-color: #ffffff;
  border-radius: 100px;
`
export const ClosedPanelUserCircle = styled(OpenPanelUserCircle)`
  top: -50px;
  left: 9px;
`
export const HistoryTrailButtonsContainer = styled.div`
  width: 100%;
  background-color: var(--asc-cultured);
`
const backgroundFadeNotInProgress = keyframes`
0% {
  background-color: var(--asc-selectedgreen);
  border: solid 1px var(--primary);
}
80% {
  background-color: var(--asc-selectedgreen);
  border: solid 1px var(--primary);
}
100% {
  background-color: #fff;
  border: solid 1px var(--asc-coolgray);
}
`
const backgroundFadeInProgress = keyframes`
0% {
  border: solid 1px var(--primary);
}
80% {
  border: solid 1px var(--primary);
}
100% {
  border: solid 1px var(--asc-coolgray);
}
`
export const StopCard = styled.div<StatusProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${props =>
    props.planStatus?.toLowerCase() !== 'planning' ? 'space-between' : ' '};
  border: ${props =>
    props.isInProgress
      ? 'solid 1px var(--asc-moss)'
      : props.isInTransit
      ? 'solid 1px var(--asc-darkolive)'
      : props.isCompleted
      ? 'solid 1px var(--asc-mediumgray)'
      : 'solid 1px var(--asc-coolgray)'};
  position: relative;
  margin: 5px;
  border-radius: 15px;
  background-color: ${props =>
    props.isInProgress
      ? 'var(--asc-selectedgreen)'
      : props.isInTransit
      ? 'var(--asc-floralwhite)'
      : props.isCompleted
      ? 'var(--grey-200)'
      : '#ffffff'};
  animation: ${props =>
      props.isSelected
        ? props.isInProgress
          ? backgroundFadeInProgress
          : backgroundFadeNotInProgress
        : null}
    3s linear;
`
export const MarkerContainer = styled.div`
  position: relative;
  height: 0;
  width: 0;
  z-index: 1;
  .hidden {
    display: none !important;
  }
`
export const MarkerContainerForArrow = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transform: translate(-50%, -55px);
  bottom: 0;
  left: 0;
  background-color: white;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  font-weight: 700;
  width: 50px;
  height: 44px;
  border-radius: 5px;
  border: solid 4px ${props => (props.color ? props.color : 'var(--asc-lime)')};
  cursor: default;
`
export const BottomArrow = styled.div`
  border-top-width: 24px;
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translate(-1px, -28px);
  .left {
    position: absolute;
    overflow: hidden;
    left: -15px;
    top: 13px;
    width: 16px;
    height: 30px;
    div {
      position: absolute;
      left: 0;
      background-color: rgb(255, 255, 255);
      transform: skewX(45deg);
      transform-origin: 0px 0px 0px;
      height: 15px;
      width: 15px;
      border-left: solid 4px
        ${props => (props.color ? props.color : 'var(--asc-lime)')};
    }
  }
  .right {
    position: absolute;
    overflow: hidden;
    left: 0;
    top: 13px;
    width: 16px;
    height: 30px;
    div {
      position: absolute;
      left: 0px;
      background-color: rgb(255, 255, 255);
      transform: skewX(-45deg);
      transform-origin: 10px 0px 0px;
      height: 15px;
      width: 15px;
      border-right: solid 4px
        ${props => (props.color ? props.color : 'var(--asc-lime)')};
    }
  }
`

export const StopInfoMenu = styled.div`
  height: 20px;
  width: 20px;
  border: 1px solid
    ${({menuOpen}: {menuOpen: boolean}) =>
      menuOpen ? 'var(--secondary)' : 'var(--primary)'};
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  border-radius: 5px;
  cursor: pointer;
  svg {
    margin-right: 0px;
    fill: ${({menuOpen}: {menuOpen: boolean}) =>
      menuOpen ? 'var(--secondary)' : 'var(--primary)'};
  }
  :hover {
    color: var(--secondary);
    border: 1px solid var(--secondary);
    svg {
      fill: var(--secondary);
    }
  }
`

export const ErrorMessage = styled.div`
  width: 100%;
  padding: 20px;
  text-align: center;
`

export const StyledArrowLeft = styled.div`
  cursor: pointer;
  width: fit-content;
`
export const RouteTimeTableRow = styled.div`
  height: 112px;
  overflow: hidden;
  width: 100%;
  .timeTable {
    overflow-y: hidden;
  }
  svg {
    max-width: none;
    width: 2400px;
  }
`
export const RouteMapColumn = styled(Column)`
  flex-grow: 1;
  width: calc(100% - 830px); //both side panel widths
`
export const SvgRow = styled(Row)`
  svg {
    min-width: 18px;
    min-height: 18px;
  }
`
export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  align-items: center;
  width: fit-content;
  gap: 5px;
  font-size: 13px;
`
