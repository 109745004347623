import toastr from 'toastr'
// Set the default toastr options
toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: 'toast-top-right',
  preventDuplicates: false,
  onclick: undefined,
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
}

// Export a function to show toasts
export const showToast = (message: string, type: string) => {
  if (type === 'success') {
    toastr.success(message)
  } else if (type === 'error') {
    toastr.error(message)
  } else if (type === 'report') {
    toastr.info(message, '', {iconClass: 'report-info'})
  } else {
    toastr.info(message)
  }
}
