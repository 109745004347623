import {library} from '@fortawesome/fontawesome-svg-core'

import {
  faFilter,
  faDownload,
  faSignOutAlt,
  faInfoCircle,
  faUsers,
  faBuilding,
  faUser,
  faUpload,
  faAngleDown,
  faMapMarkerAlt,
  faUserFriends,
  faCheckSquare,
  faSquare,
  faPhone,
  faPlus,
  faMinus,
  faCogs,
  faList,
  faCode,
  faSpinner,
  faCircle,
  faCircleNotch,
  faCheck,
  faTimes,
  faKey,
  faBan,
  faArrowUp,
  faUserAltSlash,
  faCube,
  faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons'

export default function registerIcons() {
  library.add(
    faFilter,
    faDownload,
    faSignOutAlt,
    faInfoCircle,
    faUsers,
    faBuilding,
    faUser,
    faUpload,
    faAngleDown,
    faMapMarkerAlt,
    faUserFriends,
    faCheckSquare,
    faSquare,
    faPhone,
    faPlus,
    faMinus,
    faCogs,
    faList,
    faCode,
    faSpinner,
    faCircle,
    faCircleNotch,
    faCheck,
    faTimes,
    faKey,
    faBan,
    faArrowUp,
    faUserAltSlash,
    faCube,
    faExchangeAlt,
  )
}
