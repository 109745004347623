import React from 'react'
import styled from 'styled-components/macro'
import {ButtonInput} from 'common/components/RadioButton'
import {ascendColors} from 'common/GlobalStyles'

type Option = {
  value: string
  renderItem?: () => JSX.Element
  text: string
}

type Props<T> = {
  options: Option[]
  selected: string
  setSelected: (selected: T) => void
  name: string
  direction?: 'horizontal' | 'vertical'
}

const Group = styled.div`
  display: flex;
  flex-direction: ${({direction}: {direction: 'horizontal' | 'vertical'}) =>
    direction === 'vertical' ? 'column' : 'row'};
  gap: ${({direction}: {direction: 'horizontal' | 'vertical'}) =>
    direction === 'vertical' ? '0' : '16'}px;
  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    /* font-size: 16px; */
    cursor: pointer;
  }
`

const RadioGroup = <T,>({
  options,
  selected,
  name,
  setSelected,
  direction = 'vertical',
}: Props<T>) => (
  <Group direction={direction}>
    {options.map((option, index) => {
      return (
        <label key={index}>
          <ButtonInput
            type="radio"
            onChange={() => setSelected(option.value as T)}
            fontOnPrimary={ascendColors.fontOnPrimary}
            fontOnSecondary={ascendColors.fontOnSecondary}
            checked={option.value === selected}
            value={option.value}
            name={name}
          />
          {option.renderItem ? <option.renderItem /> : option.text}
        </label>
      )
    })}
  </Group>
)
export default RadioGroup
