import React from 'react'
import styled from 'styled-components/macro'
import TrafficImg from 'images/traffic.png'
import {QUERIES} from 'common/constants'

const TrafficControlDiv = styled.div`
  display: none;
  align-content: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: white;
  cursor: pointer;
  margin: 0 10px 10px 10px;
  padding: 4px;
  @media ${QUERIES.tabletAndUp} {
    display: flex;
  }
`

export const initTrafficControl = (maps, map) => {
  let trafficShowing = false
  let trafficLayer
  const trafficControlDiv = (
    <TrafficControlDiv
      data-testid="traffic-overlay"
      className="map-control map-traffic-control"
      title="Traffic"
      onClick={() => {
        // if (map.overlayMapTypes.length) {
        if (!trafficShowing) {
          if (!trafficLayer) {
            trafficLayer = new maps.TrafficLayer()
          }
          trafficLayer.setMap(map)
          trafficShowing = true
        } else {
          trafficLayer.setMap(null)
          trafficShowing = false
        }

        // } else {
        //   // map.overlayMapTypes.push(radar)
        //   //clear weather after 1 minute
        //   setTimeout(() => {
        //     map.overlayMapTypes.clear()
        //   }, 60000)
        // }
      }}
    >
      <img src={TrafficImg}></img>
    </TrafficControlDiv>
  )
  //radarControlDiv.push(controlUI)
  // var weatherLayer = new google.maps.TrafficLayer();

  //controlUI.addEventListener('click', function () {})

  return trafficControlDiv
}
