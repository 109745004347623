import {User} from './types/types'

type Action = {
  type:
    | 'setSelected'
    | 'clearSelected'
    | 'setLogo'
    | 'setNewLogo'
    | 'saveLogo'
    | 'cancelLogo'
    | 'error'
    | 'setCreds'
    | 'setUsers'
    | 'setUsersAfterDelete'
    | 'clearUserFilter'
    | 'addCustomer'
    | 'editCustomer'
    | 'addUser'
    | 'editUser'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reducer = (state: any, action: Action) => {
  let filteredUsers = []
  switch (action.type) {
    case 'error':
      return {...state, usersLoading: false, error: true}
    case 'setSelected':
      if (action.data?.Customer_Name) {
        action.data.name = action.data.Customer_Name
        filteredUsers = state.allUsers.filter(
          (u: User) => u.idCustomer === action.data.idcustomer,
        )
      } else if (action.data?.Dealer_Name) {
        action.data.name = action.data.Dealer_Name
        filteredUsers = state.allUsers.filter(
          (u: User) => u.idDealer === action.data.idDealer,
        )
      } else {
        filteredUsers = state.allUsers
      }
      return {...state, selected: action.data, filteredUsers}
    case 'setLogo':
      return {
        ...state,
        newLogoFile: '',
        newLogoFileURL: '',
        idBranding: action.data.idBranding,
        logoFile:
          action.data && action.data.Logo
            ? `../images/${action.data.Logo}`
            : '',
      }
    case 'setNewLogo':
      return {
        ...state,
        newLogoFileURL: action.data.fileURL,
        newLogoFile: action.data.file,
      }
    case 'setUsers': {
      if (state.selected?.Customer_Name) {
        filteredUsers = action.data.filter(
          (u: User) => u.idCustomer === state.selected.idcustomer,
        )
      } else if (state.selected?.Dealer_Name) {
        filteredUsers = action.data.filter(
          (u: User) => u.idDealer === state.selected.idDealer,
        )
      } else {
        filteredUsers = action.data
      }
      return {
        ...state,
        allUsers: action.data,
        filteredUsers,
        usersLoading: false,
      }
    }

    case 'setUsersAfterDelete':
      return {
        ...state,
        allUsers: action.data.all,
        filteredUsers: action.data.filtered,
      }
    case 'saveLogo':
      return {
        ...state,
        logoFile: state.newLogoFileURL,
        newLogoFile: '',
        newLogoFileURL: '',
      }
    case 'cancelLogo':
      return {...state, newLogoFile: '', newLogoFileURL: ''}
    case 'setCreds':
      return {...state, creds: action.data}
    case 'clearUserFilter':
      return {...state, filteredUsers: state.allUsers, selected: null}
    case 'addCustomer':
      return {
        ...state,
        customers: [...state.customers, action.data],
        selected: null,
      }
    case 'editCustomer':
      return {...state, customers: action.data, selected: null}
    case 'addUser':
      return {
        ...state,
        allUsers: [...state.allUsers, action.data],
        filteredUsers: [...state.filteredUsers, action.data],
      }
    case 'editUser':
      return {
        ...state,
        allUsers: action.data.allUsers,
        filteredUsers: action.data.filteredUsers,
        selectedUser: null,
      }
    default:
      return state
  }
}

type State = {
  loading: boolean
  selected: {
    logo: string
    name: string
    id: number
  } | null
  allUsers: User[]
  filteredUsers: User[]
  activeTab: string
  activeSubTab: string
  logoFile: string
  newLogoFile: string
  newLogoFileURL: string
  idBranding: number
  // creds: {
  //   auth: string
  //   userName: string
  //   userCompany: string
  //   dateFormat: string
  // }
}

export const initialState: State = {
  loading: true,
  selected: null,
  allUsers: [],
  filteredUsers: [],
  activeTab: 'users',
  activeSubTab: '',
  logoFile: '',
  newLogoFile: '',
  newLogoFileURL: '',
  idBranding: 0,
  // creds: {
  //   auth: '',
  //   userName: '',
  //   userCompany: '',
  //   dateFormat: 'mm/dd/yyyy',
  // },
}
