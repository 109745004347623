import * as React from 'react'
import styled from 'styled-components/macro'
import UnassignedCard from './UnassignedCard'
import {matchSorter} from 'match-sorter'
import {useMapStore} from 'map/useMapStore'
import {focusMap} from './openingHelpers'
import {Device} from 'common/types/opening1Response'

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-margin: 30px;
  background-color: white;
  padding: 0px 5px;
`

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
`

type UnassignedListProps = {
  unassignedDevices: Device[]
  searchValue: string
  mapRef: {current: {map: google.maps.Map}}
}

const UnassignedList = ({
  unassignedDevices,
  searchValue,
  mapRef,
}: UnassignedListProps) => {
  const [filteredUnassigned, setFilteredUnassigned] = React.useState<Device[]>(
    [],
  )

  const setActiveUnassignedMarker = useMapStore(
    state => state.setActiveUnassignedMarker,
  )
  const changeActiveMarkerFromClick = (e: React.MouseEvent<HTMLElement>) => {
    const id = e.currentTarget.dataset.unassignedid
    const unassignedDevice = unassignedDevices.find(
      device => device.idDevice === Number(id),
    )

    if (unassignedDevice && mapRef?.current?.map) {
      setActiveUnassignedMarker(unassignedDevice)
      focusMap({
        lat: unassignedDevice.Latitude,
        lng: unassignedDevice.Longitude,
        map: mapRef?.current?.map,
      })
    }
  }

  React.useEffect(() => {
    if (searchValue) {
      const newFilteredUnassigned = matchSorter(
        unassignedDevices,
        searchValue,
        {
          keys: [
            'DeviceType',
            'EngineHoursSource',
            'Event_Description',
            'Last_Nearby_Address',
            'OdometerSource',
            'Serial_Number_Displayed',
            'SpeedSource',
            'idDevice',
          ],
          threshold: matchSorter.rankings.CONTAINS,
        },
      )
      setFilteredUnassigned(newFilteredUnassigned)
    } else {
      setFilteredUnassigned(unassignedDevices)
    }
  }, [unassignedDevices, searchValue])

  return (
    <ListContainer>
      <List>
        {filteredUnassigned.map(device => (
          <UnassignedCard
            key={device.idDevice}
            device={device}
            changeActiveMarkerFromClick={changeActiveMarkerFromClick}
          />
        ))}
      </List>
    </ListContainer>
  )
}
export default UnassignedList
