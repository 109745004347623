import {Input} from 'common/components'
import {useStore} from 'common/useStore'
import usePostGroup from 'groups/hooks/usePostGroup'
import usePutAssetsAndUsers, {
  SubmitUpdateAssetsGroupsType,
} from 'groups/hooks/usePutAssetsAndUsers'
import * as React from 'react'
import {Icon, Loader, Button} from 'semantic-ui-react'
import styled from 'styled-components/macro'
import Swal from 'sweetalert2'
import {Plus, Minus} from 'phosphor-react'
import {Asset} from 'common/types/opening1Response'
const Wrapper = styled.div`
  display: flex;
  margin-left: 10px;
`

const IconRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 45px;
`

const TitleButton = styled(Button)`
  && {
    margin: 0 5px;
    .icon {
      font-size: 1rem;
      text-align: center;
      /* padding-left: 5px; */
    }
  }
`
const AddGroupButton = styled(Button)`
  &&& {
    display: ${props => (props.disabled ? 'none' : 'flex')};
    flex-direction: row;
    background-color: transparent;
    align-items: center;
    span {
      margin-left: 5px;
      display: flex;
      justify-content: flex-start;
      width: 200px;
      color: var(--primary);
    }
    .icon {
      font-size: 1rem;
    }
  }
`

type AddNewGroupProps = {
  tableRows: Asset[]
  allData: Asset[]
}

const AddNewGroupButton = ({tableRows, allData}: AddNewGroupProps) => {
  const [groupName, setGroupName] = React.useState('')
  const {mutateAsync: postGroup} = usePostGroup()
  const {mutateAsync: updateAssetsAndUsers} = usePutAssetsAndUsers()
  const [submiting, setSubmitting] = React.useState(false)
  const [showInput, setShowInput] = React.useState(false)
  const userConfig = useStore(state => state.userConfig)

  const addNewGroup = async () => {
    setSubmitting(true)
    try {
      const filteredAssets = tableRows && tableRows?.map(row => row?.idAsset)
      const submitGroup = {
        idUser: userConfig?.idUser,
        idCustomer: userConfig?.idCustomer,
        Group_Name: groupName,
        Group_Description: '',
        StartDate: null,
        EndDate: null,
        IsTimeBased: false,
        GroupLandmarkList: null,
        Group_Name_With_Number_Of_Assets: `${groupName} (${filteredAssets.length})`,
        Number_Of_Assets: filteredAssets?.length,
        Assets: filteredAssets,
        Created_On: null,
        Updated_On: null,
      }
      const submitUpdateAssetsGroups: SubmitUpdateAssetsGroupsType = {
        ListOfUserIds: [],
        ListOfAssetIds: filteredAssets,
        IsTimeBased: false,
        idGroup: 0,
      }
      const returnedGroup = await postGroup(submitGroup)
      submitUpdateAssetsGroups.idGroup = returnedGroup.idGroup
      await updateAssetsAndUsers(submitUpdateAssetsGroups)
      setSubmitting(false)
      setGroupName('')
      setShowInput(false)
      Swal.fire('Group Added', `${groupName} has been created.`, 'success')
    } catch {
      Swal.fire(
        `Creating group`,
        'There was an error creating the group.',
        'error',
      )
    }
  }
  return (
    <Wrapper>
      <AddGroupButton
        onClick={() => setShowInput(!showInput)}
        disabled={allData?.length === tableRows?.length}
        data-cy="add-group-button"
        icon
        data-testid="assetgrid-newgroup"
      >
        {showInput ? (
          <Minus size={20} color={'var(--primary)'} weight={'bold'} />
        ) : (
          <Plus size={20} color={'var(--primary)'} weight={'bold'} />
        )}
        <span>
          New Group from {tableRows?.length} asset{tableRows?.length > 1 && 's'}
        </span>
      </AddGroupButton>
      {showInput && (
        <>
          <Input
            value={groupName}
            onChange={event => setGroupName(event.target.value)}
            name="group-name"
          />
          <TitleButton
            onClick={addNewGroup}
            disabled={!tableRows?.length || !groupName}
          >
            <IconRow>
              {submiting ? (
                <Loader size="tiny" inline="centered" active />
              ) : (
                <Icon name={'check circle'}></Icon>
              )}

              <div>Add</div>
            </IconRow>
          </TitleButton>
        </>
      )}
    </Wrapper>
  )
}

export default React.memo(AddNewGroupButton)
