import Axios from 'axios'
import {useMutation, useQueryClient} from '@tanstack/react-query'

const bulkDeleteLocations = async (locations: number[]) => {
  const {data} = await Axios.put(`/LandmarkList`, locations)
  return data
}

function useBulkDeleteLocations() {
  const cache = useQueryClient()
  return useMutation(bulkDeleteLocations, {
    onSettled: () => {
      cache.invalidateQueries(['locations'])
    },
  });
}

export default useBulkDeleteLocations
