import {create} from 'zustand'
import {Device} from 'common/types/opening1Response'
import {AlertEvents} from 'common/types/alertTypes'

const initialStore = {
  socketListening: false,
  pendingDeviceUpdates: [],
  pendingMapUpdates: [],
  pendingAlertUpdates: [],
}

export type State = {
  socketListening: boolean
  pendingDeviceUpdates: Device[]
  pendingMapUpdates: Device[]
  pendingAlertUpdates: AlertEvents[]
  emptyStore: () => void
}

export const useWebsocketStore = create<State>(set => ({
  ...initialStore,
  emptyStore: () => set(state => ({...state, ...initialStore})),
}))
