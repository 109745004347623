import Axios from 'axios'
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

const vinAuditPromise = async (vin: string) => {
  const {data}: {data: VINAuditReportData} = await Axios.get(
    `/vinaudit/getvinauditdata/${vin}`,
  )
  return data
}

const getVINAudit = async (vin: string | undefined) => {
  if (!vin) return
  const [vinAuditDetails] = await Promise.all([vinAuditPromise(vin)])
  const returnObject = {
    ...vinAuditDetails,
  }
  return returnObject
}

const useVINAudit = (vin: string | undefined) => {
  const user = useStore(state => state.user)
  const queryClient = useQueryClient()
  return useQuery(['vinAudit', vin], () => getVINAudit(vin), {
    enabled: Boolean(vin && user?.token),
    keepPreviousData: true,
  })
}

export default useVINAudit

export interface VINAuditReportData {
  attributes: Attributes
  error: string
  photos: any[]
  recalls: Recall[]
  success: boolean
  warranties: Warranty[]
}

export interface Attributes {
  anti_brake_system: string
  category: string
  city_mileage: string
  curb_weight: string
  doors: string
  drivetrain: string
  engine: string
  engine_cylinders: string
  engine_size: string
  fuel_capacity: string
  fuel_type: string
  gross_vehicle_weight_rating: string
  highway_mileage: string
  invoice_price: string
  made_in: string
  made_in_city: string
  make: string
  manufacturer_suggested_retail_price: string
  model: string
  overall_height: string
  overall_length: string
  overall_width: string
  size: string
  standard_seating: string
  steering_type: string
  style: string
  transmission: string
  transmission_speeds: string
  transmission_type: string
  trim: string
  type: string
  wheelbase_length: string
  year: string
}

export interface Recall {
  campaign: string
  components: string
  consequence: string
  notes: string
  remedy: string
  source: string
  summary: string
}

export interface Warranty {
  miles: string
  months: string
  type: string
}
