import { BellRinging } from 'phosphor-react'
import * as React from 'react'
import styled, {keyframes} from 'styled-components/macro'


const pulse = keyframes`
0% {
    opacity: .2;
}
5% {
    opacity: .2
}
20% {
    opacity: 1;
}
80% {
    opacity: 1;
}
95% {
    opacity:.2;
}
100% {
    opacity: .2;
}
`
const ChipWrapper = styled.div`
    background-color: var(--asc-babyblue);
    display: flex;
    flex-direction: row;
    gap: 7px;
    height: 18px;
    width: 107px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    animation: ${pulse} 4s linear infinite;
`
const Message = styled.span`
    font-family: 'Open Sans', sans-serif;
    font-weight: 600; 
    font-size: 10px;
    letter-spacing: 0.05em;
`
type Props = {
    className?: string;
}

const JustUpdatedChip: React.FC<Props> = ({className}) => {
  return (
    <ChipWrapper className={className}>
        <BellRinging size={13} />
        <Message>Just Updated</Message>
        
    </ChipWrapper>
  )
} 

export default JustUpdatedChip