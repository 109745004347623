import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

const getOpening5 = async () => {
  const {data} = await Axios.get('/opening/5')
  return data
}

export default function useOpening5() {
  const user = useStore(state => state.user)
  return useQuery(['opening5'], getOpening5, {
    enabled: Boolean(user?.token),
  });
}
