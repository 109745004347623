import * as React from 'react'
import styled from 'styled-components/macro'
import {Checkbox} from 'common/components'

const Container = styled.li`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 20px;
  border-bottom: 1px solid var(--asc-coolgray);
  cursor: pointer;
  &:hover {
    background: #f0f6e1;
    input {
      border: 1px solid var(--asc-moss);
      box-sizing: border-box;
      box-shadow: 0px 0px 6px 1px var(--secondary);
      border-radius: 5px;
    }
  }
`

const LeftSection = styled.div`
  display: flex;
  margin-top: 5px;
`

const RightSection = styled.div`
  font-size: 14px;
`

const ZoneName = styled.div`
  font-weight: 700;
`

const ZoneDescription = styled.div`
  span {
    font-weight: ${props => (props.isType ? '700' : 'regular')};
  }
  color: ${props => (props.isType ? 'black' : 'var(--asc-sonicsilver)')};
  margin: 5px 0px;
`
const ZoneAddress = styled.div``

const ZoneCard = ({zone, toggleZone, selectedZoneIDs}) => {
  const [selected, setSelected] = React.useState(false)

  React.useEffect(() => {
    if (zone?.idLandmark && selectedZoneIDs.includes(zone.idLandmark)) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }, [zone.idLandmark, selectedZoneIDs, zone])

  const city = zone?.Landmark_City ? zone.Landmark_City + ', ' : ''
  return (
    <Container
      data-cy={'zone-list-card'}
      onClick={toggleZone}
      data-zoneid={zone.idLandmark}
    >
      <LeftSection>
        <Checkbox selected={selected} dataCy="zone-select" />
      </LeftSection>
      <RightSection>
        <ZoneName>{zone?.Landmark_Name || ''}</ZoneName>
        <ZoneDescription isType={false}>
          {zone?.Landmark_Label || ''}
        </ZoneDescription>
        {zone?.Landmark_Style && (
          <ZoneDescription isType={true}>
            <span>Type: </span>
            {zone.Landmark_Style}
          </ZoneDescription>
        )}
        <ZoneAddress>{zone?.Landmark_Address_Line1}</ZoneAddress>
        <ZoneAddress>{zone?.Landmark_Address_Line2}</ZoneAddress>
        <ZoneAddress>
          {city}
          {`${zone?.Landmark_Region || ''} ${zone?.Landmark_Country || ''}`}
        </ZoneAddress>
      </RightSection>
    </Container>
  )
}
export default ZoneCard
