import React from 'react'
import Spinner from 'common/components/Spinner'
import SearchInput from 'common/components/SearchInput'
import Swal from 'sweetalert2'
import {XCircle} from 'phosphor-react'
import {
  DirectionsMapContainer,
  DirectionsInputsPanel,
  IconButtonContainer,
  IconButton,
  ReverseIcon,
  TurnRightIcon,
  DirectionPanel,
  CollapseIcon,
  //CollapseIcon,
} from './Styles'
import {getDestination} from './directionsHelper'
import {useNavigate} from 'react-router-dom'
// import {Icon} from 'semantic-ui-react'

const GetDirections = ({map, address}) => {
  const [origin, setOrigin] = React.useState(address || '')
  const [destination, setDestination] = React.useState('')
  const [originDisplay, setOriginDisplay] = React.useState('')
  const [destinationDisplay, setDestinationDisplay] = React.useState('')
  const [closeHover, setCloseHover] = React.useState(false)
  //Start Get Directions
  const [directionsDisplay] = React.useState(
    new window.google.maps.DirectionsRenderer({
      draggable: true,
    }),
  )
  const [directionsService] = React.useState(
    new window.google.maps.DirectionsService(),
  )
  //End Get Directions

  const navigate = useNavigate()

  const switchDirections = () => {
    setDestination(origin)
    setOrigin(destination)
    setDestinationDisplay(origin)
    setOriginDisplay(destination)
  }

  const clearDestination = () => {
    directionsDisplay.setMap(null)
    directionsDisplay.setPanel(null)
  }
  React.useEffect(() => {
    if (address && !(origin && destination)) {
      setOrigin(address)
      setOriginDisplay(address)
    }
  }, [address, origin, destination])
  React.useEffect(() => {
    if (origin && destination) {
      directionsDisplay.setMap(null)
      directionsDisplay.setPanel(null)
      const directionPanel = document.getElementById('directions-panel')
      directionsService.route(
        {
          origin,
          destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
          drivingOptions: {
            departureTime: new Date /* now, or future date */(),
            trafficModel: 'optimistic',
          },
          unitSystem: window.google.maps.UnitSystem.IMPERIAL,
        },
        (response, status) => {
          if (status === 'OK') {
            directionsDisplay.setDirections(response)
            directionsDisplay.setPanel(directionPanel)
            directionsDisplay.setMap(map)
          } else {
            Swal.fire(
              'We could not find the result.',
              'Please make sure your search is spelled correctly. Try adding a city, state, or zip code.',
              'error',
            )
          }
        },
      )
    }
  }, [destination, origin, map, directionsDisplay, directionsService])

  return (
    <DirectionsMapContainer data-cy="get-directions">
      {status === 'loading' && <Spinner />}
      <DirectionsInputsPanel>
        <CollapseIcon
          data-cy="info-window-close"
          onClick={() => {
            clearDestination()
            navigate('/map')
          }}
          onMouseOver={() => setCloseHover(true)}
          onMouseLeave={() => setCloseHover(false)}
        >
          <XCircle size={30} weight={closeHover ? 'fill' : null} />
          {/* <Icon name="close" color="grey" /> */}
        </CollapseIcon>
        <SearchInput
          label="Origin"
          onChange={setOrigin}
          displayValue={originDisplay}
          onChangeText={setOriginDisplay}
          dataCy="origin"
        />
        <SearchInput
          label="Destination"
          onChange={setDestination}
          displayValue={destinationDisplay}
          onChangeText={setDestinationDisplay}
          dataCy="destination"
        />
        <IconButtonContainer>
          <IconButton
            disabled={Boolean(origin === '' && destination === '')}
            data-cy="switch-direction"
            onClick={switchDirections}
          >
            <ReverseIcon />
          </IconButton>
          <IconButton
            data-cy="get-destination"
            disabled={Boolean(origin === '' && destination === '')}
            onClick={() => getDestination(origin, destination)}
          >
            <TurnRightIcon />
          </IconButton>
        </IconButtonContainer>
        <DirectionPanel id="directions-panel"></DirectionPanel>
      </DirectionsInputsPanel>
    </DirectionsMapContainer>
  )
}

export default GetDirections
