import styled from 'styled-components/macro'

type RowProps = {
  alignItems?: string
  justifyContent?: string
  gap?: string
  padding?: string
  background?: string
}

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  background: ${props => props.background || ''};
  align-items: ${props => props.alignItems || ''};
  justify-content: ${props => props.justifyContent || ''};
  gap: ${props => (props.gap ? props.gap : '')};
  padding: ${props => (props.padding ? props.padding : '')};
  .icon {
    margin-top: 1px;
  }
`

type ColumnProps = {
  width?: string
  height?: string
  zIndex?: number
  position?: string
}

export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || 'auto'};
  z-index: ${props => props.zIndex || 'auto'};
  position: ${props => props.position || 'auto'};
`

export const CardHeader = styled(Column)`
  gap: 20px;
  padding: 20px;
  background: white;
`

type TypographyProps = {
  fontSize: number
  marginTop?: string
  marginRight?: string
  marginLeft?: string
  marginBottom?: string
  fontWeight?: string
  color?: string
  width?: string
  textAlign?: string
  fontStyle?: string
  whiteSpace?: string
  minWidth?: string
}

export const Typography = styled.div<TypographyProps>`
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '700')};
  font-size: ${props => props.fontSize}px;
  margin-top: ${props => (props.marginTop ? props.marginTop : '')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '')};
  color: ${props => (props.color ? props.color : '')};
  width: ${props => (props.width ? props.width : '')};
  text-align: ${props => (props.textAlign ? props.textAlign : '')};
  font-style: ${props => (props.fontStyle ? props.fontStyle : '')};
  white-space: ${props => (props.whiteSpace ? props.whiteSpace : '')};
  min-width: ${props => (props.minWidth ? props.minWidth : '')};
`

type BadgeProps = {
  background: string
  fontColor: string
  borderColor: string
  hideShadow?: boolean
  lessPadding?: boolean
  padding?: string
  maxWidth?: string
  minWidth?: string
}

export const Badge = styled(Row)<BadgeProps>`
  align-items: center;
  min-width: ${props => (props.minWidth ? props.minWidth : 'fit-content')};
  min-height: 25px;
  max-width: ${props => (props.maxWidth ? props.maxWidth : 'none')};
  padding: 2px 8px !important;
  line-height: 1em;
  justify-content: center;
  border-radius: 500rem;
  background-color: ${props => props.background};
  width: ${props =>
    props.maxWidth && props.maxWidth !== 'none' ? '100%' : 'auto'};
  box-shadow: ${props =>
    props.hideShadow ? '' : '1px 1px 1px var(--asc-coolgray)'};
  color: ${props => props.fontColor};
  font-weight: bold;
  border: 1px solid ${props => props.borderColor};
  white-space: nowrap;
  svg {
    min-width: 16px;
    min-height: 16px;
    margin-right: 3px;
  }
`

// export const MapContainer = styled.div`
//   position: relative;
//   height: 100%;
//   width: 100%;
//   div[role='menubar'] {
//     bottom: 14px !important;
//     right: 60px !important;
//   }
// `

type SectionRowProps = {
  minHeight?: string
  alignItems?: string
  flexGrow?: number
  zIndex?: number
  display?: string
}

export const SectionRow = styled(Row)<SectionRowProps>`
  width: 100%;
  min-height: ${({minHeight}) => minHeight || ''};
  flex-grow: ${({flexGrow}) => (flexGrow ? flexGrow : '')};
  z-index: ${({zIndex}) => (zIndex ? zIndex : '')};
  display: ${({display}) => (display ? display : 'auto')};
`

export const ListContainer = styled.div`
  width: 100%;
  height: calc(100vh - 74.3vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 85px;
  flex-grow: 1;
`

type CardProps = {
  width: string
  height: string
  border?: string
  borderLeft?: string
  borderBottom?: string
  padding?: string
}

export const Card = styled(Column)<CardProps>`
  width: ${props => props.width};
  border: ${props => props.border};
  padding: ${props => props.padding};
  border: ${props => (props.border ? props.border : '')};
  border-left: ${props => (props.borderLeft ? props.borderLeft : '')};
  border-bottom: ${props => (props.borderBottom ? props.borderBottom : '')};
`
export const PlanInfoListContainer = styled(Card)<{pageViewState: string}>`
  max-height: ${({pageViewState}) => (pageViewState === 'map' ? '' : '290px')};
  overflow-y: scroll;
`
export const AttributePill = styled.div`
  padding: 1px 20px;
  border-radius: 200px;
  background-color: var(--asc-gold);
  border: solid 1px var(--asc-gold);
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  white-space: nowrap;
  width: fit-content;
`
