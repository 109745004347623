import * as React from 'react'
import styled from 'styled-components/macro'
import {parseISO, format} from 'date-fns'
import {useStore} from 'common/useStore'
import {NotePencil, MapPinLine, Broadcast} from 'phosphor-react'
//import {createPoints} from 'map/mapHelpers'
// import Checkbox from 'common/components/Checkbox'

const Container = styled.li`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 20px;
  border-bottom: 1px solid var(--asc-coolgray);
  &:hover {
    background: #f0f6e1;
    input {
      border: 1px solid var(--asc-moss);
      box-sizing: border-box;
      box-shadow: 0px 0px 6px 1px var(--secondary);
      border-radius: 5px;
    }
  }
`

// const LeftSection = styled.div`
//   display: flex;
//   margin-top: 5px;
// `

const RightSection = styled.div`
  font-size: 14px;
`

const DeviceName = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 700;
  align-items: center;
  span {
    font-weight: normal;
    margin-left: 5px;
    font-size: 13px;
  }
`

const DeviceDescription = styled.div`
  color: ${props => (props.hasNoInfo ? 'black' : 'var(--asc-sonicsilver)')};
  margin: 5px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin-right: 20px;
  }
`
const DeviceAddress = styled.div`
  color: ${props => (props.hasNoInfo ? 'black' : 'var(--asc-sonicsilver)')};
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  svg {
    margin-right: 20px;
    margin-top: 3px;
  }
`

const UnassignedCard = ({device, changeActiveMarkerFromClick}) => {
  const user = useStore(state => state.userConfig)
  let createdOn = ''
  const timeFormat = user?.Time_Format === '12HR' ? 'h:mm a' : 'H:mm a'
  if (device?.Created_On) {
    createdOn = format(
      parseISO(device?.Created_On),
      `${user.Date_Format} ${timeFormat}`,
    )
  }
  //const point = device && device.latitude ? createPoints([device]) : null

  // const city = device?.Landmark_City ? device.Landmark_City + ', ' : ''
  return (
    <Container
      data-unassignedid={device.idDevice}
      onClick={changeActiveMarkerFromClick}
    >
      <RightSection>
        <DeviceName
          hasNoInfo={
            device?.Last_Nearby_Address || device?.Last_Location_Date_Local
          }
        >
          {`${device?.Serial_Number_Displayed || ''}`}
          <span>{` (${device?.Device_Type})`}</span>
        </DeviceName>
        <DeviceDescription
          hasNoInfo={
            device?.Last_Nearby_Address || device?.Last_Location_Date_Local
          }
        >
          <NotePencil weight="fill" size={16} /> {createdOn || ''}
        </DeviceDescription>

        <DeviceAddress
          hasNoInfo={
            device?.Last_Nearby_Address || device?.Last_Location_Date_Local
          }
        >
          {device?.Last_Nearby_Address && (
            <MapPinLine weight="fill" size={16} />
          )}
          {device?.Last_Nearby_Address}
        </DeviceAddress>
        <DeviceAddress
          hasNoInfo={
            device?.Last_Nearby_Address || device?.Last_Location_Date_Local
          }
        >
          <Broadcast weight="fill" size={16} />
          {(device?.Last_Location_Date_Local &&
            `${device?.Last_Location_Date_Local} ${device?.Last_Location_Time_Local}`) ||
            `---`}
        </DeviceAddress>
      </RightSection>
    </Container>
  )
}
export default UnassignedCard
