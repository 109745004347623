const landmarkStyles = [
  {
    key: 0,
    value: '',
    text: '-- Select Type --',
  },
  {
    key: 15,
    value: 15,
    text: 'Airport',
  },
  {
    key: 76,
    value: 76,
    text: 'Apartment',
  },
  {
    key: 16,
    value: 16,
    text: 'Bar',
  },
  {
    key: 85,
    value: 85,
    text: 'Base Camp',
  },
  {
    key: 14,
    value: 14,
    text: 'Branch Office',
  },
  {
    key: 17,
    value: 17,
    text: 'Bridge',
  },
  {
    key: 19,
    value: 19,
    text: 'Building',
  },
  {
    key: 20,
    value: 20,
    text: 'Bus Station',
  },
  {
    key: 18,
    value: 18,
    text: 'Bus Stop',
  },
  {
    key: 21,
    value: 21,
    text: 'Cafe',
  },
  {
    key: 77,
    value: 77,
    text: 'Condo',
  },
  {
    key: 24,
    value: 24,
    text: 'Construction Site',
  },
  {
    key: 26,
    value: 26,
    text: 'Contractor',
  },
  {
    key: 27,
    value: 27,
    text: 'Convenient Store',
  },
  {
    key: 25,
    value: 25,
    text: 'Convention Center',
  },
  {
    key: 28,
    value: 28,
    text: 'Customer Service Center',
  },
  {
    key: 8,
    value: 8,
    text: 'Customer Site',
  },
  {
    key: 22,
    value: 22,
    text: 'Dealership',
  },
  {
    key: 29,
    value: 29,
    text: 'Department Store',
  },
  {
    key: 30,
    value: 30,
    text: 'Distribution Center',
  },
  {
    key: 83,
    value: 83,
    text: 'Down Line',
  },
  {
    key: 31,
    value: 31,
    text: 'Factory',
  },
  {
    key: 32,
    value: 32,
    text: 'Field',
  },
  {
    key: 82,
    value: 82,
    text: 'Field Camp',
  },
  {
    key: 33,
    value: 33,
    text: 'Fuel Area',
  },
  {
    key: 34,
    value: 34,
    text: 'Garage',
  },
  {
    key: 35,
    value: 35,
    text: 'Gas Station',
  },
  {
    key: 13,
    value: 13,
    text: 'Generic',
  },
  {
    key: 36,
    value: 36,
    text: 'Government Facility',
  },
  {
    key: 37,
    value: 37,
    text: 'Grocery Store',
  },
  {
    key: 7,
    value: 7,
    text: 'Headquarters',
  },
  {
    key: 38,
    value: 38,
    text: 'Highway',
  },
  {
    key: 39,
    value: 39,
    text: 'Hospital',
  },
  {
    key: 40,
    value: 40,
    text: 'Hotel',
  },
  {
    key: 74,
    value: 74,
    text: 'House',
  },
  {
    key: 10,
    value: 10,
    text: 'Illegal Stop',
  },
  {
    key: 41,
    value: 41,
    text: 'Industrial',
  },
  {
    key: 43,
    value: 43,
    text: 'Intersection',
  },
  {
    key: 42,
    value: 42,
    text: 'Job Location',
  },
  {
    key: 44,
    value: 44,
    text: 'Landfill',
  },
  {
    key: 46,
    value: 46,
    text: 'Maintenance Service Center',
  },
  {
    key: 45,
    value: 45,
    text: 'Mall',
  },
  {
    key: 47,
    value: 47,
    text: 'Manufacturing Facility',
  },
  {
    key: 81,
    value: 81,
    text: 'Mine',
  },
  {
    key: 48,
    value: 48,
    text: 'Miscellaneous',
  },
  {
    key: 72,
    value: 72,
    text: 'Off Limits Location',
  },
  {
    key: 49,
    value: 49,
    text: 'Office',
  },
  {
    key: 50,
    value: 50,
    text: 'Other',
  },
  {
    key: 51,
    value: 51,
    text: 'Park',
  },
  {
    key: 52,
    value: 52,
    text: 'Parking Lot',
  },
  {
    key: 53,
    value: 53,
    text: 'Partner',
  },
  {
    key: 54,
    value: 54,
    text: 'Pet Store',
  },
  {
    key: 78,
    value: 78,
    text: 'Port',
  },
  {
    key: 55,
    value: 55,
    text: 'Public',
  },
  {
    key: 73,
    value: 73,
    text: 'Quarry',
  },
  {
    key: 84,
    value: 84,
    text: 'Ready Line',
  },
  {
    key: 57,
    value: 57,
    text: 'Receiving',
  },
  {
    key: 23,
    value: 23,
    text: 'Rental Facility',
  },
  {
    key: 58,
    value: 58,
    text: 'Repair Shop',
  },
  {
    key: 11,
    value: 11,
    text: 'Residence',
  },
  {
    key: 59,
    value: 59,
    text: 'Restaurant',
  },
  {
    key: 86,
    value: 86,
    text: 'Sand Plant',
  },
  {
    key: 60,
    value: 60,
    text: 'School',
  },
  {
    key: 61,
    value: 61,
    text: 'Shipping',
  },
  {
    key: 62,
    value: 62,
    text: 'Shop/Yard',
  },
  {
    key: 63,
    value: 63,
    text: 'Shopping Center',
  },
  {
    key: 64,
    value: 64,
    text: 'Stadium',
  },
  {
    key: 65,
    value: 65,
    text: 'Storage',
  },
  {
    key: 66,
    value: 66,
    text: 'Store',
  },
  {
    key: 67,
    value: 67,
    text: 'Street',
  },
  {
    key: 68,
    value: 68,
    text: 'Supplier',
  },
  {
    key: 79,
    value: 79,
    text: 'Terminal',
  },
  {
    key: 88,
    value: 88,
    text: 'Test Bay',
  },
  {
    key: 69,
    value: 69,
    text: 'Train Station',
  },
  {
    key: 56,
    value: 56,
    text: 'Transportation',
  },
  {
    key: 70,
    value: 70,
    text: 'Vendor',
  },
  {
    key: 71,
    value: 71,
    text: 'Warehouse',
  },
  {
    key: 87,
    value: 87,
    text: 'Wash Bay',
  },
  {
    key: 80,
    value: 80,
    text: 'Well Site',
  },
  {
    key: 9,
    value: 9,
    text: 'Work Order',
  },
  {
    key: 90,
    value: 90,
    text: 'Day - Sunday',
  },
  {
    key: 91,
    value: 91,
    text: 'Day - Monday',
  },
  {
    key: 93,
    value: 93,
    text: 'Day - Tuesday',
  },
  {
    key: 94,
    value: 94,
    text: 'Day - Wednesday',
  },
  {
    key: 95,
    value: 95,
    text: 'Day - Thursday',
  },
  {
    key: 96,
    value: 96,
    text: 'Day - Friday',
  },
  {
    key: 97,
    value: 97,
    text: 'Day - Saturday',
  },
]

export default landmarkStyles
