import {Column} from 'common/components'
import SearchContainer from 'opening/SearchContainer'
import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 75px;
`
export const Header = styled(Column)<{panelOpen: boolean}>`
  padding: 10px 20px;
  width: 100%;
  position: relative;
  z-index: 1;
  svg {
    min-height: 20px;
    min-width: 20px;
  }
`
export const StyledLabel = styled.label`
  display: block;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  display: flex;
  margin-bottom: 5px;
  z-index: 2;
`
export const StyledSearchContainer = styled(SearchContainer)`
  padding: 0 !important;
  z-index: 2;
`
export const Panel = styled.div<{panelOpen: boolean}>`
  background: ${p => (p.panelOpen ? 'white' : 'transparent')};
  box-shadow: ${p => (p.panelOpen ? '0px 0px 22px 0px #00000026' : 'none')};
  border: ${p =>
    p.panelOpen ? '1px solid var(--asc-coolgray)' : '1px solid transparent'};
  border-radius: 5px;
  z-index: ${p => (p.panelOpen ? '1' : '0')};
  position: absolute;
  width: 100%;
  height: fit-content;
  top: -15px;
  left: -15px;
`

export const ListPanel = styled(Column)`
  height: 190px;
  overflow-y: auto;
  svg {
    right: 15px;
    bottom: 5px;
    position: absolute;
    min-height: 30px !important;
    min-width: 30px !important;
    :hover {
      fill: var(--secondary);
      cursor: pointer;
    }
  }
`
export const ListContainer = styled.div<{totalHeight: number}>`
  position: relative;
  height: ${p => p.totalHeight}px;
`
