import axios from 'axios'
import {useStore} from 'common/useStore'
import {format} from 'date-fns'
import {groupBy} from 'lodash'
import Swal from 'sweetalert2'
import {validateEmail} from 'common/helpers'
import {setToken} from '../common/setToken'

export const reducer = (state, action) => {
  switch (action.type) {
    case 'loadUser':
      return {
        ...state,
        user: {...state.user, ...action.data.user},
        errors: {},
        loaded: true,
      }
    case 'loadGroups':
      return {...state, ...action.data}
    case 'loadDriver': {
      const driver = action.data

      return {
        ...state,
        user: {
          ...state.user,
          First_Name: driver.FirstName,
          Last_Name: driver.LastName,
          Email_Address: driver.EmailAddress,
          Address: driver.Address,
          WorkPhone: driver.WorkPhone,
          CellPhone: driver.CellPhone,
          Title: driver.Title,
          Department: driver.Dept,
          EmployeeID: driver.EmployeeID,
        },
        errors: {},
        loaded: true,
      }
    }
    case 'changeTabs':
      return {...state, ...action.data}
    case 'updateState':
      return {...state, ...action.data, isDirty: true}
    case 'noHierarchy':
      return {...state, user: {...state.user, ...action.data}}
    case 'updateUser': {
      const errors = checkForErrors({
        ...state,
        user: {...state.user, ...action.data},
      })
      return {
        ...state,
        user: {...state.user, ...action.data},
        isDirty: true,
        errors,
      }
    }
    case 'updateAddress':
      return {
        ...state,
        user: {
          ...state.user,
          Address: {
            ...state.user.Address,
            ...action.data,
          },
          isDirty: true,
        },
      }
    default:
      break
  }
}

const userConfig = useStore.getState().userConfig
// Map date-fns formats (from userConfig) to option text in the UI
const dateFormatMap = {
  'M/d/yyyy': 'mm/dd/yyyy',
  'd/M/yyyy': 'dd/mm/yyyy',
  'yyyy/M/d': 'yyyy/mm/dd',
}

const initialUser = {
  TZ_Index: userConfig?.TZ_Index || 0,
  Distance_UOM: userConfig?.Distance_Format === 22 ? 'Metric' : 'US',
  Date_Format: dateFormatMap[userConfig?.Date_Format] || 'mm/dd/yyyy',
  Time_Format: userConfig?.Time_Format || '12HR',
  DST_Flag: userConfig?.DST_Flag || true,
  Asset_Labels: 'true',
  CellPhone: null,
  WorkPhone: null,
  Title: null,
  EmployeeID: null,
  Department: null,
  Division: null,
  idCustomerType: 1,
  AvailableCustomers: [],
  RestrictedAdminAccess: null,
  First_Name: '',
  Last_Name: '',
  Email_Address: '',
  isDirty: false,
  Expiration_Date: null,
  activePassword: '',
  password: '',
  // idCustomerType: userConfig?.idCustomerType,
  Address: {
    Address_Line1: null,
    Address_Line2: null,
    Locality: null,
    Postal_Code: null,
    Region: null,
    isoCountryCode: null,
  },
}

export const initialState = {
  user: initialUser,
  loaded: false,
  submitting: false,
  activeTab: 'User',
  selectedGroupIds: [],
  selectedProjectIds: [],
  activePassword: 'automated',
  errors: {
    tabs: {user: true, security: true},
  },
  isDirty: false,
}

export const inputChanged = ({e, dispatch}) => {
  let value = e?.currentTarget?.value
  const name = e?.currentTarget?.name

  dispatch({
    type: 'updateUser',
    data: {
      [name]: value,
    },
  })
}

export const hasHierarchy = customer => {
  return Boolean(customer.CustomerList && customer.CustomerList.length)
}
export const isSelected = (selectedCustomers, id) => {
  return selectedCustomers?.indexOf(id) !== -1
}
// export const appendGroups = (customersGroups, checkedCustomer, groupsData) => {
//   const newCustomerGroups = cloneDeep(customersGroups)
//   const groups = {
//     idCustomer: checkedCustomer.idCustomer,
//     Customer_Name: checkedCustomer.Customer_Name,
//     groups: groupsData,
//   }
//   newCustomerGroups[checkedCustomer.idCustomer] = groups
//   return newCustomerGroups
// }
export const getSelectedGroup = (selectedGroupsIds, customersGroups) => {
  const currentGroups = Object.assign([], selectedGroupsIds)
  customersGroups?.forEach(group => {
    const groupIndex = selectedGroupsIds?.indexOf(group.idGroup)
    if (groupIndex !== -1) {
      currentGroups.splice(groupIndex, 1)
    }
  })
  return currentGroups
}
export const handleSelectItem = (id, selectedItems, callBack) => {
  const currentSelected = Object.assign([], selectedItems)
  const index = currentSelected?.indexOf(id)
  if (index > -1) {
    //remove  selected item from the list
    currentSelected.splice(index, 1)
  } else {
    currentSelected.push(id)
  }
  callBack(currentSelected)
}
export const compare = (a, b) => {
  // Use toUpperCase() to ignore character casing
  const labelA = a.label.toUpperCase()
  const labelB = b.label.toUpperCase()

  let comparison = 0
  if (labelA > labelB) {
    comparison = 1
  } else if (labelA < labelB) {
    comparison = -1
  }
  return comparison
}
export const isExpired = date => {
  if (!date) {
    return false
  } else {
    const today = new Date()
    return new Date(date) < today
  }
}
export const buildInnerTree = (node, customerNameMap) => {
  node.CustomerList?.forEach(child => {
    if (child.CustomerList && 0 < child.CustomerList.length) {
      buildInnerTree(child, customerNameMap)
    }
  })
  if (node.idCustomer && node.Customer_Name) {
    customerNameMap.push({
      idCustomer: node.idCustomer,
      Customer_Name: node.Customer_Name,
    })
  }
}
export const getSelectedGroupsAndProjects = (tree, userCustomersGroups) => {
  const groupedByIdCustomer = groupBy(
    userCustomersGroups?.results,
    'idCustomer',
  )
  const customersGroups = []
  const selectedGroups = {}
  const selectedProjects = {}
  buildInnerTree(tree[0], customersGroups)
  for (const [key, value] of Object.entries(groupedByIdCustomer)) {
    const currentCust = customersGroups?.filter(
      cust => cust.idCustomer === Number(key),
    )
    if (currentCust?.length) {
      const currentCustGroups = value.filter(
        group => group.IsTimeBased === false,
      )
      const currentCustProjects = value.filter(
        group => group.IsTimeBased === true,
      )
      selectedGroups[currentCust[0].idCustomer] = {
        idCustomer: currentCust[0].idCustomer,
        Customer_Name: currentCust[0].Customer_Name,
        groups: currentCustGroups,
      }
      selectedProjects[currentCust[0].idCustomer] = {
        idCustomer: currentCust[0].idCustomer,
        Customer_Name: currentCust[0].Customer_Name,
        groups: currentCustProjects,
      }
    }
  }
  return {selectedGroups, selectedProjects}
}

export const getDefaultUOM = data => {
  let Distance_UOM = 'Metric'
  if (data?.Distance_Format === 26 || data?.Distance_UOM === 'mi') {
    Distance_UOM = 'US'
  }
  return Distance_UOM
}
export const getUserDistanceUOM = () => {
  const user = useStore.getState().userConfig
  let Distance_UOM = 'kilometers'
  if (user?.Distance_Format === 26 || user?.Distance_UOM === 'mi') {
    Distance_UOM = 'miles'
  }
  return Distance_UOM
}
export const getUserDistanceUOMPerHour = () => {
  const user = useStore.getState().userConfig
  let Distance_UOM = 'kmh'
  if (user?.Distance_Format === 26 || user?.Distance_UOM === 'mi') {
    Distance_UOM = 'mph'
  }
  return Distance_UOM
}

export const submitUser = (user, state) => {
  const submitObject = {...user}
  submitObject.ListOfGroupIds = state.selectedGroupIds
  submitObject.ListOfProjectIds = state.selectedProjectIds
  submitObject.idCustomer = user.AvailableCustomers[0].idCustomer

  submitObject.Expiration_Date = submitObject?.Expiration_Date
    ? format(
        new Date(submitObject.Expiration_Date),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
      )
    : null
  if (user.activePassword !== 'automated') {
    submitObject.NewUserInfo = user.password
  }
  if (submitObject.Distance_UOM === 'Metric') {
    submitObject.Distance_Format = 22
    submitObject.Speed_Format = 3
    submitObject.FuelEfficiency_Format = 37
    submitObject.Temperature_Format = 11
    submitObject.Volume_Format = 29
  } else {
    submitObject.Distance_Format = 26
    submitObject.Speed_Format = 2
    submitObject.FuelEfficiency_Format = 36
    submitObject.Temperature_Format = 12
    submitObject.Volume_Format = 33
  }
  return submitObject
}

export const submitUserGroups = async submitObject => {
  const updatedUserGroups = {
    idUser: submitObject.idUser,
    ListOfGroupIds: submitObject.ListOfGroupIds,
    ListOfProjectIds: submitObject.ListOfProjectIds,
  }
  try {
    const response = await axios.put(`/groups/update/user`, updatedUserGroups)
    if (!response) {
      throw new Error()
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Failed to update user groups.',
      text: 'An unexpected error occurred.',
    })
    return null
  }
}

export const checkForErrors = state => {
  if (!state) return
  const errors = {}
  const safeAddTabs = url => {
    if (errors.tabs) {
      errors.tabs[url] = true
    } else {
      errors.tabs = {
        [url]: true,
      }
    }
  }
  if (!state.user?.First_Name) {
    errors.First_Name = {message: 'First name is required.'}
    safeAddTabs('user')
  }
  if (!state.user?.Last_Name) {
    errors.Last_Name = {message: 'Last name is required.'}
    safeAddTabs('user')
  }
  if (!state.user?.Email_Address) {
    errors.Email_Address = {message: 'Email/User ID is required.'}
    safeAddTabs('user')
  } else {
    const emailCheck = validateEmail(state.user?.Email_Address)
    if (!emailCheck) {
      errors.Email_Address = {
        message: 'Email/User ID is not a valid email address.',
      }
      safeAddTabs('user')
    }
  }
  if (!state.user?.Role_Id) {
    errors.Role_Id = {message: 'Role is required.'}
    safeAddTabs('security')
  }
  if (
    !state.user?.AvailableCustomers ||
    state.user?.AvailableCustomers.length === 0
  ) {
    errors.hierarchies = {
      message: 'A customer must be selected for this user',
    }
    safeAddTabs('security')
  }

  if (!state.user?.TZ_Index) {
    errors.TZ_Index = {message: 'Time Zone is required.'}
    safeAddTabs('preferences')
  }
  if (
    !state.user?.AvailableCustomers ||
    state.user?.AvailableCustomers.length === 0
  ) {
    errors.hierarchies = {
      message: 'A customer must be selected for this user',
    }
    safeAddTabs('security')
  }

  if (state.activePassword === 'createnew') {
    if (!state.user?.password) {
      errors.password = {
        message: 'Password is required',
      }
      safeAddTabs('security')
    } else if (state.user.password.length < 8) {
      errors.password = {
        message: 'Password must be at least 8 characters',
      }
      safeAddTabs('security')
    }
  }

  return errors
}

export const sendPasswordEmail = async (emailAddress, activePassword) => {
  try {
    const email = encodeURIComponent(emailAddress)
    ;(await activePassword) === 'accesscode'
      ? axios.get(
          `/users?a=resetpassword&type=newmobiledeviceaccesscode&email=${email}`,
        )
      : axios.get(`/users?a=resetpassword&type=new&email=${email}`)
  } catch (error) {
    console.error(error)
  }
}

export const sendDriverEmail = async emailAddress => {
  try {
    const email = encodeURIComponent(emailAddress)
    await axios.get(
      `/users?a=resetpassword&type=newmobiledeviceaccesscode&email=${email}`,
    )
  } catch (error) {
    console.error(error)
  }
}

export const onSubmit = async ({
  state,
  postAddress,
  putAddress,
  putUser,
  postUser,
  navigate,
  locationState,
  cache,
  shouldSetNewToken = false,
}) => {
  const submitObject = submitUser(state.user, state)
  const addressModified = Object.keys(submitObject.Address || {}).filter(
    function (key) {
      return submitObject.Address[key] !== null
    },
  )
  let address = null
  if (addressModified?.length) {
    if (!submitObject.idAddress) {
      address = await postAddress(submitObject.Address)
    } else {
      submitObject.Address.idAddress = submitObject.idAddress
      address = await putAddress(submitObject.Address)
    }
  }

  if (address?.idAddress) {
    submitObject.idAddress = address?.idAddress
  }
  let userDetail = null
  if (submitObject.idUser) {
    //put user
    userDetail = await putUser(submitObject)
  } else {
    userDetail = await postUser(submitObject)
    submitObject.idUser = userDetail.idUser
    if (state.activePassword === 'automated') {
      sendPasswordEmail(submitObject.Email_Address)
    }
    if (state.activePassword === 'accesscode') {
      sendDriverEmail(submitObject.Email_Address)
    }
  }
  // If current user is editing their own email address, a new token needs to be generated
  if (shouldSetNewToken) {
    const lookup = localStorage.getItem('lookup')
    let email
    let password
    if (lookup) {
      const decodedToken = window.atob(lookup)
      const decodedTokenArray = decodedToken.split(':')
      email = submitObject.Email_Address
      password = decodedTokenArray[1]
    }

    localStorage.removeItem('token')
    const token = window.btoa(`${email}:${password}`)

    setToken({token: token, freshToken: true})
  }

  await submitUserGroups(submitObject)
  cache.invalidateQueries(['userDetail', String(submitObject.idUser)])
  cache.invalidateQueries(['users'])

  navigate('/users', {state: {filters: locationState?.filters}})
}
