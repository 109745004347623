import Axios from 'axios'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {CustomerTemplateType} from '../notificationsHelpers'
import {showToast} from 'common/components/Toastr'

const postCustomerTemplate = async (template: CustomerTemplateType) => {
  const {data} = await Axios.post(`rd/eta/templates/customer`, template)
  return data
}

const usePostCustomerTemplate = () => {
  const cache = useQueryClient()
  return useMutation(postCustomerTemplate, {
    onError: (err, variables, previousValue) => {
      console.error(err, variables, previousValue)
      showToast(
        `Unable to update ${variables.NotificationName} Template`,
        'error',
      )
      cache.setQueryData(['customerTemplate'], previousValue)
    },
    onSuccess: (data, variables) => {
      showToast(
        `${variables.NotificationName} updated successfully!`,
        'success',
      )
    },
    onSettled: () => {
      cache.invalidateQueries({queryKey: ['customerTemplate']})
    },
  })
}

export default usePostCustomerTemplate
