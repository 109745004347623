import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'
export interface RouteLocationsType {
  _id: string
  title: string
  cardtype: string
  listId: string
  boardId: string
  sort: string
  carddata: Carddata
  imported: string
  generating: string
  createdAt: string
  dateLastActivity: string
  archived: string
  systemState: string
  userId: string
  companyId: string | null
  lastUserActivity?: string | null
}

export interface Carddata {
  ID: string
  CompanyId?: string | null
  CompanyName?: string | null
  Address1: string
  Address2?: string | null
  City: string
  State: string
  ZipCode: string
  PrimaryPhone?: string | null
  SecondaryPhone?: string | null
  Fax?: string | null
  Email?: string | null
  Contact?: string | null
  LastUpdated: string
  CarrierNotes: string[]
  Roles: Roles
  Latitude?: string | null
  Longitude?: string | null
  GeoFence: GeoFence[]
  Coords?: Coords
}

export interface Roles {
  Name: string | null
}

export interface GeoFence {
  X: number
  Y: number
}

export interface Coords {
  type: string
  coordinates: number[]
}

const getRouteLocations = async () => {
  const {data}: {data: RouteLocationsType[]} = await Axios.get(
    '/rd/locations/search',
  )
  return data
}

export default function useRouteLocations() {
  const user = useStore(state => state.user)
  return useQuery(['routeLocations'], getRouteLocations, {
    enabled: Boolean(user?.token),
  });
}
