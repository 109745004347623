import {useQueryClient, useMutation} from '@tanstack/react-query'
import axios from 'axios'

export type DeviceTransferItem = {
  Serial_Number_Displayed: string
  idDevice: number
  Unit_Price: number
}

export type DeviceTransferType = {
  FromidCustomer: number | undefined
  ToidCustomer: number | undefined
  Order_Num: string
  deviceTransfers: DeviceTransferItem[]
  idDevice_Status: number
}

const postDeviceTransfer = async (deviceTransfer: DeviceTransferType) => {
  const {data} = await axios.post(
    `/accountsupport/devicetransfer`,
    deviceTransfer,
  )
  return data
}

export default function usePostDeviceTransfer() {
  const cache = useQueryClient()
  return useMutation(postDeviceTransfer, {
    onError: (err, variables, previousValue) => {
      console.error(err, variables, previousValue)
    },
    // After success or failure, refetch the devices query
    onSettled: () => {
      cache.invalidateQueries(['devices'])
    },
  })
}
