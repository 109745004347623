import {LandmarkType} from 'locations/hooks/useLocations'

export type ActionTypes = {
  type: 'setState' | 'searchLocations'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}

export const reducer = (state: State, action: ActionTypes) => {
  switch (action.type) {
    case 'setState':
      return {...state, ...action.data}
    case 'searchLocations': {
      const searchValue = action.data.searchValue.toLowerCase()
      const filteredLocations = searchNestedLocations(
        state.originalLocations,
        searchValue,
      )
      return {...state, filteredLocations, searchValue}
    }
    default:
      break
  }
}

const searchNestedLocations = (
  locations: LandmarkType[],
  searchValue: string,
): LandmarkType[] => {
  return locations.filter(location => searchLocation(location, searchValue))
}

const searchLocation = (
  location: LandmarkType,
  searchValue: string,
): boolean => {
  return Object.values(location).some(value => searchNested(value, searchValue))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const searchNested = (obj: any, searchValue: string): boolean => {
  if (!obj) {
    return false
  }

  if (typeof obj === 'object' && obj !== null) {
    return Object.values(obj).some(value => searchNested(value, searchValue))
  } else if (
    typeof obj === 'string' &&
    obj.toLowerCase().includes(searchValue)
  ) {
    return true
  }

  return false
}
export type State = {
  searchValue: string
  panelOpen: boolean
  selectedLocation: LandmarkType | null
  originalLocations: LandmarkType[]
  filteredLocations: LandmarkType[]
}
export const initialState: State = {
  searchValue: '',
  panelOpen: false,
  originalLocations: [],
  filteredLocations: [],
  selectedLocation: null,
}
