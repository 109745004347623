import * as React from 'react'
import CameraEventRequestModal, {
  RequestedCameraType,
} from '../cameras/CameraEventRequestModal'
import {Container} from './cameraEventRequestVideoHelper'
import {Button} from 'common/components'
import useCameraStatusData from 'map/infoWindow/hooks/useCameraStatusData'
import {ascendColors} from 'common/GlobalStyles'

const CameraEventRequestVideo: React.FC<{
  requestedCamera: RequestedCameraType | null
  resetRequestedCamera?: () => void
  onClick?: () => void
  idAsset?: number
}> = ({requestedCamera, resetRequestedCamera, onClick, idAsset}) => {
  const [showDialog, setShowDialog] = React.useState(false)
  const {data: assetOnline} = useCameraStatusData(
    idAsset || 0,
    requestedCamera?.idDevice_Type || 35,
  )
  React.useEffect(() => {
    if (requestedCamera) {
      setShowDialog(true)
    }
  }, [requestedCamera])

  return (
    idAsset ? assetOnline === 'online' || assetOnline === 'standby' : true
  ) ? (
    <Container
      data-cy="camera-request-video"
      fontOnPrimary={ascendColors.fontOnPrimary}
      fontOnSecondary={ascendColors.fontOnSecondary}
    >
      <Button
        onClick={() => {
          setShowDialog(true)
          onClick && onClick()
        }}
      >
        Request Camera Video
      </Button>

      {showDialog && (
        <CameraEventRequestModal
          closeModal={() => setShowDialog(false)}
          requestedCamera={requestedCamera}
          resetRequestedCamera={resetRequestedCamera}
        />
      )}
    </Container>
  ) : (
    <></>
  )
}
export default CameraEventRequestVideo
