type ErrorType = {[key: string]: {type?: string; message: string}}

type NotificationProps = {
  idNotification: number
  Notification_Name: string
  Email_List: string
  SMS_List: string
}

type State = {
  message: string
  expiresIn: number
  phone: string
  isDirty: boolean
  submitting: boolean
  errors?: ErrorType
  notification?: NotificationProps
}

export const initialState: State = {
  message: '',
  expiresIn: 1,
  phone: '',
  isDirty: false,
  submitting: false,
  errors: {},
  notification: {
    idNotification: 0,
    Notification_Name: '',
    Email_List: '',
    SMS_List: '',
  },
}

type Action = {
  type: 'setState' | 'submitting' | 'setPhone' | 'setEmail'
  data: {
    [key: string]: number | string | boolean | ErrorType | NotificationProps
  }
}

export const reducer = (state: any, action: Action) => {
  switch (action.type) {
    case 'setState':
      return {
        ...state,
        ...action.data,
        isDirty: true,
      }
    case 'submitting':
      return {
        ...state,
        submitting: action.data,
      }
    case 'setEmail': {
      return {
        ...state,
        validEmail: action.data.validEmail,
        ordinalNumberEmail: action.data.ordinalNumberEmail,
        notification: action.data.notification,
      }
    }
    case 'setPhone':
      return {
        ...state,
        validPhone: action.data.validPhone,
        ordinalNumberPhone: action.data.ordinalNumberPhone,
        notification: action.data.notification,
      }
    default:
      console.error('invalid reducer action', action)
      break
  }
}
