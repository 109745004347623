import {useQuery} from '@tanstack/react-query'
import axios from 'axios'
import {useStore} from 'common/useStore'

export type CameraStatusType = 'offline' | 'online' | 'standby' | 'Not Found'

export type CameraListType = {
  idAsset: number
  Asset_Label: string
  Asset_Description: string | null
  idDevice: number
  Serial_Number_Displayed: string
  idDevice_Type: number
  CurrentDriver: string | null
  CurrentIdDriver: number | null
  Last_Location_DateTime_Local: string
  AvailableChannels?: string
  DeviceModelIDDV?: string
  CameraStatus: CameraStatusType
  Device_Type: string
  Last_Nearby_Address: string
  Asset_Icon_Full_Path: string
  Last_Event: string
}

const getCameraList = async () => {
  const {data}: {data: CameraListType[]} = await axios.get('/camera/assetlist')
  return data
}

const useCameraList = () => {
  const user = useStore(state => state.user)
  return useQuery(['cameraList'], () => getCameraList(), {
    enabled: Boolean(user?.token),
  })
}

export default useCameraList
