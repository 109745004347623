import * as React from 'react'
import {
  ButtonsRow,
  TabContentContainer,
  Button,
  StyledRow,
  PreviewContainer,
  PreviewTitle,
  PreviewContentTitle,
  PreviewText,
  CharacterCount,
  LabelRow,
  ContentHeaderRow,
  StyledColumn,
  ToggleGroup,
  InputContainer,
} from './notificationStyles'
import {
  Checkbox,
  InputErrorMessage,
  InsertAtCursorDropdown,
  InsertAtCursorText,
  RequiredAsterisk,
  Row,
} from 'common/components'
import {ArrowClockwise} from 'phosphor-react'
import {MAX_MESSAGE_CHAR_LIMIT} from 'common/constants'
import {OptionType} from 'common/components/InsertAtCursor/InsertAtCursorDropdown'
import styled from 'styled-components/macro'

const PreviewColumn = styled.div`
  max-width: 45%;
`
type ServiceEtaModalProps = {
  name: string
  handleInputChange: ({name, value}: {name: string; value: string}) => void
  subject: string
  body: string
  errors: {
    EmailSubject: {message: string} | undefined
    EmailBody: {message: string} | undefined
    SmsBody: {message: string} | undefined
  }
  onResetButton: (name: string) => void
  onToggle: (enabled: boolean) => void
  insertOptions: OptionType[]
  enabled: boolean
}
const TabContent: React.FC<ServiceEtaModalProps> = ({
  name,
  handleInputChange,
  subject,
  body,
  errors,
  onResetButton,
  insertOptions,
  enabled,
  onToggle,
}) => {
  const [subjectCursorPosition, setSubjectCursorPosition] = React.useState<
    number | null
  >(null)
  const [contentCursorPosition, setContentCursorPosition] = React.useState<
    number | null
  >(null)

  const getPreviewValue = React.useCallback(
    (inputString: string) => {
      let result = inputString
      insertOptions?.forEach(option => {
        const {text, preview} = option
        const token = new RegExp(`{${text}}`, 'g') // Use regex to match all occurrences

        result = result.replace(token, preview)
      })
      return result
    },
    [insertOptions],
  )

  const onChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    {value}: {value: string},
  ) => {
    handleInputChange({
      name: e.target.name,
      value: value,
    })
  }
  return (
    <TabContentContainer>
      <ContentHeaderRow>
        <ToggleGroup>
          <Checkbox
            selected={enabled}
            dataCy={'send-toggle'}
            onChange={() => onToggle(!enabled)}
            disabled={false}
          />
          <span>Send {`${name === 'Email' ? name : 'Text'}`}</span>
        </ToggleGroup>
      </ContentHeaderRow>
      <StyledRow>
        <StyledColumn>
          {name === 'Email' && (
            <div>
              <LabelRow>
                <Row>
                  <span>Subject</span>
                  <RequiredAsterisk />
                </Row>
                <div>
                  <CharacterCount>{`${subject.length}/${MAX_MESSAGE_CHAR_LIMIT}`}</CharacterCount>
                </div>
              </LabelRow>
              <InputContainer>
                <InsertAtCursorText
                  value={subject}
                  name={`EmailSubject`}
                  rows={1}
                  onChange={onChange}
                  handleClick={position => setSubjectCursorPosition(position)}
                />
                {name === 'Email' && errors.EmailSubject?.message && (
                  <InputErrorMessage message={errors.EmailSubject?.message} />
                )}
              </InputContainer>

              <ButtonsRow>
                <Button onClick={() => onResetButton('EmailSubject')}>
                  <ArrowClockwise size={12} />
                  Reset
                </Button>
                <InsertAtCursorDropdown
                  name={`EmailSubject`}
                  value={subject}
                  insertOptions={insertOptions}
                  handleInputChange={handleInputChange}
                  cursorPosition={subjectCursorPosition || subject.length}
                />
              </ButtonsRow>
            </div>
          )}
          <div>
            <LabelRow>
              <Row>
                <span>Message</span>
                <RequiredAsterisk />
              </Row>
            </LabelRow>
            <InputContainer>
              <InsertAtCursorText
                value={body}
                name={`${name}Body`}
                rows={10}
                onChange={onChange}
                handleClick={position => setContentCursorPosition(position)}
              />
              {name === 'Email' && errors.EmailBody?.message && (
                <InputErrorMessage message={errors.EmailBody?.message} />
              )}
              {name === 'Sms' && errors.SmsBody?.message && (
                <InputErrorMessage message={errors.SmsBody?.message} />
              )}
            </InputContainer>

            <ButtonsRow>
              <Button onClick={() => onResetButton(`${name}Body`)}>
                <ArrowClockwise size={12} />
                Reset
              </Button>
              <InsertAtCursorDropdown
                name={`${name}Body`}
                value={body}
                insertOptions={insertOptions}
                handleInputChange={handleInputChange}
                cursorPosition={contentCursorPosition || body.length}
              />
            </ButtonsRow>
          </div>
        </StyledColumn>
        <PreviewColumn>
          <PreviewTitle>Preview Text</PreviewTitle>
          <PreviewContainer>
            {name === 'Email' && (
              <PreviewContentTitle>
                <span
                  dangerouslySetInnerHTML={{
                    __html: getPreviewValue(subject || ''),
                  }}
                />
              </PreviewContentTitle>
            )}
            <PreviewText>
              <span dangerouslySetInnerHTML={{__html: getPreviewValue(body)}} />
            </PreviewText>
          </PreviewContainer>
        </PreviewColumn>
      </StyledRow>
    </TabContentContainer>
  )
}
export default TabContent
