import Swal from 'sweetalert2'

export const submitUserProfile = async ({user, putUser, putAddress}) => {
  putAddress(user.Address)
  await putUser(user)
  location.reload()
}

export const initialState = {
  groupOptions: [],
  isDirty: false,
  submitting: false,
  user: {
    First_Name: '',
    Last_Name: '',
    Email_Address: '',
    TZ_Index: 20,
    Distance_UOM: 'mile',
    Date_Format: 'mm/dd/yyyy',
    Time_Format: '12HR',
    DST_Flag: true,
    Speed_UOM: 'MPH',
    Asset_Labels: 'true',
    Speed_Format: 2,
    FuelEfficiency_Format: 36,
    Temperature_Format: 12,
    Volume_Format: 33,
    Default_Group: null,
    Address: {
      Address_Line1: null,
      Address_Line2: null,
      Locality: null,
      Postal_Code: null,
      Region: null,
      isoCountryCode: null,
    },
  },
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setErrors':
      return {
        ...state,
        errors: action.data,
        submitting: false,
      }
    case 'loadUser':
      return {
        ...state,
        user: {...state.user, ...action.data},
        isDirty: false,
      }
    case 'setState':
      return {
        ...state,
        ...action.data,
        isDirty: true,
      }
    case 'setGroupOptions':
      return {
        ...state,
        ...action.data,
      }
    case 'updateAddress':
      return {
        ...state,
        user: {
          ...state.user,
          Address: {
            ...state.user.Address,
            ...action.data,
          },
        },
        isDirty: true,
      }
    case 'updateUser':
      var errors
      // if (state.errors && state.errors !== {}) {
      //   errors = checkErrors({...state.user, ...action.data})
      // }
      return {
        ...state,
        errors,
        user: {...state.user, ...action.data},
        isDirty: true,
      }
    default:
      console.error('invalid reducer action', action)
      break
  }
}

export const onCancel = (isDirty, onDismiss) => {
  if (isDirty) {
    Swal.fire({
      icon: 'question',
      title: 'Cancel User Profile',
      text: 'Are you sure you want to discard your changes?',
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
    }).then(res => {
      if (res.isConfirmed) {
        // deleteCookie('googtrans')
        onDismiss()
      }
    })
  } else {
    // deleteCookie('googtrans')
    onDismiss()
  }
}
export const alertIncomplete = () => {
  Swal.fire(
    'Missing Information',
    'Please fill out all required fields.',
    'error',
  )
}
