import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'
import {subHours} from 'date-fns'
import {formatInTimeZone} from 'date-fns-tz'
import {Range} from 'react-date-range'
import {Asset} from 'common/types/opening1Response'
import {getBeforeAfterStringForAPI} from 'common/helpersDateTime'

const getAlertEvents = async ({
  dateRange,
  assetDetail,
}: {
  dateRange: Range
  assetDetail?: Asset
}) => {
  const userConfig = useStore.getState().userConfig
  const startDate = dateRange?.startDate
    ? dateRange.startDate
    : subHours(new Date(), 24)
  const endDate = dateRange?.endDate ? dateRange.endDate : new Date()
  if (userConfig) {
    const dateFormat = 'MM/dd/yyyy%20HH:mm:ss'
    const startDateFormatted = formatInTimeZone(
      startDate,
      userConfig.TZ_Moment_Name,
      dateFormat,
    )
    const endDateFormatted = formatInTimeZone(
      endDate,
      userConfig.TZ_Moment_Name,
      dateFormat,
    )
    if (assetDetail?.idAsset) {
      const beforeAfterDate = getBeforeAfterStringForAPI(dateRange)
      const {data} = await Axios.get(
        `assets/${assetDetail.idAsset}/alertevents?${beforeAfterDate}`,
      )
      return data
    } else {
      const {data} = await Axios.get(
        `/alertevents?after=${startDateFormatted}&before=${endDateFormatted}`,
      )
      return data
    }
  }
  return
}

const useAlertEvents = (dateRange: Range, assetDetail?: Asset) => {
  const user = useStore(state => state.user)
  const dateString = dateRange
    ? getBeforeAfterStringForAPI(dateRange)
    : undefined
  const queryKey = dateString + String(assetDetail?.idAsset)
  return useQuery(
    ['alertEvents', queryKey],
    () => getAlertEvents({dateRange, assetDetail}),
    {
      enabled: Boolean(user?.token),
    },
  )
}

export default useAlertEvents
