import {useQuery} from '@tanstack/react-query'
import axios from 'axios'
import {useStore} from 'common/useStore'

type AuthConfigType = {
  authToken: string
  idOrganization: string
  imei: string
}

const getAuthConfig = async (idDevice: number) => {
  const {data}: {data: AuthConfigType} = await axios.get(
    `/camera/surfsight/authconfig/${idDevice}`,
  )
  return data
}

const useAuthConfig = (idDevice: number) => {
  const user = useStore(state => state.user)
  return useQuery(['authConfig', idDevice], () => getAuthConfig(idDevice), {
    enabled: Boolean(user?.token),
  })
}

export default useAuthConfig
