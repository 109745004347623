import {ascendColors} from 'common/GlobalStyles'
import {useMapStore} from 'map/useMapStore'

export const toggleBoxSearch = ({
  map,
  assetsRef,
  setAssetFilter,
  clearAssetFilter,
}) => {
  const showBoxSearch = useMapStore.getState().showBoxSearch
  if (showBoxSearch) {
    clearDrawingManager()
    clearAssetFilter && clearAssetFilter()
  } else {
    boxSearchStartDrawing({
      map,
      assetsRef,
      setAssetFilter,
      clearAssetFilter,
    })
  }
}

export const clearDrawingManager = () => {
  const boxSearchRectangle = useMapStore.getState().boxSearchRectangle
  const boxSearchDrawingManager = useMapStore.getState().boxSearchDrawingManager
  const boxSearchMarker = useMapStore.getState().boxSearchMarker
  if (boxSearchRectangle) {
    boxSearchRectangle?.setMap(null)
  }
  if (boxSearchDrawingManager) {
    boxSearchDrawingManager?.setMap(null)
  }
  if (boxSearchMarker) {
    boxSearchMarker?.setMap(null)
  }
  useMapStore.setState({
    showBoxSearch: false,
    boxSearchMarker: null,
    boxSearchRectangle: null,
    boxSearchDrawingManager: null,
  })
}

const drawingCompleteListener = ({
  event,
  assetsRef,
  map,
  setAssetFilter,
  clearAssetFilter,
}) => {
  const boxSearchDrawingManager = useMapStore.getState().boxSearchDrawingManager

  useMapStore.setState({boxSearchRectangle: event.overlay, showBoxSearch: true})
  boxSearchDrawingManager.setOptions({
    drawingControl: false,
  })

  //remove it from the map
  boxSearchDrawingManager.setMap(null)

  addCancelMarkerToRectangle({
    map,
    rectangle: event.overlay,
    clearAssetFilter,
  })

  getAssetsWithinBoundsCoordinates({
    map,
    assetsRef,
    setAssetFilter,
  })

  window.google.maps.event.addListener(event.overlay, 'bounds_changed', () => {
    const boxSearchMarker = useMapStore.getState().boxSearchMarker
    boxSearchMarker?.setMap(null)
    useMapStore.setState({
      boxSearchMarker: null,
    })
    addCancelMarkerToRectangle({
      map,
      rectangle: event.overlay,
      clearAssetFilter,
    })
    getAssetsWithinBoundsCoordinates({
      map,
      assetsRef,
      setAssetFilter,
    })
  })
}

const boxSearchStartDrawing = ({
  assetsRef,
  map,
  setAssetFilter,
  clearAssetFilter,
}) => {
  const boxSearchDrawingManager = useMapStore.getState().boxSearchDrawingManager

  if (!boxSearchDrawingManager) {
    const drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingMode: window.google.maps.drawing.OverlayType.RECTANGLE,
      drawingControl: false,
      drawingControlOptions: {
        drawingModes: ['rectangle'],
      },
      rectangleOptions: {
        strokeColor: ascendColors.customSecondary,
        fillColor: ascendColors.customSecondary,
        editable: true,
        strokeOpacity: 1,
        fillOpacity: 0.2,
      },
    })
    drawingManager.setMap(map)

    window.google.maps.event.addListener(
      drawingManager,
      'overlaycomplete',
      event =>
        drawingCompleteListener({
          event,
          assetsRef,
          map,
          setAssetFilter,
          clearAssetFilter,
        }),
    )

    useMapStore.setState({
      boxSearchDrawingManager: drawingManager,
      showBoxSearch: true,
    })
  }
}

export const inBounds = (point, bounds) => {
  var eastBound = point.long <= bounds.eastLong
  var westBound = point.long > bounds.westLong
  var inLong

  if (bounds.eastLong < bounds.westLong) {
    inLong = eastBound || westBound
  } else {
    inLong = eastBound && westBound
  }
  var inLat = point.lat >= bounds.southLat && point.lat < bounds.northLat
  return inLat && inLong
}
const getAssetsInBounds = (
  westLong,
  eastLong,
  northLat,
  southLat,
  allAssets,
) => {
  var filteredAssets = []
  allAssets?.forEach(asset => {
    if (
      inBounds(
        {
          lat: asset.Device.Latitude,
          long: asset.Device.Longitude,
        },
        {westLong, eastLong, northLat, southLat},
      )
    ) {
      if (!asset.Primary_idAsset) {
        filteredAssets.push(asset)
      }
    }
  })
  return filteredAssets
}

const deletePath = `M387.128,170.748L306,251.915l-81.128-81.167l-54.124,54.124L251.915,306l-81.128,81.128l54.085,54.086L306,360.086     l81.128,81.128l54.086-54.086L360.086,306l81.128-81.128L387.128,170.748z M522.38,89.62     c-119.493-119.493-313.267-119.493-432.76,0c-119.493,119.493-119.493,313.267,0,432.76     c119.493,119.493,313.267,119.493,432.76,0C641.873,402.888,641.873,209.113,522.38,89.62z M468.295,468.295     c-89.62,89.619-234.932,89.619-324.551,0c-89.62-89.62-89.62-234.932,0-324.551c89.62-89.62,234.931-89.62,324.551,0     C557.914,233.363,557.914,378.637,468.295,468.295z`
export const deleteIcon = {
  path: deletePath,
  fillColor: ascendColors.customSecondary,
  fillOpacity: 1,
  scale: 0.05,
  strokeColor: 'white',
  strokeWeight: 1,
  origin: new window.google.maps.Point(0, 200),
  anchor: new window.google.maps.Point(-100, 600),
}

const addCancelMarkerToRectangle = ({
  map,
  rectangle,
  clearAssetFilter = undefined,
}) => {
  //find asset within the overlay
  const boxSearchMarker = new window.google.maps.Marker({
    icon: deleteIcon,
    map: map,
  })
  const overlayBounds = rectangle.getBounds()
  var NE = overlayBounds.getNorthEast()
  boxSearchMarker.setPosition(NE)

  useMapStore.setState({boxSearchMarker})
  window.google.maps.event.addListenerOnce(boxSearchMarker, 'click', () => {
    clearDrawingManager()
    clearAssetFilter?.()
  })
}

const getAssetsWithinBoundsCoordinates = ({map, assetsRef, setAssetFilter}) => {
  const boxSearchRectangle = useMapStore.getState().boxSearchRectangle
  const overlayBounds = boxSearchRectangle.getBounds()
  var NE = overlayBounds.getNorthEast()
  var SW = overlayBounds.getSouthWest()

  map.fitBounds(overlayBounds, 30)

  var newMapBounds = {
    westLongitude: SW.lng(),
    eastLongitude: NE.lng(),
    northLatitude: NE.lat(),
    southLatitude: SW.lat(),
  }
  const assetsList = getAssetsInBounds(
    newMapBounds.westLongitude,
    newMapBounds.eastLongitude,
    newMapBounds.northLatitude,
    newMapBounds.southLatitude,
    assetsRef.current,
  )

  setAssetFilter({
    label: 'Box Search',
    filteredAssetIDs: assetsList?.map(item => item.idAsset),
  })
}
