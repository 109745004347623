import * as React from 'react'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components/macro'
import Button from 'common/components/Button'
import FindNearestAssets from 'mapControl/FindNearestAssets'
import {useStore} from 'common/useStore'

import QuickReportModal from 'common/components/QuickReportModal'
// import useOpening2 from 'opening/hooks/useOpening2'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
`

const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  button {
    padding: 10px;
  }
`

const CommandsTab = ({map, asset}) => {
  const [showingQuickReportsModal, setShowingQuickReportsModal] =
    React.useState(false)
  const userConfig = useStore(state => state.userConfig)
  const navigate = useNavigate()
  const openGetDirections = () => {
    navigate(`/map/directions?address=${asset?.Device?.Last_Nearby_Address}`)
  }
  // const {data: opening2Data} = useOpening2()
  let latlng = null
  if (asset?.Device.Latitude && asset?.Device.Longitude) {
    latlng = new window.google.maps.LatLng(
      asset?.Device.Latitude,
      asset?.Device.Longitude,
    )
  }

  const quickReport = () => {
    //  console.log ('')
    setShowingQuickReportsModal(true)
  }

  const editAsset = () => {
    navigate(`/assets/${asset.idAsset}`, {state: {from: 'map'}})
  }

  const addLocation = () => {
    navigate(
      `/location?address=latlng:${asset?.Device?.Latitude},${asset?.Device?.Longitude}`,
    )
  }

  const driverHistory = () => {
    navigate(`/assets/${asset.idAsset}?start=driver-history`)
  }

  return (
    <Container>
      <ButtonColumn>
        <Button data-cy="get-directions" onClick={openGetDirections}>
          Get Directions
        </Button>
        <Button data-cy="quick-report" onClick={quickReport}>
          Select Quick Report
        </Button>

        <FindNearestAssets map={map} latlng={latlng} />
      </ButtonColumn>
      {userConfig?.UserRole?.idUserRole === 1 && (
        <ButtonColumn>
          <Button data-cy="edit-asset" onClick={editAsset}>
            Edit Asset
          </Button>
          <Button data-cy="add-location" onClick={addLocation}>
            Add Location
          </Button>
          <Button data-cy="driver-history" onClick={driverHistory}>
            Driver History
          </Button>
        </ButtonColumn>
      )}
      <QuickReportModal
        setShowingQuickReportsModal={setShowingQuickReportsModal}
        showingQuickReportsModal={showingQuickReportsModal}
        asset={asset}
        // opening2Data={opening2Data}
      />
    </Container>
  )
}
export default CommandsTab
