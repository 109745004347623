import * as React from 'react'
import styled from 'styled-components/macro'
import InputErrorMessage from 'common/components/InputErrorMessage'

//An input meant to replace semantic input - in progress...

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string
  icon?: string
  suffix?: string
  className?: string
  disableAutoComplete?: boolean
  errors?: {
    [key: string]: {
      message: string
    }
  }
}

const Wrapper = styled.div`
  position: relative;
  font-style: normal;
  display: inline-flex;
  width: 100%;
`

const StyledInput = styled.input`
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 0.67857143em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
  &:disabled {
    background-color: var(--grey-300);
    opacity: 0.9;
  }
  &::placeholder {
    opacity: 0.7;
  }
`

const StyledLabel = styled.label``

const SuffixLabel = styled.label`
  position: absolute;
  line-height: 1;
  text-align: center;
  top: 0;
  right: 0;
  margin: 0;
  height: 100%;
  width: 2.67142857em;
  opacity: 0.5;
  border-radius: 0 0.28571429rem 0.28571429rem 0;
  padding-top: 2.65em;
  padding-right: 2em;
  &:before {
    left: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 100%;
    margin-top: -0.5em;
  }
`

const Input = ({
  name,
  label,
  onChange,
  errors,
  suffix,
  className,
  disableAutoComplete = false,
  ...rest
}: InputProps) => {
  if (!onChange) {
    //to get rid of react warning value without onchange
    onChange = () => ''
  }
  const inputOptions = disableAutoComplete ? {autoComplete: 'off'} : {}

  return (
    <Wrapper className={className}>
      {label && <StyledLabel htmlFor={`input${name}`}>{label}</StyledLabel>}
      <StyledInput
        id={`input${name}`}
        {...rest}
        onChange={onChange}
        type="text"
        {...inputOptions}
      ></StyledInput>
      {suffix && <SuffixLabel>{suffix}</SuffixLabel>}
      {errors && <InputErrorMessage message={errors[name]?.message} />}
    </Wrapper>
  )
}
export default Input
