import Axios from 'axios'

export const resetPassword = async email => {
  try {
    const encodedEmail = encodeURIComponent(email)
    const response = await Axios.get(
      `/users?a=resetpassword&email=${encodedEmail}&f=json`,
    )
    if (!response) {
      return 'error'
    }
    return 'success'
  } catch (error) {
    return 'error'
  }
}
