import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'
export type ServiceType = {
  Abbr: string
  Name: string
}
const getServiceTypes = async () => {
  const {data}: {data: ServiceType[]} = await Axios.get(
    `/rd/customer/servicetypes`,
  )
  const filteredData = data.filter(serviceType => {
    return serviceType.Abbr !== ''
  })
  const trimmedData = filteredData.map(serviceType => ({
    ...serviceType,
    Name: serviceType.Name.trim(),
  }))

  return trimmedData
}

export default function useCustomerServiceTypes(showAddOrdersDialog?: boolean) {
  if (showAddOrdersDialog === undefined) showAddOrdersDialog = true
  const user = useStore(state => state.user)
  return useQuery(['serviceTypes'], getServiceTypes, {
    enabled: Boolean(user?.token && showAddOrdersDialog),
  })
}
