import * as React from 'react'
import styled from 'styled-components/macro'

const Wrapper = styled.button`
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  background-color: ${(p: {active: boolean}) =>
    p.active ? '#c0c1c2' : '#e0e1e2'};
  box-shadow: ${(p: {active: boolean}) =>
    p.active && '0 0 0 1px transparent inset'};
  color: rgba(0, 0, 0, ${(p: {active: boolean}) => (p.active ? '.6' : '.95')});
  padding: 0.5em 1.2em 0.5em;
  text-transform: none;
  text-shadow: none;
  font-weight: 500;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0.28571429rem;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
  :hover {
    background-color: #cacbcd;
    background-image: none;
    box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgb(34 36 38 / 15%) inset;
    color: rgba(0, 0, 0, 0.8);
  }
  :active {
    background-color: #b1b1b1;
  }
`

const Button = ({
  onClick,
  children,
  active,
  id,
  disabled,
  ...rest
}: {
  children: React.ReactNode
  onClick: () => void
  active?: boolean
  id?: string
  disabled?: boolean
}) => {
  return (
    <Wrapper
      onClick={onClick}
      active={active || false}
      type="button"
      {...rest}
      id={id}
      disabled={disabled}
    >
      {children}
    </Wrapper>
  )
}
export default Button
