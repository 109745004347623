import * as React from 'react'
import styled from 'styled-components/macro'
import {Truck, User, MapPin} from 'phosphor-react'
import {Checkbox} from 'common/components'

const Container = styled.li`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 20px;
  border-bottom: 1px solid var(--asc-coolgray);
  cursor: pointer;
  &:hover {
    background: #f0f6e1;
    input {
      border: 1px solid var(--asc-moss);
      box-sizing: border-box;
      box-shadow: 0px 0px 6px 1px var(--secondary);
      border-radius: 5px;
    }
  }
`

const LeftSection = styled.div`
  display: flex;
  margin-top: 5px;
`

const Section = styled.div`
  font-size: 14px;
  width: ${props => (props.section === 'right' ? '30%' : '100%')};
`

const ProjectNameAndDates = styled.div`
  display: flex;
  width: 100%;
`

const ProjectName = styled.div`
  font-weight: 700;
`

const ProjectDescription = styled.div``

const Count = styled.div`
  font-weight: bold;
`

const AssetsCount = styled.div`
  display: flex;
  width: 100%;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  svg {
    margin: 0px 10px;
    margin-right: 15px;
  }
`

const ProjectCard = ({project, toggleProject, selectedProjects}) => {
  const [selected, setSelected] = React.useState(false)

  React.useEffect(() => {
    if (selectedProjects.includes(project.idGroup)) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }, [project.idGroup, selectedProjects])

  return (
    <Container onClick={toggleProject} data-projectid={project.idGroup}>
      <LeftSection>
        <Checkbox selected={selected} dataCy={'group-select'} />
      </LeftSection>
      <Section>
        <ProjectNameAndDates>
          <ProjectName>{project?.Group_Name || ''}</ProjectName>
        </ProjectNameAndDates>
        <ProjectDescription>
          {project?.Group_Description || ''}
        </ProjectDescription>

        {/* {group?.ProjectManager && <div>Manager: {group?.ProjectManager}</div>}
        {(startDate || endDate) && (
          <GroupDates>
            <CalendarBlank size={16} />
            {`${startDate} - ${endDate}`}
          </GroupDates>
        )} */}
      </Section>
      <Section section={'right'}>
        <AssetsCount>
          <Truck size={16} />
          <Count>{project?.Number_Of_Assets}</Count>
        </AssetsCount>

        <AssetsCount>
          <User size={16} />
          <Count>{project?.Number_Of_Users}</Count>
        </AssetsCount>

        <AssetsCount>
          <MapPin size={16} />
          <Count>{project?.Number_Of_Locations}</Count>
        </AssetsCount>
      </Section>
    </Container>
  )
}
export default ProjectCard
