import * as React from 'react'
import styled from 'styled-components/macro'
import AscendLogo from 'images/Ascend-Logo-No-Slogan.svg'
import TemedaLogo from 'images/temeda-logo-2020.png'
import {APP_FLAVOR} from 'common/constants'
import {Button} from 'semantic-ui-react'
import {Printer} from 'phosphor-react'
import useVINAudit from './hooks/useVINAudit'
import {useParams} from 'react-router-dom'
import {Spinner} from 'common/components'

const Container = styled.div`
  width: 100vw;
  height: 100%;
  overflow-y: scroll !important;
  overflow-x: hidden;
  @media print {
    overflow-y: hidden !important;
  }
`
const ErrorMessage = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    color: var(--asc-vermilion);
  }
`

const Header = styled.div`
  width: 100%;
  height: 84px;
  border-bottom: 1px solid var(--asc-coolgray);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 24px;
    font-weight: bold;
  }
  @media print {
    justify-content: space-around;
  }
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Title = styled.div`
  margin-top: 30px;
  span {
    font-size: 32px;
  }
  div {
    font-size: 20px;
    span {
      font-size: 20px;
      font-weight: bold;
    }
  }
`
const PrintButton = styled(Button)`
  &&& {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: var(--secondary);
    color: var(--primary);
    margin-right: 10px;
    :hover {
      background: var(--primary);
      color: var(--secondary);
    }
    svg {
      margin-right: 5px;
    }
    @media print {
      display: none;
    }
  }
`
const SubTitle = styled.div<{$marginTop?: string}>`
  margin-top: ${({$marginTop}) => $marginTop};
  font-size: 24px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
  @media print {
    display: none;
  }
`

const InformationContainer = styled.div`
  margin-top: 30px;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: row;
`

const WarrantiesContainer = styled.div`
  margin-top: 90px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--asc-stone);
  @media print {
    margin-top: 20px;
    margin-left: 20px;
  }
`
const WarrantiesRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 120px;
  justify-content: center;
  @media print {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`
const WarrantyBadge = styled.div`
  background: var(--primary);
  border-radius: 8px;
  color: white;
  width: 468px;
  text-align: center;
  padding: 60px;
  margin-left: 24px;
  span {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
  div {
    color: var(--secondary);
    font-size: 24px;
    span {
      font-size: 24px;
      color: white;
      margin: 0px 5px;
    }
  }
  :first-child {
    margin-left: 0px;
  }
  @media print {
    width: 268px;
    margin-left: 10px;
    background: transparent;
    color: var(--primary);
    border-left: 5px solid var(--primary);
    border-radius: 0px;
    padding: 10px;
    div {
      font-size: 16px;
      color: black;
      span {
        color: black;
        font-size: 16px;
      }
    }
  }
`

const TableContainer = styled.div`
  width: 20%;
  margin: 0px 20px;
  @media print {
    width: 50%;
  }
`

const Table = styled.table`
  width: 100%;
  text-align: left;
  font-size: 14px;
  border-collapse: collapse;
  tr:nth-child(even) {
    background-color: var(--asc-cultured);
  }
  tr:first-child {
    border-top: 1px solid var(--asc-coolgray);
  }
`
const Heading = styled.th`
  width: 50%;
  padding: 7px 10px;
  border-bottom: 1px solid var(--asc-coolgray);
`
const Cell = styled.td`
  border-bottom: 1px solid var(--asc-coolgray);
`

const RecallsContainer = styled.div`
  width: 100%;
  @media print {
    margin-left: 25px;
  }
`
const RecallSection = styled.div`
  width: 100%;
  :nth-child(odd) {
    background-color: var(--asc-cultured);
  }
  @media print {
    :nth-child(odd) {
      background-color: transparent;
    }
  }
`

const RecallInformation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 400px;
  height: fit-content;
  padding: 30px 0px;
  @media print {
    justify-content: flex-start;
  }
`
const VerticalBar = styled.div`
  width: 5px;
  background: var(--asc-gold);
`

const Summary = styled.div`
  margin-left: 35px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 600px;
  margin-right: 40px;
  span {
    margin-top: 20px;
  }
  div {
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    span {
      margin-right: 50px;
      margin-top: 0px;
    }
    div {
      margin-top: 20px;
    }
  }
  h6 {
    color: var(--asc-sonicsilver);
    font-weight: bold;
    font-size: 14px;
  }
  h5 {
    color: black;
    font-weight: bold;
    font-size: 14px;
    margin-right: 5px;
  }
  @media print {
    margin-left: 20px;
    margin-right: 20px;
    width: 400px;
    span {
      margin-top: 10px;
    }
    div {
      display: flex;
      flex-direction: row;
      margin-top: 0px;
      span {
        margin-right: 25px;
        margin-top: 0px;
      }
      div {
        margin-top: 10px;
      }
    }
  }
`

const RemedyInformationBox = styled.div`
  border: 1px solid var(--asc-coolgray);
  border-radius: 10px;
  width: 400px;
  padding: 20px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-self: center;
  h6 {
    color: var(--asc-sonicsilver);
    font-weight: bold;
    font-size: 14px;
  }
  @media print {
    padding: 10px;
    width: 300px;
  }
`
const Disclaimer = styled.div`
  width: 100%;
  border-top: 1px solid var(--secondary);
  padding-left: 200px;
  padding-right: 200px;
  margin-bottom: 30px;
  div {
    margin-top: 30px;
    span {
      font-weight: bold;
    }
  }
  @media print {
    padding-left: 25px;
    padding-right: 25px;
  }
`
/*For printing purposes*/
const GreenSeparator = styled.div`
  display: none;

  @media print {
    display: flex;
    width: 30px;
    height: 5px;
    background: var(--secondary);
    margin-left: 30px;
    margin-top: 55px;
  }
`

const PrintSubTitle = styled.div`
  display: none;
  @media print {
    display: flex;
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    color: var(--tertiary);
    margin-top: 15px;
    margin-left: 30px;
  }
`
const firstTable = [
  {title: 'Type', property: 'type'},
  {title: 'Size', property: 'size'},
  {title: 'Category', property: 'category'},
  {title: 'Made In', property: 'made_in'},
  {title: 'Made In City', property: 'made_in_city'},
  {title: 'Doors', property: 'doors'},
  {title: 'Fuel Type', property: 'fuel_type'},
  {title: 'Fuel Capacity', property: 'fuel_capacity'},
  {title: 'City Mileage', property: 'city_mileage'},
  {title: 'Highway Mileage', property: 'highway_mileage'},
  {title: 'Engine', property: 'engine'},
  {title: 'Engine Size', property: 'engine_size'},
  {title: 'Engine Cylinders', property: 'engine_cylinders'},
  {title: 'Transmission', property: 'transmission'},
  {title: 'Transmission Type', property: 'transmission_type'},
]
const secondTable = [
  {title: 'Transmission Speeds', property: 'transmission_speeds'},
  {title: 'Drivetrain', property: 'drivetrain'},
  {title: 'Anti-Lock Brake System', property: 'anti_brake_system'},
  {title: 'Steering Type', property: 'steering_type'},
  {title: 'Curb Weight', property: 'curb_weight'},
  {title: 'Gross Vehicle Weight', property: 'gross_vehicle_weight_rating'},
  {title: 'Overall Height', property: 'overall_height'},
  {title: 'Overall Length', property: 'overall_length'},
  {title: 'Overall Width', property: 'overall_width'},
  {title: 'Wheelbase Length', property: 'wheelbase_length'},
  {title: 'Standard Seating', property: 'standard_seating'},
  {title: 'Invoice Price', property: 'invoice_price'},
  {title: 'Delivery Charges', property: 'delivery_charges'},
  {title: 'MSRP', property: 'manufacturer_suggested_retail_price'},
]

const VINAuditReport = () => {
  const {vin} = useParams()
  const {data: vinAuditData, status} = useVINAudit(vin)

  const componentRef = React.useRef(null)

  return vin && status === 'loading' ? (
    <Spinner type="partial" />
  ) : status === 'success' && vinAuditData ? (
    <Container id="container" ref={componentRef}>
      <Header>
        <span>VIN Audit Report</span>
        <PrintButton onClick={() => window.print()}>
          <Printer weight="fill" />
          PRINT
        </PrintButton>
      </Header>
      <TitleContainer>
        <Title>
          <span>{`${vinAuditData.attributes.year} ${vinAuditData.attributes.make} ${vinAuditData.attributes.model} ${vinAuditData.attributes.trim}`}</span>
          <div>
            <span>VIN: </span>
            {`${vin}`}
          </div>
          <div>{`${vinAuditData.attributes.style}`}</div>
        </Title>
      </TitleContainer>
      <GreenSeparator />
      <PrintSubTitle>Vehicle Specifications</PrintSubTitle>
      <InformationContainer>
        <TableContainer>
          <Table>
            <tbody>
              {firstTable?.map((item, index) => (
                <tr key={index}>
                  <Heading>{item.title}</Heading>
                  <Cell>
                    {
                      vinAuditData.attributes[
                        item.property as keyof typeof vinAuditData.attributes
                      ]
                    }
                  </Cell>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
        <TableContainer>
          <Table>
            <tbody>
              {secondTable?.map((item, index) => (
                <tr key={index}>
                  <Heading>{item.title}</Heading>
                  <Cell>
                    {
                      vinAuditData.attributes[
                        item.property as keyof typeof vinAuditData.attributes
                      ]
                    }
                  </Cell>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </InformationContainer>
      <GreenSeparator />
      <PrintSubTitle>Warranties</PrintSubTitle>
      <WarrantiesContainer>
        <SubTitle $marginTop={'120px'}>Warranties</SubTitle>
        <WarrantiesRow>
          {vinAuditData.warranties.map((warranty, index: number) => (
            <WarrantyBadge key={index}>
              <span>{warranty.type}</span>
              <div>
                {warranty.months}
                <span>/</span>
                {warranty.miles}
              </div>
            </WarrantyBadge>
          ))}
        </WarrantiesRow>
      </WarrantiesContainer>
      <GreenSeparator />
      <PrintSubTitle>Recalls</PrintSubTitle>
      <RecallsContainer>
        <SubTitle $marginTop={'60px'}>Recalls</SubTitle>
        {vinAuditData.recalls.map((recall, index) => (
          <RecallSection key={index}>
            <RecallInformation>
              <VerticalBar />
              <Summary>
                <div>
                  {/* <span>
                    <h6>Date</h6>MM/DD/YYYY
                  </span> 
                  The data does not return a date property*/}
                  <span>
                    <h6>Campaign</h6>
                    {recall.campaign}
                  </span>
                  <span>
                    <h6>Components</h6>
                    {recall.components}
                  </span>
                </div>
                <span>
                  <h6>Summary</h6>
                  {recall.summary}
                </span>
                <span>
                  <h6>Consequence</h6>
                  {recall.consequence}
                </span>
                <div>
                  <div>
                    <h5>Source:</h5>
                    {recall.source}
                  </div>
                </div>
              </Summary>
              <RemedyInformationBox>
                <span>
                  <h6>Remedy</h6>
                  {recall.remedy}
                </span>
                <span>
                  <h6>Notes</h6>
                  {recall.notes}
                </span>
              </RemedyInformationBox>
            </RecallInformation>
          </RecallSection>
        ))}
      </RecallsContainer>
      <Disclaimer>
        <div>
          <span>Disclaimer: </span>The information used to compile this report
          is aggregated from various government agencies, non-profit
          organizations, and industry sources. Access to the National Motor
          Vehicle Title Information System (NMVTIS) is facilitated through
          VinAudit Inc., an Approved NMVTIS Data Provider. The accuracy and
          reliability of the information supplied depends primarily on the
          reporting sources, and all entities involved in compiling this report
          accept no liability for any errors or omissions. Furthermore, all
          warranties, expressed or implied, including any implied warranties of
          merchantability or fitness for a particular purpose are hereby
          disclaimed.
        </div>
      </Disclaimer>
    </Container>
  ) : (
    <ErrorMessage>
      <h1>An Error Occured While Loading The VIN Audit Report</h1>
      <h3>
        Please check the VIN number and try again, or contact support for
        assistance.
      </h3>
    </ErrorMessage>
  )
}

export default VINAuditReport
