import {useStore} from './useStore'

export const distanceOptions = [
  {key: 26, value: 26, text: 'Miles'},
  {key: 22, value: 22, text: 'Kilometers'},
]
export const speedOptions = [
  {key: 2, value: 2, text: 'Miles per Hour (mph)'},
  {key: 3, value: 3, text: 'Kilometers per Hour (kmh)'},
]
export const fuelOptions = [
  {key: 36, value: 36, text: 'Miles per Gallon (mpg)'},
  {key: 37, value: 37, text: 'Liters per 100 km (L/100km)'},
]
export const temperatureOptions = [
  {key: 12, value: 12, text: 'Farenheit (\u00B0F)'},
  {key: 11, value: 11, text: 'Celsius (\u00B0C)'},
]
export const volumeOptions = [
  {key: 33, value: 33, text: 'Gallons (G)'},
  {key: 29, value: 29, text: 'Liters (L)'},
]
export const pressureOptions = [
  {key: 39, value: 39, text: 'Pounds per square inch (psi)'},
  {key: 40, value: 40, text: 'Kilogram/square centimeter (kg/cm2)'},
  {key: 41, value: 41, text: 'Kilopascal (kpa)'},
]

export const getDistanceFormat = () => {
  const user = useStore.getState().userConfig
  return user?.Distance_UOM.toLowerCase() === 'km' ? 'kilometers' : 'miles'
}
