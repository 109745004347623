import {useQuery} from '@tanstack/react-query'
import axios from 'axios'

type CameraUsage = {
  liveStreamViewingMinutes: number
  liveStreamingDataUsageMB: number
  recordingStreamViewingMinutes: number
  recordingStreamingDataUsageMB: number
  recordingsUploadDataUsageMB: number
  serialNumber: string
  totalDataReceivedMB: number
  totalDataTransmittedMB: number
  uploadedEventsDataUsageMB: number
  videoEventsMonth: number
  videoEventsToday: number
}
const getCameraDataUsage = async (idAsset: number | null | undefined) => {
  if (!idAsset) return
  const {data} = await axios.get(`/camera/${idAsset}/data-usage`)
  return data as CameraUsage
}

export default function useCameraDataUsage(idAsset: number | null | undefined) {
  return useQuery(['cameraUsage', idAsset], () => getCameraDataUsage(idAsset), {
    enabled: idAsset ? true : false,
  })
}
