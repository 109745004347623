import * as React from 'react'
import styled from 'styled-components/macro'

const Icon = styled.i`
  pointer-events: none;
  cursor: default;
  position: absolute;
  line-height: 1;
  text-align: center;
  top: 12px !important;
  right: 5px !important;
  margin: 0;
  height: 100%;
  width: 2.67142857em;
  opacity: 0.5;
  border-radius: 0 0.28571429rem 0.28571429rem 0;
  transition: opacity 0.3s ease;
  font-size: 1em;
  display: inline-block;
  opacity: 1;
  margin: 0 0.25rem 0 0;
  width: 1.18em;
  height: 1em;
  font-style: normal;
  font-weight: 400;
  font-smoothing: antialiased;
  ::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: -0.64285714em 0 0 -0.64285714em;
    width: 1.28571429em;
    height: 1.28571429em;
    border-radius: 500rem;
    border: 0.2em solid rgba(0, 0, 0, 0.1);
  }
  ::after {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: -0.64285714em 0 0 -0.64285714em;
    width: 1.28571429em;
    height: 1.28571429em;
    animation: button-spin 0.6s linear;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: #767676 transparent transparent;
    border-style: solid;
    border-width: 0.2em;
    -webkit-box-shadow: 0 0 0 1px transparent;
    box-shadow: 0 0 0 1px transparent;
  }
`

const InputSpinner = () => {
  return <Icon data-cy="input-spinner" aria-hidden="true"></Icon>
}
export default InputSpinner
