import {useQueryClient, useMutation} from '@tanstack/react-query'
import axios from 'axios'

export interface AddDeviceType {
  Serial_Number_Displayed: string
  IMEI: string
  Order_Nbr: string
  Unique_ID_Source: number | null
  Item_Nbr: string
  Order_Price: string | null
  TMC_Part_Nbr: string
  SIMNBR: string
  Salesforce_Customer_ID: string
  DeviceModel: string
  Device_Notes: string
  idCustomer_Final: number
  idDevice_Final: number
  DeviceSwapType: number
  idDealer_Swappable: number
  idDevice_Type: number
  Device_Type?: string
  idCarrier: number
  idCustomer: number
  idDevice_Status?: number
  Device_Status_Description?: string
  MSISDN: string
}

export interface EditDeviceType extends AddDeviceType {
  idDevice: number
}

const putDevice = async (device: EditDeviceType) => {
  const {data} = await axios.put(`/devices`, device)
  return data
}

export default function usePutDevice() {
  const cache = useQueryClient()
  return useMutation(putDevice, {
    onError: (err, variables, previousValue) => {
      console.error(err, variables, previousValue)
    },
    // After success or failure, refetch the devices query
    onSettled: () => {
      cache.invalidateQueries([`devices`])
    },
  })
}
