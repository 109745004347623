import {useMutation, useQueryClient} from '@tanstack/react-query'
import axios from 'axios'
import Swal from 'sweetalert2'

const putUser = async users => {
  const {data} = await axios.put(`/users/${users.idUser}`, users)
  return data
}

export default function usePutUser() {
  const cache = useQueryClient()
  return useMutation(putUser, {
    onError: (err, variables, previousValue) => {
      console.error(err, variables, previousValue)
      Swal.fire(
        'Error',
        'There was an error saving this user. Please check that the email address is not already in use and try again.',
        'error',
      )
      cache.setQueryData(['users'], previousValue)
    },
    // After success or failure, refetch the invitees query
    onSettled: () => {
      cache.invalidateQueries(['users'])
    },
  });
}
