import * as React from 'react'
import styled from 'styled-components/macro'
import {format, parseISO} from 'date-fns'
import {Spinner} from 'common/components'
import {Warning} from 'phosphor-react'

const severityColors = {
  Warning: '--asc-gold',
  Critical: '--asc-vermilion',
  Information: '--asc-graphite',
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 20px;
  height: ${p => (p.isMobile ? '100%' : '208px')};
  margin-left: 5px;
`

const AlertCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: ${({severity}) => `1px solid var(${severityColors[severity]})`};
  box-sizing: border-box;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  margin-bottom: 20px;
`

const AlertHeader = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const AlertMessage = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`

const TimeStamp = styled.span`
  font-size: 13px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0.5px;
  color: var(--asc-sonicsilver);
  padding-top: 7px;
  margin-bottom: 10px;
`
const AlertName = styled.span`
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-bottom: 20px;
`
const AlertSeverity = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({severity}) => `var(${severityColors[severity]})`};
  font-weight: ;
  svg {
    fill: ${({severity}) => `var(${severityColors[severity]})`};
    margin-right: 5px;
  }
`

const AlertsTab = ({alertData, userConfig, isMobile}) => {
  if (!alertData) return <Spinner type="partial" />
  return (
    <Container isMobile={isMobile}>
      {alertData.map(alert => {
        const timestamp = alert.Event_Timestamp_Local
          ? parseISO(alert.Event_Timestamp_Local)
          : parseISO(alert.Created_On)
        return (
          <AlertCard
            key={alert.idAlert_Event}
            severity={alert.User_Alert_Defs_Severity_LevelName}
          >
            <AlertHeader>
              {alert.Alert_Type}
              <AlertSeverity
                severity={alert.User_Alert_Defs_Severity_LevelName}
              >
                <Warning weight="fill" />
                {alert.User_Alert_Defs_Severity_LevelName}
              </AlertSeverity>
            </AlertHeader>
            <TimeStamp>
              {`${format(timestamp, userConfig.Date_Format)} ${format(
                timestamp,
                'hh:mm a',
              )}`}
            </TimeStamp>
            <AlertName
              dangerouslySetInnerHTML={{
                __html: alert.User_Alert_Defs_Alert_Name,
              }}
            ></AlertName>
            <AlertMessage
              dangerouslySetInnerHTML={{
                __html: alert.Alert_Message,
              }}
            ></AlertMessage>
          </AlertCard>
        )
      })}
    </Container>
  )
}
export default AlertsTab
