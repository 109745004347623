/* eslint-disable max-lines */
import React from 'react'
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom'
import Opening from 'opening/Opening'
import ProtectedRoute from './ProtectedRoute'
import ManagementRoute from './ManagementRoute'
import Spinner from 'common/components/Spinner'
import Login from 'profileAdmin/Login'
import Forgot from 'profileAdmin/ForgotPassword'
import ResetPassword from 'profileAdmin/ResetPassword'
// import ScrollToTop from './ScrollToTop'
import FourOhFour from './FourOhFour'
import VINAuditReport from 'assets/VINAuditReport'
import {setFavicon} from './helpers'
import {APP_FLAVOR} from './constants'
import RoutingRoutes from 'routingAndDispatch/RoutingRoutes'
import Error from 'common/Error'
import CamerasList from 'cameras/CameraList'

const DriverSafetyDashboard = React.lazy(
  () => import('driverSafetyDashboard/DriverSafetyDashboard'),
)
const RunSchedule = React.lazy(() => import('reports/RunSchedule'))
const LocationDetail = React.lazy(() => import(`locations/LocationDetail`))
const GroupList = React.lazy(() => import(`groups/GroupsList`))
const GroupDetail = React.lazy(() => import(`groups/GroupDetail`))
const AssetDetail = React.lazy(() => import(`assets/AssetDetail`))
const AssetList = React.lazy(() => import(`assets/AssetList`))
const ProjectDetail = React.lazy(() => import(`projects/ProjectDetail`))
const DriverDetail = React.lazy(() => import(`drivers/DriverDetail`))
// const Analytics = React.lazy(() => import(`assetDashboard/analytics/Analytics`))
const Locations = React.lazy(() => import(`locations/LocationList`))
const ZoneList = React.lazy(() => import(`zones/ZoneList`))
const ZoneDetail = React.lazy(() => import(`zones/ZoneDetail`))
const DriverList = React.lazy(() => import(`drivers/DriverList`))
const SensorList = React.lazy(() => import(`sensors/SensorList`))
// const TimesheetList = React.lazy(() => import(`timesheets/TimesheetList`))
// const TimesheetDetails = React.lazy(() => import(`timesheets/TimesheetDetails`))
const CameraEventsList = React.lazy(() => import(`cameras/CameraEventsList`))
// const CameraEventDetail = React.lazy(
//   () => import(`cameraEvents/CameraEventDetail`),
// )
const CameraFootage = React.lazy(() => import(`cameras/CameraFootage`))
const CameraSettings = React.lazy(() => import(`cameras/CameraSettings`))
// const CameraLivelook = React.lazy(() => import(`../common/components/LiveLook`))

const AssetDashboard = React.lazy(() => import(`assetDashboard/AssetDashboard`))
const HistoryTrail = React.lazy(() => import(`historyTrail/HistoryTrail`))
const ProjectList = React.lazy(() => import(`projects/ProjectList`))
const UnassignedDevicesList = React.lazy(
  () => import(`unassignedDevices/UnassignedDevicesList`),
)
const UserList = React.lazy(() => import(`users/UserList`))
const UserDetail = React.lazy(() => import(`users/UserDetail`))
const ViewSchedules = React.lazy(() => import(`reports/ViewSchedules`))
// const RunReport = React.lazy(() => import(`reports/RunReport`))
const RunReportContainer = React.lazy(
  () => import(`reports/RunReportContainer`),
)
// const ScheduleReport = React.lazy(() =>
//   import(`reports/ScheduleReport`),
// )
// const GetDirections = React.lazy(() =>
//   import(`directions/GetDirections`),
// )
const MaintenanceRoutes = React.lazy(
  () => import(`maintenance/MaintenanceRoutes`),
)

const AlertList = React.lazy(() => import(`alerts/AlertList`))
const AlertDetail = React.lazy(() => import(`alerts/AlertDetail`))
const AlertEventsList = React.lazy(() => import(`alerts/AlertEventList`))

const AccountHierarchy = React.lazy(
  () => import(`account/hierarchy/AccountHierarchy`),
)
const AccountHierarchyChildType = React.lazy(
  () => import(`account/hierarchy/AccountHierarchyChildType`),
)
const AccountHierarchyChildTypeDetail = React.lazy(
  () => import(`account/hierarchy/AccountHierarchyChildTypeDetail`),
)
const AccountContacts = React.lazy(
  () => import(`account/contacts/AccountContacts`),
)
const AccountIntegrations = React.lazy(
  () => import(`account/integrations/AccountIntegrations`),
)
const AccountCustomzieView = React.lazy(
  () => import(`account/customizeViews/CustomizeViews`),
)
const AccountScorecardSettings = React.lazy(
  () => import(`account/scorecard/ScorecardSettingsPage`),
)
const SSOLanding = React.lazy(() => import(`whiteLabel/SSOLanding`))

const Home = React.lazy(() => import(`home/GridContainer`))

const Dashboard = React.lazy(() => import(`dashboard/Dashboard`))
const Logout = React.lazy(() => import(`profileAdmin/Logout`))
const PublicLogin = React.lazy(() => import(`whiteLabel/PublicLogin`))
const PublicForgotPassword = React.lazy(
  () => import(`whiteLabel/PublicForgotPassword`),
)
const PublicResetPassword = React.lazy(
  () => import(`whiteLabel/PublicResetPassword`),
)
const CommandConsole = React.lazy(() => import(`commandConsole/App`))
const FuelCardList = React.lazy(() => import(`fuelCards/FuelCardsList`))
const FuelCardTransactionsList = React.lazy(
  () => import(`fuelCards/transactions/TransactionsListView`),
)
const DriverUser = React.lazy(
  () => import('routingAndDispatch/users/DriverUser'),
)
// const SurfSightLiveFeed = React.lazy(
//   () => import(`cameraEvents/SurfSightLiveFeed`),
// )
const TripSharePublic = React.lazy(() => import(`tripshare/TripSharePublic`))
const InspectionList = React.lazy(
  () => import(`maintenance/inspections/InspectionList`),
)
const InspectionDetail = React.lazy(
  () => import(`maintenance/inspections/InspectionDetail`),
)
const InspectionTypeDetail = React.lazy(
  () => import(`maintenance/inspections/InspectionTypeDetail`),
)
const InspectionTypeList = React.lazy(
  () => import(`maintenance/inspections/InspectionTypeList`),
)
const InspectionTasks = React.lazy(() => import(`maintenance/tasks/TaskList`))
const InspectionTaskDetail = React.lazy(
  () => import(`maintenance/tasks/TaskDetail`),
)

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<Error />}>
      <Route path="/" element={<Login />}></Route>
      <Route path="login" element={<Login />} />
      <Route path="logout" element={<Logout />} />
      <Route
        path="map/:id?"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Map`}
          >
            <Opening />
          </ProtectedRoute>
        }
      />
      {/* <Route
        path="analytics"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Analytics`}
          >
            <Analytics />
          </ProtectedRoute>
        }
      /> */}
      <Route
        path="group"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} New Group`}
            restrictedAdminText="groups"
          >
            <GroupDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="groups/:id"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Group Detail`}
            restrictedAdminText="groups"
          >
            <GroupDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="groups"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Groups`}
            restrictedAdminText="groups"
          >
            <GroupList />
          </ProtectedRoute>
        }
      />
      <Route
        path="locations/:id"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Location Detail`}
            restrictedAdminText="locations"
          >
            <LocationDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="locations"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Locations`}
            restrictedAdminText="locations"
          >
            <Locations isRouteOptimization={false} />
          </ProtectedRoute>
        }
      />
      <Route
        path="location"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} New Location`}
            restrictedAdminText="locations"
          >
            <LocationDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="zones/:id"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Zone Detail`}
            restrictedAdminText="zones"
          >
            <ZoneDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="zones"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Zones`}
            restrictedAdminText="zones"
          >
            <ZoneList />
          </ProtectedRoute>
        }
      />
      <Route
        path="zone"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} New Zone`}
            restrictedAdminText="zones"
          >
            <ZoneDetail />
          </ProtectedRoute>
        }
      />

      <Route
        path="sensors"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Sensors`}
            restrictedAdminText="sensors"
          >
            <SensorList />
          </ProtectedRoute>
        }
      />
      {/* <Route
        path="cameraevents/:id"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Camera Event Detail`}
            restrictedAdminText="cameras"
          >
            <CameraEventDetail />
          </ProtectedRoute>
        }
      /> */}
      <Route
        path="cameras/list"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Cameras`}
            restrictedAdminText="cameras"
          >
            <CamerasList />
          </ProtectedRoute>
        }
      />
      <Route
        path="cameras/cameraevents"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Camera Events`}
            restrictedAdminText="cameras"
          >
            <CameraEventsList />
          </ProtectedRoute>
        }
      />
      <Route
        path="cameras/settings"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Camera Settings`}
            restrictedAdminText="cameras"
          >
            <CameraSettings />
          </ProtectedRoute>
        }
      />
      <Route
        path="cameras/settings/:id"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Camera Settings`}
            restrictedAdminText="cameras"
          >
            <CameraSettings />
          </ProtectedRoute>
        }
      />
      <Route
        path="camerafootage/:id"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Camera Footage`}
            restrictedAdminText="cameras"
          >
            <CameraFootage />
          </ProtectedRoute>
        }
      />
      {/* <Route
        path="livelook/:id"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Live Look`}
            restrictedAdminText="cameras"
          >
            <CameraLivelook />
          </ProtectedRoute>
        }
      /> */}
      {/* <Route
        path="timesheets"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Timesheets`}
            restrictedAdminText="timesheets"
          >
            <TimesheetList />
          </ProtectedRoute>
        }
      />
      <Route
        path="timesheetdetails"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Timesheet Details`}
            restrictedAdminText="timesheets"
          >
            <TimesheetDetails />
          </ProtectedRoute>
        }
      /> */}
      <Route
        path="project"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} New Project`}
            restrictedAdminText="projects"
          >
            <ProjectDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="projects/:id"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Project Detail`}
            restrictedAdminText="projects"
          >
            <ProjectDetail />
          </ProtectedRoute>
        }
      />

      <Route
        path="projects"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Projects`}
            restrictedAdminText="projects"
          >
            <ProjectList />
          </ProtectedRoute>
        }
      />
      <Route
        path="asset/:idDevice?"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} New Asset`}
            restrictedAdminText="assets"
          >
            <AssetDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="assets/:id"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Asset Detail`}
            restrictedAdminText="assets"
          >
            <AssetDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="assets"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Assets`}
            restrictedAdminText="assets"
          >
            <AssetList />
          </ProtectedRoute>
        }
      />

      <Route
        path="asset"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} New Asset`}
            restrictedAdminText="assets"
          >
            <AssetDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="devices"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Devices`}
            restrictedAdminText="unassignedDevices"
          >
            <UnassignedDevicesList />
          </ProtectedRoute>
        }
      />
      <Route
        path="drivers/:id"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Driver Detail`}
            restrictedAdminText="drivers"
          >
            <DriverDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="drivers"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Drivers`}
            restrictedAdminText="drivers"
          >
            <DriverList />
          </ProtectedRoute>
        }
      />

      <Route
        path="driver"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} New Driver`}
            restrictedAdminText="drivers"
          >
            <DriverDetail />
          </ProtectedRoute>
        }
      />

      <Route
        path="asset-dashboard/:id?"
        element={
          <ProtectedRoute
            showMainMenuHeader={false}
            title={`${APP_FLAVOR.toUpperCase()} Asset Dashboard`}
          >
            <AssetDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboards/driver-safety"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Driver Safety`}
            restrictedAdminText="restricted"
          >
            <DriverSafetyDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path={`driver/user`}
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Driver User Detail`}
            restrictedAdminText="users"
          >
            <DriverUser />
          </ProtectedRoute>
        }
      />
      <Route
        path="users/:id"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} User Detail`}
            restrictedAdminText="users"
          >
            <UserDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="users"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Users`}
            restrictedAdminText="users"
          >
            <UserList />
          </ProtectedRoute>
        }
      />
      <Route
        path="user"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} New User`}
            restrictedAdminText="users"
          >
            <UserDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="historytrail/:id/:selectedDates?"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} History Trail`}
          >
            <HistoryTrail />
          </ProtectedRoute>
        }
      />
      <Route
        path="historytrail"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} History Trail`}
          >
            <HistoryTrail />
          </ProtectedRoute>
        }
      />
      <Route
        path="reports/run"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Reports`}
          >
            <RunReportContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path="reports/saved"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Saved Reports`}
          >
            <ViewSchedules />
          </ProtectedRoute>
        }
      />
      <Route
        path="reports/schedule/:id"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Report Schedule`}
          >
            <RunSchedule />
          </ProtectedRoute>
        }
      />
      <Route
        path="maintenance/*"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Maintenance`}
          >
            <MaintenanceRoutes />
          </ProtectedRoute>
        }
      />

      <Route
        path="inspections/list"
        element={
          <ProtectedRoute showMainMenuHeader={true} title={`Inspections`}>
            <InspectionList />
          </ProtectedRoute>
        }
      />
      <Route
        path="inspections/:id"
        element={
          <ProtectedRoute showMainMenuHeader={true} title={`Inspections`}>
            <InspectionDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="inspections/inspection"
        element={
          <ProtectedRoute showMainMenuHeader={true} title={`Inspections`}>
            <InspectionDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="inspection-types"
        element={
          <ProtectedRoute showMainMenuHeader={true} title={`Inspection Types`}>
            <InspectionTypeList />
          </ProtectedRoute>
        }
      />
      <Route
        path="inspection-types/inspection-type"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`New Inspection Type`}
          >
            <InspectionTypeDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="inspection-types/:id"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`Edit Inspection Type`}
          >
            <InspectionTypeDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="inspections/tasks"
        element={
          <ProtectedRoute showMainMenuHeader={true} title={`Inspection Tasks`}>
            <InspectionTasks />
          </ProtectedRoute>
        }
      />
      <Route
        path="inspections/task"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`New Inspection Task`}
          >
            <InspectionTaskDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="inspections/tasks/:id"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`Edit Inspection Task`}
          >
            <InspectionTaskDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="alertevents"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Alert Events`}
          >
            <AlertEventsList />
          </ProtectedRoute>
        }
      />
      <Route
        path="alerts/:id"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Alert Detail`}
            restrictedAdminText="manage-alerts"
          >
            <AlertDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="alerts"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            restrictedAdminText="manage-alerts"
          >
            <AlertList />
          </ProtectedRoute>
        }
      />
      <Route
        path="alert"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Alert Detail`}
          >
            <AlertDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="hierarchy/:parentid/:childid"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Hierarchy Configure`}
          >
            <AccountHierarchyChildType />
          </ProtectedRoute>
        }
      />
      <Route
        path="hierarchyDetail/:parentlevel/:childid/"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Hierarchy Configure`}
          >
            <AccountHierarchyChildTypeDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="hierarchy/:new?"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Hierarchy`}
          >
            <AccountHierarchy />
          </ProtectedRoute>
        }
      />
      <Route
        path="contacts/:new?"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Contacts`}
            restrictedAdminText="account-contacts"
          >
            <AccountContacts />
          </ProtectedRoute>
        }
      />
      <Route
        path="integrations/:new?"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Integrations`}
            restrictedAdminText="account-integrations"
          >
            <AccountIntegrations />
          </ProtectedRoute>
        }
      />
      <Route
        path="customizeviews"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Customize Views`}
          >
            <AccountCustomzieView />
          </ProtectedRoute>
        }
      />
      <Route
        path="scorecardsettings"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Scorecard Settings`}
          >
            <AccountScorecardSettings />
          </ProtectedRoute>
        }
      />
      <Route
        path="dashboards/weekly-snapshot"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Weekly Snapshot`}
            restrictedAdminText="restricted"
          >
            <Home />
          </ProtectedRoute>
        }
      />
      <Route path="management/*" element={<ManagementRoute />} />
      <Route
        path="command-console/*"
        element={
          <ProtectedRoute showMainMenuHeader={false} title={`Command Console`}>
            <CommandConsole />
          </ProtectedRoute>
        }
      />
      <Route
        path="dashboards/:id"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Dashboard`}
            restrictedAdminText="restricted"
          >
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="fuel-cards/transactions"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Transactions`}
            restrictedAdminText="fuelCards"
          >
            <FuelCardTransactionsList />
          </ProtectedRoute>
        }
      />
      <Route
        path="fuel-cards/*"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Fuel Cards`}
            restrictedAdminText="fuelCards"
          >
            <FuelCardList />
          </ProtectedRoute>
        }
      />
      <Route
        path="vin-audit-report/:vin"
        element={
          <ProtectedRoute showMainMenuHeader={false} title={`VIN Audit Report`}>
            <VINAuditReport />
          </ProtectedRoute>
        }
      />
      <Route
        path="routing/*"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`Route Optimization`}
          >
            <RoutingRoutes />
          </ProtectedRoute>
        }
      />

      <Route
        path="halliburton/login"
        element={<PublicLogin hideForgotPassword={true} />}
      />
      <Route path="halliburton/ssologin" element={<SSOLanding />} />
      <Route
        path="halliburton"
        element={<PublicLogin hideForgotPassword={true} />}
      />
      <Route path="ssologin" element={<SSOLanding />} />
      <Route path="sany/ssologin" element={<SSOLanding />} />
      <Route path="sany/login" element={<PublicLogin />} />
      <Route path="sany/forgotPassword" element={<PublicForgotPassword />} />
      <Route path="sany/resetPassword" element={<PublicResetPassword />} />
      <Route path="sany" element={<PublicLogin />} />
      <Route path="assetManagement" element={<PublicLogin />} />
      <Route path="eams/login" element={<PublicLogin />} />
      <Route path="eams/forgotPassword" element={<PublicForgotPassword />} />
      <Route path="eams/resetPassword" element={<PublicResetPassword />} />
      <Route path="eams" element={<PublicLogin />} />
      <Route path="eams/devices" element={<PublicLogin />} />
      <Route path="amat/login" element={<PublicLogin />} />
      <Route path="amat/forgotPassword" element={<PublicForgotPassword />} />
      <Route path="amat/resetPassword" element={<PublicResetPassword />} />
      <Route path="amat" element={<PublicLogin />} />
      <Route path="trackmobile/login" element={<PublicLogin />} />
      <Route
        path="trackmobile/forgotPassword"
        element={<PublicForgotPassword />}
      />
      <Route
        path="trackmobile/resetPassword"
        element={<PublicResetPassword />}
      />
      <Route path="trackmobile" element={<PublicLogin />} />
      <Route path="forgot" element={<Forgot />} />
      <Route path="resetPassword" element={<ResetPassword />} />
      <Route
        path="opening.html"
        element={
          <ProtectedRoute
            showMainMenuHeader={true}
            title={`${APP_FLAVOR.toUpperCase()} Map`}
          >
            <Opening />
          </ProtectedRoute>
        }
      />
      <Route path="tripshare/:token" element={<TripSharePublic />} />

      <Route
        path=""
        element={
          APP_FLAVOR.toLowerCase() === 'att' ? (
            <Navigate to="/eams" />
          ) : (
            <Login />
          )
        }
      />
      <Route path="*" element={<FourOhFour />} />
    </Route>,
  ),
)

export default function Routes() {
  React.useEffect(() => {
    setFavicon()
    // setColorsBasedOnFlavor()
  }, [])
  return (
    <React.Suspense fallback={<Spinner />}>
      <RouterProvider router={router} />
    </React.Suspense>
  )
}
