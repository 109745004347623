import {endOfToday, format, startOfToday} from 'date-fns'
import {
  AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE,
  REPORTING_SERVER,
  APP_FLAVOR,
} from 'common/constants'
import {checkEmails} from 'reports/scheduleHelper'
import TemedaLogo from 'images/temeda-logo-2020.png'
import ATTLogo from 'images/att-logo-with-text.png'
export const reducer = (state, action) => {
  let newState = state
  let emailReplaceCommas
  switch (action.type) {
    case 'setState':
      newState = {...state, ...action.data, isDirty: true}
      newState.errors = getErrors(newState)
      return newState
    case 'setCleanState':
      newState = {...state, ...action.data}
      newState.errors = getErrors(newState)
      return newState
    case 'clearForm':
      return {...action.data, isDirty: false}
    case 'setEmails':
      //replace commas with new line
      emailReplaceCommas = action.data.replace(',', '\n')
      newState = {...state, emailList: emailReplaceCommas, isDirty: true}
      newState.errors = getErrors(newState)

      return newState
    case 'setAssetSelectionType': {
      newState = {
        ...state,
        ...action.data,
        labels: '',
        selectedAssets: [],
        selectedGroups: [],
        selectedDrivers: [],
        selectedProjects: [],
        selectedHierarchy: [],
        isDirty: true,
      }
      newState.errors = getErrors(newState)

      return newState
    }
    case 'setSelected':
      switch (action.data.type) {
        case 'asset':
          newState = {
            ...state,
            labels: action.data.labels,
            selectedAssets: action.data.ids,
            isDirty: true,
          }
          break
        case 'group':
          newState = {
            ...state,
            labels: action.data.labels,
            selectedGroups: action.data.ids,
            isDirty: true,
          }
          break
        case 'driver':
          newState = {
            ...state,
            labels: action.data.labels,
            selectedDrivers: action.data.ids,
            isDirty: true,
          }
          break
        case 'project':
          newState = {
            ...state,
            labels: action.data.labels,
            selectedProjects: action.data.ids,
            selectedLocations: [],
            isDirty: true,
          }
          break
        case 'hierarchy':
          newState = {
            ...state,
            labels: action.data.labels,
            selectedHierarchy: action.data.ids,
            isDirty: true,
          }
          break
        case 'location':
          newState = {
            ...state,
            locationNames: action.data.labels,
            selectedLocations: action.data.ids,
            isDirty: true,
          }
          break
        default:
          newState = {...state}
          break
      }
      newState.errors = getErrors(newState)
      return newState
    default:
      break
  }
}

export const reportsInitialState = {
  selectedReport: '[Select a report]',
  selectedAssets: [],
  selectedLocations: [],
  locationNames: '',
  selectedGroups: [],
  selectedDrivers: [],
  selectedProjects: [],
  labels: '',
  selectedHierarchy: [],
  selectedHierarchyLevel: [],
  selectedAlert: undefined,
  assetSelectionType: 'Asset',
  alertSelectionType: 'Type',
  alertName: 'All Alerts',
  selectedAlertType: 'All Alerts',
  proximity: 'Location',
  proximityAddress: '',
  proximityDistance: 1,
  reportFrequency: 'Once',
  durationHoursThreshold: 0,
  fuelCost: '3.33',
  dateRange: {
    startDate: startOfToday(),
    endDate: endOfToday(),
  },
  shiftReportingEnabled: false,
  shiftStartTime: {
    hour: 7,
    minute: 0,
    ampm: 'AM',
  },
  shiftEndTime: {
    hour: 4,
    minute: 0,
    ampm: 'PM',
  },
  speed: 75,
  speeding: 10,
  paginate: true,
  rpm: 1000,
  errors: {reportName: true, ids: true},
}

export const schedulesInitialState = {
  ...reportsInitialState,
  reportFormat: 'Excel',
  reportFrequency: 'Once',
  occurrence: 'Everyday',
  dailyDateRangeOption: 'PreviousDay',
  endDateType: 'None',
  sendTime: {
    hour: 1,
    minute: 0,
    ampm: 'AM',
  },
  weeklyOption: [
    {label: 'Sunday', value: 'Sun', active: false},
    {label: 'Monday', value: 'Mon', active: true},
    {label: 'Tuesday', value: 'Tue', active: false},
    {label: 'Wednesday', value: 'Wed', active: false},
    {label: 'Thursday', value: 'Thu', active: false},
    {label: 'Friday', value: 'Fri', active: false},
    {label: 'Saturday', value: 'Sat', active: false},
  ],
  reportDateRange: 'Today',
  startDate: format(new Date().setHours(0, 0, 0), 'MM/dd/yyyy'),
  // endDate: format(new Date().setHours(23, 59, 59), userConfig.Date_Format),
  monthlyOption: 'Date',
  monthlyWeeknum: '1st',
  monthlyWeekday: 'Mon',
  monthlyDate: 15,
  idUser: null,
  emailList: '',
  title: '',
  username: '',
  endoption: 'Date:' + format(new Date().setHours(23, 59, 59), 'yyyy-MM-dd'),
  idReport_Schedules: null,
  endDateCount: 6,
  editingReportType: false,
  isDirty: false,
}
export const getAppFlavorBrandingImage = userConfig => {
  let base = AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE.substring(
    8,
    AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE.length,
  )
  if (userConfig.Branding.Logo) {
    return `${base}/images/${userConfig.Branding.Logo}`
  } else {
    //azure blob linking directly because it seems to work better for pdf export
    return APP_FLAVOR === 'ascend'
      ? `${base}/images/branding_images/logo/7215_20221025_013909.png` //ascend logo
      : APP_FLAVOR === 'att'
      ? ATTLogo
      : TemedaLogo
  }
}

export const postReport = (formData, userConfig) => {
  formData.brandingImage = getAppFlavorBrandingImage(userConfig)

  var form = document.createElement('form')
  form.target = '_blank'
  form.setAttribute('method', 'post')
  form.setAttribute('action', REPORTING_SERVER)
  for (var i in formData) {
    var input = document.createElement('input')
    input.type = 'hidden'
    input.name = i
    input.value = formData[i]
    form.appendChild(input)
  }
  document.body.appendChild(form)
  form.submit()

  document.body.removeChild(form)
}

export const parseReportURL = (obj, reporturl) => {
  var pairs = reporturl.split('&')
  for (var i in pairs) {
    var split = pairs[i].split('=')
    obj[decodeURIComponent(split[0])] = decodeURIComponent(split[1])
  }
  return obj
}

export const getUOMs = uomArray => {
  const data = {}
  for (var key in uomArray) {
    var obj = uomArray[key]
    switch (obj.Fact_Type.toLowerCase()) {
      case 'velocity': {
        data.ihVelocity = obj.UOM_Abbreviation
        break
      }
      case 'distance': {
        data.ihDistance = obj.UOM_Abbreviation
        break
      }
      case 'fuelefficiency': {
        //Fuel usage report compares !== 'mpg' and sometimes authuser is 'MPG'
        data.ihFuelEconomy = obj.UOM_Abbreviation.toLowerCase()
        break
      }
      case 'volume': {
        data.ihVolume = obj.UOM_Abbreviation
        break
      }
      case 'temperature': {
        data.ihTemperature = obj.UOM_Abbreviation
        break
      }
      case 'pressure': {
        data.ihPressure = obj.UOM_Abbreviation
        break
      }
    }
  }
  return data
}

export const reportTypesOptions = selectedReport => {
  return [
    {
      label: 'Assets',
      value: 'Asset',
      disabled: selectedReport ? !selectedReport?.Assets_Select : true,
    },
    {
      label: 'Groups',
      value: 'Group',
      disabled: selectedReport ? !selectedReport?.Groups_Select : true,
    },
    {
      label: 'Projects',
      value: 'Project',
      disabled: selectedReport ? !selectedReport?.Projects_Select : true,
    },
    {
      label: 'Drivers',
      value: 'Driver',
      disabled: selectedReport ? !selectedReport?.Drivers_Select : true,
    },
    {
      label: 'Hierarchy',
      value: 'Hierarchy',
      disabled: selectedReport ? !selectedReport?.Assets_Select : true,
    },
    {
      label: 'Company',
      value: 'Company',
      disabled: selectedReport ? !selectedReport?.Assets_Select : true,
    },
  ]
}

const currentReportType = selectedReport => {
  switch (selectedReport) {
    case 'Asset':
      return 'Assets'
    case 'Group':
      return 'Groups'
    case 'Project':
      return 'Projects'
    case 'Driver':
      return 'Drivers'
    case 'Hierarchy':
      return 'Hierarchy'
    case 'Company':
      return 'Company'
    default:
      'Assets'
  }
}

const switchReportType = (current, selectedReport) => {
  switch (current) {
    case 'Assets':
      return selectedReport.Assets_Select === true
    case 'Groups':
      return selectedReport.Groups_Select === true
    case 'Projects':
      return selectedReport.Projects_Select === true
    case 'Drivers':
      return selectedReport.Drivers_Select === true
    default:
      return false
  }
}

const getReportType = (current, reportType) => {
  if (current === 'Hierarchy' && reportType.Assets_Select === true)
    return 'Hierarchy'
  else if (current === 'Company' && reportType.Assets_Select === true)
    return 'Company'
  else if (current === 'Asset' && reportType.Assets_Select === true)
    return current
  else if (current === 'Group' && reportType.Groups_Select === true)
    return current
  else if (current === 'Project' && reportType.Projects_Select === true)
    return current
  else if (current === 'Driver' && reportType.Drivers_Select === true)
    return current
  else if (reportType.Assets_Select === true) return 'Asset'
  else if (reportType.Groups_Select === true) return 'Group'
  else if (reportType.Projects_Select === true) return 'Project'
  else if (reportType.Drivers_Select === true) return 'Driver'
  else if (
    reportType.Assets_Select === false &&
    reportType.Groups_Select === false &&
    reportType.Projects_Select === false &&
    reportType.Drivers_Select === false
  )
    return 'Company'
}

export const getReportChangeData = (assetSelectionType, newReport) => {
  const current = currentReportType(assetSelectionType)
  const isCurrentType = switchReportType(current, newReport)
  const assetTypeValue = getReportType(assetSelectionType, newReport)

  return {
    currentAssetType: current,
    isCurrentAssetType: isCurrentType,
    selectionType: assetTypeValue,
  }
}

export const isJson = str => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const getLabels = (ids, lists, idField, labelField) => {
  const allLabels = ids?.map(id => {
    const item = lists.find(item => item[idField] === id)
    if (item) {
      return item[`${labelField}`]
    } else {
      return ''
    }
  })

  let labelString = allLabels
    ?.map(label => label.replace(/,/g, '&#44;'))
    .join(', ')
  if (allLabels?.length > 50) {
    labelString = 'More than 50 items selected'
  }
  return labelString
}

export const convertFormDataToLogiFormat = ({
  state,
  user,
  uoms,
  userConfig,
}) => {
  const convertedUOMs = getUOMs(uoms)
  const {
    selectedReport,
    labels,
    assetSelectionType,
    selectedAssets,
    selectedLocations,
    locationNames,
    selectedGroups,
    selectedProjects,
    selectedDrivers,
    selectedHierarchy,
    paginate,
    shiftReportingEnabled,
    fuelCost,
    dateRange,
    proximity,
    proximityDistance,
    proximityAddress,
    selectedAlert,
    alertSelectionType,
    selectedAlertType,
    speed,
    speeding,
    shiftStartTime,
    shiftEndTime,
    selectedHierarchyLevel,
    rpm,
    durationHoursThreshold,
  } = state
  const dateFormat =
    userConfig?.Date_Format === 'd/M/yyyy'
      ? 'dd/MM/yyyy'
      : userConfig?.Date_Format === 'yyyy/M/d'
      ? 'yyyy-MM-dd'
      : 'MM/dd/yyyy'
  const inpStartDate = format(dateRange.startDate, 'MM/dd/yy+HH:mm')
  const inpEndDate = format(dateRange.endDate, 'MM/dd/yy+HH:mm')
  const inpStartDateDisp = format(dateRange.startDate, `${dateFormat} hh:mm a`)
  const inpEndDateDisp = format(dateRange.endDate, `${dateFormat} hh:mm a`)

  const safeNames = labels.replaceAll(/'/g, '&#8217;')

  const logiFormat = {
    rdReport: selectedReport?.Report_Name,
    names: safeNames,
    irbAssetSelOptions: assetSelectionType,
    islReport2Run: selectedReport?.Report_Name,
    idUser: userConfig.idUser,
    brandingImage: `${AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE}/images/${userConfig?.Branding?.Logo}`,
    dateselect: selectedReport?.Date_Select,
    rowCount: 100000,
    divisionName: '',
    islDateRange: 'Today',
    inpStartDate: inpStartDate,
    inpEndDate: inpEndDate,
    inpStartDateDisp: inpStartDateDisp,
    inpEndDateDisp: inpEndDateDisp,
    inpShiftEnabled: 0,
    inpShiftStart: convertTimeSubmitFormat(shiftStartTime),
    inpShiftEnd: convertTimeSubmitFormat(shiftEndTime),
    username: userConfig.Email_Address,
    token: 'Basic ' + user.token,
    ihVelocity: convertedUOMs.ihVelocity,
    ihDistance: convertedUOMs.ihDistance,
    ihFuelEconomy: convertedUOMs.ihFuelEconomy,
    ihVolume: convertedUOMs.ihVolume,
    ihPressure: convertedUOMs.ihPressure,
    ihTemperature: convertedUOMs.ihTemperature,
    dateTimeFormat: `${dateFormat.toUpperCase()} hh:mm A `,
    fuelCost: fuelCost,
    itMPG: '',
  }

  switch (assetSelectionType.toLowerCase()) {
    case 'asset':
      logiFormat.ids = selectedAssets.join()
      break
    case 'group':
      logiFormat.ids = selectedGroups.join()
      break
    case 'project':
      logiFormat.ids = selectedProjects.join()
      break
    case 'driver':
      logiFormat.ids = selectedDrivers.join()
      break
    case 'hierarchy':
      logiFormat.ids = selectedHierarchy.join()
      break
    case 'company':
      logiFormat.ids = 'Company'
      logiFormat.names = 'Company'
      break
  }

  if (selectedReport?.Paginate && paginate) logiFormat.rowCount = 50
  if (selectedReport?.Alert_Select) {
    logiFormat.alertSelectionType = alertSelectionType
    if (alertSelectionType === 'One') {
      logiFormat.alerts = JSON.parse(selectedAlert).idUser_Alert_Def
      logiFormat.alertName = JSON.parse(selectedAlert).AlertName
    } else {
      if (selectedAlertType !== 'All Alerts') {
        logiFormat.alertName = JSON.parse(selectedAlertType).Alert_Name
        logiFormat.alerts = JSON.parse(selectedAlertType).idAlert
      } else {
        logiFormat.alertName = 'All Alerts'
      }
    }
  }
  if (selectedReport?.Speed_Select) {
    logiFormat.speed = speed
    logiFormat.speeding = speeding
  }

  if (selectedReport?.Location_Select) {
    logiFormat.irbPlace = 'Location'
    logiFormat.itDistance = 1
    logiFormat.location = selectedLocations.join(',')
    logiFormat.locationName = locationNames
  }
  if (
    (selectedReport?.Proximity_Select && proximity === 'Location') ||
    (selectedReport?.Location_Select && !selectedReport.Proximity_Select)
  ) {
    logiFormat.location = selectedLocations.join(',')
    logiFormat.locationName = locationNames
  }
  if (selectedReport?.Location_Select && selectedReport?.Location_Multiple) {
    logiFormat.location = selectedLocations.join(',')
    logiFormat.locationName = locationNames
  }
  if (
    selectedReport?.Report_Name === 'FuelUsage' ||
    selectedReport?.Report_Name === 'FuelUsagePerJobSite'
  ) {
    logiFormat.fuelCost = fuelCost
  }
  if (selectedReport?.Proximity_Select && !selectedReport?.Location_Multiple) {
    logiFormat.irbPlace = proximity
    logiFormat.itDistance = Number(proximityDistance)
    if (proximity === 'Location') {
      logiFormat.location = selectedLocations[0]?.toString()
      logiFormat.locationName = locationNames
    } else if (proximity === 'Address') {
      logiFormat.location = proximityAddress
      logiFormat.locationName = proximityAddress
    }
  }

  if (assetSelectionType === 'Hierarchy') {
    logiFormat.divisionName = selectedHierarchyLevel?.text
    logiFormat.divisionLevel = selectedHierarchyLevel.value
  }
  if (selectedReport?.Report_Name === 'IdleRPMReport') {
    logiFormat.rpm = rpm
  }
  if (shiftReportingEnabled === true && selectedReport?.Shift_Select) {
    logiFormat.inpShiftEnabled = 1
  }
  if (
    selectedReport?.Report_Name === 'HalliburtonLocationDuration' ||
    selectedReport?.Report_Name === 'LocationDuration'
  ) {
    logiFormat.durationHoursThreshold = durationHoursThreshold
  }

  return logiFormat
}

export const getErrors = newState => {
  let errors = {}
  //report name
  if (!newState.selectedReport?.Report_Name) {
    errors.reportName = true
  }
  //id required
  if (!newState.labels?.length && newState.assetSelectionType !== 'Company') {
    errors.ids = true
  }
  //driver safety scorecard and detail speed threshold
  if (newState.selectedReport?.Speed_Select) {
    if (!newState.speed) {
      errors.speed = true
    }
    if (!newState.speeding) {
      errors.speeding = true
    }
  }

  //location required

  if (
    newState.selectedReport?.Location_Select &&
    !newState.selectedReport?.Proximity_Select &&
    !newState.selectedLocations?.length
  ) {
    errors.location = true
  }
  //2 fuel usage reports
  if (
    newState.selectedReport?.Report_Name === 'FuelUsage' &&
    !newState.fuelCost
  ) {
    errors.fuelCost = true
  }
  //rpm for idle report
  if (
    newState.selectedReport?.Report_Name === 'IdleRPMReport' &&
    (newState.rpm === '' || newState.rpm === undefined)
  ) {
    errors.rpm = true
  }
  //proximity miles address
  if (newState.selectedReport?.Proximity_Select) {
    if (
      newState.proximityDistance === '' ||
      newState.proximityDistance === undefined
    ) {
      errors.proximity = true
    }
    if (
      newState.proximity === 'Address' &&
      (newState.proximityAddress === '' ||
        newState.proximityAddress === undefined)
    ) {
      errors.proximity = true
    }
    if (newState.proximity === 'Location' && newState.locationNames === '') {
      errors.proximity = true
    }

    //proximity miles location
    if (
      newState.selectedReport?.Proximity_Select &&
      newState.proximity === 'Location' &&
      newState.locationNames === ''
    ) {
      errors.proximity = true
    }
  }

  if (
    newState.selectedReport?.Report_Name === 'FuelUsage' ||
    newState.selectedReport?.Report_Name === 'FuelUsagePerJobSite'
  ) {
    if (!newState.fuelCost) {
      errors.fuelCost = true
    }
  }
  if (
    newState.selectedReport?.Report_Name === 'LocationDuration' ||
    newState.selectedReport?.Report_Name === 'HalliburtonLocationDuration'
  ) {
    if (!newState.durationHoursThreshold) {
      errors.durationHours = true
    }
  }

  if (newState.reportFrequency !== 'Once') {
    //schedule errors
    //title
    if (!newState.title) {
      errors.title = true
    }
    //email list
    if (!newState.emailList) {
      errors.emailList = true
    } else {
      const {isValid, ordinalNumberEmail} = checkEmails(newState.emailList)
      if (!isValid) {
        errors.emailListInvalid = ordinalNumberEmail
      }
    }
    //weekly weekday selection
    if (newState.reportFrequency === 'Weekly') {
      const occurrence = newState.weeklyOption
        ?.filter(item => item.active)
        .map(item => item.value)
      if (!occurrence?.length) {
        errors.weeklyOption = true
      }
    }
    //monthly # of every month
    if (
      newState.reportFrequency === 'Monthly' &&
      newState.Option === 'Date' &&
      !newState.monthlyDate
    ) {
      errors.monthlyDate = true
    }
    //reports remaining
    if (newState.endDateType === 'Count' && !newState.endDateCount) {
      errors.endDateCount = true
    }
  }

  return errors
}

const convertHoursTo24Clock = timeObject => {
  const newTimeObject = {...timeObject}
  if (timeObject.ampm === 'PM' && timeObject.hour < 12) {
    newTimeObject.hour += 12
  } else if (timeObject.ampm === 'AM' && timeObject.hour === 12) {
    newTimeObject.hour -= 12
  }
  return newTimeObject
}

export const convertTimeSubmitFormat = timeObject => {
  if (timeObject) {
    const newTimeObject = convertHoursTo24Clock(timeObject)
    const hours = newTimeObject?.hour.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
    })
    const minutes = newTimeObject?.minute.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
    })
    return `${hours}:${minutes}:00`
  } else {
    return ''
  }
}

export const formatTimeObjectToUserTimeFormat = (
  timeObject,
  timeFormat = '12HR',
) => {
  let newTimeObject = convertHoursTo24Clock(timeObject)
  if (timeFormat === '12HR') {
    newTimeObject = convertHoursTo24Clock(timeObject)
  }
  const hours = newTimeObject?.hour.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
  })
  const minutes = newTimeObject?.minute.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
  })
  return `${hours}:${minutes} ${timeObject.ampm}`
}
