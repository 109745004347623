import * as React from 'react'
import styled from 'styled-components/macro'
import {Form, Dropdown} from 'semantic-ui-react'
import {StyledLabel} from './StyledComponents'
import InputErrorMessage from './InputErrorMessage'
import {PlanStatusOption} from 'common/types/maintenanceTypes'

export type Option = {
  key: number | string
  value: number | string
  text: string | JSX.Element
}
type Errors = {
  [key: string]: {
    message: string
  }
}

export type SemanticInputType =
  | string
  | number
  | boolean
  | (string | number | boolean)[]
  | null

export type InputDropdownOptionalProps = {
  options?: Option[] | PlanStatusOption[]
  name: string
  label: string
  disabled?: boolean
  placeholder?: string
  clearable?: boolean
  required?: boolean
  search?: boolean
  loading?: boolean
  onChange: ({name, value}: {name: string; value: SemanticInputType}) => void
  value: SemanticInputType
  errors?: Errors
  className?: string
}

const RequiredAsterisk = styled.span`
  margin-left: 5px;
  ::after {
    content: '*';
    font-size: 0.9rem;
    color: var(--asc-vermilion);
  }
`

const StyledForm = styled(Form.Field)`
  &&& {
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 120px;
    display: flex;
    margin-bottom: 16px;
    > div {
      border: 1px solid var(--asc-coolgray);
      :focus {
        box-shadow: 0px 0px 6px 1px var(--asc-coolgray) !important;
        border: 1px solid var(--tertiary) !important;
      }
    }
    .ui.selection.active.dropdown .menu {
      border: 1px solid var(--tertiary) !important;
      border-top: 0px !important;
    }
    .ui.selection.active.dropdown {
      border: 1px solid var(--tertiary) !important;
      border-bottom: 0px !important;
      box-shadow: 0px 0px 6px 1px var(--asc-coolgray) !important;
    }
  }

  .dd-header {
    opacity: 0.85 !important;
    border: 1px solid var(--grey-300);
    :hover {
      background-color: white !important;
    }
    span {
      opacity: 0.85;
      color: black !important;
      font-weight: bold;
      margin-left: -8px;
    }
  }
`

const DropdownOptional = ({
  options,
  name,
  label,
  disabled,
  clearable = true,
  required = false,
  placeholder,
  onChange,
  value,
  errors = {},
  search,
  className,
  loading,
}: InputDropdownOptionalProps) => {
  return (
    <StyledForm className={className}>
      <StyledLabel htmlFor={name}>
        {label}
        {required && <RequiredAsterisk />}
      </StyledLabel>
      <Dropdown
        data-cy={`${name}-dropdown`}
        fluid
        selection
        clearable={clearable}
        selectOnBlur={false}
        disabled={disabled}
        aria-label={label}
        name={name}
        id={name}
        placeholder={placeholder || '---Select---'}
        options={options || []}
        value={value !== undefined && value !== null ? value : ''}
        search={search}
        data-lpignore="true"
        loading={loading}
        onChange={(e, {value}) => {
          if (value !== undefined) onChange({name, value})
        }}
      />
      {errors && <InputErrorMessage message={errors[name]?.message} />}
    </StyledForm>
  )
}
export default DropdownOptional
