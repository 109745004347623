import styled from 'styled-components/macro'
import {ArrowLeft, SpinnerGap} from 'phosphor-react'
import {QUERIES} from 'common/constants'
import IconButton from 'common/components/IconButton'
import {ActiveTabType} from './InfoWindow'

export const InfoWindowContainer = styled.div`
  background-color: white;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  cursor: initial;
  font-family: 'Open Sans';
  position: relative;
  width: 100vw;
  height: 450px;
  @media ${QUERIES.tabletAndUp} {
    position: absolute;
    left: ${({left}: {left?: string; bottom?: string}) =>
      left ? left : '-368px'};
    bottom: ${({bottom}) => (bottom ? bottom : '52px')};
    width: 706px;
    height: 304px;
    border-radius: 0 8px 8px 8px;
  }
`

export const InfoWindowLabelRow = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
export const CloseButton = styled.div`
  top: 20px;
  right: 20px;
  cursor: pointer;
  svg {
    color: var(--primary);
  }
  &:hover {
    svg {
      fill: var(--secondary);
    }
  }
`

export const InfoWindowArrow = styled.div`
  border-top-width: 24px;
  position: absolute;
  left: 250px;
  bottom: 0px;
  .left {
    position: absolute;
    overflow: hidden;
    left: 100px;
    bottom: -30px;
    width: 16px;
    height: 30px;
    div {
      position: absolute;
      left: 6px;
      background-color: rgb(255, 255, 255);
      transform: skewX(22.6deg);
      transform-origin: 0px 0px 0px;
      height: 24px;
      width: 10px;
      box-shadow: rgba(0, 0, 0, 0.6) 0px 1px 6px;
    }
  }
  .right {
    position: absolute;
    overflow: hidden;
    bottom: -30px;
    left: 116px;
    width: 16px;
    height: 30px;
    div {
      position: absolute;
      left: 0px;
      background-color: rgb(255, 255, 255);
      transform: skewX(-22.6deg);
      transform-origin: 10px 0px 0px;
      height: 24px;
      width: 10px;
      box-shadow: rgba(0, 0, 0, 0.6) 0px 1px 6px;
    }
  }
`

export const Header = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  border-bottom: 1px solid #d2d2d7;
  padding: 15px 20px;
  height: 88px;
`

export const ColorCorner = styled.div`
  width: 0;
  height: 0;
  display: none;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid ${({iconColor}: {iconColor: string}) => iconColor};
  transform: rotate(135deg);
  position: absolute;
  top: -2px;
  left: -10px;
  @media ${QUERIES.tabletAndUp} {
    display: block;
  }
`

export const HeaderAssetLabel = styled.h3`
  font-weight: 300;
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;
`
export const HeaderLabel = styled.div<{
  $divHeight: string
}>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 700;
  font-size: 20px;
  max-width: 525px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({$divHeight}) => $divHeight};
  svg {
    min-width: 20px;
  }
`
export const HeaderTitleAndButton = styled.div`
  display: flex;
  align-items: center;
`

export const SubHeader = styled.div`
  background-color: var(--asc-cultured);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid var(--asc-coolgray);
  @media ${QUERIES.tabletAndUp} {
    justify-content: space-between;
  }
`

export const TabContent = styled.div`
  min-height: 200px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  height: 40px;
`

export const TimeStamp = styled.span`
  font-size: 13px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0.5px;
  color: var(--asc-dimgray);
  display: flex;
  align-items: center;
  margin-left: ${({tab}: {tab: ActiveTabType}) =>
    tab !== 'asset' ? '30px' : '0'};
`

export const EquipmentID = styled.span`
  font-size: 13px;
  line-height: 13px;
  color: var(--geoforce-dark-blue);
  padding-bottom: 6px;
`

// export const TabTitle = styled.span`
//   font-weight: 700;
//   font-size: 20px;
//   line-height: 28px;
//   margin-right: 10px;
// `
// export const TabHeader = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: baseline;
//   gap: 15px;
//   width: 100%;
// `
export const TabAsset = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--asc-sonicsilver);
  white-space: nowrap;
  margin-left: 10px;
  max-width: 60vw;
  margin-right: 5px;
`
export const BackIcon = styled(ArrowLeft)`
  cursor: pointer;
  display: inline;
  margin-right: 10px;
`
export const VerticalBarSeparator = styled.span`
  margin-right: 7px;
  margin-left: 7px;
  color: var(--asc-dimgray);
`
export const TimeAgo = styled.span`
  text-align: right;
  font-size: 13px;
  font-weight: 700;
  color: var(--asc-dimgray);
`

export const LiveLook = styled.button<{
  disabled?: boolean
  fontOnSecondary?: string
  iconColor?: string
}>`
  outline: none;
  border: 1px solid var(--primary);
  border-radius: 5px;
  background: none;
  width: 115px;
  margin-right: 15px;
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  font-size: 13px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  color: var(--primary);
  padding: 5px 0;
  svg {
    color: ${({iconColor}: {iconColor?: string}) => iconColor};
    margin-right: 10px;
  }
  cursor: ${({disabled}: {disabled?: boolean}) =>
    disabled ? 'not-allowed' : 'pointer'};
  &:hover {
    /* box-shadow: inset 0 -5px var(--secondary); */
    background: ${({disabled}: {disabled?: boolean}) =>
      !disabled && 'var(--secondary)'};
    color: ${({fontOnSecondary}: {fontOnSecondary?: string}) =>
      fontOnSecondary} !important;
    border: ${({disabled}: {disabled?: boolean}) =>
      !disabled && '2px solid var(--secondary)'};
    svg {
      color: ${({fontOnSecondary}: {fontOnSecondary?: string}) =>
        fontOnSecondary} !important;
    }
  }
`
export const HeaderDetails = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const SpinSpinnerGap = styled(SpinnerGap)`
  @keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
  animation: spin-animation 1.5s infinite;
  animation-timing-function: linear;
  display: inline-block;
`

export const DesktopIconButton = styled(IconButton)`
  display: none;
  @media ${QUERIES.tabletAndUp} {
    display: flex;
  }
`
