import * as React from 'react'
import styled from 'styled-components/macro'
import {Dropdown} from 'semantic-ui-react'
import {useStore} from 'common/useStore'
import {useQueryClient} from '@tanstack/react-query'
import {uploadFromTemplate} from './bulkLocationImportHelpers'

const Container = styled.div`
  &&& {
    margin-left: 10px;
    margin-top: 1px;

    .button {
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;
      line-height: 16px;
      font-size: 13px;
      border: solid 1px var(--primary);
      color: var(--primary);
      background-color: #fff;
      align-items: center;
      .text {
        margin-right: 10px;
        letter-spacing: 0.5px;
      }
    }
    .item {
      display: flex;
      justify-content: flex-start;
    }
  }
`

const BulkLocationImport = ({
  setLoading,
}: {
  setLoading: (loading: boolean) => void
}) => {
  const {useRef} = React
  const uploadInputRef = useRef<HTMLInputElement>(null)
  const uploadFormRef = useRef<HTMLFormElement>(null)
  const userConfig = useStore(state => state.userConfig)
  const cache = useQueryClient()

  const downloadTemplate = () =>
    window.open('/files/BulkLocationImportTemplate.xlsx', '_blank')

  const triggerUpload = () => {
    const input = uploadInputRef.current
    if (input) {
      input.click()
    }
  }

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    uploadFromTemplate({
      event,
      userConfig,
      uploadFormRef,
      cache,
      setLoading,
    })
  }

  return (
    <Container>
      <Dropdown button text="Import locations">
        <Dropdown.Menu>
          <form ref={uploadFormRef}>
            <input
              accept=".xlsx"
              type="file"
              style={{display: 'none'}}
              ref={uploadInputRef}
              onChange={handleUpload}
            />
          </form>
          <Dropdown.Item
            icon="download"
            text="Download Template"
            onClick={downloadTemplate}
          />
          <Dropdown.Item
            icon="upload"
            text="Import From Template"
            onClick={triggerUpload}
          />
        </Dropdown.Menu>
      </Dropdown>
    </Container>
  )
}
export default BulkLocationImport
