import * as React from 'react'
import {Segment} from 'routingAndDispatch/types/typesModule'
import {Clock, FlagCheckered, House, CheckCircle, Truck} from 'phosphor-react'
import {eachDayOfInterval, format, isSameDay, parseISO} from 'date-fns'
import {useStore} from 'common/useStore'
import {
  // DashedLineGreen,
  EndPoint,
  EndPointColumn,
  HomeColumn,
  Address,
  TimeText,
  // DashedLineWhite,
  DropdownColumn,
  Spacer,
  TimeColumn,
  TimeRow,
  // DashedLineGray,
  DifferentDayColumn,
} from '../routeDetailStyles'
import StopCardDropdown from './StopCardDropdown'
import ServiceTypeBadge from 'routingAndDispatch/common/ServiceTypeBadge'
import {StopReducerAction} from 'routingAndDispatch/Stops/stopHelper'
import {isValidDate} from 'common/helpersDateTime'
import {Tooltip} from 'common/components'

interface StopCardProps {
  segment: Segment | null
  type: 'start' | 'end'
  planStartDateTime: Date | undefined
  planEndDateTime: Date | undefined
  idPlan: string
  refetch: () => void
  routeDetailDispatch: (action: StopReducerAction) => void
}

const EndPointCard: React.FC<StopCardProps> = ({
  segment,
  planStartDateTime,
  planEndDateTime,
  idPlan,
  type,
  refetch,
  routeDetailDispatch,
}) => {
  const userConfig = useStore(state => state.userConfig)
  const index =
    type === 'start' ? 0 : (segment && segment.stops.length - 1) || -1
  const stop = index !== -1 && segment ? segment.stops[index] : null
  const allDates =
    planEndDateTime &&
    planStartDateTime &&
    eachDayOfInterval({start: planStartDateTime, end: planEndDateTime})

  const firstStopOfEachDate: string[] = []
  const timeFormat =
    userConfig && userConfig.Time_Format === '12HR' ? 'h:mm a' : 'HH:mm'

  allDates?.forEach(date => {
    const firstStop = segment?.stops.find(stop =>
      stop.ServiceStartDateTime
        ? isSameDay(stop.ServiceStartDateTime, date)
        : undefined,
    )?.idStop
    if (!firstStop) return false
    return firstStopOfEachDate.push(firstStop)
  })

  if (segment && stop) {
    const routeHasArrivedStop =
      segment.stops.filter(stop => stop?.stopStatus === 'Arrived').length > 0

    const currentStop = segment.stops.findIndex(
      stop => stop?.stopStatus === 'New',
    )
    const isOnSameDay = !stop.stopStatus
      ? true
      : stop && stop.ServiceStartDateTime && stop?.ArrivalDate && type === 'end'
      ? isSameDay(stop.ServiceStartDateTime, new Date(stop?.ArrivalDate))
      : stop &&
        stop.ServiceEndDateTime &&
        stop?.DepartureDate &&
        type === 'start'
      ? isSameDay(stop.ServiceEndDateTime, new Date(stop?.DepartureDate))
      : false

    return type === 'start' ? (
      <EndPoint
        data-date={
          planStartDateTime
            ? format(planStartDateTime, userConfig?.Date_Format || 'MM/dd/yyyy')
            : 'noStartDate'
        }
        className="stopCard"
      >
        <HomeColumn>
          {stop.stopStatus?.toLowerCase() === 'new' ? (
            <Truck color="var(--asc-moss)" size={20} weight="fill" />
          ) : (
            <House size={18} weight={'fill'} color={'var(--asc-moss)'} />
          )}
        </HomeColumn>
        <EndPointColumn>
          <ServiceTypeBadge serviceType={stop.orderServiceType || undefined} />
          <Address>
            {stop &&
              (stop.companyName ||
                stop.address ||
                (stop.location.lat &&
                  stop.location.lat + ', ' + stop.location.lng) ||
                '')}
          </Address>
          <TimeRow gap="10px" marginBottom="5px">
            <TimeColumn>
              <TimeText color="var(--asc-dimgray)" gap="5px" isOnSameDay={true}>
                <Tooltip
                  tooltip={'Estimated Departure Time'}
                  position={'top center'}
                  trigger={<Clock size={18} />}
                />
                ETD:
                {stop.ServiceEndDateTime &&
                  isValidDate(stop.ServiceEndDateTime) && (
                    <DifferentDayColumn>
                      <div>
                        {stop?.ServiceEndDateTime &&
                          format(stop?.ServiceEndDateTime, timeFormat)}
                      </div>
                    </DifferentDayColumn>
                  )}
              </TimeText>
            </TimeColumn>
            {stop?.DepartureDate &&
              stop.DepartureDate !== '0001-01-01T00:00:00' && (
                <TimeColumn>
                  <TimeText
                    color="var(--asc-brown)"
                    gap="5px"
                    isOnSameDay={isOnSameDay}
                  >
                    <Tooltip
                      tooltip={'Actual Departure Time'}
                      position={'top center'}
                      trigger={<CheckCircle size={18} weight="fill" />}
                    />
                    <DifferentDayColumn>
                      <div>
                        {stop?.DepartureDate &&
                        stop?.DepartureDate.startsWith('2')
                          ? format(parseISO(stop.DepartureDate), timeFormat)
                          : ''}
                      </div>
                    </DifferentDayColumn>
                  </TimeText>
                </TimeColumn>
              )}
          </TimeRow>
        </EndPointColumn>
        <DropdownColumn>
          {segment.idSegment && segment.stops[0].stopStatus !== 'Completed' ? (
            <StopCardDropdown
              idStop={segment.stops[0]?.idStop || ''}
              idSegment={segment.idSegment}
              stopStatus={''}
              idPlan={idPlan}
              stop={segment.stops[0]}
              isCurrentStop={currentStop === 0}
              startRoute={currentStop === 0}
              refetch={refetch}
              planStartDateTime={planStartDateTime}
              stopBeforeEndDate={segment.stops[0]?.ServiceEndDateTime}
              routeDetailDispatch={routeDetailDispatch}
            />
          ) : (
            <Spacer />
          )}
        </DropdownColumn>
      </EndPoint>
    ) : (
      <EndPoint
        data-date={
          planEndDateTime
            ? format(planEndDateTime, userConfig?.Date_Format || 'MM/dd/yyyy')
            : 'noStartDate'
        }
        className="stopCard"
        isEnd={true}
      >
        <HomeColumn>
          {/* {stop &&
          stop.stopStatus &&
          stop.stopStatus.toLowerCase() === 'completed' ? (
            <DashedLineGreen />
          ) : stop.stopStatus?.toLowerCase() !== 'planning' ? (
            <DashedLineGray />
          ) : (
            <DashedLineWhite />
          )} */}
          <FlagCheckered size={18} weight={'fill'} color={'#ffffff'} />
        </HomeColumn>
        <EndPointColumn>
          {/* {segment.idSegment && (
            <StyledStatusBadge
              lessPadding={true}
              status={
                stop.stopStatus !== 'New' ? 'ArrivedHome' : 'PendingArrival'
              }
            />
          )} */}
          <Address>{stop && (stop.companyName || stop.address || '')}</Address>
          <TimeRow gap="10px" marginBottom="5px">
            <TimeColumn>
              <TimeText color="white" gap="5px" isOnSameDay={true}>
                <Tooltip
                  tooltip={'Estimated Arrival Time'}
                  position={'top center'}
                  trigger={<Clock size={18} />}
                />
                ETA:
                {stop.ServiceStartDateTime &&
                  isValidDate(stop.ServiceStartDateTime) && (
                    <DifferentDayColumn>
                      <div>
                        {stop?.ServiceStartDateTime &&
                          format(stop?.ServiceStartDateTime, timeFormat)}
                      </div>
                    </DifferentDayColumn>
                  )}
              </TimeText>
            </TimeColumn>
            {stop?.ArrivalDate &&
              stop.ArrivalDate !== '0001-01-01T00:00:00' && (
                <TimeColumn>
                  <TimeText color="white" gap="5px" isOnSameDay={true}>
                    <Tooltip
                      tooltip={'Actual Arrival Time'}
                      position={'top center'}
                      trigger={<CheckCircle size={18} weight="fill" />}
                    />
                    <DifferentDayColumn>
                      {!isOnSameDay && (
                        <div>
                          {format(
                            new Date(stop?.ArrivalDate),
                            userConfig?.Date_Format || 'MM/dd/YYYY',
                          )}
                        </div>
                      )}
                      <div>
                        {stop?.ArrivalDate && stop?.ArrivalDate.startsWith('2')
                          ? format(parseISO(stop.ArrivalDate), timeFormat)
                          : ''}
                      </div>
                    </DifferentDayColumn>
                  </TimeText>
                </TimeColumn>
              )}
          </TimeRow>
        </EndPointColumn>
        <DropdownColumn>
          {segment.idSegment &&
          currentStop > 0 &&
          !routeHasArrivedStop &&
          stop?.stopStatus !== 'Arrived' &&
          stop?.stopStatus !== 'Completed' ? (
            <StopCardDropdown
              idStop={stop?.idStop || ''}
              idSegment={segment.idSegment}
              stopStatus={''}
              idPlan={idPlan}
              stop={stop}
              isCurrentStop={currentStop === segment.stops.length - 1}
              startRoute={false}
              endRoute={currentStop > 0}
              refetch={refetch}
              stopBeforeEndDate={
                segment.stops[
                  type === 'end'
                    ? segment.stops.length - 2 >= 0
                      ? segment.stops.length - 2
                      : 0
                    : 0
                ].ServiceEndDateTime //puts date before the end of the route instead of after
              }
              planStartDateTime={planStartDateTime}
              routeDetailDispatch={routeDetailDispatch}
              isEnd={true}
            />
          ) : (
            <Spacer />
          )}
        </DropdownColumn>
      </EndPoint>
    )
  }
  return null
}
export default EndPointCard
