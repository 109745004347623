import * as React from 'react'
import Button from 'common/components/Button'
import styled from 'styled-components/macro'
import EventFilterDropDownCard from './EventFilterDropdownCard'
import {CaretDown, CaretUp} from 'phosphor-react'
import {useHistoryTrailStore} from './hooks/useHistoryTrailStore'
import {EventFilterType} from './hooks/historyTrailTypes'
import {ascendColors} from 'common/GlobalStyles'
import {OptionsType} from './EventFilter'
const Wrapper = styled.div`
  position: relative;
  width: auto;
  display: flex;
`
const StyledButton = styled(Button)`
  margin-left: 5px;
  background: transparent;
  color: var(--primary);
  font-weight: bold;
  display: flex;
  flex-direction: row;
  padding: 0px;
  align-items: center;
  svg {
    margin-right: 5px;
  }
  :hover {
    background: transparent;
  }
`
const Container = styled.div<{
  active: boolean
  eventFilter: EventFilterType
  fontOnPrimary: string
  fontOnSecondary: string
}>`
  width: fit-content;
  padding: 8px 10px;
  background: ${props =>
    props.active
      ? 'var(--primary)'
      : props.eventFilter === 'All'
      ? 'white'
      : 'var(--primary)'};
  border: 1px solid var(--asc-graphite);
  border-radius: 30px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  :hover {
    background: var(--secondary);
    span {
      color: ${p => p.fontOnSecondary};
      div {
        background: var(--primary);
        color: ${p => p.fontOnPrimary};
      }
    }
  }
`
const Text = styled.span<{
  active: boolean
  eventFilter: EventFilterType
  fontOnPrimary: string
  fontOnSecondary: string
}>`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: 600;
  color: ${({active, eventFilter, fontOnPrimary}) =>
    active
      ? fontOnPrimary
      : eventFilter === 'All'
      ? 'var(--primary)'
      : fontOnPrimary};
  align-items: center;
  span {
    margin-right: 10px;
  }
  svg {
    color: ${({active, eventFilter, fontOnPrimary}) =>
      active ? fontOnPrimary : eventFilter === 'All' ? 'black' : fontOnPrimary};
    margin-left: 5px;
    margin-right: 0px;
  }
  text-transform: uppercase;
`
const ItemBadge = styled.div<{fontOnSecondary: string}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 1em;
  padding: 4px 7px;
  line-height: 1em;
  justify-content: center;
  border-radius: 500rem;
  background-color: var(--secondary);
  font-weight: bold;
  color: ${p => p.fontOnSecondary};
`

const EventFilterDropDown = ({
  map,
  options,
}: {
  map: google.maps.Map
  options: OptionsType[]
}) => {
  const eventFilter = useHistoryTrailStore(state => state.eventFilter)
  const totalCount = useHistoryTrailStore(state => state.totalCount)
  const [open, setOpen] = React.useState(false)
  const drop = React.useRef(null)

  return (
    <Wrapper className="dropdown" ref={drop}>
      <StyledButton
        onClick={() => {
          setOpen(open => !open)
        }}
        active={open}
        data-cy="eventFilter"
      >
        <Container
          eventFilter={eventFilter}
          active={open}
          fontOnPrimary={ascendColors.fontOnPrimary}
          fontOnSecondary={ascendColors.fontOnSecondary}
        >
          <Text
            eventFilter={eventFilter}
            active={open}
            fontOnPrimary={ascendColors.fontOnPrimary}
            fontOnSecondary={ascendColors.fontOnSecondary}
          >
            <span data-cy="eventFilterHeader">{`${eventFilter} EVENTS`}</span>
            <ItemBadge fontOnSecondary={ascendColors.fontOnSecondary}>
              {eventFilter === 'All'
                ? new Intl.NumberFormat().format(totalCount)
                : options.find(
                    (option: OptionsType) => option.value === eventFilter,
                  )?.text?.props?.children[1]?.props?.children}
            </ItemBadge>
            {open ? (
              <CaretUp size={14} weight="fill" />
            ) : (
              <CaretDown size={14} weight="fill" />
            )}
          </Text>
        </Container>
      </StyledButton>
      {open && (
        <EventFilterDropDownCard
          options={options}
          setOpen={setOpen}
          closeDropdown={() => setOpen(false)}
          map={map}
        />
      )}
    </Wrapper>
  )
}

export default EventFilterDropDown
