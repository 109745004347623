import * as React from 'react'
import {
  RoutePlanType,
  Segment,
  StopType,
} from 'routingAndDispatch/types/typesModule'
import {CalendarBlank} from 'phosphor-react'
import {eachDayOfInterval, format, formatISO, isSameDay} from 'date-fns'
import {useStore} from 'common/useStore'
import {ScrollContainer, StopCardDateHeader} from '../routeDetailStyles'
import EndPointCard from './EndPointCard'
import StopPointCard from './StopPointCard'
import {HistoryTrailPoint} from 'historyTrail/hooks/historyTrailTypes'
import {StopReducerAction} from 'routingAndDispatch/Stops/stopHelper'

import {getStopKeyForTravelTime, getTravelTime} from './stopCardListHelpers'
import {useRouteDetailStore, TravelTimes} from '../useRouteDetailStore'
import TravelTimeSection from './TravelTimeSection'

interface StopCardListProps {
  segment: Segment | null
  historyTrail?: boolean
  planStatus: string
  planStartDateTime: Date | undefined
  planEndDateTime: Date | undefined
  idPlan: string
  currentStop: number
  currentScrollDate: string
  setCurrentScrollDate: React.Dispatch<React.SetStateAction<string>>
  // routeHasArrivedStop: boolean
  historyTrailPoints: HistoryTrailPoint[] | undefined
  refetch: () => void
  routeDetailDispatch: (action: StopReducerAction) => void
  showHistoryButtons?: boolean
  scrollToIndex?: number
  routePlan: RoutePlanType //needed to navigate back and forth between order detail
}

const checkInView = (container: HTMLElement, element: HTMLElement) => {
  const {bottom, height, top} = element.getBoundingClientRect()
  const containerRect = container.getBoundingClientRect()

  return top <= containerRect.top
    ? containerRect.top - top <= height
    : bottom - containerRect.bottom <= height
}

const StopCardList: React.FC<StopCardListProps> = ({
  segment,
  historyTrail = false,
  planStatus,
  planStartDateTime,
  planEndDateTime,
  idPlan,
  refetch,
  currentStop,
  // routeHasArrivedStop,
  currentScrollDate,
  setCurrentScrollDate,
  routeDetailDispatch,
  showHistoryButtons = true,
  scrollToIndex = 0,
  routePlan,
}) => {
  const userConfig = useStore(state => state.userConfig)

  const setTravelTimes = useRouteDetailStore(state => state.setTravelTimes)
  const travelTimes = useRouteDetailStore(state => state.travelTimes)
  const [travelTimesStopCount, setTravelTimesStopCount] = React.useState(0)
  const allDates =
    planEndDateTime &&
    planStartDateTime &&
    eachDayOfInterval({start: planStartDateTime, end: planEndDateTime})

  const firstStopOfEachDate: {stop: StopType; index: number}[] = []

  allDates?.forEach(date => {
    let index = 0
    const firstStop = segment?.stops.find((stop, stopIndex) => {
      if (stop.ServiceEndDateTime && isSameDay(stop.ServiceEndDateTime, date)) {
        index = stopIndex
      }
      return stop.ServiceEndDateTime && isSameDay(stop.ServiceEndDateTime, date)
    })
    if (!firstStop) return false
    return firstStopOfEachDate.push({stop: firstStop, index: index})
  })

  //For date header scrolling
  const handleScroll = () => {
    const cards = document.getElementsByClassName('stopCard')
    const container = document.getElementById('scrollContainer')
    for (let index = 0; index < cards.length; index++) {
      const element = cards[index] as HTMLElement
      if (container && element && checkInView(container, element)) {
        if (
          currentScrollDate !== element.dataset.date &&
          element.dataset.date
        ) {
          setCurrentScrollDate(element.dataset.date)
        }
        break
      }
    }
  }

  React.useEffect(() => {
    const getTravelTimesForRoute = () => {
      if (
        segment?.stops.length &&
        travelTimesStopCount !== segment?.stops.length
      ) {
        segment.stops.forEach(async (_stop, index) => {
          if (index === segment.stops.length - 1) return
          const firstStop = segment?.stops[index]
          const secondStop = segment?.stops[index + 1]
          //the key is origin lat lng to destination lat lng
          const newKey = getStopKeyForTravelTime(firstStop, secondStop)
          const foundTravelTime = Boolean(travelTimes[newKey])
          if (firstStop && secondStop && !foundTravelTime) {
            const response = await getTravelTime({firstStop, secondStop})
            if (response?.distance && response?.duration) {
              const newTravelTime: TravelTimes = {[newKey]: response}
              setTravelTimes(newTravelTime)
            }
          }
        })
        setTravelTimesStopCount(segment.stops.length)
      }
    }
    getTravelTimesForRoute()
  }, [
    segment?.stops,
    segment?.stops.length,
    setTravelTimes,
    travelTimes,
    travelTimes.length,
    travelTimesStopCount,
  ])

  React.useEffect(() => {
    if (scrollToIndex) {
      const scrollToCard = document.getElementById('card' + scrollToIndex)
      if (scrollToCard) {
        scrollToCard.scrollIntoView({behavior: 'smooth'})
      }
    }
  }, [scrollToIndex])

  return (
    segment && (
      <ScrollContainer
        id="scrollContainer"
        onScroll={handleScroll}
        showHistoryButtons={showHistoryButtons}
      >
        <EndPointCard
          segment={segment}
          type={'start'}
          planStartDateTime={planStartDateTime}
          planEndDateTime={planEndDateTime}
          idPlan={idPlan}
          refetch={refetch}
          routeDetailDispatch={routeDetailDispatch}
        />
        {segment.stops.map((stop, index) => {
          if (index === 0 || index === segment.stops.length - 1) return null

          const isFirstStopOfDate = !!firstStopOfEachDate.find(
            firstStop => firstStop.index === index,
          )

          return (
            <div
              key={
                stop.idStop ||
                (stop.ServiceStartDateTime &&
                  formatISO(stop.ServiceStartDateTime) + stop.address)
              }
              id={'card' + index.toString()}
            >
              {isFirstStopOfDate && (
                <StopCardDateHeader
                  key={
                    stop.ServiceEndDateTime &&
                    format(
                      stop.ServiceEndDateTime,
                      userConfig?.Date_Format || 'MM/dd/yyyy',
                    )
                  }
                >
                  <CalendarBlank weight="bold" size={16} />
                  {stop.ServiceEndDateTime &&
                    format(
                      stop.ServiceEndDateTime,
                      userConfig?.Date_Format || 'MM/dd/yyyy',
                    )}
                </StopCardDateHeader>
              )}
              {index !== 0 && (
                <TravelTimeSection
                  key={index}
                  idStop={stop.idStop || ''}
                  index={index}
                  segment={segment}
                />
              )}
              <StopPointCard
                stop={stop}
                planStatus={planStatus}
                index={index}
                historyTrail={historyTrail}
                segment={segment}
                currentStop={currentStop}
                idPlan={idPlan}
                planStartDateTime={planStartDateTime}
                refetch={refetch}
                // routeHasArrivedStop={routeHasArrivedStop}
                routeDetailDispatch={routeDetailDispatch}
                isSelected={scrollToIndex === index}
                routePlan={routePlan}
              />
              {/* {index === segment.stops.length - 2 && (

              )} */}
            </div>
          )
        })}
        <TravelTimeSection
          idStop={segment.stops[segment.stops.length - 1].idStop || ''}
          index={segment.stops.length - 1}
          segment={segment}
        />
        <EndPointCard
          segment={segment}
          type={'end'}
          planStartDateTime={planStartDateTime}
          planEndDateTime={planEndDateTime}
          idPlan={idPlan}
          refetch={refetch}
          routeDetailDispatch={routeDetailDispatch}
        />
      </ScrollContainer>
    )
  )
}

export default StopCardList
