import {RequiredAsterisk, Spinner} from 'common/components'
import useOnClickOutside from 'common/components/hooks/useOnClickOutside'
import * as React from 'react'
import {
  ActionTypes,
  State,
  initialState,
  reducer,
} from './locationsSearchPanelHelpers'
import LocationCard from './LocationCard'
import {
  Header,
  ListContainer,
  ListPanel,
  Panel,
  StyledLabel,
  StyledSearchContainer,
  Wrapper,
} from './locationsSearchPanelStyles'
import {useVirtualizer} from '@tanstack/react-virtual'
import {ArrowCircleUp} from 'phosphor-react'
import useLocations, {LandmarkType} from 'locations/hooks/useLocations'

interface Props {
  id: string
  label?: string
  handleLocationChange: (location: LandmarkType | null) => void
  value?: string
}

const LocationsSearchPanel: React.FC<Props> = ({
  id,
  label = '',
  handleLocationChange,
}) => {
  const panel = React.useRef<HTMLDivElement>(null)
  const scrollContainer = React.useRef<HTMLDivElement>(null)
  const [state, dispatch]: [
    state: State,
    dispatch: React.Dispatch<ActionTypes>,
  ] = React.useReducer(reducer, initialState)
  const {data: locationsData, status: locationsStatus} = useLocations()

  const rowVirtualizer = useVirtualizer({
    count: state.filteredLocations?.length || 0,
    getScrollElement: () => scrollContainer.current,
    estimateSize: () => 90,
    overscan: 3,
  })

  const virtualItems = rowVirtualizer.getVirtualItems()
  const scrollToIndex = rowVirtualizer.scrollToIndex
  React.useEffect(() => {
    if (locationsData) {
      dispatch({
        type: 'setState',
        data: {
          originalLocations: locationsData,
          filteredLocations: locationsData,
        },
      })
    }
  }, [locationsData])

  const clickedOutside = () => {
    if (state.panelOpen) {
      dispatch({type: 'setState', data: {panelOpen: false}})
    }
  }

  useOnClickOutside(panel, clickedOutside)

  const setSelectedLocation = (location: LandmarkType) => {
    dispatch({
      type: 'setState',
      data: {
        selectedLocation: location,
        panelOpen: false,
        searchValue: location.Landmark_Name,
      },
    })
    handleLocationChange(location)
  }

  const onClear = () => {
    dispatch({
      type: 'setState',
      data: {
        searchValue: '',
        filteredLocations: state.originalLocations,
      },
    })
    handleLocationChange(null)
  }

  return (
    <Wrapper>
      {locationsStatus === 'loading' ? (
        <Spinner />
      ) : locationsStatus === 'success' ? (
        <Panel ref={panel} panelOpen={state.panelOpen}>
          <Header panelOpen={state.panelOpen}>
            {label && (
              <StyledLabel htmlFor={id}>
                {label}
                <RequiredAsterisk />
              </StyledLabel>
            )}
            <StyledSearchContainer
              handleSearch={(e: {target: {value: string}}) =>
                dispatch({
                  type: 'searchLocations',
                  data: {searchValue: e.target.value},
                })
              }
              searchValue={state.searchValue}
              clearFunction={onClear}
              searchType="location name, street, city, etc."
              onClick={() => {
                dispatch({type: 'setState', data: {panelOpen: true}})
              }}
            />
          </Header>
          {state.panelOpen && (
            <ListPanel ref={scrollContainer}>
              <ListContainer totalHeight={rowVirtualizer.getTotalSize()}>
                <div
                  ref={rowVirtualizer.measureElement}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
                  }}
                >
                  {virtualItems.map(virtualRow => {
                    const location = state.filteredLocations[virtualRow.index]
                    return (
                      <LocationCard
                        key={virtualRow.index}
                        index={virtualRow.index}
                        location={location}
                        selectLocation={setSelectedLocation}
                      />
                    )
                  })}{' '}
                </div>
              </ListContainer>
              <ArrowCircleUp
                fill={'var(--primary)'}
                size={30}
                weight="fill"
                onClick={() => scrollToIndex(0)}
              />
            </ListPanel>
          )}
        </Panel>
      ) : (
        <></>
      )}
    </Wrapper>
  )
}
export default LocationsSearchPanel
