import {
  AlertModal,
  Checkbox,
  Column,
  InputErrorMessage,
  InputWithLabel,
  RequiredAsterisk,
  Row,
} from 'common/components'
import {WarningCircle} from 'phosphor-react'
import * as React from 'react'
import {
  SendButtonInput,
  Tab,
  TabRow,
  ToggleGroup,
  ErrorMessage,
} from 'routingAndDispatch/Setup/routeSettings/Customer Notifications/notificationStyles'
import {ViewableStop} from 'routingAndDispatch/Stops/stopHelper'
import {Loader, TextArea} from 'semantic-ui-react'
import styled from 'styled-components/macro'
import {
  ActionTypes,
  State,
  initialState,
  reducer,
} from './sendNotificationHelpers'
import useNotificationInfoForStop from './hooks/useNotificationInfoForStop'
import Modal from 'common/Modal'

const Banner = styled(Row)`
  width: 100%;
  padding: 10px 20px;
  background: var(--asc-floralwhite);
  gap: 10px;
  border-bottom: 1px solid var(--asc-coolgray);
  svg {
    margin-top: 3px;
    min-width: 20px;
    min-height: 20px;
  }
`
const StyledTab = styled(Tab)`
  width: 100%;
`
const StyledTabRow = styled(TabRow)`
  margin-bottom: 0px;
`
const Content = styled(Column)`
  padding: 10px 20px;
  background-color: var(--asc-cultured);
  flex-grow: 1;
  min-height: 400px;
  /* gap: 10px; */
`
const MessageInput = styled(TextArea)`
  padding: 10px;
  resize: none;
  border: 1px solid var(--table-borders);
  border-radius: 5px;
  width: 100%;
  height: 200px;
`
const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.928571em;
  font-weight: 700;
  display: flex;
`
const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
`
const MessageInputContainer = styled.div`
  position: relative;
`
type SendNotificationModalProps = {
  showNotificationModal: boolean
  onDismiss: () => void
  onSave: (body: {
    sendEmail: boolean
    emailAddresses: string
    ccAddresses: string
    emailSubject: string
    emailBody: string
    sendSms: boolean
    phoneNumbers: string
    smsBody: string
    idOrder: string
  }) => void
  stop: ViewableStop | null
  idSegment: string | null
  selectedNotification: number | null
}
const SendNotificationModal: React.FC<SendNotificationModalProps> = ({
  showNotificationModal,
  onDismiss,
  onSave,
  stop,
  idSegment,
  selectedNotification,
}) => {
  const [state, dispatch]: [
    state: State,
    dispatch: React.Dispatch<ActionTypes>,
  ] = React.useReducer(reducer, initialState)

  const {data: notificationInfo, status} = useNotificationInfoForStop(
    idSegment,
    stop ? stop.idStop : null,
    selectedNotification,
  )

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const errors = {...state.errors}
    const hasError = {...state.hasError}
    if (errors[e.target.name] && errors[e.target.name]?.message !== '') {
      errors[e.target.name].message = ''
    }

    if (
      !errors.emailBody?.message &&
      !errors.emailAddresses?.message &&
      !errors.subject?.message
    ) {
      hasError.emailContent = false
    }
    if (!errors.smsBody?.message && !errors.phoneNumbers?.message) {
      hasError.textContent = false
    }
    dispatch({
      type: 'setState',
      data: {[e.target.name]: e.target.value, errors, hasError},
    })
  }

  React.useEffect(() => {
    if (showNotificationModal && notificationInfo && stop) {
      const emailAddresses = `${stop?.email ? `${stop?.email},` : ''}`
      const phoneNumbers = `${stop?.phone ? `${stop?.phone},` : ''}`

      dispatch({
        type: 'setState',
        data: {
          emailAddresses,
          phoneNumbers,
          sendEmail: notificationInfo.SendEmail,
          sendText: notificationInfo.SendSms,
          subject: notificationInfo.EmailSubject,
          smsBody: notificationInfo.SmsBody,
          emailBody: notificationInfo.EmailBody,
        },
      })
    } else {
      dispatch({type: 'setState', data: initialState})
    }
  }, [notificationInfo, showNotificationModal, stop])

  const onSendFlagChanged = () => {
    const errors = {...state.errors}
    if (errors['sendToggle'] && errors['sendToggle']?.message !== '') {
      errors['sendToggle'].message = ''
    }

    if (state.currentTab === 'Email') {
      dispatch({type: 'setState', data: {sendEmail: !state.sendEmail, errors}})
    } else {
      dispatch({type: 'setState', data: {sendText: !state.sendText}})
    }
  }

  const checkFormValidation = () => {
    const errors = {
      emailAddresses: {message: ''},
      subject: {message: ''},
      emailBody: {message: ''},
      phoneNumbers: {message: ''},
      smsBody: {message: ''},
      sendToggle: {message: ''},
    }
    let hasError = state.hasError
    if (state.sendEmail) {
      if (state.emailAddresses === '') {
        hasError.emailContent = true
        errors['emailAddresses'].message = 'Email is required'
      }
      if (state.subject === '') {
        hasError.emailContent = true
        errors['subject'].message = 'Subject is required'
      }
      if (state.emailBody === '') {
        hasError.emailContent = true
        errors['emailBody'].message = 'Message is required'
      }
    }
    if (state.sendText) {
      if (state.phoneNumbers === '') {
        hasError.textContent = true
        errors['phoneNumbers'].message = 'Phone number is required'
      }
      if (state.smsBody === '') {
        hasError.textContent = true
        errors['smsBody'].message = 'Message is required'
      }
    }
    if (!state.sendText && !state.sendEmail) {
      errors['sendToggle'].message =
        'Must select to send either an email or text'
    }
    if (
      !hasError.emailContent &&
      !hasError.textContent &&
      errors['sendToggle'].message === ''
    ) {
      dispatch({type: 'setState', data: {showConfirmModal: true}})
    } else {
      dispatch({type: 'setState', data: {errors, hasError}})
    }
  }

  const getText = () => {
    let emailAddresses = state.emailAddresses
    const emails = emailAddresses.split(',').filter(Boolean) //removes emepty strings

    if (emails.length < 2) {
      emailAddresses = emails[0]
    }
    let phoneNumbers = state.phoneNumbers
    const numbers = phoneNumbers.split(',').filter(Boolean)
    if (numbers.length < 2) {
      phoneNumbers = numbers[0]
    }
    if (state.sendEmail && state.sendText) {
      return `An email and text message will be sent to ${emailAddresses} and ${phoneNumbers}`
    } else if (state.sendEmail) {
      return `An email will be sent to ${emailAddresses}`
    } else if (state.sendText) {
      return `A text message will be sent to ${phoneNumbers}`
    } else {
      return ''
    }
  }
  return (
    <Modal
      showDialog={showNotificationModal}
      title={'Send Customer Notification'}
      onDismiss={() => {
        dispatch({type: 'setState', data: initialState})
        onDismiss()
      }}
      width="800px"
      renderFooter={true}
      submitButtonText={'SEND'}
      onSave={checkFormValidation}
    >
      {selectedNotification === 1 &&
        ((notificationInfo?.SendEmail && state.currentTab === 'Email') ||
          (notificationInfo?.SendSms && state.currentTab === 'Text')) && (
          <Banner>
            <WarningCircle weight="duotone" color="black" size={20} />
            <div>
              Route Notifications are <strong>enabled</strong> for{' '}
              {`${state.currentTab.toLowerCase()}`}. Notifications will be sent
              automatically when a driver or dispatcher selects “On My Way”.
            </div>
          </Banner>
        )}

      <StyledTabRow>
        <StyledTab
          onClick={() => {
            dispatch({type: 'setState', data: {currentTab: 'Email'}})
          }}
          isSelected={state.currentTab === 'Email'}
          key={'email'}
          data-cy="tab"
        >
          Email
          {state.sendEmail && state.hasError.emailContent && (
            <WarningCircle
              size={16}
              color={'var(--asc-vermilion)'}
              weight="fill"
            />
          )}
        </StyledTab>
        <StyledTab
          onClick={() => {
            dispatch({type: 'setState', data: {currentTab: 'Text'}})
          }}
          isSelected={state.currentTab === 'Text'}
          key={'text'}
          data-cy="tab"
        >
          Text Message
          {state.sendText && state.hasError.textContent && (
            <WarningCircle
              size={16}
              color={'var(--asc-vermilion)'}
              weight="fill"
            />
          )}
        </StyledTab>
      </StyledTabRow>
      <Content>
        <SendButtonInput>
          <ToggleGroup>
            <Checkbox
              selected={
                state.currentTab === 'Email' ? state.sendEmail : state.sendText
              }
              dataCy={'send-toggle'}
              onChange={onSendFlagChanged}
              disabled={false}
            />
            <span>Send {`${state.currentTab}`}</span>
          </ToggleGroup>
          {state.errors['sendToggle']?.message &&
            state.errors['sendToggle'].message !== '' && (
              <ErrorMessage>{state.errors['sendToggle']?.message}</ErrorMessage>
            )}
        </SendButtonInput>

        {status === 'loading' ? (
          <LoaderContainer>
            <Loader active inline size="small" />
          </LoaderContainer>
        ) : (
          <>
            <InputWithLabel
              name={
                state.currentTab === 'Email' ? 'emailAddresses' : 'phoneNumbers'
              }
              label={'To'}
              value={
                state.currentTab === 'Email'
                  ? state.emailAddresses
                  : state.phoneNumbers
              }
              onChange={handleInputChange}
              required={
                state.currentTab === 'Email' ? state.sendEmail : state.sendText
              }
              errors={
                (state.currentTab === 'Email' && state.sendEmail) ||
                (state.currentTab === 'Text' && state.sendText)
                  ? state.errors
                  : undefined
              }
            />
            {state.currentTab === 'Email' && (
              <>
                <InputWithLabel
                  name={'ccAddresses'}
                  label={'cc'}
                  value={state.ccAddresses}
                  onChange={handleInputChange}
                  required={false}
                />
                <InputWithLabel
                  name={'subject'}
                  label={'Subject'}
                  value={state.subject}
                  onChange={handleInputChange}
                  required={true}
                  errors={state.errors}
                />
              </>
            )}
            <Row>
              <Label>Message</Label>
              <RequiredAsterisk />
            </Row>
            <MessageInputContainer>
              <MessageInput
                value={
                  state.currentTab === 'Email' ? state.emailBody : state.smsBody
                }
                name={state.currentTab === 'Email' ? 'emailBody' : 'smsBody'}
                rows={5}
                onChange={handleInputChange}
                data-cy={`message-input`}
                errors={state.errors}
              />
              {state.currentTab === 'Email' &&
                state.errors['emailBody']?.message &&
                state.errors['emailBody'].message !== '' && (
                  <InputErrorMessage
                    message={state.errors['emailBody']?.message}
                  ></InputErrorMessage>
                )}
              {state.currentTab === 'Text' &&
                state.errors['smsBody']?.message &&
                state.errors['smsBody'].message !== '' && (
                  <InputErrorMessage
                    message={state.errors['smsBody']?.message}
                  ></InputErrorMessage>
                )}
            </MessageInputContainer>
          </>
        )}
      </Content>
      <AlertModal
        showDialog={state.showConfirmModal}
        showDenyButton={true}
        onSave={() => {
          dispatch({type: 'setState', data: {showConfirmModal: false}})
          onSave({
            sendEmail: state.sendEmail,
            emailAddresses: state.emailAddresses,
            ccAddresses: state.ccAddresses,
            emailSubject: state.subject,
            emailBody: state.emailBody,
            sendSms: state.sendText,
            phoneNumbers: state.phoneNumbers,
            smsBody: state.smsBody,
            idOrder: '',
          })
        }}
        title={`Are you sure you want to send the notification${
          state.sendEmail && state.sendText ? 's' : ''
        }?`}
        text={getText()}
        onDismiss={() =>
          dispatch({type: 'setState', data: {showConfirmModal: false}})
        }
      />
    </Modal>
  )
}
export default SendNotificationModal
