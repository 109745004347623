import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

type NotificationStopType = {
  idRDEtaCustomerTemplate: number
  idCustomer: number
  idRDEtaDefaultTemplate: number
  NotificationName: string
  Enabled: boolean
  AutoSend: boolean
  SendEmail: boolean
  EmailSubject: string
  EmailBody: string
  SendSms: boolean
  SmsBody: string
}
const getNotificationInfoForStop = async (
  idSegment: string | null,
  idStop: string | null,
  idTemplate: number | null,
) => {
  const {data}: {data: NotificationStopType} = await Axios.get(
    `/rd/eta/customer/notification/${idSegment}/${idStop}/${idTemplate}`,
  )
  return data
}

export default function useNotificationInfoForStop(
  idSegment: string | null,
  idStop: string | null,
  idTemplate: number | null,
) {
  const user = useStore(state => state.user)
  return useQuery(
    [`notificationInfoForStop-${idStop}`],
    () => getNotificationInfoForStop(idSegment, idStop, idTemplate),
    {
      enabled:
        idSegment !== null &&
        idStop !== '' &&
        idTemplate !== null &&
        idTemplate >= 0 &&
        Boolean(user?.token),
    },
  )
}
