import * as React from 'react'
import {EllipsisText} from 'common/components'
import {Badge} from 'routingAndDispatch/Routes/routesStyles'

interface Props {
  serviceType: string | undefined
  lessPadding?: boolean
  className?: string
  padding?: string
}

const ServiceTypeBadge: React.FC<Props> = ({
  serviceType,
  lessPadding = false,
  className,
  padding,
}) => {
  return serviceType ? (
    <Badge
      className={className}
      background={'var(--asc-platinum)'}
      fontColor={'black'}
      borderColor={'var(--asc-concrete)'}
      hideShadow={true}
      lessPadding={lessPadding}
      padding={padding}
      maxWidth={'none'}
      minWidth={'50px'}
    >
      <EllipsisText>{serviceType}</EllipsisText>
    </Badge>
  ) : (
    <></>
  )
}
export default ServiceTypeBadge
