import {convertToUsersTimeFormat} from 'common/helpersDateTime'
import {isValid} from 'date-fns'
import {StopType} from 'routingAndDispatch/types/typesModule'
import {getIsStopLate} from './routePlanTimeTableHelpers'

export const getServiceWindowString = (stop: StopType) => {
  let windowString = ''
  if (stop.ServiceStartDateTime && isValid(stop.ServiceStartDateTime)) {
    windowString += convertToUsersTimeFormat(stop.ServiceStartDateTime)
  }
  windowString += ' - '
  if (stop.ServiceEndDateTime && isValid(stop.ServiceEndDateTime)) {
    windowString += convertToUsersTimeFormat(stop.ServiceEndDateTime)
  }
  return windowString
}

export const getStopStatus = (stop: StopType) => {
  const isLate = getIsStopLate(stop)
  const status = (stop.stopStatus || '').toLowerCase()
  const stopType = stop.stopType || ''
  const isAnEndpoint =
    stop.stopType === 'origin' || stop.stopType === 'destination'

  if (status === 'completed') {
    if (isAnEndpoint) {
      return isLate
        ? `${stopType === 'origin' ? 'DepartedHome' : 'ArrivedHome'}-late`
        : `${stopType === 'origin' ? 'DepartedHome' : 'ArrivedHome'}`
    } else {
      return isLate ? 'completed-late' : 'Completed'
    }
  } else if (status === 'new') {
    if (isAnEndpoint) {
      return isLate
        ? `${stopType === 'origin' ? 'not-departed' : 'new'}-late`
        : `${stopType === 'origin' ? 'not-departed' : 'new'}`
    } else {
      return isLate ? 'new-late' : 'new'
    }
  } else if (status === 'missed') {
    return isLate ? 'missed' : status
  } else if (status === 'intransit') {
    return isLate ? 'intransit-late' : status
  }

  return status
}
