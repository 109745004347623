export const numberFormatter = (valueToConvert: number, digits: number) => {
  return new Intl.NumberFormat(undefined, {
    maximumFractionDigits: digits,
  }).format(valueToConvert)
}

export const isDiffNumbers = (arr1: number[], arr2: number[]) => {
  const numberSortFn = function (a: number, b: number) {
    return a - b
  }
  const sortedArr1 = arr1?.length ? arr1.sort(numberSortFn) : []
  const sortedArr2 = arr2?.length ? arr2.sort(numberSortFn) : []
  return JSON.stringify(sortedArr1) !== JSON.stringify(sortedArr2)
}

export const ordinalNumber = (number: number) => {
  const order: {[key: number]: string} = {
    0: 'first',
    1: 'second',
    2: 'third',
    3: 'fourth',
    4: 'fifth',
    5: 'sixth',
    6: 'seventh',
    7: 'eighth',
    8: 'ninth',
    9: 'tenth',
    10: 'eleventh',
    11: 'twelfth',
    12: 'thirteenth',
    13: 'fourteenth',
    14: 'fifteenth',
    15: 'sixteenth',
    16: 'seventeenth',
    17: 'eighteenth',
    18: 'nineteenth',
    19: 'twentieth',
  }
  const ordinalNumber: string = order[number]
  return ordinalNumber
}

export const numberWithCommas = (
  value: string | number,
  numOfDecimals = 0,
): string => {
  // @ts-expect-error could be a string representing a number
  if (value && isNumeric(Number(value))) {
    const number = Number(value)
    const rounded =
      numOfDecimals !== undefined
        ? roundToDecimals(number, numOfDecimals)
        : number
    let returnValue = rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    if (numOfDecimals > 0 && typeof value === 'string' && value.endsWith('.')) {
      returnValue = returnValue + '.'
    }
    return returnValue
  } else {
    if (!value) {
      return ''
    } else {
      return String(value)
    }
  }
}

export const getNumberFormattedInInputNoDecimals = (value: string) => {
  const numberValue = (
    Number(value.replaceAll(/\D/g, '')) || ''
  ).toLocaleString()
  return numberValue
}

// export const getNumberFormattedInInput = (value: string, original = '') => {
//   if (value.split('.').length > 2) return original
//   const endsWithDecimal = value.endsWith('.')
//   let numberValue = (
//     Number(value.replace(/(?!\.)\D/g, '')) || ''
//   ).toLocaleString()
//   if (endsWithDecimal) numberValue = numberValue + '.'
//   return numberValue
// }

export const roundToDecimals = (value: number, numOfDecimals: number) => {
  if (value === undefined || value === null) return ''
  const multiplier = Number(`1${'0'.repeat(numOfDecimals || 0)}`)
  return Math.round((value + Number.EPSILON) * multiplier) / multiplier
}

export const nFormatter = (num: number, digits: number) => {
  const lookup = [
    {value: 1, symbol: ''},
    {value: 1e3, symbol: 'k'},
    {value: 1e6, symbol: 'M'},
    {value: 1e9, symbol: 'G'},
    {value: 1e12, symbol: 'T'},
    {value: 1e15, symbol: 'P'},
    {value: 1e18, symbol: 'E'},
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value
    })
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0'
}

export const isNumberOrComma = (value: string) => /^[0-9,]*$/.test(value)

export const isNumeric = (n: string) => {
  return !Number.isNaN(parseFloat(n)) && Number.isFinite(n)
}

export const formatAsCurrency = (value: number) => {
  const roundedNumber = roundToDecimals(value, 2)
  return roundedNumber.toLocaleString('en-US', {minimumFractionDigits: 2})
}
