import * as React from 'react'
import styled from 'styled-components/macro'
import {Dropdown} from 'semantic-ui-react'
import {useStore} from 'common/useStore'
import {useQueryClient} from '@tanstack/react-query'
import {uploadFromTemplate} from './bulkAssetImportHelpers'
import {QUERIES} from 'common/constants'

const Container = styled.div`
  display: none;
  @media ${QUERIES.tabletAndUp} {
    display: initial;
  }
  &&& {
    margin-left: 10px;
    .button {
      padding: 7px 14px;
      display: flex;
      justify-content: space-between;
      .text {
        margin-right: 10px;
      }
    }
    .item {
      display: flex;
      justify-content: flex-start;
    }
  }
`

const BulkAssetImport = () => {
  const {useRef} = React
  const uploadInputRef = useRef(null)
  const uploadFormRef = useRef(null)
  const userConfig = useStore(state => state.userConfig)
  const cache = useQueryClient()

  const downloadTemplate = () =>
    window.open('./files/BulkAssetImportTemplate.xlsx', '_blank')

  const triggerUpload = () => {
    const input = uploadInputRef.current
    input.click()
  }

  const handleUpload = event =>
    uploadFromTemplate({
      event,
      userConfig,
      uploadFormRef,
      cache,
    })

  return (
    <Container>
      <Dropdown button text="Bulk Asset Import">
        <Dropdown.Menu>
          <form ref={uploadFormRef}>
            <input
              accept=".xlsx"
              type="file"
              style={{display: 'none'}}
              ref={uploadInputRef}
              onChange={handleUpload}
            />
          </form>
          <Dropdown.Item
            icon="download"
            text="Download Template"
            onClick={downloadTemplate}
          />
          <Dropdown.Item
            icon="upload"
            text="Import From Template"
            onClick={triggerUpload}
          />
        </Dropdown.Menu>
      </Dropdown>
    </Container>
  )
}
export default BulkAssetImport
