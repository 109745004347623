import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'
import {EditDeviceType} from 'management/components/TemedaAdmin/Devices/hooks/usePutDevice'

const getDevices = async (idCustomer: number | undefined) => {
  const {data}: {data: EditDeviceType[]} = await Axios.get(
    `/management/devices/customer/${idCustomer}`,
  )
  return data
}

const useDevices = (idCustomer: number | undefined) => {
  const user = useStore(state => state.user)
  return useQuery([`devices`, idCustomer], () => getDevices(idCustomer), {
    enabled: Boolean(user.token && idCustomer),
  })
}

export default useDevices
