type ErrorType = {type?: string; message: string}

export type State = {
  currentTab: 'Email' | 'Text'
  subject: string
  sendEmail: boolean
  sendText: boolean
  emailAddresses: string
  ccAddresses: string
  phoneNumbers: string
  smsBody: string
  emailBody: string
  errors: Record<string, ErrorType>
  showConfirmModal: boolean
  hasError: {emailContent: boolean; textContent: boolean}
}
export const initialState: State = {
  currentTab: 'Email',
  subject: '',
  sendEmail: false,
  sendText: false,
  emailAddresses: '',
  ccAddresses: '',
  phoneNumbers: '',
  smsBody: '',
  emailBody: '',
  errors: {},
  showConfirmModal: false,
  hasError: {emailContent: false, textContent: false},
}

export type ActionTypes = {
  type: 'setState'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}

export const reducer = (state: State, action: ActionTypes) => {
  switch (action.type) {
    case 'setState':
      return {...state, ...action.data}
    default:
      break
  }
}
