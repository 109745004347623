import styled from 'styled-components/macro'
import {EllipsisText, Row} from 'common/components'
import DropdownOptional from 'common/components/InputDropdownOptional'
import React from 'react'

export const PlanReviewStyledDropdownOptional = styled(({...props}) => (
  <DropdownOptional {...props} />
))`
  width: 120px;
  margin-right: 20px;
  &&& {
    margin-bottom: 0 !important;
  }
  label {
    display: none;
  }
`

export const NoRecordsRow = styled.div`
  width: 50%;
  text-align: center;
  padding: 6px;
`

export const CompletedCellContainer = styled.div`
  color: var(--asc-darkolive);
  grid-area: completed;
  width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 5px;
  height: 100%;
  width: 100%;
  span {
    font-weight: bold;
    margin-right: 3px;
  }
  svg {
    margin-right: 5px;
    min-width: 16px;
    min-height: 16px;
  }
`

export const StopSummaryInfo = styled(EllipsisText)`
  width: 55%;
  font-size: 14px;
`

export const StopSummaryToolTip = styled.div`
  background-color: white;
  width: 450px;
  height: fit-content;
  border: 1px solid #d2d2d7;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 30px;
`
export const StopSummaryTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`

export const StopSummaryRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 5px;
`

export const StopSummaryTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 30px;
  justify-content: space-between;
  width: 100%;
`

export const StopSummaryLabel = styled(Row)`
  width: 45%;
  color: var(--asc-sonicsilver);
  font-size: 14px;
  gap: 15px;
`

export const StopSummaryText = styled.div`
  width: 55%;
  font-size: 14px;
`

export const RouteTimeTableContainer = styled.div`
  display: grid;
  grid-auto-rows: 41px;
  gap: 0px 0px;
  height: 100%;
  width: 100%;
  overflow: auto;
`

type RouteTimeTableRowProps = {
  isPlanning: boolean
  isHeader: boolean
  isRouteDetail: boolean
}

export const RouteTimeTableRowContainer = styled.div<RouteTimeTableRowProps>`
  display: grid;
  grid-template-areas: ${props =>
    props.isRouteDetail
      ? "'chart'"
      : props.isPlanning
      ? "'checkbox driverName assetLabel totalStops distance duration buttons chart'"
      : "'checkbox driverName assetLabel status completed distance duration buttons chart'"};
  grid-template-columns: ${props =>
    props.isRouteDetail
      ? '1fr'
      : props.isPlanning
      ? '40px 200px 200px 80px 100px 100px 80px 2400px'
      : '40px 200px 85px 135px 100px 100px 100px 80px 2400px'};
  cursor: ${props =>
    props.isHeader || props.isRouteDetail ? 'default' : 'pointer'};
  //to apply sticky columns to checkbox and driver column
  & > *:nth-child(1),
  & > *:nth-child(2) {
    position: sticky;
    left: 0;
    background-color: ${props =>
      props.isHeader
        ? props.isRouteDetail
          ? 'var(--asc-brown)'
          : 'var(--primary)'
        : 'white'};
    z-index: ${props => (props.isRouteDetail ? 'auto' : '10')};
  }

  //prevent highlight when clicking to sort or draw on map
  user-select: none !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`
type RouteTimeTableColumnProps = {
  isDriver?: boolean
  isTimeTable?: boolean
  isRouteDetail?: boolean
}

export const RouteTimeTableColumn = styled.div<RouteTimeTableColumnProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border-bottom: 1px solid var(--asc-coolgray);
  border-right: ${props =>
    props.isDriver ? '1px solid var(--asc-coolgray)' : 'none'};
  border-left: ${props =>
    props.isTimeTable && !props.isRouteDetail
      ? '1px solid var(--asc-coolgray)'
      : 'none'};
  position: ${props => (props.isDriver ? 'sticky' : 'default')};
`
type RouteTimeTableHeaderColumnProps = {
  isDriver?: boolean
  isTimeTable?: boolean
  isSortable?: boolean
  isRouteDetail?: boolean
}
export const RouteTimeTableHeaderColumn = styled.div<RouteTimeTableHeaderColumnProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props =>
    props.isTimeTable ? 'var(--asc-brown)' : 'var(--primary)'};
  color: white;
  font-weight: bold;
  border-bottom: 1px solid var(--asc-coolgray);
  border-right: ${props =>
    props.isDriver ? '1px solid var(--asc-coolgray)' : 'none'};
  border-left: ${props =>
    props.isTimeTable && !props.isRouteDetail
      ? '1px solid var(--asc-coolgray)'
      : 'none'};
  position: ${props => (props.isDriver ? 'sticky' : 'default')};
  cursor: ${props => (props.isSortable ? 'pointer' : 'default')};
`
type TextCellProps = {
  gridArea: string
}
export const TextCell = styled(EllipsisText)<TextCellProps>`
  padding: 5px 10px;
  grid-area: ${props => props.gridArea};
`

export const SelectAllHeaderCell = styled.div`
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    cursor: pointer;
  }
`
export const ChartSvg = styled.svg`
  grid-area: chart;
`
