/* eslint-disable @typescript-eslint/no-explicit-any */
import {Range} from 'react-date-range'
import {IDateRange} from './DateRangePicker'
import {
  format,
  startOfWeek,
  startOfMonth,
  endOfWeek,
  endOfMonth,
  sub,
  isSameDay,
  startOfToday,
  endOfToday,
  differenceInHours,
  subDays,
  startOfDay,
  endOfDay,
  isWithinInterval,
  differenceInDays,
} from 'date-fns'

const sevenDaysAgo = subDays(new Date(), 6)

const thirtyDaysAgo = subDays(new Date(), 29)

const definitions = {
  startOfToday: startOfToday(),
  endOfToday: endOfToday(),
  startOfYesterday: sub(startOfToday(), {days: 1}),
  endOfYesterday: sub(endOfToday(), {days: 1}),
  startOfWeekToDate: startOfWeek(startOfToday()),
  endOfWeekToDate: endOfToday(),
  startOfLast7Days: startOfDay(sevenDaysAgo),
  endOfLast7Days: endOfDay(new Date()),
  startOfLastWeek: startOfWeek(sub(startOfToday(), {weeks: 1})),
  endOfLastWeek: endOfWeek(sub(startOfToday(), {weeks: 1})),
  startOfLastMonth: startOfMonth(sub(startOfToday(), {months: 1})),
  endOfLastMonth: endOfMonth(sub(startOfToday(), {months: 1})),
  startOfMonthToDate: startOfMonth(startOfToday()),
  endOfMonthToDate: endOfToday(),
  startOfLast30Days: startOfDay(thirtyDaysAgo),
  endOfLast30Days: endOfDay(new Date()),
}

const staticRangeHandler: {
  range: () => IDateRange
  isSelected: (range: IDateRange) => boolean
} = {
  range: () => ({
    startDate: definitions.startOfToday,
    endDate: definitions.endOfToday,
  }),
  isSelected(range: IDateRange) {
    const definedRange: IDateRange = this.range()
    if (
      range?.startDate &&
      definedRange?.startDate &&
      range?.endDate &&
      definedRange?.endDate
    ) {
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      )
    } else {
      return false
    }
  },
}

function createStaticRanges(
  ranges: {label: string; range: () => IDateRange}[],
) {
  return ranges.map(range => ({...staticRangeHandler, ...range}))
}

export const staticRanges = createStaticRanges([
  {
    label: 'Today',
    range: () => ({
      startDate: definitions.startOfToday,
      endDate: definitions.endOfToday,
    }),
  },
  {
    label: 'Last 24 Hours',
    range: () => ({
      startDate: sub(new Date(), {hours: 24}),
      endDate: new Date(),
    }),
  },
  {
    label: 'Yesterday',
    range: () => ({
      startDate: definitions.startOfYesterday,
      endDate: definitions.endOfYesterday,
    }),
  },
  {
    label: 'Week To Date',
    range: () => ({
      startDate: definitions.startOfWeekToDate,
      endDate: definitions.endOfWeekToDate,
    }),
  },
  {
    label: 'Last 7 Days',
    range: () => ({
      startDate: definitions.startOfLast7Days,
      endDate: definitions.endOfLast7Days,
    }),
  },
  {
    label: 'Last Week',
    range: () => ({
      startDate: definitions.startOfLastWeek,
      endDate: definitions.endOfLastWeek,
    }),
  },
  {
    label: 'Last 30 Days',
    range: () => ({
      startDate: definitions.startOfLast30Days,
      endDate: definitions.endOfLast30Days,
    }),
  },
  {
    label: 'Last Month',
    range: () => ({
      startDate: definitions.startOfLastMonth,
      endDate: definitions.endOfLastMonth,
    }),
  },
  {
    label: 'Month To Date',
    range: () => ({
      startDate: definitions.startOfMonthToDate,
      endDate: definitions.endOfMonthToDate,
    }),
  },
])

export const getDefinedRangeString = (
  range: IDateRange,
  dateFormat: string,
  singleDay?: boolean,
) => {
  const startDateString = range?.startDate
    ? format(range?.startDate, dateFormat)
    : ''
  const endDateString = range?.endDate ? format(range?.endDate, dateFormat) : ''

  if (
    range?.startDate?.getTime() === definitions.startOfToday.getTime() &&
    range?.endDate?.getTime() === definitions.endOfToday.getTime()
  ) {
    return 'Today'
  } else if (
    range?.startDate?.getTime() === definitions.startOfYesterday.getTime() &&
    range?.endDate?.getTime() === definitions.endOfYesterday.getTime()
  ) {
    return 'Yesterday'
  } else if (
    range?.startDate &&
    range?.endDate &&
    differenceInHours(range.endDate, range.startDate) <= 24 &&
    differenceInHours(new Date(), range.startDate) <= 24 &&
    differenceInHours(new Date(), range.startDate) > 23
  ) {
    return 'Last 24 Hours'
  } else if (
    range?.startDate?.getTime() === definitions.startOfWeekToDate.getTime() &&
    range?.endDate?.getTime() === definitions.endOfWeekToDate.getTime()
  ) {
    return `Week To Date`
  } else if (
    range.startDate &&
    range.endDate &&
    isWithinInterval(range.startDate, {
      start: definitions.startOfLast7Days,
      end: definitions.endOfLast7Days,
    }) &&
    isWithinInterval(range.endDate, {
      start: definitions.startOfLast7Days,
      end: definitions.endOfLast7Days,
    }) &&
    Math.abs(differenceInDays(range.startDate, range.endDate)) === 6
  ) {
    return `Last 7 Days`
  } else if (
    range?.startDate?.getTime() === definitions.startOfLastWeek.getTime() &&
    range?.endDate?.getTime() === definitions.endOfLastWeek.getTime()
  ) {
    return `Last Week`
  } else if (
    range?.startDate?.getTime() === definitions.startOfMonthToDate.getTime() &&
    range?.endDate?.getTime() === definitions.endOfMonthToDate.getTime()
  ) {
    return `Month To Date`
  } else if (
    range?.startDate?.getTime() === definitions.startOfLastMonth.getTime() &&
    range?.endDate?.getTime() === definitions.endOfLastMonth.getTime()
  ) {
    return `Last Month`
  } else if (
    range.startDate &&
    range.endDate &&
    isWithinInterval(range.startDate, {
      start: definitions.startOfLast30Days,
      end: definitions.endOfLast30Days,
    }) &&
    isWithinInterval(range.endDate, {
      start: definitions.startOfLast30Days,
      end: definitions.endOfLast30Days,
    }) &&
    Math.abs(differenceInDays(range.startDate, range.endDate)) === 29
  ) {
    return `Last 30 Days`
  } else {
    return singleDay
      ? `${startDateString}`
      : `${startDateString} - ${endDateString}`
  }
}

type GetDateRangeStringType = {
  range: Range
  dateFormat: string
  singleDay: boolean
  showTime: boolean
}

export const getDateRangeString = ({
  range,
  dateFormat,
  singleDay,
  showTime,
}: GetDateRangeStringType) => {
  let newRangeString = ''
  if (range.startDate && range.endDate && !singleDay) {
    newRangeString =
      format(range.startDate, dateFormat) +
      '-' +
      format(range.endDate, dateFormat)
  } else if (range.startDate && singleDay) {
    if (showTime && range.endDate) {
      newRangeString =
        format(range.startDate, dateFormat) +
        '-' +
        format(range.endDate, dateFormat)
    } else {
      newRangeString = format(range.startDate, dateFormat)
    }
  } else {
    newRangeString = ''
  }
  return newRangeString
}

type ActionTypes = {
  type: string
  data?: any
}

export const reducer = (state: any, action: ActionTypes) => {
  switch (action.type) {
    case 'setState':
      return {...state, ...action.data}
    case 'setShow':
      return {...state, show: action.data}
    case 'predefinedSelected':
      return {
        ...state,
        startDate: action.data.startDate,
        endDate: action.data.endDate,
        rangeString: action.data.rangeString,
        show: false,
      }
    case 'setLocalStaticRanges':
      return {
        ...state,
        localStaticRanges: action.data,
      }
    default:
      break
  }
}
