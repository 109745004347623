import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

const getCustomerSupport = async () => {
  const {data} = await Axios.get('dealer/user')
  return data
}

export default function useCustomerSupport() {
  const user = useStore(state => state.user)
  return useQuery(['customerSupport'], getCustomerSupport, {
    enabled: Boolean(user?.token),
  });
}
