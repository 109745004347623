import styled from 'styled-components/macro'

export const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  div {
    width: 100%;
  }
  div:last-child {
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
  }
`

export const AssetLabel = styled.div`
  color: var(--asc-dimgray);
  text-align: right;

  font-family: 'Open Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  align-items: center;
`

export const Container = styled.div`
  border-top: solid 1px var(--asc-coolgray);
  /* border-bottom: solid 1px var(--asc-coolgray); */
`

export const StyledMessageInput = styled.div`
  margin-bottom: 16px;
`

export const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
`

export const InfoContainer = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid #a1ad74;
  background: #f0f6e1;
  margin: 30px;
  margin-bottom: 0;
  padding: 10px;
`

export const InfoText = styled.div`
  display: flex;
  margin-left: 5px;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const DestinationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
`
export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--asc-cultured);
  width: 50%;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
`

export const PhoneDescriptionList = styled.ul`
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 0px;
  font-weight: normal;
  list-style: none;
`

export const TextArea = styled.textarea`
  border: 1px solid var(--asc-graphite);
  border-radius: 10px;
  padding: 15px;
  resize: vertical;
  width: 100%;
`

export const EmailDescription = styled.div`
  font-weight: normal;
  margin-bottom: 30px;
  margin-top: 10px;
`
export const PhoneDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`
