import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'
import {StopType} from 'routingAndDispatch/types/typesModule'

const getRouteDetailByDriver = async (
  idPlan: string | undefined,
  idRouteDispatchDriver: string | undefined,
) => {
  const {data} = await Axios.get(
    `rd/plans/${idPlan}/driver/${idRouteDispatchDriver}`,
  )
  const newStops = data.segments[0].stops.map(
    (stop: StopType, index: number) => {
      if (!stop.idStop) {
        stop.idStop = `${index}`
      }
      const stopsOnSameOrder = data.segments[0].stops.filter(
        (otherStop: StopType) =>
          stop.idOrder && otherStop.idOrder === stop.idOrder,
      )

      stop.stopType = 'Service'
      if (index === 0) {
        stop.stopType = 'origin'
      } else if (index === data.segments[0].stops.length - 1) {
        stop.stopType = 'destination'
      } else if (stopsOnSameOrder.length > 1) {
        if (stopsOnSameOrder[0].idStop === stop.idStop) {
          stop.stopType = 'Pickup'
        } else {
          stop.stopType = 'Delivery'
        }
      }

      if (!stop.ServiceStartDateTime?.toString().includes('Invalid')) {
        stop.ServiceStartDateTime = new Date(stop.ServiceStartDateTime || '')
      } else {
        stop.ServiceStartDateTime = undefined
      }
      if (!stop.ServiceEndDateTime?.toString().includes('Invalid')) {
        stop.ServiceEndDateTime = new Date(stop.ServiceEndDateTime || '')
      } else {
        stop.ServiceEndDateTime = undefined
      }
      return {
        ...stop,
      }
    },
  )

  // Separate origin and destination stops
  const originStop = newStops.find(
    (stop: StopType) => stop.stopType === 'origin',
  )
  const destinationStop = newStops.find(
    (stop: StopType) => stop.stopType === 'destination',
  )

  // Remove origin and destination stops from the array
  const otherStops = newStops.filter(
    (stop: StopType) =>
      stop.stopType !== 'origin' && stop.stopType !== 'destination',
  )

  // Sort the other stops by ServiceStartDateTime
  const sortedStops = otherStops.sort((a: StopType, b: StopType) => {
    const timeA = a.ServiceStartDateTime?.getTime() || 0
    const timeB = b.ServiceStartDateTime?.getTime() || 0
    return timeA - timeB
  })

  // Insert origin and destination stops back into the array
  const mergedStops = [originStop, ...sortedStops, destinationStop].filter(
    stop => stop !== undefined,
  )
  data.planStartDateTime = new Date(data.planStartDateTime)
  data.planEndDateTime = new Date(data.planEndDateTime)
  data.segments[0].stops = mergedStops
  return data
}

const useRouteDetailByDriver = (
  idPlan: string | undefined,
  idRouteDispatchDriver: string | undefined,
  refetch: boolean,
) => {
  const user = useStore(state => state.user)
  return useQuery(
    [`routeDetail`, idRouteDispatchDriver],
    () => getRouteDetailByDriver(idPlan, idRouteDispatchDriver),
    {
      enabled: Boolean(
        idRouteDispatchDriver && idPlan && user?.token && !refetch,
      ),
      refetchInterval: 300000,
    },
  )
}

export default useRouteDetailByDriver
