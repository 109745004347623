import * as React from 'react'
import styled from 'styled-components/macro'
import {InputWithLabel} from '../common/components'
import CustomizeInstruction from 'account/customizeViews/CustomizeInstruction'
import PasswordRequirements from './PasswordRequirements'
import {Icon} from 'semantic-ui-react'
import {
  changePasswordReducer,
  initialChangePassword,
  validateCapital,
  validateNumbers,
  validateLength,
  validateMatchingPassword,
  validateNoColon,
  submitNewPassword,
} from './changePasswordHelper'
import {useStore} from 'common/useStore'
import Modal from 'common/Modal'

const Container = styled.div`
  display: flex;
`
const ModalBody = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
`
const Column = styled.div`
  padding: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
`
const Instruction = styled.div`
  display: flex;
  align-self: flex-start;
  justify-content: flex-start;
  padding-bottom: 10px;
`
const ChangePassword = ({onDismiss}) => {
  const userConfig = useStore.getState().userConfig
  const user = useStore.getState().user
  const [state, dispatch] = React.useReducer(
    changePasswordReducer,
    initialChangePassword,
  )
  const [submitting, setSubmitting] = React.useState(false)
  let password
  const splitToken = window.atob(user.token).split(':')
  if (user.impersonatingEmail) {
    password = splitToken[1] + ':' + user.impersonatingEmail
  } else {
    password = splitToken[1]
  }
  const containNumber = validateNumbers(state)
  const containCapital = validateCapital(state)
  const length = validateLength(state)
  const matched = validateMatchingPassword(state)
  const noColon = validateNoColon(state)
  const currentPasswordMatched = password === state.currentPassword

  const onSave = () => {
    setSubmitting(true)
    return submitNewPassword(userConfig, user, state.newPassword, onDismiss)
  }
  const disabled = !(
    currentPasswordMatched &&
    containNumber &&
    containCapital &&
    length &&
    matched &&
    noColon
  )
  const currentPasswordError = {
    currentPassword: {
      message:
        password === state.currentPassword
          ? undefined
          : state.currentPassword &&
            state.blur &&
            'Current password does not match',
    },
  }
  const setBlur = () =>
    dispatch({
      type: 'setState',
      data: {blur: true},
    })
  const onCurrentPasswordChanged = () =>
    dispatch({
      type: 'setState',
      data: {currentPassword: event.target.value},
    })

  const onNewPasswordChanged = event =>
    dispatch({
      type: 'setState',
      data: {newPassword: event.target.value},
    })
  const showNewPassword = () =>
    dispatch({
      type: 'setState',
      data: {showNewPassword: !state.showNewPassword},
    })
  const onConfirmNewPasswordChange = event =>
    dispatch({
      type: 'setState',
      data: {confirmPassword: event.target.value},
    })
  const showConfirmPassword = () =>
    dispatch({
      type: 'setState',
      data: {showConfirmPassword: !state.showConfirmPassword},
    })
  return (
    <Container>
      <Modal
        showDialog={!(status === 'loading')}
        title={'Change Password'}
        width={'400px'}
        renderFooter={true}
        loading={submitting}
        onSave={onSave}
        disabled={disabled}
        onDismiss={onDismiss}
        submitButtonText="Save"
      >
        <ModalBody>
          <Column>
            <Instruction>
              <CustomizeInstruction
                message={`Note: Passwords must be at least 8 characters, no longer than 32 characters, and must contain at least 1 capital letter and 1 number.`}
              />
            </Instruction>
            <InputWithLabel
              errors={currentPasswordError}
              inputProps={{
                type: 'password',
                autoComplete: 'new-password',
                value: state.currentPassword || '',
                onBlur: setBlur,
                onChange: onCurrentPasswordChanged,
              }}
              label="Current Password"
              name="currentPassword"
            />

            <InputWithLabel
              inputProps={{
                type: !state.showNewPassword ? 'password' : 'text',
                autoComplete: 'new-password',
                value: state.newPassword || '',
                onChange: onNewPasswordChanged,
              }}
              label="New Password"
              name="newPassword"
              onSuffixClick={showNewPassword}
              suffix={
                <Icon name={state.showNewPassword ? 'eye' : 'eye slash'} />
              }
            />
            {password === state.currentPassword && (
              <PasswordRequirements
                containNumber={containNumber}
                containCapital={containCapital}
                length={length}
                matched={matched}
                noColon={noColon}
              />
            )}

            <InputWithLabel
              inputProps={{
                type: !state.showConfirmPassword ? 'password' : 'text',
                autoComplete: 'new-password',
                value: state.confirmPassword || '',
                onChange: onConfirmNewPasswordChange,
              }}
              label="Confirm New Password"
              name="confirmPassword"
              onSuffixClick={showConfirmPassword}
              suffix={
                <Icon name={state.showConfirmPassword ? 'eye' : 'eye slash'} />
              }
            />
          </Column>
        </ModalBody>
      </Modal>
    </Container>
  )
}
export default ChangePassword
