export const setCurrentViewAssets = async ({user, activeMarker}) => {
  const currentRecentAssetsLocalStorage = await sessionStorage.getItem(
    `recentlyViewAssets_${user.idUser}_${user.idCustomer}`,
  )
  const recentAssets = currentRecentAssetsLocalStorage
    ? JSON.parse(currentRecentAssetsLocalStorage)
    : []
  const idAsset = activeMarker.idAsset

  //remove if found

  if (recentAssets?.length) {
    const foundAssetIndex = recentAssets?.findIndex(ra => ra === idAsset)
    if (foundAssetIndex > -1) {
      recentAssets.splice(foundAssetIndex, 1)
    }
  }

  //put it at the top
  if (recentAssets?.length) {
    recentAssets.unshift(idAsset)
  } else {
    recentAssets.push(idAsset)
  }

  if (recentAssets?.length > 20) {
    recentAssets.pop()
  }
  await sessionStorage.setItem(
    `recentlyViewAssets_${user.idUser}_${user.idCustomer}`,
    JSON.stringify(recentAssets),
  )
  // setAssetList({user, assetsRef, setRecentViewAssets, activeMarker})
}

export const setAssetList = async ({user, assetsRef, setRecentViewAssets}) => {
  if (user && assetsRef.current) {
    const currentRecentAssetsLocalStorage = await sessionStorage.getItem(
      `recentlyViewAssets_${user.idUser}_${user.idCustomer}`,
    )
    const recentAssetIDList = currentRecentAssetsLocalStorage
      ? JSON.parse(currentRecentAssetsLocalStorage)
      : []
    const recentAssets = []
    recentAssetIDList?.length &&
      recentAssetIDList?.forEach(ra => {
        const foundAsset = assetsRef.current.find(asset => asset.idAsset === ra)
        if (foundAsset) {
          recentAssets.push(foundAsset)
        }
      })
    setRecentViewAssets(recentAssets)
  }
}
