import * as React from 'react'
import styled from 'styled-components/macro'
import {QUERIES} from 'common/constants'
import {ButtonDropDown} from 'common/components'
import {DownloadSimple} from 'phosphor-react'

const getOptions = (hiddenGlobalFilter: boolean, testIdName?: string) => {
  if (hiddenGlobalFilter) {
    return [
      {
        key: 0,
        text: 'Export All XLSX',
        value: 0,
        dataTestId: `${testIdName}-export-xlsx`,
      },
      {
        key: 2,
        text: 'Export All CSV',
        value: 2,
        dataCy: 'export-all-csv',
        dataTestId: `${testIdName}-export-csv`,
      },
      {
        key: 4,
        text: 'Export All PDF',
        value: 4,
        dataTestId: `${testIdName}-export-pdf`,
      },
    ]
  } else {
    return [
      {
        key: 0,
        text: 'Export All XLSX',
        value: 0,
        dataTestId: `${testIdName}-export-xlsx`,
      },
      {
        key: 1,
        text: 'Export Filtered XLSX',
        value: 1,
        dataTestId: `${testIdName}-export-xlsx-filtered`,
      },
      {
        key: 2,
        text: 'Export All CSV',
        value: 2,
        dataCy: 'export-all-csv',
        dataTestId: `${testIdName}-export-csv`,
      },
      {
        key: 3,
        text: 'Export Filtered CSV',
        value: 3,
        dataTestId: `${testIdName}-export-csv-filtered`,
      },
      {
        key: 4,
        text: 'Export All PDF',
        value: 4,
        dataTestId: `${testIdName}-export-pdf`,
      },
      {
        key: 5,
        text: 'Export Filtered PDF',
        value: 5,
        dataTestId: `${testIdName}-export-pdf-filter`,
      },
    ]
  }
}
const dropdownClicked = (
  value: number,
  exportData: (type: 'xlsx' | 'csv' | 'pdf', allRecords: boolean) => void,
) => {
  const type =
    value === 0 || value === 1
      ? 'xlsx'
      : value === 2 || value === 3
      ? 'csv'
      : 'pdf'
  const allRecords = value === 0 || value === 2 || value === 4
  exportData(type, allRecords)
}

const StyledButtonDropdown = styled(ButtonDropDown)`
  display: none;
  @media ${QUERIES.tabletAndUp} {
    display: block;
  }
`

const ExportText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: Center;
  font-size: 13px;
  margin-right: 20px;
  svg {
    min-width: 20px;
  }
`

type Props = {
  hiddenGlobalFilter: boolean
  exportData: (type: 'xlsx' | 'csv' | 'pdf', allRecords: boolean) => void
  testIdName?: string
}

const TableExportButton = ({
  hiddenGlobalFilter = false,
  exportData,
  testIdName,
}: Props) => {
  return (
    <StyledButtonDropdown
      text={
        <ExportText>
          <DownloadSimple size={20} />
          Export
        </ExportText>
      }
      direction="right"
      options={getOptions(hiddenGlobalFilter, testIdName)}
      onChange={value => dropdownClicked(value, exportData)}
      dataCy="export-button"
      dataTestId={`${testIdName}-export`}
    />
  )
}
export default TableExportButton
