import {InputDropdownOptional} from 'common/components'
import {
  Option,
  SemanticInputType,
} from 'common/components/InputDropdownOptional'
import {Funnel} from 'phosphor-react'
import * as React from 'react'
import styled from 'styled-components/macro'
import CollapsiblePanel from './CollapsiblePanel'
import useWindowSize from 'common/useWindowSize'

export interface CollapsibleFilters {
  titleText: string
  filterId: string
  options: Option[]
}

export type FilterState = {
  filterIsOpen: boolean
  filters?: CollapsibleFilters[]
  filterValues?: {name: string; value: SemanticInputType}[] | null
}

const FilterContainer = styled.div`
  padding: 0 20px;
`
// interface FilterActionTypes {
//   type: 'setState' | 'setFilterValues'
//   //action type
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   data?: any
// }

interface Props {
  title: string
  filterState: FilterState
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: React.Dispatch<any>
  filters: CollapsibleFilters[]
  defaultOpen?: boolean
  onChange?: (name: string, value?: unknown) => void
  className?: string
}

const CollapsibleFilter: React.FC<Props> = ({
  title,
  filterState,
  dispatch,
  filters,
  onChange,
  className = '',
  defaultOpen = false,
}) => {
  const {isMobile} = useWindowSize()

  return !isMobile ? (
    <CollapsiblePanel
      className={className}
      titleText={title}
      iconWhenClosed={<Funnel size={16} weight={'fill'} />}
      defaultOpen={filterState?.filterIsOpen || defaultOpen}
    >
      <FilterContainer>
        {filters &&
          filters?.map(filter => {
            let value: SemanticInputType = ''
            if (filterState?.filterValues) {
              const foundValue = filterState.filterValues.find(
                element => element.name === filter.filterId,
              )
              if (foundValue?.value) {
                value = foundValue.value
              }
            }
            return (
              <InputDropdownOptional
                placeholder="Display All"
                key={filter.filterId}
                options={filter.options}
                name={filter.titleText}
                label={filter.filterId}
                clearable={true}
                required={false}
                onChange={e =>
                  onChange
                    ? onChange(e.name, e.value)
                    : dispatch({
                        type: 'setFilterValues',
                        data: {name: filter.filterId, value: e.value},
                      })
                }
                value={value}
                errors={{}}
              />
            )
          })}
      </FilterContainer>
    </CollapsiblePanel>
  ) : (
    <></>
  )
}
export default CollapsibleFilter
