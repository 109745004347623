import {Asset} from 'common/types/opening1Response'
import {UserConfig, useStore} from 'common/useStore'
import {cloneDeep, sortBy} from 'lodash'
import {useMapStore} from 'map/useMapStore'
const setDirections = useMapStore.getState().setDirections
const directions = useMapStore.getState().directions
const removeDirections = useMapStore.getState().removeDirections

type ApplyDistancesToAssetsProps = {
  filteredAssets: Asset[]
  nearByDistances: {idAsset: number; distance: number}[]
}

export const applyDistancesToAssets = ({
  filteredAssets,
  nearByDistances,
}: ApplyDistancesToAssetsProps) => {
  const newAssets = cloneDeep(filteredAssets)
  newAssets.map(asset => {
    const foundIndex = nearByDistances.findIndex(
      d => d.idAsset === asset.idAsset,
    )
    if (foundIndex > -1) {
      asset.FindNearestAssetDistance = nearByDistances[foundIndex].distance
    }
    return asset
  })
  return newAssets
}
const convertMetersToUsersDistance = function (
  numbers: number,
  des: number,
  userConfig: UserConfig,
) {
  let conversionPercent = 0.000621371192 //default to miles
  if (userConfig.Distance_UOM && userConfig.Distance_UOM === 'km') {
    conversionPercent = 0.001
  }
  if (des) {
    return parseFloat((numbers * conversionPercent).toFixed(des))
  } else {
    return Math.round(numbers * conversionPercent)
  }
}

const getAssetDistances = (
  latlng: google.maps.LatLng,
  bounds: google.maps.LatLngBounds,
  assets: Asset[],
) => {
  const distances: {
    idAsset: number
    distance: number
    directions: {
      origin: google.maps.LatLng | null
      destination: google.maps.LatLng | null
    }
  }[] = []
  let destlatlong: google.maps.LatLng | null = null
  const userConfig = useStore.getState().userConfig
  let distance
  if (assets?.length && userConfig) {
    bounds.extend(latlng)
    assets.forEach(function (asset) {
      distance = null
      if (
        asset &&
        asset.Device &&
        asset.Device.Latitude &&
        asset.Device.Longitude &&
        !asset.Primary_idAsset
      ) {
        destlatlong = new window.google.maps.LatLng(
          asset.Device.Latitude,
          asset.Device.Longitude,
        )
        distance = window.google.maps.geometry.spherical.computeDistanceBetween(
          destlatlong,
          latlng,
        )
      }
      if (distance !== null && distance !== undefined && !isNaN(distance)) {
        distances.push({
          idAsset: asset.idAsset,
          distance: convertMetersToUsersDistance(distance, 2, userConfig),
          directions: {
            origin: latlng,
            destination: destlatlong,
          },
        })
      } else {
        distances.push({
          idAsset: asset.idAsset,
          distance: 999999999999,
          directions: {origin: null, destination: null},
        })
      }
    })
  }
  const sortedArr = sortBy(distances, 'distance')
  return sortedArr
}

type ShowDestinationMarkerProps = {
  directionResult: google.maps.DirectionsResult
  index: number
  bounds: google.maps.LatLngBounds
  markersTemp: google.maps.Marker[]
  map: google.maps.Map
}

const showDestinationMarker = ({
  directionResult,
  index,
  bounds,
  markersTemp,
  map,
}: ShowDestinationMarkerProps) => {
  bounds.extend(directionResult.routes[0].legs[0].start_location)
  const m = new window.google.maps.Marker({
    position: directionResult.routes[0].legs[0].start_location,
    label: (index + 1).toString(),
    title:
      'Driving distance: ' +
        directionResult?.routes[0]?.legs[0]?.distance?.text || '',
    map: map,
  })
  window.google.maps.event.addListener(
    m,
    'click',
    function (event: {latLng: google.maps.LatLng}) {
      map.setZoom(17)
      map.panTo(event.latLng)
    },
  )
  markersTemp.push(m)
  return markersTemp
}

type DirectionsRouteType = {
  directions: {
    destination: google.maps.LatLng | null
    origin: google.maps.LatLng | null
  }
  idAsset: number
  distance: number
}

type DisplayRoutesProps = {
  routes: DirectionsRouteType[]
  bounds: google.maps.LatLngBounds
  map: google.maps.Map
}

const displayRoutes = ({routes, bounds, map}: DisplayRoutesProps) => {
  //only redraw if routes have changed
  // let markersTemp = []
  removeDirections()
  //const newDirections = {routes: [], markers: [], directionsServiceArray: []}

  routes.forEach((route, index) => {
    const request = {
        origin: route.directions.destination,
        destination: route.directions.origin,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      rendererOptions = {
        preserveViewport: true,
        map: map,
        suppressMarkers: true,
        draggable: false,
        polylineOptions: {
          strokeColor: '#337ab7',
          strokeWeight: 8,
        },
        routeIndex: index,
      }
    directions.routes[index] = new window.google.maps.DirectionsRenderer(
      rendererOptions,
    )
    var directionservice = new window.google.maps.DirectionsService()
    if (directions?.directionsServiceArray) {
      directions.directionsServiceArray[index] = directionservice

      directions.directionsServiceArray[index].route(
        //@ts-expect-error need to figure out how to make destination optional
        request,
        function (
          directionResult: google.maps.DirectionsResult,
          status: google.maps.DirectionsStatus,
        ) {
          if (status === window.google.maps.DirectionsStatus.OK) {
            if (directions?.routes?.[index]) {
              directions.routes[index]?.setDirections(directionResult)

              directions.markers = showDestinationMarker({
                directionResult,
                index,
                bounds,
                markersTemp: directions.markers || [],
                map,
              })
              //fitbounds is set when clicking find closest then set to false so websockets don't update bounds
              // if (fitbounds) {
              // if (index === directions.directionsServiceArray.length) {
              map.fitBounds(bounds)
              // }
              // }
            }
          }
        },
      )
    }
  })
  setDirections(directions)
}

type GetClosetsAssetsProps = {
  latlng: google.maps.LatLng
  assets: Asset[]
  map: google.maps.Map
  setAssetListOpen?: (inp: boolean) => void
}

export const getClosestAssets = ({
  latlng,
  assets,
  map,
  setAssetListOpen,
}: GetClosetsAssetsProps) => {
  // const setDirections = useMapStore.getState().setDirections

  //called from search map, location click or info window
  let routeAssets: DirectionsRouteType[] = []
  const bounds = new window.google.maps.LatLngBounds() //reset bounds

  if (directions.route) {
    removeDirections()
  }
  //add distance to asset objects in dataset
  const distanceArray = getAssetDistances(latlng, bounds, assets)
  useMapStore.setState({
    showingNearestAssetDistances: true,
    activeMarker: undefined,
    nearByDistances: distanceArray,
  })
  routeAssets = distanceArray.slice(0, 3)
  useStore.setState({
    gridOpen: true,
  })
  if (setAssetListOpen) {
    setAssetListOpen(false)
  }
  // var listener = google.maps.event.addListener(map, 'idle', function () {
  displayRoutes({routes: routeAssets, bounds, map})
  // google.maps.event.removeListener(listener)
  // })
}
