import styled from 'styled-components/macro'

export const InputLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  em {
    color: red;
    font-size: calc(11 / 14 * 1rem);
  }
`

export const StyledLabel = styled.label`
  font-size: calc(14 / 14 * 1rem);
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0;
`

export const StyledInput = styled.input`
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  width: 100%;
`

export const StyledInputTextArea = styled.textarea`
  margin-bottom: 1rem;
  padding: 0.5rem;
`

export const StyledSelect = styled.select`
  min-width: 176px;
  margin-bottom: calc(0.5rem - 2px);
  padding: 0.5rem;
`
