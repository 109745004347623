import * as React from 'react'
import styled from 'styled-components/macro'
import {Checkbox} from 'common/components'
const Container = styled.li`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 20px;
  border-bottom: 1px solid var(--asc-coolgray);
  cursor: pointer;
  &:hover {
    background: #f0f6e1;
    input {
      border: 1px solid var(--asc-moss);
      box-sizing: border-box;
      box-shadow: 0px 0px 6px 1px var(--secondary);
      border-radius: 5px;
    }
  }
`

const LeftSection = styled.div`
  display: flex;
  margin-top: 5px;
`

const RightSection = styled.div`
  font-size: 14px;
`

const LocationName = styled.div`
  font-weight: 700;
`

const LocationDescription = styled.div`
  span {
    font-weight: ${props => (props.isType ? '700' : 'regular')};
  }
  color: ${props => (props.isType ? 'black' : 'var(--asc-sonicsilver)')};
  margin: 5px 0px;
`
const LocationAddress = styled.div``

const LocationCard = ({location, toggleLocation, selectedLocationIDs}) => {
  const [selected, setSelected] = React.useState(false)

  React.useEffect(() => {
    if (
      location?.idLandmark &&
      selectedLocationIDs.includes(location.idLandmark)
    ) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }, [location.idLandmark, selectedLocationIDs])

  const city = location?.Landmark_City ? location.Landmark_City + ', ' : ''
  return (
    <Container
      data-cy={'location-list-card'}
      onClick={toggleLocation}
      data-locationid={location.idLandmark}
    >
      <LeftSection>
        <Checkbox selected={selected} dataCy="location-select" />
      </LeftSection>
      <RightSection>
        <LocationName>{location?.Landmark_Name || ''}</LocationName>
        <LocationDescription isType={false}>
          {location?.Landmark_Label || ''}
        </LocationDescription>
        {location?.Landmark_Style && (
          <LocationDescription isType={true}>
            <span>Type: </span>
            {location.Landmark_Style}
          </LocationDescription>
        )}
        <LocationAddress>{location?.Landmark_Address_Line1}</LocationAddress>
        <LocationAddress>{location?.Landmark_Address_Line2}</LocationAddress>
        <LocationAddress>
          {city}
          {`${location?.Landmark_Region || ''} ${
            location?.Landmark_Country || ''
          }`}
        </LocationAddress>
      </RightSection>
    </Container>
  )
}
export default LocationCard
