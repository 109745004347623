import * as React from 'react'
import styled from 'styled-components/macro'
import {CaretUp, CaretDown} from 'phosphor-react'
import {Dropdown, DropdownItemProps} from 'semantic-ui-react'

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  /* align-items: center; */
  /* align-self: center; */
  a {
    color: black;
    font-weight: 500;
  }
  a:hover {
    color: black !important;
    text-decoration: none;
  }
`
const Container = styled.div`
  display: flex;
  position: relative;
  a:hover {
    text-decoration: none;
  }
`
const StyledDropdown = styled(Dropdown)`
  &&& {
    .icon {
      display: none;
    }
    .menu {
      width: 142px;
      padding: 8px;
      background-color: white;
      border: 1px solid var(--asc-subduedmoss);
      .item {
        padding: 5px 10px !important;
        background: transparent;
        line-height: 22px;
      }
      :hover {
        background: var(--secondary);
      }
    }
    .text {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }
`
const Button = styled.button`
  &&& {
    background-color: var(--secondary);
    border-radius: 5px 0px 0px 5px;
    border-width: 1px 1px 1px 1px;
    border-color: var(--secondary);
    color: var(--primary);
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    height: 30px;
    margin: 0;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
    :first-of-type {
      padding: 0 15px;
    }
    :last-of-type {
      border-radius: 0px 5px 5px 0px;
      border-width: 1px 1px 1px 0px;
      padding: 0 10px !important;
      svg {
        min-height: 14px;
        min-width: 14px;
      }
    }

    :hover {
      background-color: var(--primary) !important;
      color: var(--secondary) !important;
    }
  }
`
const Toggle = styled(Button)`
  &&& {
    border-radius: 0px 5px 5px 0px;
    border-width: 1px 1px 1px 0px;
    padding: 0 10px !important;
    background-color: ${({open}: {open: boolean}) =>
      open ? 'var(--primary)' : 'var(--secondary)'} !important;
    color: ${({open}) =>
      open ? 'var(--secondary)' : 'var(--primary)'} !important;
    svg {
      min-height: 14px;
      min-width: 14px;
    }
    :hover {
      background-color: var(--primary) !important;
      color: var(--secondary) !important;
    }
  }
`
type Props = {
  buttonText: string
  onButtonClick: React.MouseEventHandler<HTMLButtonElement> | undefined
  dropdownItems: {
    text: string
    onClick:
      | ((
          event: React.MouseEvent<HTMLDivElement>,
          data: DropdownItemProps,
        ) => void)
      | undefined
    dataCy: string
  }[]
}
const SplitDropdownButton: React.FC<Props> = ({
  buttonText,
  onButtonClick,
  dropdownItems,
}) => {
  const [open, setOpen] = React.useState(false)

  return (
    <Controls>
      <StyledDropdown
        direction="left"
        open={open}
        onClose={() => setOpen(false)}
        trigger={
          <Container data-cy="add-button">
            <Button onClick={onButtonClick}>{buttonText}</Button>
            <Toggle onClick={() => setOpen(!open)} open={open} data-cy="toggle">
              {open ? <CaretUp weight="fill" /> : <CaretDown weight="fill" />}
            </Toggle>
          </Container>
        }
      >
        <Dropdown.Menu data-cy="dropdown-menu">
          {dropdownItems.map((item, index) => (
            <Dropdown.Item
              key={index}
              text={item.text}
              data-cy={item.dataCy}
              onClick={item.onClick}
            />
          ))}
        </Dropdown.Menu>
      </StyledDropdown>
    </Controls>
  )
}

export default SplitDropdownButton
