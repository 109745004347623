import * as React from 'react'
import {Icon} from 'semantic-ui-react'
import styled from 'styled-components/macro'

const Container = styled.div`
  display: flex;
  border: 1px solid #ccc;
  padding: 15px;
  transition: 0.3s;
  margin: 10px 0px;
  flex-direction: column;
  border-radius: 5px;
`
const InstructionRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  aling-items: center;
  padding: 5px;
`

const PasswordRequirements = ({
  containNumber,
  containCapital,
  length,
  matched,
  noColon,
}) => {
  return (
    <Container data-cy="password-requiremets" className="resetPassword">
      <InstructionRow>
        <Icon
          name={containNumber ? 'check' : 'times'}
          color={containNumber ? 'green' : 'red'}
        ></Icon>
        <div> Contain at least 1 number</div>
      </InstructionRow>
      <InstructionRow>
        <Icon
          name={containCapital ? 'check' : 'times'}
          color={containCapital ? 'green' : 'red'}
        ></Icon>
        <div> Contain at least 1 capital letter</div>
      </InstructionRow>
      <InstructionRow>
        <Icon
          name={length ? 'check' : 'times'}
          color={length ? 'green' : 'red'}
        ></Icon>
        <div> Must be between 8 and 32 characters.</div>
      </InstructionRow>
      <InstructionRow>
        <Icon
          name={noColon ? 'check' : 'times'}
          color={noColon ? 'green' : 'red'}
        ></Icon>
        <div> No colons.</div>
      </InstructionRow>
      <InstructionRow>
        <Icon
          name={matched ? 'check' : 'times'}
          color={matched ? 'green' : 'red'}
        ></Icon>
        <div> Confirm password match.</div>
      </InstructionRow>
    </Container>
  )
}
export default PasswordRequirements
