import * as React from 'react'
import styled from 'styled-components/macro'

const GenericButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 14px;
  gap: 8px;
  border: solid 1px var(--primary);
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
  margin-top: 4px;
  height: fit-content;
  &:hover {
    filter: brightness(90%);
  }
`
const Label = styled.span`
  font-weight: 700;
  font-size: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: var(--primary);
`
interface Props {
  onClick: () => void
  label?: string
  children?: React.ReactNode
}

const StyledButton: React.FC<Props> = ({onClick, label, children}) => {
  return (
    <GenericButton onClick={onClick}>
      {label && <Label>{label}</Label>}
      {children}
    </GenericButton>
  )
}

export default StyledButton
