import * as React from 'react'
import {
  WarningCircle,
  Timer,
  CheckCircle,
  Circle,
  Warning,
  Info,
  ArrowCircleRight,
} from 'phosphor-react'
import {Badge} from 'routingAndDispatch/Routes/routesStyles'
import styled from 'styled-components/macro'

interface Props {
  status: string
  lessPadding?: boolean
  className?: string
  padding?: string
  hasMissed?: boolean
  isLate?: boolean
  maxWidth?: string
}

type OptionType = {
  background: string
  fontColor: string
  borderColor: string
  icon: React.ReactChild
  text: string
  status: string //todo type this to the specific API status strings
}

const IconHolder = styled.div`
  border-radius: 50px;
  height: 18px;
  width: 18px;
  background: white;
  display: flex;
  align-items: Center;
  justify-content: center;
  margin-right: 3px;
  svg {
    margin-right: 0;
  }
`
const options: OptionType[] = [
  {
    background: 'var(--asc-wheat)',
    fontColor: 'black',
    borderColor: 'var(--asc-wheat)',
    icon: <></>,
    text: 'Planning',
    status: 'planning',
  },
  {
    background: 'var(--asc-keylime)',
    fontColor: 'var(--asc-darkolive)',
    borderColor: 'var(--asc-keyline)',
    icon: <Circle color="var(--asc-darkolive)" size={16} weight="regular" />,
    text: 'Driver not yet departed',
    status: 'Pending',
  },
  {
    background: 'var(--asc-keylime)',
    fontColor: 'var(--asc-darkolive)',
    borderColor: 'var(--asc-keyline)',
    icon: <Circle color="var(--asc-darkolive)" size={16} weight="regular" />,
    text: 'Route not yet complete',
    status: 'PendingArrival',
  },
  {
    background: 'var(--asc-selectedgreen)',
    fontColor: 'var(--asc-darkolive)',
    borderColor: 'var(--asc-moss)',
    icon: <CheckCircle color="var(--asc-darkolive)" size={16} weight="fill" />,
    text: 'Departed',
    status: 'DepartedHome',
  },
  {
    background: 'var(--asc-selectedgreen)',
    fontColor: 'var(--asc-darkolive)',
    borderColor: 'var(--asc-moss)',
    icon: (
      <WarningCircle color="var(--asc-vermilion)" weight="fill" size={16} />
    ),
    text: 'Departed Late',
    status: 'DepartedHome-late',
  },
  {
    background: 'var(--asc-platinum)',
    fontColor: 'black',
    borderColor: 'var(--asc-coolgray)',
    icon: (
      <IconHolder>
        <WarningCircle color="var(--asc-vermilion)" size={16} weight="fill" />
      </IconHolder>
    ),
    text: 'Not Departed Yet',
    status: 'not-departed-late',
  },
  {
    background: 'var(--asc-gold)',
    fontColor: 'black',
    borderColor: 'var(--asc-gold)',
    icon: <ArrowCircleRight color="black" size={16} weight="fill" />,
    text: 'In Transit',
    status: 'InTransit',
  },
  {
    background: 'var(--asc-gold)',
    fontColor: 'black',
    borderColor: 'var(--asc-gold)',
    icon: (
      <IconHolder>
        <WarningCircle color="var(--asc-vermilion)" size={16} weight="fill" />
      </IconHolder>
    ),
    text: 'In Transit',
    status: 'intransit-late',
  },
  {
    background: 'var(--asc-selectedgreen)',
    fontColor: 'var(--asc-darkolive)',
    borderColor: 'var(--asc-moss)',
    icon: <CheckCircle color="var(--asc-darkolive)" size={16} weight="fill" />,
    text: 'Arrived',
    status: 'ArrivedHome',
  },
  {
    background: 'var(--asc-selectedgreen)',
    fontColor: 'var(--asc-darkolive)',
    borderColor: 'var(--asc-moss)',
    icon: (
      <WarningCircle color="var(--asc-vermilion)" weight="fill" size={16} />
    ),
    text: 'Arrived Late',
    status: 'ArrivedHome-late',
  },
  {
    background: 'var(--asc-lime)',
    fontColor: 'black',
    borderColor: 'var(--asc-lime)',
    icon: <Timer color="black" size={16} />,
    text: 'In Progress',
    status: 'in-progress-ontime',
  },
  {
    background: 'var(--asc-lime)',
    fontColor: 'black',
    borderColor: 'var(--asc-lime)',
    icon: <Timer color="black" size={16} />,
    text: 'In Progress',
    status: 'inProgress',
  },
  {
    background: 'var(--asc-lime)',
    fontColor: 'black',
    borderColor: 'var(--asc-lime)',
    icon: <Timer color="black" size={16} />,
    text: 'In Progress',
    status: 'arrived',
  },
  {
    background: 'var(--asc-lime)',
    fontColor: 'black',
    borderColor: 'var(--asc-lime)',
    icon: (
      <WarningCircle color="var(--asc-vermilion)" weight="fill" size={16} />
    ),
    text: 'In Progress',
    status: 'arrived-late',
  },
  {
    background: 'var(--asc-lime)',
    fontColor: 'black',
    borderColor: 'var(--asc-lime)',
    icon: <Timer color="black" size={16} />,
    text: 'In Progress',
    status: 'Pending',
  },
  {
    background: 'var(--asc-lime)',
    fontColor: 'black',
    borderColor: 'var(--asc-lime)',
    icon: (
      <IconHolder>
        <WarningCircle color="var(--asc-vermilion)" size={16} weight="fill" />
      </IconHolder>
    ),
    text: 'In Progress',
    status: 'in-progress-late',
  },
  {
    background: 'var(--asc-platinum)',
    fontColor: 'black',
    borderColor: 'var(--asc-collgray)',
    icon: (
      <IconHolder>
        <WarningCircle color="var(--asc-vermilion)" size={16} weight="fill" />
      </IconHolder>
    ),
    text: 'Not Arrived Yet',
    status: 'new-late',
  },
  {
    background: 'var(--asc-selectedgreen)',
    fontColor: 'var(--asc-darkolive)',
    borderColor: 'var(--asc-moss)',
    icon: <CheckCircle color="var(--asc-darkolive)" size={16} weight="fill" />,
    text: 'Completed',
    status: 'Completed',
  },
  {
    background: 'var(--asc-selectedgreen)',
    fontColor: 'var(--asc-darkolive)',
    borderColor: 'var(--asc-moss)',
    icon: (
      <WarningCircle color="var(--asc-vermilion)" size={16} weight="fill" />
    ),
    text: 'Completed',
    status: 'completed-late',
  },
  {
    background: 'var(--asc-selectedgreen)',
    fontColor: 'var(--asc-darkolive)',
    borderColor: 'var(--asc-moss)',
    icon: <Warning color="var(--asc-vermilion)" size={16} weight="fill" />,
    text: 'Completed',
    status: 'completed-missed',
  },
  {
    background: 'tranparent',
    fontColor: 'tranparent',
    borderColor: 'tranparent',
    icon: <></>,
    text: '',
    status: 'new',
  },
  {
    background: 'var(--asc-keylime)',
    fontColor: 'var(--asc-darkolive)',
    borderColor: 'var(--asc-keyline)',
    icon: <></>,
    text: 'Dispatched',
    status: 'Dispatched',
  },
  {
    background: 'var(--asc-stone)',
    fontColor: 'var(--asc-graphite)',
    borderColor: 'var(--asc-stone)',
    icon: <></>,
    text: 'Processing',
    status: 'processing',
  },
  //Nothing should be 'assigned' - blank just in case
  {
    background: 'tranparent',
    fontColor: 'tranparent',
    borderColor: 'tranparent',
    icon: <></>,
    text: '',
    status: 'assigned',
  },
  {
    background: 'var(--asc-cultured)',
    fontColor: 'var(--asc-dimgray)',
    borderColor: 'var(--asc-coolgray)',
    icon: <></>,
    text: 'Unassigned',
    status: 'unassigned',
  },
  {
    background: 'var(--asc-vermilion)',
    fontColor: 'white',
    borderColor: 'var(--asc-vermilion)',
    icon: <Warning color="white" size={16} weight="fill" />,
    text: 'Missed',
    status: 'missed',
  },
  {
    background: 'var(--asc-vermilion)',
    fontColor: 'white',
    borderColor: 'var(--asc-vermilion)',
    icon: <Info color="white" size={16} weight="fill" />,
    text: 'Error',
    status: 'error',
  },
  {
    background: 'var(--asc-keylime)',
    fontColor: 'var(--asc-darkolive)',
    borderColor: 'var(--asc-keyline)',
    icon: <></>,
    text: 'Dispatched',
    status: 'planned',
  },
  {
    background: 'var(--asc-vermilion)',
    fontColor: 'white',
    borderColor: 'var(--asc-vermilion)',
    icon: <Warning color="white" size={16} weight="fill" />,
    text: 'Partial',
    status: 'Partial',
  },
]
const StatusBadge: React.FC<Props> = ({
  status,
  lessPadding = false,
  className,
  padding,
  hasMissed = false,
  isLate = false,
  maxWidth,
}) => {
  const statusWithLate = `${status.toLowerCase()}${
    isLate && status.toLowerCase() !== 'missed' ? '-late' : ''
  }`
  const statusOption = options.find(
    option => option.status.toLowerCase() === statusWithLate,
  )

  return statusOption?.text ? (
    <Badge
      className={className}
      background={statusOption.background}
      fontColor={statusOption.fontColor}
      borderColor={statusOption.borderColor}
      hideShadow={true}
      lessPadding={lessPadding}
      padding={padding}
      data-cy="status-badge"
      maxWidth={maxWidth || 'none'}
    >
      {hasMissed ? (
        <Warning weight="fill" color="var(--asc-vermilion)" />
      ) : (
        statusOption.icon
      )}
      {statusOption.text}
    </Badge>
  ) : (
    <></>
  )
}
export default StatusBadge
