import {ascendColors} from './GlobalStyles'
import {checkContrastAndReturnBestFontColor} from './helpers'

export const setCustomColors = ({
  primary,
  secondary,
  tertiary,
}: {
  primary: string
  secondary: string
  tertiary: string
}) => {
  const fontOnPrimary = checkContrastAndReturnBestFontColor(primary, secondary)
  const fontOnSecondary = checkContrastAndReturnBestFontColor(
    secondary,
    primary,
  )
  const fontOnTertiary = checkContrastAndReturnBestFontColor(tertiary, primary)
  ascendColors.fontOnPrimary = fontOnPrimary
  ascendColors.fontOnSecondary = fontOnSecondary
  ascendColors.fontOnTertiary = fontOnTertiary
  ascendColors.customPrimary = primary
  ascendColors.customSecondary = secondary
  ascendColors.customTertiary = tertiary
}
