import {RequiredAsterisk, Spinner} from 'common/components'
import {SemanticInputType} from 'common/components/InputDropdownOptional'
import {FlagCheckered, House} from 'phosphor-react'
import * as React from 'react'
import useRouteLocations from 'routingAndDispatch/hooks/useRouteLocations'
import {Dropdown} from 'semantic-ui-react'
import styled from 'styled-components/macro'

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 200px;
  border: solid 1px var(--asc-coolgray);
  border-radius: 5px;

  .visible.menu.transition {
    //makes the dropdown window full width
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
  }

  &&& > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    justify-content: space-between;
    padding: 10px;
  }

  &&& > div > input.search {
    padding-left: 38px !important;
    //fix bug with clearable in semantic react
    width: initial !important;
  }

  svg {
    min-height: 20px;
    min-width: 20px;
  }
`
const StyledLabel = styled.label`
  display: block;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  display: flex;
  margin-bottom: 5px;
`
const IconWithText = styled.span`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  max-width: 100%;
`

interface Props {
  value: string | undefined
  id: string
  onChange: ({name, value}: {name: string; value: SemanticInputType}) => void
  clearable?: boolean
  disabled?: boolean
  className?: string
  startOrEnd?: 'start' | 'end'
  label?: string
  required?: boolean
  showDefault?: boolean
}
export const DEFAULT_DRIVER_LOCATION = ''

const LocationsDropdown: React.FC<Props> = ({
  value,
  clearable = false,
  disabled = false,
  onChange,
  className = '',
  id,
  startOrEnd = 'start',
  label = '',
  required = false,
  showDefault = false,
}) => {
  const {data: locationsData, status: locationsStatus} = useRouteLocations()

  const [options, setOptions] = React.useState<
    {key: number; text: string; value: string}[]
  >([])
  const [searchQuery, setSearchQuery] = React.useState('')

  const handleSearch = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    {searchQuery}: {searchQuery: string},
  ) => {
    setSearchQuery(searchQuery)
  }

  const trigger =
    startOrEnd === 'start' ? (
      <IconWithText data-cy={'start-icon'}>
        <House size={20} color="var(--tertiary)" weight="fill" />
        {searchQuery
          ? null
          : options?.find(option => option.value === value)?.text ||
            '--Choose one--'}
      </IconWithText>
    ) : (
      <IconWithText data-cy={'end-icon'}>
        <FlagCheckered size={20} color="#000" weight="fill" />
        {searchQuery
          ? null
          : options?.find(option => option.value === value)?.text ||
            '--Choose one--'}
      </IconWithText>
    )

  React.useEffect(() => {
    if (locationsStatus === 'success') {
      const tempOptions: {text: string; value: string; key: number}[] = []
      const sortedLocationsData = locationsData.sort((a, b) =>
        a.title.localeCompare(b.title),
      )

      sortedLocationsData?.forEach((location, index) => {
        const locationOption = {
          key: index,
          text: location.title,
          value: location._id,
        }
        tempOptions.push(locationOption)
      })
      setOptions(tempOptions)
    }
  }, [locationsData, locationsStatus, showDefault, startOrEnd])

  return (
    <DropdownWrapper className={className}>
      {label && (
        <StyledLabel htmlFor={id}>
          {label}
          {required && <RequiredAsterisk />}
        </StyledLabel>
      )}
      <Container className={className}>
        {locationsStatus === 'loading' ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <></>
        )}
        <Dropdown
          data-cy={`location-dropdown`}
          fluid
          trigger={trigger}
          clearable={clearable}
          selectOnBlur={false}
          disabled={disabled}
          id={id}
          placeholder={'--Choose one--'}
          options={options || []}
          search
          onSearchChange={handleSearch}
          searchQuery={searchQuery}
          value={value}
          data-lpignore="true"
          onBlur={() => setSearchQuery('')}
          onChange={(e, {name, value}) => {
            setSearchQuery('')
            if (value !== undefined) onChange({name, value})
          }}
        />
      </Container>
    </DropdownWrapper>
  )
}
export default LocationsDropdown
