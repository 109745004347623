import * as React from 'react'

import styled from 'styled-components/macro'
import Routes from 'common/Routes'

const Container = styled.div`
  height: 100%;
  width: 100%;
`

const Main = () => {
  return (
    <Container>
      <Routes />
    </Container>
  )
}
export default Main
