import {ButtonType} from 'common/components/AlertModal'
import {isValidDate} from 'common/helpersDateTime'
import {addMinutes, endOfDay} from 'date-fns'
import {
  StopReducerAction,
  ViewableStop,
} from 'routingAndDispatch/Stops/stopHelper'
import {OrderType, StopType} from 'routingAndDispatch/types/typesModule'

export interface StopCardDropdownProps {
  idStop: string
  idSegment: string
  stopStatus: string
  idPlan: string
  stop: StopType
  isCurrentStop: boolean
  startRoute?: boolean
  endRoute?: boolean
  isMissed?: boolean
  stopBeforeEndDate?: Date | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetch: any
  planStartDateTime: Date | undefined
  routeDetailDispatch: (action: StopReducerAction) => void
  isEnd?: boolean
  routeHasStarted?: boolean
  otherStopsOnOrder?: StopType[]
}
interface State {
  menuOpen: boolean
  errorMessage: string
  submitting: boolean
  showDialogModal: boolean
  showStopModal: boolean
  isDirty: boolean
  currentStopLoaded: boolean
  showAddressOrCoordinates: 'address' | 'coordinates'
  errors: {
    [key: string]: {type: string; message: string}
  }
  stop: Partial<ViewableStop> | null
  modalType: ButtonType
  modalTitle: string
  modalText: string
  selectedDropdownOption: DropdownOption | null
  showDenyButton: boolean
}

type ActionTypes = {
  type: 'setState' // | 'otherType'
  data?: unknown
}

export type ReducerType = [
  state: State & Partial<OrderType>,
  dispatch: (action: ActionTypes | StopReducerAction) => void,
]

export enum DropdownOption {
  SendNotification = 1,
  DriverOnTheWay,
  Arrive,
  Depart,
  //Edit,
  Delete,
  StartRoute,
  EndRoute,
  AddStop,
}

export const initialState: State = {
  menuOpen: false,
  errorMessage: '',
  submitting: false,
  showDialogModal: false,
  showStopModal: false,
  isDirty: false,
  currentStopLoaded: false,
  showAddressOrCoordinates: 'address',
  errors: {},
  stop: null,
  modalType: '',
  modalTitle: '',
  modalText: '',
  showDenyButton: false,
  selectedDropdownOption: null,
}
export const getStopDate = ({
  stopBeforeEndDate,
  planStartDateTime,
}: {
  stopBeforeEndDate: Date | undefined
  planStartDateTime: Date | undefined
}) => {
  let stopStartDate: Date = new Date()
  let stopEndDate: Date = new Date()

  //for start date
  if (stopBeforeEndDate && isValidDate(stopBeforeEndDate)) {
    stopStartDate = addMinutes(stopBeforeEndDate, 1)
  } else if (planStartDateTime) {
    stopStartDate = planStartDateTime
  }

  if (stopBeforeEndDate && isValidDate(stopBeforeEndDate)) {
    stopEndDate = addMinutes(stopBeforeEndDate, 31)
  } else {
    stopEndDate = endOfDay(planStartDateTime ? planStartDateTime : new Date())
  }

  return {stopStartDate, stopEndDate}
}

export const getOptions = ({
  startRoute,
  endRoute,
  stopStatus,
  routeHasStarted,
}: {
  startRoute: boolean
  endRoute: boolean
  stopStatus: string
  routeHasStarted: boolean
}) => {
  if (!routeHasStarted && !startRoute && !endRoute) {
    return [
      {
        key: DropdownOption.Delete,
        text: 'Delete',
        value: DropdownOption.Delete,
        dataCy: 'Delete',
      },
    ]
  }
  if (startRoute) {
    return [
      {
        key: DropdownOption.StartRoute,
        text: 'Begin Route',
        value: DropdownOption.StartRoute,
        dataCy: 'begin-route',
      },
    ]
  }
  if (endRoute) {
    return [
      {
        key: DropdownOption.EndRoute,
        text: 'Complete Route',
        value: DropdownOption.EndRoute,
        dataCy: 'end-route',
      },
    ]
  }
  if (stopStatus.toLocaleLowerCase() === 'arrived')
    return [
      {
        key: DropdownOption.Depart,
        text: 'Depart',
        value: DropdownOption.Depart,
        dataCy: 'Depart',
      },
      {
        key: DropdownOption.SendNotification,
        text: 'Send Notification',
        value: DropdownOption.SendNotification,
        dataCy: 'send-notification',
      },
    ]
  if (stopStatus.toLocaleLowerCase() === 'intransit')
    return [
      {
        key: DropdownOption.Arrive,
        text: 'Arrive',
        value: DropdownOption.Arrive,
        dataCy: 'Arrive',
      },
      {
        key: DropdownOption.SendNotification,
        text: 'Send Notification',
        value: DropdownOption.SendNotification,
        dataCy: 'send-notification',
      },
    ]
  // if (routeHasArrivedStop) {
  //   return [
  //     {
  //       key: DropdownOption.SendNotification,
  //       text: 'Send Notification',
  //       value: DropdownOption.SendNotification,
  //       dataCy: 'send-notification',
  //     },
  //     // {key: DropdownOption.Edit, text: 'Edit', value: 4, dataCy: 'Edit'},
  //     {
  //       key: DropdownOption.Delete,
  //       text: 'Delete',
  //       value: DropdownOption.Delete,
  //       dataCy: 'Delete',
  //     },
  //     // {
  //     //   key: DropdownOption.AddStop,
  //     //   text: 'Insert Stop After This One',
  //     //   value: DropdownOption.AddStop,
  //     //   dataCy: 'add-stop',
  //     // },
  //   ]
  // }
  if (stopStatus.toLocaleLowerCase() === 'missed')
    return [
      {
        key: DropdownOption.SendNotification,
        text: 'Send Notification',
        value: DropdownOption.SendNotification,
        dataCy: 'send-notification',
      },
    ]

  return [
    {
      key: DropdownOption.DriverOnTheWay,
      text: 'Driver On The Way',
      value: DropdownOption.DriverOnTheWay,
      dataCy: 'DriverOnTheWay',
    },
    //TODO make add stop allowed on completed stop as long as next stop is new
    {
      key: DropdownOption.Arrive,
      text: 'Arrive',
      value: DropdownOption.Arrive,
      dataCy: 'Arrive',
    },
    {
      key: DropdownOption.SendNotification,
      text: 'Send Notification',
      value: DropdownOption.SendNotification,
      dataCy: 'send-notification',
    },
    {
      key: DropdownOption.Delete,
      text: 'Delete',
      value: DropdownOption.Delete,
      dataCy: 'Delete',
    },
  ]
}
