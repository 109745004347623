import * as React from 'react'
import {TextArea} from 'semantic-ui-react'
import styled from 'styled-components/macro'

const MessageInput = styled(TextArea)`
  padding: 10px;
  resize: none;
  border: 1px solid var(--table-borders);
  border-radius: 3px;
  width: 100%;
  ${(
    {rows}, //applies single line input styling
  ) =>
    rows === 1 &&
    `
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      ::-webkit-scrollbar {
        display: none;
      }
    `}
`
type InsertAtCursorTextProps = {
  value: string
  name: string
  rows?: number
  onChange: (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    {value}: {value: string},
  ) => void
  handleClick: (position: number) => void
}

const InsertAtCursorText: React.FC<InsertAtCursorTextProps> = ({
  value,
  name,
  rows, //pass as 1 for input like styling
  onChange,
  handleClick,
}) => {
  const onClick = (e: React.MouseEvent<HTMLTextAreaElement>) => {
    const input = e.target as HTMLTextAreaElement
    handleClick(input.selectionStart)
  }

  return (
    <MessageInput
      value={value}
      name={name}
      rows={rows || 10}
      onChange={onChange}
      onClick={onClick}
      data-cy={`${name}-insert-input`}
    />
  )
}
export default InsertAtCursorText
