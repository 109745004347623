import * as React from 'react'
import styled from 'styled-components/macro'
import {QUERIES} from 'common/constants'

const Container = styled.div`
  overflow: auto;
  height: 170px;
  width: 100vw;
  border-radius: 0px 0px 20px 0px;

  @media ${QUERIES.tabletAndUp} {
    width: 300px;
    ::-webkit-scrollbar-track {
      border-radius: 0px 0px 150px 0px;
    }
  }
`
const Table = styled.table`
  width: 100%;
  text-align: left;
  font-size: 14px;
  border-collapse: collapse;
  tr:nth-child(even) {
    background-color: var(--asc-cultured);
  }
`
const Heading = styled.th`
  width: 50%;
  padding: 7px 10px;
  border-bottom: 1px solid var(--asc-coolgray);
`
const Cell = styled.td`
  border-bottom: 1px solid var(--asc-coolgray);
`

const LimitedHeight = styled.div`
  overflow-y: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`

const DCDTable = ({dcdRenderer, returnDCDValue, asset}) => {
  return (
    <Container>
      <Table>
        <tbody>
          {dcdRenderer?.map(item => (
            <tr key={item.idSchema}>
              <Heading>{item.columnheader}</Heading>
              <Cell>
                <LimitedHeight>{returnDCDValue(asset, item)}</LimitedHeight>
              </Cell>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

export default DCDTable
