import * as React from 'react'
import styled from 'styled-components/macro'

const Container = styled.div`
  display: flex;
`
const Message = styled.div`
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: #31708f;
`
const Info = styled.div`
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
`
const CustomizeInstruction = ({message}) => {
  return (
    <Container data-cy="customize-instruction">
      <Info>
        <Message>{message}</Message>
      </Info>
    </Container>
  )
}
export default CustomizeInstruction
