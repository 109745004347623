import {getTravelTimeAndDistance} from '../routeDetailHelpers'
import {useStore} from 'common/useStore'
import {StopType} from 'routingAndDispatch/types/typesModule'
import {TravelTimeObj} from '../useRouteDetailStore'
import {numberFormatter} from 'common/helpersNumber'

export const getTravelTime = async ({
  firstStop,
  secondStop,
}: {
  firstStop: StopType
  secondStop: StopType
}): Promise<TravelTimeObj | null> => {
  const userConfig = useStore.getState().userConfig
  const unitSystem =
    userConfig?.Distance_UOM === 'km'
      ? google.maps.UnitSystem.METRIC
      : google.maps.UnitSystem.IMPERIAL
  const response = await getTravelTimeAndDistance({
    points: {
      start: {
        lat: firstStop?.location.lat || 0,
        lng: firstStop?.location.lng || 0,
      },
      end: {
        lat: secondStop?.location.lat || 0,
        lng: secondStop?.location.lng || 0,
      },
    },
    unitSystem,
  })
  if (response) {
    const newTravelTime = {
      distance: response.routes[0].legs[0].distance?.value || 0,
      duration: response.routes[0].legs[0].duration?.text || '',
    }
    return newTravelTime
  }
  return null
}

export const getFormattedDistance = (distance: number | undefined) => {
  if (!distance) return ''
  const userConfig = useStore.getState().userConfig
  let formattedDistance = `${numberFormatter(distance / 1000, 1)} kilometers`
  if (userConfig?.Distance_UOM === 'mi') {
    formattedDistance = `${numberFormatter(distance / 1609.34, 1)} miles`
  }
  return formattedDistance
}

export const getStopKeyForTravelTime = (stop1: StopType, stop2: StopType) => {
  const firstStopLatLngString = `${stop1?.location?.lat},${stop1?.location?.lng}`
  const secondStopLatLngString = `${stop2?.location?.lat},${stop2?.location?.lng}`
  return firstStopLatLngString + ':' + secondStopLatLngString
}
