import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'
import {CameraStatusType} from 'cameras/hooks/useCameraList'

const getCameraStatus = async (idAsset: number, idDevice_Type: number) => {
  if (idAsset && (idDevice_Type === 11 || idDevice_Type === 35)) {
    const {data}: {data: CameraStatusType} = await Axios.get(
      '/camera/assetonline/' + idAsset,
    )
    return data
  } else {
    return null
  }
}

export default function useCameraStatusData(
  idAsset: number,
  idDevice_Type: number,
) {
  const user = useStore(state => state.user)
  return useQuery(
    ['cameraStatus', idAsset],
    () => getCameraStatus(idAsset, idDevice_Type),
    {
      enabled: Boolean(user?.token),
    },
  )
}
