import {GOOGLE_KEYS} from 'common/constants'
import * as React from 'react'
import {Icon} from 'semantic-ui-react'
import {RenderMarker} from './cameraFootageHelper'
import GoogleMapReact from 'google-map-react'

type GoogleMapReactProps = {
  bootstrapURLKeys: {
    client: string
    libraries: string[]
  }
  center: google.maps.LatLngLiteral
  onGoogleApiLoaded: ({map}: {map: google.maps.Map}) => void
  yesIWantToUseGoogleMapApiInternals?: boolean
  zoom: number
}

const MapFootage = ({
  coordinates,
  setMapRendered,
  mapRendered,
}: {
  coordinates: google.maps.LatLngLiteral
  setMapRendered: (isMapRendered: boolean) => void
  mapRendered: boolean
}) => {
  const googleMapProps: GoogleMapReactProps = {
    bootstrapURLKeys: GOOGLE_KEYS,
    zoom: 17,
    center: coordinates,
    yesIWantToUseGoogleMapApiInternals: true,
    onGoogleApiLoaded: ({map}) => (map ? setMapRendered(true) : null),
  }

  return (
    <GoogleMapReact {...googleMapProps}>
      {mapRendered && (
        <RenderMarker lat={coordinates.lat} lng={coordinates.lng} text="Marker">
          <Icon name="map marker alternate" color="red" size="huge" />
        </RenderMarker>
      )}
    </GoogleMapReact>
  )
}
export default MapFootage
