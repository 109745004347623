import JsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

export const saveAsPDF = (columns, data, fileName) => {
  const headerNames = columns.map(
    column => column.exportHeader || column.exportValue || column.header,
  )
  const doc = new JsPDF({
    orientation: 'l',
    format: 'letter',
  })
  let columnStyles = {}
  columns.forEach((column, index) => {
    if (column?.formatting === 'number' || column.formatting === 'currency') {
      columnStyles[index] = {halign: 'right'}
    }
  })
  data.forEach((row, rowIndex) => {
    row.forEach((value, colIndex) => {
      if (!value) return ''
      let formattedValue = value
      if (columns[colIndex]?.formatting === 'number') {
        formattedValue = value.toLocaleString(undefined, {
          minimumFractionDigits: columns[colIndex]?.decimals,
          maximumFractionDigits: columns[colIndex]?.decimals,
        })
      } else if (columns[colIndex]?.formatting === 'currency') {
        formattedValue =
          '$' +
          value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
      }
      if (formattedValue !== value) {
        data[rowIndex][colIndex] = formattedValue
      }
    })
    return row
  })

  autoTable(doc, {
    head: [headerNames],
    body: data,
    margin: {top: 10, left: 10, right: 10, bottom: 10},
    styles: {
      minCellHeight: 8,
      halign: 'left',
      valign: 'center',
      fontSize: 9,
    },
    columnStyles,
  })
  doc.save(`${fileName || 'export'}.pdf`)
}
