import {create} from 'zustand'
import {ColumnFilterType} from './gridTypes'
import {Asset} from 'common/types/opening1Response'

type State = {
  globalFilter: string
  setGlobalFilter: (globalFilter: string) => void
  columnFilters: ColumnFilterType[]
  setColumnFilter: ({name, value}: {name: keyof Asset; value: string}) => void
  clearAllFilters: () => void
}

const initialStore = {
  globalFilter: '',
  columnFilters: [],
}

const updateColumnFilters = ({
  columnFilters = [],
  name,
  value,
}: {
  columnFilters: ColumnFilterType[]
  name: keyof Asset
  value: string
}) => {
  let filtersChanged = false
  const newColumnFilters: ColumnFilterType[] = [...columnFilters]
  const foundFilterIndex = newColumnFilters.findIndex(cf => cf?.name === name)
  if (foundFilterIndex > -1) {
    if (value) {
      if (newColumnFilters[foundFilterIndex].value !== value) {
        filtersChanged = true
        newColumnFilters[foundFilterIndex].value = value
      }
    } else {
      filtersChanged = true
      newColumnFilters.splice(foundFilterIndex, 1)
    }
  } else {
    if (value) {
      filtersChanged = true
      newColumnFilters.push({name, value})
    }
  }

  return filtersChanged ? newColumnFilters : null
}

export const useGridFilterStore = create<State>((set, get) => ({
  globalFilter: initialStore.globalFilter,
  setGlobalFilter: globalFilter => set({globalFilter}),
  columnFilters: initialStore.columnFilters,
  // getColumnFilter: ({name}: {name: string}) => get().columnFilters[name],
  setColumnFilter: ({name, value}) => {
    const columnFilters = get().columnFilters || []
    const newColumnFilters = updateColumnFilters({columnFilters, name, value})
    if (newColumnFilters) {
      set({columnFilters: newColumnFilters})
    }
  },
  clearAllFilters: () => {
    const globalFilter = get().globalFilter
    const columnFilters = get().columnFilters
    const htmlColumnFilterInputs = document.getElementsByClassName(
      'columnFilter',
    ) as HTMLCollectionOf<HTMLInputElement>
    //grid column filters are uncontrolled
    for (var i = 0; i < htmlColumnFilterInputs.length; i++) {
      if (htmlColumnFilterInputs[i].value) {
        // console.log('clearing', htmlColumnFilterInputs[i])
        htmlColumnFilterInputs[i].value = ''
      }
    }
    if (globalFilter || columnFilters.length) {
      set(state => {
        return {...state, globalFilter: '', columnFilters: []}
      })
    }
  },
}))
