import * as React from 'react'
import styled from 'styled-components/macro'
import {CellContext} from '@tanstack/react-table'
import {Asset} from 'common/types/opening1Response'
import {oneDecimalRender} from 'common/tables/tableHelpers'

const DirectDistanceDiv = styled.div`
  color: white;
  text-align: right;
  padding-right: 5px;
`

const DirectDistanceCell = (instance: CellContext<Asset, unknown>) => {
  let value = Number(instance.getValue())
  if (value) {
    return (
      <DirectDistanceDiv>{`${
        value > 9999999999 ? '' : oneDecimalRender({value}, 0)
      }`}</DirectDistanceDiv>
    )
  }
  return null
}

export default DirectDistanceCell
