import {create} from 'zustand'

const initialStore = {
  map: null,
  directionsRendered: [],
}

type DirectionsType = {
  id: string
  direction: google.maps.DirectionsRenderer
}

type State = {
  map: google.maps.Map | null
  directionsRendered: DirectionsType[]
  setMap: (map: google.maps.Map) => void
  removeDirection: (id: string) => void
  addDirection: (direction: DirectionsType) => void
  emptyStore: () => void
}

export const useRouteDetailStore = create<State>(set => ({
  ...initialStore,
  emptyStore: () => set(state => ({...state, ...initialStore})),
  setMap: (map: google.maps.Map) => set(state => ({...state, map})),
  addDirection: (direction: DirectionsType) =>
    set(state => ({
      ...state,
      directionsRendered: [...state.directionsRendered, direction],
    })),
  removeDirection: (id: string) =>
    set(state => {
      const thisDirection = state.directionsRendered.find(d => d.id === id)
      if (thisDirection) {
        thisDirection.direction.setMap(null)
        thisDirection.direction.setDirections(null)
      }
      const otherDirections = state.directionsRendered.filter(d => d.id !== id)
      return {...state, directionsRendered: otherDirections}
    }),
}))
