import * as React from 'react'
import styled from 'styled-components/macro'
import {roundToDecimals, numberWithCommas} from 'common/helpersNumber'

const Container = styled.div`
  overflow: auto;
  height: 208px;
  margin-left: 5px;
`
const Table = styled.table`
  width: 100%;
  text-align: left;
  font-size: 14px;
  border-collapse: collapse;
  tr {
    border-bottom: 1px solid var(--asc-coolgray);
  }
  tr:nth-child(even) {
    background-color: var(--asc-cultured);
  }
`
const Heading = styled.th`
  padding: 10px 35px;
  width: 45%;
`
const Value = styled.td`
  padding: 10px 35px;
`

const DataTab = ({asset}) => {
  const [formattedData, setFormattedData] = React.useState({})

  const stringifedAsset = JSON.stringify(asset)

  React.useEffect(() => {
    if (asset) {
      const lat =
        isNaN(asset?.Device?.Latitude) === false
          ? roundToDecimals(asset?.Device?.Latitude, 5)
          : ''
      const lng =
        isNaN(asset?.Device?.Latitude) === false
          ? roundToDecimals(asset?.Device?.Longitude, 5)
          : ''

      const speed = roundToDecimals(asset?.Device?.Last_GPS_Speed, 1)
      const hours = numberWithCommas(asset?.Device?.Estimated_Engine_Hours, 1)
      const voltage = roundToDecimals(asset?.Device?.Last_Battery_Voltage, 1)
      const odometer = numberWithCommas(asset?.Device?.Last_GPS_Odometer, 1)
      const fuelEconomy = numberWithCommas(
        asset?.Device?.Last_Fuel_Efficiency,
        1,
      )
      const deviceType = asset?.Device?.Device_Type
      const deviceModel = asset?.Device.DeviceModel
      setFormattedData({
        lat,
        lng,
        speed,
        hours,
        voltage,
        odometer,
        fuelEconomy,
        deviceType,
        deviceModel,
      })
    }
  }, [stringifedAsset, asset])

  return (
    <Container>
      <Table>
        <tbody>
          <tr>
            <Heading>Lat/Lng</Heading>
            <Value>
              {formattedData.lat}, {formattedData?.lng}
            </Value>
          </tr>
          <tr>
            <Heading>Device Type</Heading>
            <Value>{formattedData.deviceType}</Value>
          </tr>
          <tr>
            <Heading>Device Model</Heading>
            <Value>{formattedData.deviceModel}</Value>
          </tr>
          <tr>
            <Heading>Serial #</Heading>
            <Value>{asset.Device?.Serial_Number_Displayed}</Value>
          </tr>
          <tr>
            <Heading>idD/A/C</Heading>
            <Value>
              {asset.Device?.idDevice}/{asset.idAsset}
              {'/'}
              {asset.Device?.idCustomer}
            </Value>
          </tr>
          <tr>
            <Heading>Bus Type</Heading>
            <Value>{asset.Device?.Bus_Type}</Value>
          </tr>
          <tr>
            <Heading>Carrier</Heading>
            <Value>{asset.Device?.Carrier1}</Value>
          </tr>
          <tr>
            <Heading>Battery Voltage</Heading>
            <Value>
              {formattedData?.voltage ? `${formattedData?.voltage} v` : ''}
            </Value>
          </tr>
          <tr>
            <Heading>Firmware</Heading>
            <Value>{asset.Device?.Firmware}</Value>
          </tr>
          <tr>
            <Heading>Days since device shipped</Heading>
            <Value>{asset.Device?.Days_Since_Start}</Value>
          </tr>
        </tbody>
      </Table>
    </Container>
  )
}
export default DataTab
