import * as React from 'react'
import SearchContainer from 'opening/SearchContainer'

const GlobalFilter = ({totalCount, globalFilter, setGlobalFilter}) => {
  const [value, setValue] = React.useState(globalFilter)
  const onChange = value => {
    setGlobalFilter(value || undefined)
  }

  return (
    <SearchContainer
      dataCy={'grid-global-search-input'}
      placeholder={true}
      clearFunction={() => {
        setValue('')
        onChange('')
      }}
      handleSearch={e => {
        setValue(e.target.value)
        onChange(e.target.value)
      }}
      searchValue={value || ''}
      searchType={`${totalCount} records`}
    />
  )
}

export default GlobalFilter
