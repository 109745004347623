import * as React from 'react'
import {
  EndMarker,
  // MissedStopMarker,
  SameEndpointMarker,
  StartMarker,
  StopMarker,
} from './routeDetailHelpers'
import {SegmentWithColor} from '../PlanReview/PlanReview'
import {StopType} from 'routingAndDispatch/types/typesModule'
import {formatISO} from 'date-fns'
import {ascendColors} from 'common/GlobalStyles'

const getMarkerColor = ({
  stopStatus,
  segmentColor,
  detail,
}: {
  stopStatus: StopType['stopStatus']
  segmentColor: string
  detail: boolean
}) => {
  if (!detail) {
    return {
      backgroundColor: segmentColor,
      color: 'white',
      borderColor: segmentColor,
    }
  }
  switch (stopStatus) {
    case 'Completed':
      return {
        backgroundColor: ascendColors.moss,
        color: 'white',
        borderColor: ascendColors.subduedmoss,
      }
    case 'Arrived':
      return {
        backgroundColor: ascendColors.lime,
        color: 'black',
        borderColor: ascendColors.primary,
      }
    case 'Missed':
      return {
        backgroundColor: ascendColors.vermilion,
        color: 'white',
        borderColor: ascendColors.brick,
      }
    case 'InTransit':
      return {
        backgroundColor: ascendColors.gold,
        color: 'black',
        borderColor: 'black',
      }
    default:
      return {
        backgroundColor: '#EAEAEC',
        color: 'black',
        borderColor: ascendColors.darkolive,
      }
  }
}

const RenderStop = ({
  stop,
  segmentToRender,
  index,
  lat,
  lng,
  detail,
}: {
  stop: StopType
  segmentToRender: SegmentWithColor
  index: number
  lat: number
  lng: number
  detail: boolean
}) => {
  const origin = index === 0
  const destination = index === segmentToRender.stops.length - 1
  const markerColors = getMarkerColor({
    stopStatus: stop.stopStatus,
    detail,
    segmentColor: segmentToRender.color || ascendColors.brown,
  })
  const sameStartAndEndLocation =
    segmentToRender.stops[0].location.lat ===
      segmentToRender.stops[segmentToRender.stops.length - 1].location.lat &&
    segmentToRender.stops[0].location.lng ===
      segmentToRender.stops[segmentToRender.stops.length - 1].location.lng
  if (sameStartAndEndLocation && origin) {
    return (
      <SameEndpointMarker
        text={
          segmentToRender.driverName.split(' ')[0].charAt(0) +
          segmentToRender.driverName.split(' ')[1].charAt(0)
        }
        lat={Number(stop.location.lat)}
        lng={Number(stop.location.lng)}
        markerColors={markerColors}
      />
    )
  } else {
    if (origin) {
      return (
        <StartMarker
          text={
            segmentToRender.driverName.split(' ')[0].charAt(0) +
            segmentToRender.driverName.split(' ')[1].charAt(0)
          }
          markerColors={markerColors}
          lat={lat}
          lng={lng}
        />
      )
    } else if (destination && !sameStartAndEndLocation) {
      return (
        <EndMarker
          key={formatISO(stop.ServiceStartDateTime || 0)}
          text={
            segmentToRender.driverName.split(' ')[0].charAt(0) +
            segmentToRender.driverName.split(' ')[1].charAt(0)
          }
          markerColors={markerColors}
          lat={lat}
          lng={lng}
        />
      )
    } else if (!destination) {
      return (
        <StopMarker
          text={index.toString()}
          markerColors={markerColors}
          lat={lat}
          lng={lng}
        />
      )
    } else {
      return <></>
    }
  }
}

export default RenderStop
