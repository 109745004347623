import * as React from 'react'

import {ArrowsVertical, Truck} from 'phosphor-react'
import {
  BoldableText,
  DistanceText,
  EstimateTextContainer,
  PlaceHolderContainer,
  TimeText,
} from '../routeDetailStyles'
import ShimmerPlaceholder from 'common/components/ShimmerPlaceholder'
import {useRouteDetailStore} from '../useRouteDetailStore'
import {Segment} from 'routingAndDispatch/types/typesModule'

import {
  getFormattedDistance,
  getStopKeyForTravelTime,
} from './stopCardListHelpers'

const getShouldShowTruckIcon = (segment: Segment, idStop: string): boolean => {
  if (segment.segmentStatus !== 'InProgress') return false
  const areAnyStopsInProgress = Boolean(
    segment.stops.find(stop => stop.stopStatus === 'Arrived'),
  )
  if (areAnyStopsInProgress) return false
  const inTransitStop = segment.stops.find(
    stop => stop.stopStatus === 'InTransit',
  )
  const thisStop = segment.stops.find(stop => stop.idStop === idStop)
  if (inTransitStop && thisStop && inTransitStop.idStop !== thisStop.idStop)
    return false
  if (thisStop && thisStop.stopStatus === 'InTransit') return true
  const stopsNotCompleted = segment.stops.filter(
    stop => stop.stopStatus !== 'Completed',
  )
  if (stopsNotCompleted.length) {
    if (stopsNotCompleted[0].idStop === idStop) {
      return true
    }
  }
  return false
}

const TravelTimeSection = ({
  idStop,
  index,
  segment,
}: {
  idStop: string
  index: number
  segment: Segment
}) => {
  const firstStop = segment.stops[index - 1]
  const secondStop = segment.stops[index]
  const newKey = getStopKeyForTravelTime(firstStop, secondStop)

  const travelTimes = useRouteDetailStore(state => state.travelTimes)
  const travelInfo = travelTimes[newKey]
  const formattedDistance = getFormattedDistance(travelInfo?.distance)

  const isCurrentStopComplete =
    segment?.stops[index + 1]?.stopStatus?.toLowerCase() === 'arrived' ||
    segment?.stops[index + 1]?.stopStatus === 'Completed' ||
    segment?.stops[index + 1]?.stopStatus === 'Missed'

  const showTruckIcon = getShouldShowTruckIcon(segment, idStop)

  return (
    <EstimateTextContainer key={'container-' + index}>
      {showTruckIcon ? (
        <Truck color="var(--asc-moss)" size={20} weight="fill" />
      ) : (
        <ArrowsVertical
          color={
            isCurrentStopComplete ? 'var(--asc-moss)' : 'var(--asc-graphite)'
          }
          size={20}
        />
      )}
      {!travelInfo ? (
        <PlaceHolderContainer>
          <ShimmerPlaceholder />
        </PlaceHolderContainer>
      ) : (
        <BoldableText isBold={showTruckIcon}>
          <DistanceText>{formattedDistance} • </DistanceText>
          <TimeText>&nbsp;(about {travelInfo?.duration})</TimeText>
        </BoldableText>
      )}
    </EstimateTextContainer>
  )
}
export default TravelTimeSection
