import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

const getAssetList = async () => {
  const {data} = await Axios.get('/assets/assetlist')
  return data
}

export default function useAssetList() {
  const user = useStore(state => state.user)
  return useQuery(['assetsList'], getAssetList, {
    enabled: Boolean(user?.token),
  });
}
