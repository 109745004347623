import React from 'react'
import {isValid} from 'date-fns'
import {convertToUsersTimeFormat} from 'common/helpersDateTime'
import StatusBadge from '../StatusBadge'
import {ValuesType} from './routePlanTimeTableHelpers'
import {
  StopSummaryInfo,
  StopSummaryLabel,
  StopSummaryRow,
  StopSummaryText,
  StopSummaryTitle,
  StopSummaryTitleRow,
  StopSummaryToolTip,
} from './routePlanTimeTableStyles'
import {getServiceWindowString, getStopStatus} from './StopSummaryHelpers'

interface Props {
  //d3 data
  d: ValuesType
}

const StopSummary: React.FC<Props> = ({d}) => {
  const orderName = d.orderName
  const address =
    d.address && d.address !== '' && d.address !== ' '
      ? `${d.address} ${d.city}, ${d.state} ${d.zipCode}`
      : d.location.lat
      ? '[' + d.location.lat.toFixed(4) + ', ' + d.location.lng.toFixed(4) + ']'
      : ''
  const isAnEndpoint = d.stopType === 'origin' || d.stopType === 'destination'

  const title = !isAnEndpoint
    ? 'Stop Summary'
    : d.stopType === 'origin'
    ? 'Start Location Summary'
    : 'End Location Summary'

  return (
    <StopSummaryToolTip data-cy="stop-window">
      <StopSummaryTitleRow>
        <StopSummaryTitle>{title}</StopSummaryTitle>
        <StatusBadge status={getStopStatus(d)} />
      </StopSummaryTitleRow>
      <StopSummaryRow>
        <StopSummaryLabel>
          {d.address && d.address !== '' && d.address !== ' '
            ? 'Address:'
            : 'Location:'}
        </StopSummaryLabel>
        <StopSummaryText>{address}</StopSummaryText>
      </StopSummaryRow>
      {orderName && (
        <StopSummaryRow>
          <StopSummaryLabel>Order Name:</StopSummaryLabel>
          <StopSummaryInfo>{orderName}</StopSummaryInfo>
        </StopSummaryRow>
      )}
      {isAnEndpoint && (
        <StopSummaryRow>
          <StopSummaryLabel>
            Planned {d.stopType === 'origin' ? 'Departure:' : 'Arrival:'}
          </StopSummaryLabel>
          <StopSummaryInfo>
            {d.stopType === 'origin'
              ? d.ServiceEndDateTime &&
                isValid(d.ServiceEndDateTime) &&
                convertToUsersTimeFormat(d.ServiceEndDateTime)
              : d.ServiceStartDateTime &&
                isValid(d.ServiceStartDateTime) &&
                convertToUsersTimeFormat(d.ServiceStartDateTime)}
          </StopSummaryInfo>
        </StopSummaryRow>
      )}
      {isAnEndpoint && (d.DepartureDate || d.ArrivalDate) && (
        <StopSummaryRow>
          <StopSummaryLabel>
            Actual {d.stopType === 'origin' ? 'Departure:' : 'Arrival:'}
          </StopSummaryLabel>
          <StopSummaryInfo>
            {d.stopType === 'origin' && d.DepartureDate
              ? convertToUsersTimeFormat(d.DepartureDate)
              : d.stopType === 'destination' && d.ArrivalDate
              ? d.ArrivalDate && convertToUsersTimeFormat(d.ArrivalDate)
              : ''}
          </StopSummaryInfo>
        </StopSummaryRow>
      )}
      {!isAnEndpoint && (
        <>
          <StopSummaryRow>
            <StopSummaryLabel>Service Window:</StopSummaryLabel>
            <StopSummaryText>{getServiceWindowString(d)}</StopSummaryText>
          </StopSummaryRow>
          <StopSummaryRow>
            <StopSummaryLabel>Duration:</StopSummaryLabel>
            <StopSummaryText>{d.displayDuration}</StopSummaryText>
          </StopSummaryRow>
        </>
      )}
      {!isAnEndpoint && d.companyName && (
        <StopSummaryRow>
          <StopSummaryLabel>Company Name:</StopSummaryLabel>
          <StopSummaryInfo>{d.companyName}</StopSummaryInfo>
        </StopSummaryRow>
      )}

      {!isAnEndpoint && (
        <>
          <StopSummaryRow>
            <StopSummaryLabel>Contact Name:</StopSummaryLabel>
            <StopSummaryInfo>{d.contact || 'No Name Provided'}</StopSummaryInfo>
          </StopSummaryRow>
          <StopSummaryRow>
            <StopSummaryLabel>Contact Phone Number:</StopSummaryLabel>
            <StopSummaryText>{d.phone || 'No Number Provided'}</StopSummaryText>
          </StopSummaryRow>
        </>
      )}
    </StopSummaryToolTip>
  )
}

export default StopSummary
