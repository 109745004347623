import * as React from 'react'
import styled from 'styled-components/macro'
import useOpening5 from 'opening/hooks/useOpening5'
import LocationCard from './LocationCard'
import {filterLocations} from './locationListHelpers'
import {useMapStore} from 'map/useMapStore'
import {Checkbox} from 'common/components'

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-margin: 30px;
  background-color: white;
  padding: 0px 5px;
`

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
`

const LocationToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: transparent;
  select {
    padding: 5px;
  }
  border-bottom: 1px solid var(--table-borders);
`
const StyledSelect = styled.select`
  border-radius: 5px;
  border: 1px solid var(--asc-graphite);
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
`
const HideAssetButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  span {
    padding-bottom: 3px;
  }
`

const allType = 'All Types'
const reducer = (state, action) => {
  let types = []
  let newFilteredLocations
  switch (action.type) {
    case 'loadLocations':
      action.data.locations.forEach(location => {
        if (
          location.Landmark_Style &&
          !types.includes(location.Landmark_Style)
        ) {
          types.push(location.Landmark_Style)
        }
      })
      types = types.sort((a, b) => a.localeCompare(b))
      types.unshift(allType)
      return {
        ...state,
        filteredLocations: action.data.locations,
        allLocations: action.data.locations,
        types: types,
      }
    case 'filterLocations':
      newFilteredLocations = filterLocations({
        allLocations: state.allLocations,
        searchValue: action.data,
        typeSelected: state.typeSelected,
        showing: state.showing,
      })

      return {
        ...state,
        filteredLocations: newFilteredLocations.locations,
      }
    case 'typeChanged':
      newFilteredLocations = filterLocations({
        allLocations: state.allLocations,
        searchValue: action.data.searchValue,
        typeSelected: action.data.newType,
        showing: state.showing,
      })
      return {
        ...state,
        filteredLocations: newFilteredLocations.locations,
        typeSelected: action.data.newType,
      }
    case 'setShowing':
      newFilteredLocations = filterLocations({
        allLocations: state.allLocations,
        searchValue: action.data.searchValue,
        typeSelected: state.typeSelected,
        showing: action.data.showing,
      })
      return {
        ...state,
        showing: action.data.showing,
        filteredLocations: newFilteredLocations.locations,
      }
    case 'clearAll':
      state.allLocations.forEach(location => {
        if (
          location.Landmark_Style &&
          !types.includes(location.Landmark_Style)
        ) {
          types.push(location.Landmark_Style)
        }
      })
      types = types.sort((a, b) => a.localeCompare(b))
      types.unshift(allType)
      return {
        ...state,
        filteredLocations: action.data.locations,
        searchValue: '',
        typeSelected: allType,
        showing: allType,
        types: types,
      }

    default:
      console.error('Invalid action type', action)
      break
  }
}

const LocationList = ({
  dispatch: openingDispatch,
  selectedLocations = [],
  clearAssetFilter,
  searchValue,
}) => {
  const initialState = {
    filteredLocations: [],
    allLocations: [],
    searchValue: '',
    types: [allType],
    typeSelected: allType,
    showing: allType, //'All Types' 'Locations' or 'Zones'
  }
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const {data: opening5} = useOpening5()
  const showAssetMarkers = useMapStore(state => state.showAssetMarkers)

  React.useEffect(() => {
    const locations = opening5?.locations || []

    const allSorted = [...locations].sort((a, b) =>
      a.Landmark_Name.localeCompare(b.Landmark_Name),
    )
    dispatch({
      type: 'loadLocations',
      data: {locations: allSorted},
    })
  }, [opening5])

  React.useEffect(() => {
    dispatch({type: 'filterLocations', data: searchValue})
  }, [searchValue])

  const toggleLocation = e => {
    const locationID = Number(e.currentTarget.dataset.locationid)
    let newSelectedLocationIDs
    if (selectedLocations.includes(locationID)) {
      newSelectedLocationIDs = selectedLocations.filter(
        location => location !== locationID,
      )
      // removeLocations([locationID], map)
    } else {
      newSelectedLocationIDs = [...selectedLocations, locationID]
      state.filteredLocations.find(loc => {
        return loc.idLandmark === locationID
      })
    }
    if (newSelectedLocationIDs.length === 0) {
      const locations = opening5?.locations || []

      let allSorted = [...locations].sort((a, b) =>
        a.Landmark_Name.localeCompare(b.Landmark_Name),
      )

      dispatch({type: 'clearAll', data: {locations: allSorted}})
      clearAssetFilter()
      openingDispatch({
        type: 'setSelectedLocations',
        data: [],
      })
    }

    openingDispatch({
      type: 'setSelectedLocations',
      data: newSelectedLocationIDs,
    })
  }

  const typeChanged = e => {
    dispatch({
      type: 'typeChanged',
      data: {
        newType: e.currentTarget.value,
        searchValue: searchValue,
      },
    })
  }

  return (
    <ListContainer>
      <LocationToolbar>
        <StyledSelect
          onChange={typeChanged}
          value={state.typeSelected || allType}
        >
          {state.types.map(type => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </StyledSelect>
        <HideAssetButton
          onClick={() =>
            useMapStore.setState({showAssetMarkers: !showAssetMarkers})
          }
        >
          <Checkbox selected={!showAssetMarkers} />
          <span>Hide Assets</span>
        </HideAssetButton>
      </LocationToolbar>
      <List>
        {state.filteredLocations.map(location => (
          <LocationCard
            key={location.idLandmark}
            location={location}
            selectedLocationIDs={selectedLocations}
            toggleLocation={toggleLocation}
          />
        ))}
      </List>
    </ListContainer>
  )
}
export default LocationList
