import * as React from 'react'
import styled from 'styled-components/macro'
import GutButton from './GutButton'
import {useMapStore} from 'map/useMapStore'
import useAlertEventSummary from 'opening/hooks/useAlertEventSummary'
import AlertList from './AlertList'
import {Warning, Wrench} from 'phosphor-react'
import useAlertEvents from 'alerts/hooks/useAlertEvents'
import useWindowSize from 'common/useWindowSize'
import {QUERIES} from 'common/constants'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  position: absolute;
  bottom: 25px;
  left: 10px;
  z-index: 9;
  @media ${QUERIES.tabletAndUp} {
    bottom: 20px;
    left: 80px;
  }
`

const DesktopOnlyGutButton = styled(GutButton)`
  display: none;
  @media ${QUERIES.tabletAndUp} {
    display: flex;
  }
`

const Gut = ({assetFilter, setAssetFilter, activeMarker}) => {
  const {isMobile} = useWindowSize()
  const [showingAlerts, setShowingAlerts] = React.useState(false)
  const {data: alertEvents} = useAlertEvents()
  useAlertEventSummary() //get alert events which will go to map store after being summarized
  const gutAssetData = useMapStore(state => state.gutAssetData)
  const gutAlertData = useMapStore(state => state.gutAlertData)

  const toggleShowingAlerts = () =>
    setShowingAlerts(showingAlerts === 'true' ? 'false' : 'true')

  React.useEffect(() => {
    if (gutAlertData && !isMobile) {
      setShowingAlerts(gutAlertData.count === 0 ? 'false' : 'true')
    } else {
      setShowingAlerts(false)
    }
  }, [gutAlertData, isMobile])

  React.useEffect(() => {
    if (activeMarker) setShowingAlerts('false')
  }, [activeMarker])

  return (
    <Container>
      {alertEvents && (
        <AlertList
          alerts={gutAlertData.types}
          showingAlerts={showingAlerts}
          setShowingAlerts={setShowingAlerts}
          setAssetFilter={setAssetFilter}
          assetFilter={assetFilter}
          alertEvents={alertEvents}
        />
      )}
      <GutButton
        label="ALERTS"
        Icon={Warning}
        item={gutAlertData}
        setAssetFilter={setAssetFilter}
        assetFilter={assetFilter}
        toggleShowingAlerts={toggleShowingAlerts}
      />

      <>
        <DesktopOnlyGutButton
          label={'MAINTENANCE'}
          Icon={Wrench}
          assetFilter={assetFilter}
          setAssetFilter={setAssetFilter}
          item={{
            count: gutAssetData.due.count + gutAssetData.overdue.count,
            assets: [
              ...gutAssetData.due.assets,
              ...gutAssetData.overdue.assets,
            ],
          }}
        />
        <DesktopOnlyGutButton
          label={'RUNNING'}
          item={gutAssetData.running}
          setAssetFilter={setAssetFilter}
          assetFilter={assetFilter}
        />
        <DesktopOnlyGutButton
          label={'MOVING'}
          item={gutAssetData.moving}
          setAssetFilter={setAssetFilter}
          assetFilter={assetFilter}
        />
      </>
    </Container>
  )
}
export default Gut
