import {createGeoJson, Feature} from './createGeoJson'
import {HistoryTrailPoints, HistoryTrailPoint} from './hooks/historyTrailTypes'
import {useHistoryTrailStore} from './hooks/useHistoryTrailStore'
import {driverSafetyEventCodes} from './EventFilter'

interface ExtendedGoogleIcon extends google.maps.Icon {
  urlAnimate?: string
  animateSize?: google.maps.Size
  title?: 'start' | 'end'
  zIndex?: number
}

const iconAnchor = new google.maps.Point(12, 12)
const animateAnchor = new google.maps.Point(24, 24)
const animateSize = new google.maps.Size(50, 50)
const stoppedScaledSize = new google.maps.Size(14, 14)
const arrowScaledSize = new google.maps.Size(22, 22)
const circleArrowScaledSize = new google.maps.Size(26, 26)
const origin = new google.maps.Point(0, 0)

export function setVisible({
  map,
  featureID,
  visible,
  animate,
  animateTime = 1000,
}: {
  map: google.maps.Map
  featureID: number
  visible: boolean
  animate?: boolean
  animateTime?: number
}): void {
  const feature = map.data.getFeatureById(featureID)
  if (!feature || !feature.getProperty('icon')) {
    //console.error(`no icon when running history trail`, feature, featureID)
  } else {
    if (!feature.getProperty('validCoordinates')) {
      //don't plot invalid coordinates (0,0)
      visible = false
    }

    const featureIconProp = feature.getProperty('icon') as ExtendedGoogleIcon
    const copiedIcon = {...featureIconProp}

    if (animate) {
      copiedIcon.url = copiedIcon.urlAnimate || ''
      copiedIcon.scaledSize = copiedIcon.animateSize
      copiedIcon.size = copiedIcon.animateSize
      copiedIcon.origin = origin
      copiedIcon.anchor = animateAnchor

      map.data.overrideStyle(feature, {
        visible: visible,
        icon: copiedIcon,
        zIndex: 10000,
      })
      setTimeout(() => {
        map.data.overrideStyle(feature, {icon: featureIconProp, zIndex: 999})
      }, animateTime)
    } else {
      copiedIcon.size = copiedIcon.scaledSize
      map.data.overrideStyle(feature, {
        visible: visible,
        icon: copiedIcon,
        zIndex: 999,
      })
    }
  }
}

let startMarker: google.maps.Marker | null = null
let endMarker: google.maps.Marker | null = null
export const resetFlagMarkers = () => {
  if (startMarker) startMarker.setMap(null)
  if (endMarker) endMarker.setMap(null)
}
function plotFlag(
  point: google.maps.LatLngLiteral,
  type: 'start' | 'end',
  map: google.maps.Map,
  maps: typeof google.maps,
): google.maps.Marker {
  const image: ExtendedGoogleIcon = {
    url: `${process.env.PUBLIC_URL}/images/history/hist-flag-${type}.svg`,
    scaledSize: new maps.Size(35, 35),
    origin: new maps.Point(0, 0),
    anchor: new maps.Point(1, 50),
    title: type,
    zIndex: 1000,
  }
  if (type === 'start' && startMarker) {
    startMarker.setMap(null) // Remove existing start marker
  } else if (type === 'end' && endMarker) {
    endMarker.setMap(null) // Remove existing end marker
  }
  const flagMarker: google.maps.Marker = new maps.Marker({
    animation: maps.Animation.DROP,
    position: point,
    map: map,
    icon: image,
    title: type,
    label: '',
  })
  if (type === 'start') {
    startMarker = flagMarker
  } else if (type === 'end') {
    endMarker = flagMarker
  }
  return flagMarker
}

export const plotMarkers = (
  data: HistoryTrailPoints,
  map: google.maps.Map,
  maps: typeof google.maps,
): google.maps.Marker[] | null => {
  const setMarkerClicked = useHistoryTrailStore.getState().setMarkerClicked
  map.data.forEach(feature => {
    map.data.remove(feature)
  })
  const geoJSON: {
    type: 'FeatureCollection'
    features: Feature[]
  } = createGeoJson(data)
  const filteredEvents = geoJSON.features
  const markers: google.maps.Marker[] = []

  map.data.setMap(null)
  if (!filteredEvents || filteredEvents.length === 0) {
    return null
  }

  const startPoint: google.maps.LatLngLiteral = {
    lat: filteredEvents[0].geometry.coordinates[1],
    lng: filteredEvents[0].geometry.coordinates[0],
  }
  markers.push(plotFlag(startPoint, 'start', map, maps))

  const endPoint: google.maps.LatLngLiteral = {
    lat: filteredEvents[filteredEvents.length - 1].geometry.coordinates[1],
    lng: filteredEvents[filteredEvents.length - 1].geometry.coordinates[0],
  }
  markers.push(plotFlag(endPoint, 'end', map, maps))

  map.data = new maps.Data({map: map})
  map.data.addGeoJson(geoJSON, {
    idPropertyName: 'idRaw_Data',
  })
  map.data.setStyle(feature => {
    const type: string | undefined = feature.getGeometry()?.getType()
    if (type === 'Polygon') {
      return {
        strokeColor: '#666666',
        strokeOpacity: 1,
        strokeWeight: 3,
        fillColor: '#F6A930',
        fillOpacity: 0.6,
      }
    } else {
      return {
        icon: feature.getProperty('icon') as google.maps.Symbol,
      }
    }
  })

  map.data.setMap(map)

  map.data.addListener('click', (e: google.maps.Data.MouseEvent) => {
    const featureID: number = Number(e.feature.getProperty('idRaw_Data'))
    setMarkerClicked(featureID)
  })
  return markers
}

export const getType = (point: HistoryTrailPoint) => {
  const returnType: Partial<HistoryTrailPoint> = {}
  var validCoordinates = true
  var visible = true
  if (point.Longitude === 0 && point.Latitude === 0) {
    point.validCoordinates = true
    point.visible = true
  }
  returnType.validCoordinates = validCoordinates
  returnType.visible = visible

  //set color property that will be used by set style

  // var alertObject = point.Alerts
  var eventCode = point.idEvent_Code
  if (point.CameraMediaEventIds) {
    returnType.title = 'Camera'
  } else if (point.SensorEvent) {
    returnType.title = 'Sensors'
  } else if (
    driverSafetyEventCodes[eventCode as keyof typeof driverSafetyEventCodes]
  ) {
    returnType.title = 'Driver Safety'
  } else if (point.In_Motion) {
    returnType.title = 'Motion'
  } else {
    returnType.title = 'Stop' // Green Arrow
  }
  return returnType
}

interface StyledIcon extends google.maps.Icon {
  urlAnimate?: string
  animateSize?: google.maps.Size
}

export const getIcon = function (
  maps: typeof google.maps,
  point: HistoryTrailPoint,
): StyledIcon | undefined {
  // console.log('getIcon')
  //all this to set the icon
  if (!point.validCoordinates) {
    var invalidPoint = {
      //hide marker if invalid coordinates
      url: `${process.env.PUBLIC_URL}/images/history/invalid.png`,
      urlAnimate: `${process.env.PUBLIC_URL}/images/history/invalid.png`,
    }
    return invalidPoint
  }

  var icon
  const title = point.title
  const titleNoSpaces = title ? title.replace(' ', '') : ''

  if (!title || !titleNoSpaces) return undefined

  /**
   * use png instead of svg path so that the images dont have to render on top of the map to improve performance
   * https://developers.google.com/maps/optimization-guide#:~:text=Use%20raster%20images%2C%20such%20as,when%20the%20map%20is%20redrawn.
   */

  if (
    point.In_Motion ||
    // point.idEvent_Code === 11 ||
    driverSafetyEventCodes[
      point.idEvent_Code as keyof typeof driverSafetyEventCodes
    ]
  ) {
    const lastHeading =
      point.Last_Heading || point.Last_Heading === 0
        ? Math.round(point.Last_Heading / 10) * 10
        : null

    const urlBase = `${process.env.PUBLIC_URL}/images/history/`
    let filledArrow
    let urlAnimate
    let url
    let size = stoppedScaledSize
    if (lastHeading === null) {
      urlAnimate = `${urlBase}motion-no-heading-animatedot.png`
      if (point?.Alerts) {
        url = `${urlBase}motion-no-heading-vermiliondot.png`
      } else if (point?.SensorEvent) {
        url = `${urlBase}motion-no-heading-denimdot.png`
      } else if (
        driverSafetyEventCodes[
          point.idEvent_Code as keyof typeof driverSafetyEventCodes
        ]
      ) {
        url = `${urlBase}motion-no-heading-golddot.png`
      } else if (point.CameraMediaEventIds) {
        url = `${urlBase}motion-no-heading-purpledot.png`
      } else {
        url = `${urlBase}motion-no-heading-greendot.png`
      }
    } else {
      urlAnimate = `${urlBase}arrow_${lastHeading}deg_Animate.png`
      size = arrowScaledSize
      if (point?.Alerts) {
        url = `${urlBase}arrow_${lastHeading}deg_Alerts.png`
      } else if (point?.SensorEvent) {
        url = `${urlBase}arrow_${lastHeading}deg_Sensors.png`
      } else if (
        driverSafetyEventCodes[
          point.idEvent_Code as keyof typeof driverSafetyEventCodes
        ]
      ) {
        url = `${urlBase}arrow_${lastHeading}deg_DriverSafety.png`
      } else if (point.CameraMediaEventIds) {
        url = `${urlBase}arrow_${lastHeading}deg_Camera.png`
      } else {
        size = circleArrowScaledSize
        url = `${urlBase}arrow_${lastHeading}deg_Animate.png`
      }
    }
    filledArrow = {
      url: url,
      scaledSize: size,
      urlAnimate: urlAnimate,
      origin: origin,
      anchor: iconAnchor,
      animateSize: animateSize,
    }

    icon = filledArrow
  } else {
    let stopSign
    if (point?.Alerts) {
      stopSign = {
        url: `${process.env.PUBLIC_URL}/images/history/stop_Alerts.png`,
        urlAnimate: `${process.env.PUBLIC_URL}/images/history/stop_Animate.png`,
        scaledSize: stoppedScaledSize,
        origin: origin,
        anchor: iconAnchor,
        animateSize: animateSize,
      }
    } else if (point?.SensorEvent) {
      stopSign = {
        url: `${process.env.PUBLIC_URL}/images/history/stop_Sensors.png`,
        urlAnimate: `${process.env.PUBLIC_URL}/images/history/stop_Animate.png`,
        scaledSize: stoppedScaledSize,
        origin: origin,
        anchor: iconAnchor,
        animateSize: animateSize,
      }
    } else if (
      driverSafetyEventCodes[
        point.idEvent_Code as keyof typeof driverSafetyEventCodes
      ]
    ) {
      stopSign = {
        url: `${process.env.PUBLIC_URL}/images/history/stop_DriverSafety.png`,
        urlAnimate: `${process.env.PUBLIC_URL}/images/history/stop_Animate.png`,
        scaledSize: stoppedScaledSize,
        origin: origin,
        anchor: iconAnchor,
        animateSize: animateSize,
      }
    } else if (point.CameraMediaEventIds) {
      stopSign = {
        url: `${process.env.PUBLIC_URL}/images/history/stop_Camera.png`,
        urlAnimate: `${process.env.PUBLIC_URL}/images/history/stop_Animate.png`,
        scaledSize: stoppedScaledSize,
        origin: origin,
        anchor: iconAnchor,
        animateSize: animateSize,
      }
    } else {
      stopSign = {
        url: `${process.env.PUBLIC_URL}/images/history/stop_${titleNoSpaces}.png`,
        urlAnimate: `${process.env.PUBLIC_URL}/images/history/stop_Animate.png`,
        scaledSize: stoppedScaledSize,
        origin: origin,
        anchor: iconAnchor,
        animateSize: animateSize,
      }
    }
    icon = stopSign
  }

  return icon
}
