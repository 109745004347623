import {create} from 'zustand'
import {HubConnection} from '@microsoft/signalr'

const initialStore = {
  // socketListening: false,
  connection: null,
  unreadMessages: [],
  messagesWhileAway: false,
  newWebsocketMessage: {name: '', message: '', idConversation: 0},
  mostRecentDateTime: null,
  lastCheckTimestamp: null,
}

type State = {
  // socketListening: boolean
  connection: HubConnection | null
  unreadMessages: number[]
  messagesWhileAway: boolean
  setUnreadMessages: (incomingMessages: number[]) => void
  newWebsocketMessage: {name: string; message: string; idConversation: number}
  setNewWebsocketMessage: (
    name: string,
    message: string,
    idConversation: number,
  ) => void
  mostRecentDateTime: string | null
  lastCheckTimestamp: Date | null
}

export const useConversationsSocketStore = create<State>(set => ({
  ...initialStore,
  emptyStore: () => set(state => ({...state, ...initialStore})),
  setConnection: ({connection}: {connection: HubConnection | null}) =>
    set(state => ({...state, connection})),
  setUnreadMessages: (incomingMessages: number[]) =>
    set(state => ({...state, unreadMessages: incomingMessages})),
  setNewWebsocketMessage: (
    name: string,
    message: string,
    idConversation: number,
  ) => {
    return set(state => ({
      ...state,
      newWebsocketMessage: {name, message, idConversation},
    }))
  },
}))
