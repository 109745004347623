import * as React from 'react'
import {add, format, setHours, setMilliseconds, setMinutes} from 'date-fns'
import {
  Location,
  OrderType,
  StopType,
} from 'routingAndDispatch/types/typesModule'
import {PostOrderToSegmentType} from 'routingAndDispatch/Orders/hooks/usePostStopToSegment'
import {getTotalMinutesFromTime} from 'routingAndDispatch/common/commonHelpers'
import {Range} from 'react-date-range'
import {SemanticInputType} from 'common/components/InputDropdownOptional'

export type StopState = {
  isDirty: boolean
  showStopModal?: boolean
  showPickupModal?: boolean
  showDeliveryModal?: boolean
  currentStopLoaded?: boolean
  serviceButtonSelected?: boolean
  deliveryButtonSelected?: boolean
  showAddressOrCoordinates: 'address' | 'coordinates' | 'location'
  errors: {
    [key: string]: {type?: string; message: string}
  }
  stop: ViewableStop
}
//extending to Stop for UI purposes
export interface ViewableStop extends StopType {
  status?: string | null
  estimatedDuration?: {hrs: number; mins: number}
  formattedAddress?: string
  dateRange?: Range
  dateRangeError?: string
  marker?: unknown
  scheduledDate?: string
  scheduledEarliestTime?: {
    ampm: 'AM' | 'PM'
    hour: number
    minute: number
  }
  scheduledLatestTime?: {
    ampm: 'AM' | 'PM'
    hour: number
    minute: number
  }
  locationName?: string
  orderStatus?: string
}

export type StopReducerAction = {
  type:
    | 'setState'
    | 'setCleanState'
    | 'loadStop'
    | 'setDurationState'
    | 'setTimeState'
    | 'toggleAddressCoordinates'
    | 'updateAddress'
    | 'updateCoordinates'
    | 'updateStatus'
    | 'setErrors'
    | 'resetErrors'
    | 'resetStopForm'
    | 'resetDeliveryForm'
    | 'loadServiceTypes'
    | 'setCleanStopState'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}

export type stopParam = {
  type: string
  stop: Partial<ViewableStop>
  index?: number
}

export const reducer = (state: StopState, action: StopReducerAction) => {
  switch (action.type) {
    case 'setState': {
      const errors = state.errors
      if (typeof action.data.formattedAddress === 'string') {
        if (errors['formattedAddress']) {
          errors['formattedAddress'].message = ''
        }
        return {
          ...state,
          stop: {
            ...state.stop,
            formattedAddress: action.data.formattedAddress,
            marker: action.data.marker,
          },
          isDirty: true,
          errors,
        }
      } else {
        return {
          ...state,
          ...action.data,
          isDirty: true,
          errors,
        }
      }
    }
    case 'setCleanState': {
      return {
        ...state,
        ...action.data,
      }
    }
    case 'setCleanStopState': {
      return {
        ...state,
        stop: {...state.stop, ...action.data},
      }
    }
    case 'loadStop': {
      const stopData: ViewableStop = action.data

      const dateRange = {
        startDate:
          stopData.dateRange?.startDate ||
          new Date(stopData.scheduledEarliestDate || ''),
        endDate:
          stopData.dateRange?.endDate ||
          new Date(stopData.scheduledLatestDate || ''),
      }
      const totalMinutes = stopData.serviceDuration || 0
      const hours = Math.floor(totalMinutes / 60)
      const minutes = totalMinutes % 60
      const estimatedDuration = {hrs: hours, mins: minutes}

      return {
        ...state,
        currentStopLoaded: true,
        showAddressOrCoordinates:
          stopData.city !== '' ? 'address' : 'coordinates',
        stop: {
          ...action.data,
          status: 'new',
          estimatedDuration: estimatedDuration,
          dateRange: dateRange,
        },
      }
    }
    case 'loadServiceTypes': {
      const options = action.data.map((serviceType: string, index: number) => {
        return {
          key: index,
          value: serviceType,
          text: serviceType,
        }
      })
      return {
        ...state,
        availableServiceTypes: options,
      }
    }
    case 'setDurationState': {
      let newDurationState = {}
      const currentMinutes = state?.stop?.estimatedDuration?.mins || 0
      const currentHours = state?.stop?.estimatedDuration?.hrs || 0

      if (action.data.type === 'hours') {
        newDurationState = {
          hrs: action.data.value,
          mins: currentMinutes,
        }
      }
      if (action.data.type === 'mins') {
        newDurationState = {
          hrs: currentHours,
          mins: action.data.value,
        }
      }

      const estimatedDurationInMinutes =
        action.data.type === 'hours'
          ? action.data.value * 60 + currentMinutes
          : currentHours * 60 + action.data.value

      return {
        ...state,
        stop: {
          ...state.stop,
          estimatedDuration: newDurationState,
          serviceDuration: estimatedDurationInMinutes,
        },
        isDirty: true,
      }
    }
    case 'updateAddress': {
      const lat = action.data?.marker?.center?.lat?.()
      const long = action.data?.marker?.center?.lng?.()
      const errors = state.errors
      if (errors['formattedAddress']) {
        errors['formattedAddress'].message = ''
      }
      return lat && long
        ? {
            ...state,
            stop: {
              ...state.stop,
              formattedAddress: action.data.formattedAddress,
              address: action.data.address.Landmark_Address_Line1,
              city: action.data.address.Landmark_City,
              state: action.data.address.Landmark_Region,
              zipCode: action.data.address.Landmark_Postal_Code,
              marker: action.data.marker,
              location: {lat: lat, lng: long},
            },
            isDirty: true,
            errors,
          }
        : {
            ...state,
            stop: {
              ...state.stop,
              formattedAddress: '',
              address: '',
              city: '',
              state: '',
              zipCode: '',
              marker: null,
              location: {lat: 0, lng: 0},
            },
          }
    }
    case 'updateStatus':
      return {
        ...state,
        stop: {...state.stop, status: action.data},
        isDirty: true,
      }
    case 'toggleAddressCoordinates':
      return {
        ...state,
        showAddressOrCoordinates: action.data,
      }
    case 'setErrors': {
      const errors = {
        formattedAddress: {message: ''},
        scheduledDate: {message: ''},
        serviceWindow: {message: ''},
        latitude: {message: ''},
        longitude: {message: ''},
        title: {message: ''},
      }
      //Empty required fields
      if (
        action.data.formattedAddress === '' &&
        state.showAddressOrCoordinates === 'address'
      ) {
        errors['formattedAddress'].message = 'Missing Address'
      }
      if (action.data.idSegment && !action.data.title) {
        errors['title'].message = 'Missing Title'
      }
      if (state.showAddressOrCoordinates === 'coordinates') {
        if (action.data.latitude === 0) {
          errors['latitude'].message = `Missing Latitude`
        }
        if (action.data.longitude === 0) {
          errors['longitude'].message = `Missing Longitude`
        }
        if (action.data.latitude < -85 || action.data.latitude > 85) {
          errors['latitude'].message = `Invalid Latitude`
        }
        if (action.data.longitude < -180 || action.data.longitude > 180) {
          errors['longitude'].message = `Invalid Longitude`
        }
      }

      if (!action.data.scheduledDate) {
        errors['scheduledDate'].message = 'Missing Date'
      }

      if (!action.data.serviceWindow) {
        errors['serviceWindow'].message = 'Invalid Service Window'
      }

      return {
        ...state,
        errors,
      }
    }
    case 'resetErrors': {
      const errors = state.errors
      //Empty required fields
      if (action.data.formattedAddress) {
        errors['formattedAddress'].message = ''
      }
      if (action.data.scheduledDate) {
        errors['scheduledDate'].message = ''
      }
      if (action.data.contact) {
        errors['contact'].message = ''
      }
      if (action.data.phone) {
        errors['phone'].message = ''
      }
      if (action.data.email) {
        errors['email'].message = ''
      }
      if (action.data.serviceWindow) {
        errors['serviceWindow'].message = ''
      }
      if (action.data.latitude) {
        errors['latitude'].message = ''
      }
      if (action.data.longitude) {
        errors['longitude'].message = ''
      }
      if (action.data.title) {
        errors['title'].message = ''
      }
      return {
        ...state,
        errors,
      }
    }
    case 'resetStopForm': {
      const initialState = returnInitialStopState(state)
      return initialState
    }

    case 'resetDeliveryForm': {
      const initialState = returnInitialStopState(state)
      return {
        ...initialState,
        ...action.data,
      }
    }

    default:
      console.error('invalid reducer action', action)
      return state
  }
}

export const returnInitialStopState = (state?: StopState) => {
  const tomorrow = new Date()
  const startDate = setHours(setMinutes(setMilliseconds(tomorrow, 0), 0), 8)
  const endDate = setHours(setMinutes(setMilliseconds(tomorrow, 0), 0), 17)

  const initialStopState: StopState = {
    isDirty: false,
    showStopModal: false,
    showPickupModal: false,
    showDeliveryModal: false,
    serviceButtonSelected: false,
    deliveryButtonSelected: false,
    currentStopLoaded: false,
    errors: {},
    showAddressOrCoordinates: 'address',
    stop: {
      idStop: '',
      serviceDuration: 0,
      marker: null,
      estimatedDuration: state?.stop.estimatedDuration || {hrs: 0, mins: 30},
      description: '',
      location: {lat: 0, lng: 0},
      companyId: '',
      companyName: '',
      formattedAddress: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      phone: '',
      contact: '',
      email: '',
      UpdateReason: '',
      stopType: '',
      status: 'new',
      dateRangeError: '',
      dateRange: state?.stop.dateRange || {
        startDate,
        endDate,
      },
      scheduledEarliestDate: state?.stop.scheduledEarliestDate,
      scheduledLatestDate: state?.stop.scheduledLatestDate,
    },
  }
  return initialStopState
}

export const updateNotes = (
  dispatch: React.Dispatch<StopReducerAction>,
  {
    name,
    value,
  }: {
    name: string
    value: SemanticInputType
  },
) => {
  dispatch({
    type: 'setState',
    data: {
      [name]: value,
    },
  })
}

export const handleDropdownChange = (
  dispatch: React.Dispatch<StopReducerAction>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  event: any,
) => {
  let {name, value} = event
  dispatch({
    type: 'setState',
    data: {stop: {[name]: value}},
  })
}

export const handleDurationChange = (
  dispatch: React.Dispatch<StopReducerAction>,
  type: string,
  value: number,
) => {
  dispatch({
    type: 'setDurationState',
    data: {type: type, value: value},
  })
}

export const makeStopObject: ({
  idSegment,
  state,
}: {
  idSegment: string
  state: StopState & Partial<OrderType> & {idPlan?: string}
}) => PostOrderToSegmentType = ({idSegment, state}) => {
  const startDateTime = state.stop.dateRange?.startDate

  const endDateTime =
    startDateTime &&
    add(startDateTime, {
      minutes: state.stop.estimatedDuration?.mins || 0,
      hours: state.stop.estimatedDuration?.hrs || 0,
    })

  const returnObject: PostOrderToSegmentType = {
    idOrder: '',
    title: state.title || '',
    description: state.description,
    serviceType: state.serviceType,
    stops: [
      {
        idStop: '',
        scheduledEarliestDate: startDateTime
          ? format(startDateTime, "yyyy-MM-dd'T'HH:mm:ss")
          : undefined,
        scheduledLatestDate: endDateTime
          ? format(endDateTime, "yyyy-MM-dd'T'HH:mm:ss")
          : undefined,
        //TODO test stop description
        description: '',
        location: {
          lat: state.stop.location?.lat,
          lng: state.stop.location?.lng,
        },
        companyName: state.stop.companyName || '',
        address: state.stop.address,
        city: state.stop.city,
        state: state.stop.state,
        zipCode: state.stop.zipCode,
        country: state.stop.country,
        phone: state.stop.phone || '',
        contact: state.stop.contact || '',
        email: state.stop.email || '',
        serviceDuration: state.stop.estimatedDuration
          ? getTotalMinutesFromTime(state.stop.estimatedDuration)
          : 0,
        UpdateReason: 'add',
        stopType: 'Pickup',
      },
    ],
    createdAt: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
    totalStops: '1',
    orderStatus: '',
    idSegment: idSegment,
    idPlan: state.idPlan || '',
    idRouteDispatchDriver: '',
  }

  return returnObject
}

export type GetAddressFromCoordinatesReturnType = {
  formattedAddress: string
  address: string
  city: string
  addressState: string
  zipCode: string
  lat: number
  lng: number
}
export const getAddressFromCoordinates = async (
  lat: number,
  lng: number,
): Promise<GetAddressFromCoordinatesReturnType | null> => {
  let formattedAddress = ''
  let address = ''
  let city = ''
  let addressState = ''
  let zipCode = ''
  if (lat !== 0 && lng !== 0 && !isNaN(Number(lat)) && !isNaN(Number(lng))) {
    //find lat long of stop based on address
    const geocoder = new google.maps.Geocoder()
    try {
      const response = await geocoder.geocode({
        location: {lat: Number(lat), lng: Number(lng)},
      })

      if (response.results[0]) {
        formattedAddress = response.results[0].formatted_address
        const addressComponents = response.results[0].address_components
        for (let i = 0; i < addressComponents.length; i++) {
          const component = addressComponents[i]
          const types = component.types

          if (types.includes('street_number') || types.includes('plus_code')) {
            address += component.long_name
          }

          if (types.includes('route')) {
            address += ' ' + component.long_name
          }

          if (types.includes('locality') && types.includes('political')) {
            city = component.long_name
          }

          if (
            types.includes('administrative_area_level_1') &&
            types.includes('political')
          ) {
            addressState = component.short_name
          }

          if (types.includes('postal_code')) {
            zipCode = component.short_name
          }
        }
      } else {
        window.alert('No results found')
      }
    } catch (e) {
      window.alert('Geocoder failed due to: ' + e)
    }
    return {formattedAddress, address, city, addressState, zipCode, lat, lng}
  } else {
    return null
  }
}

export const getSettingsDateObject = (
  time: string,
  ampm: string,
  planStartDate?: Date,
) => {
  const timeParts = time.split(':')
  let hour = Number(timeParts[0])

  if (ampm === 'PM' && hour < 12) {
    hour += 12
  } else if (ampm === 'AM' && hour === 12) {
    hour = 0
  }
  const tomorrow = planStartDate ? planStartDate : new Date()
  tomorrow.setHours(hour)
  tomorrow.setMinutes(Number(timeParts[1]))
  tomorrow.setSeconds(0)
  tomorrow.setMilliseconds(0)

  const dateString = `${tomorrow.getFullYear()}-${(tomorrow.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${tomorrow.getDate().toString().padStart(2, '0')}T${hour
    .toString()
    .padStart(2, '0')}:${timeParts.slice(1).join(':')}`

  return {
    dateObject: tomorrow,
    dateString: dateString,
  }
}

export const getAddressString = (
  currentStop: Partial<ViewableStop> | undefined,
) => {
  if (!currentStop) return undefined

  let address = currentStop?.address ? currentStop.address + ' ' : ''

  address +=
    currentStop.city && currentStop.state
      ? `${currentStop.city}, ${currentStop.state} ${currentStop.zipCode || ''}`
      : ''

  // if (
  //   // (!currentStop.location?.lat || !currentStop.location?.lng) &&
  //   currentStop.address
  // ) {
  //   const geocoder = new google.maps.Geocoder()
  //   await geocoder.geocode({address}, (results, status) => {
  //     if (status === 'OK' && results) {
  //       lat = results[0].geometry?.location?.lat()
  //       lng = results[0].geometry?.location?.lng()
  //     } else {
  //       console.error(
  //         'Geocode was not successful for the following reason:',
  //         status,
  //       )
  //     }
  //   })
  // }
  return address
}

export const checkServiceWindowValidation = (dateRange: Range | undefined) => {
  const startDate = (dateRange && dateRange.startDate) || ''
  const endDate = (dateRange && dateRange.endDate) || ''
  return startDate < endDate
}

export const checkAddress = (formattedAddress: string | undefined) => {
  return formattedAddress && formattedAddress !== ''
}

export const checkCoordinates = (location: Location | undefined) => {
  return (
    location?.lat !== 0 &&
    location?.lng !== 0 &&
    Number(location?.lat) >= -85 &&
    Number(location?.lat) <= 85 &&
    Number(location?.lng) >= -180 &&
    Number(location?.lng) <= 180
  )
}

export const checkAddressOrCoordinates = ({
  formattedAddress,
  showAddressOrCoordinates,
  location,
}: {
  formattedAddress: string
  showAddressOrCoordinates: string
  location: {lat: number; lng: number} | undefined
}) => {
  if (
    showAddressOrCoordinates === 'address' ||
    showAddressOrCoordinates === 'location'
  ) {
    return formattedAddress && formattedAddress !== ''
  } else {
    return (
      location &&
      location?.lat !== 0 &&
      location?.lng !== 0 &&
      Number(location?.lat) >= -85 &&
      Number(location?.lat) <= 85 &&
      Number(location?.lng) >= -180 &&
      Number(location?.lng) <= 180
    )
  }
}

export const getServiceTitle = ({
  allowEdits,
  type,
}: {
  allowEdits: boolean
  type: StopTypeType
}) => {
  return allowEdits
    ? type === 'add'
      ? 'Add Service Stop'
      : 'Edit Service Stop'
    : 'View Service Stop'
}
export const getPickupTitle = ({
  allowEdits,
  type,
}: {
  allowEdits: boolean
  type: StopTypeType
}) => {
  return allowEdits
    ? type === 'addPickup'
      ? 'Add Pickup'
      : 'Edit Pickup'
    : 'View Pickup'
}
export const getDeliveryTitle = ({
  allowEdits,
  type,
}: {
  allowEdits: boolean
  type: StopTypeType
}) => {
  return allowEdits
    ? type === 'addDelivery'
      ? 'Add Delivery'
      : 'Edit Delivery'
    : 'View Delivery'
}

export type StopTypeType =
  | 'view'
  | 'add'
  | 'addDelivery'
  | 'addPickup'
  | 'edit'
  | 'Service'
  | 'Pickup'
  | 'Delivery'
  | 'Route Point'
  | 'origin'
  | 'destination'
  | 'None'
  | ''

export const getPillText = ({type}: {type: StopTypeType}) => {
  let pillText = ''
  if (type === 'add') {
    pillText = 'Service'
  }

  if (type === 'addDelivery') {
    pillText = 'Delivery'
  }

  if (type === 'addPickup') {
    pillText = 'Pickup'
  }

  return pillText !== '' ? pillText : type
}
