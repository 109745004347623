import * as React from 'react'
import styled from 'styled-components/macro'
import {Icon} from 'semantic-ui-react'
import {thresholdclass} from './gridMapContainerHelpers'
import {oneDecimalRender, formatDateTime} from 'common/tables/tableHelpers'
import {CellContext} from '@tanstack/react-table'
import {Asset} from 'common/types/opening1Response'
import {SchemaType} from 'account/customizeViews/SubmitPanel'

const AlertIcon = styled(Icon)`
  &&& {
    margin-top: -3px;
  }
`

const FieldDataContent = styled.div`
  display: flex;
  width: 100%;
  gap: 5px;
  justify-content: ${(p: {type: string}) =>
    p.type === 'number' ? 'flex-end' : 'flex-start'};
`
const DataContent = styled.div`
  display: flex;
  align-items: center;
  padding-left: 7px;
  color: #252525;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 95%;
  display: block;
  overflow: hidden;
`

const RenderCell = (
  instance: CellContext<Asset, number>,
  field: SchemaType,
) => {
  let value = instance.getValue()
  let dataField = value || ''

  if (field.type === 'number' && value) {
    dataField = oneDecimalRender({value}, field.precision)
  } else if (field.type === 'date' && value) {
    dataField = String(formatDateTime({value}))
  }

  const alertClass = field?.threshold
    ? thresholdclass(field, value, instance.row.original)
    : ''

  return (
    <FieldDataContent type={field.type}>
      <DataContent>
        {alertClass && (
          <AlertIcon name="exclamation triangle" color={alertClass} />
        )}
        {dataField !== '' ? `${dataField} ${field?.UOM || ''}` : ''}
      </DataContent>
    </FieldDataContent>
  )
}

export default RenderCell
