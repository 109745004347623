import React from 'react'
import {
  RouteTimeTableHeaderColumn,
  RouteTimeTableRowContainer,
} from './routePlanTimeTableStyles'
import {getHeaders} from './routePlanTimeTableHelpers'
import {RoutePlanWithColor} from 'routingAndDispatch/Routes/PlanReview/PlanReview'
import {ArrowDown, ArrowUp} from 'phosphor-react'

type RouteTimeTableHeaderProps = {
  isRouteDetail: boolean
  routeData: RoutePlanWithColor
  earliestTime: Date
  latestTime: Date
  headerClick?: ({
    accessor,
    type,
  }: {
    accessor: string
    type: 'asc' | 'des' | ''
  }) => void
  sortBy?: {
    accessor: string
    type: 'asc' | 'des' | ''
  }
  selectAllClick?: () => void
  selectedSegments?: string[]
}

type Header = {
  accessor: string
  cell: React.JSX.Element
  disableSort?: boolean
}

const RouteTimeTableHeaders: React.FC<RouteTimeTableHeaderProps> = ({
  isRouteDetail,
  routeData,
  headerClick,
  sortBy,
  earliestTime,
  latestTime,
  selectAllClick,
  selectedSegments,
}) => {
  const headers: Header[] = React.useMemo(() => {
    return getHeaders({
      routeData,
      isRouteDetail,
      earliestTime,
      latestTime,
      selectAllClick,
      selectedSegments,
    })
  }, [
    earliestTime,
    isRouteDetail,
    latestTime,
    routeData,
    selectAllClick,
    selectedSegments,
  ])

  const handleHeaderClick = (accessor: string) => {
    const newSort: {
      accessor: string
      type: 'asc' | 'des' | ''
    } = {accessor, type: 'asc'}
    if (sortBy?.accessor === newSort.accessor) {
      if (sortBy.type === 'asc') {
        newSort.type = 'des'
      } else if (sortBy.type === 'des') {
        //clear sort by
        newSort.accessor = ''
        newSort.type = ''
      } else {
        newSort.type = 'asc'
      }
    }
    headerClick && headerClick(newSort)
  }

  return (
    <RouteTimeTableRowContainer
      isPlanning={routeData.planStatus.toLowerCase() === 'planning'}
      isHeader={true}
      isRouteDetail={isRouteDetail}
      className="row"
    >
      {headers.map((value, index) => {
        return (
          <RouteTimeTableHeaderColumn
            className={'header'}
            isSortable={!value.disableSort}
            isTimeTable={index === headers.length - 1}
            isDriver={index === 1}
            key={index}
            onClick={() =>
              !value.disableSort && handleHeaderClick(value.accessor)
            }
            isRouteDetail={isRouteDetail}
          >
            {value.cell}
            {!value.disableSort &&
            sortBy &&
            sortBy.accessor === value.accessor ? (
              sortBy.type === 'asc' ? (
                <ArrowUp />
              ) : sortBy.type === 'des' ? (
                <ArrowDown />
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </RouteTimeTableHeaderColumn>
        )
      })}
    </RouteTimeTableRowContainer>
  )
}

export default RouteTimeTableHeaders
