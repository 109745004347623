import React from 'react'
import styled from 'styled-components/macro'
import {Check} from 'phosphor-react'
import {OptionType} from './SpeedDropDown'

const Wrapper = styled.div`
  position: absolute;
  background-color: white;
  z-index: 99;
  border: 1px solid lightgray;
  border-radius: 3px;
  margin-top: 45px;
  left: 0px;
  min-width: 100px;
  background: #ffffff;
  border: 1px solid #d2d2d7;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  list-style: none;
  cursor: pointer;
  padding: 10px 20px;
  background: ${(p: {isActive: boolean}) =>
    p.isActive ? 'var(--asc-selectedgreen)' : 'white'};
  font-weight: ${p => (p.isActive ? 'bold' : 'normal')};
  svg {
    margin-right: 5px;
    color: ${p => (p.isActive ? 'black' : 'transparent')};
  }
  :hover {
    background-color: var(--asc-selectedgreen);
    color: var(--asc-moss);
    div {
      div {
        background: var(--secondary);
        color: var(--primary);
      }
    }
  }
  :first-child {
    margin-top: 10px;
  }
  :last-child {
    margin-bottom: 10px;
  }
`

const CardArrow = styled.div`
  border-top-width: 24px;
  position: absolute;
  left: 40%;
  top: -20px;
  .left {
    position: absolute;
    overflow: hidden;
    left: -6px;
    top: -1px;
    width: 16px;
    height: 21px;
    transform: scaleY(-1);
    div {
      position: absolute;
      left: 6px;
      background-color: rgb(255, 255, 255);
      transform: skewX(45deg);
      transform-origin: 0px 0px 0px;
      height: 24px;
      width: 10px;
      box-shadow: rgba(0, 0, 0, 0.6) 0px 1px 6px;
    }
  }
  .right {
    position: absolute;
    overflow: hidden;
    top: -1px;
    left: 10px;
    width: 16px;
    height: 21px;
    transform: scaleY(-1);
    div {
      position: absolute;
      left: 0px;
      background-color: rgb(255, 255, 255);
      transform: skewX(-45deg);
      transform-origin: 10px 0px 0px;
      height: 24px;
      width: 10px;
      box-shadow: rgba(0, 0, 0, 0.6) 0px 1px 6px;
    }
  }
`
const IconHolder = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
`
interface SpeedDropDownCardProps {
  options: OptionType[]
  setOpen: (open: boolean) => void
  onChange: (key: string) => void
  speed: number
}

const SpeedDropDownCard = ({
  options = [],
  setOpen,
  onChange,
  speed,
}: SpeedDropDownCardProps) => {
  const onClick = (item: {key: string}) => {
    onChange(item.key)
    setOpen(false)
  }

  return (
    <Wrapper>
      <CardArrow>
        <div className="left">
          <div></div>
        </div>
        <div className="right">
          <div></div>
        </div>
      </CardArrow>
      <List>
        {options.map((item, i) => (
          <ListItem
            key={i}
            onClick={() => onClick(item)}
            isActive={speed === item.value}
          >
            <IconHolder>
              <Check />
            </IconHolder>
            {item.text}
          </ListItem>
        ))}
      </List>
    </Wrapper>
  )
}

export default SpeedDropDownCard
