import MarkerWithLabel from '@googlemaps/markerwithlabel'
import {matchSorter} from 'match-sorter'
import {createLandmarkGeoJson} from 'common/components/LandmarkDrawingManager/createLandmarkGeoJson'
import ZoneMarker from 'images/map/zoneMarker.png'
import {useMapStore} from 'map/useMapStore'
// import {getClosestAssets} from 'mapControl/findNearestAssetsHelpers'
// import {infoWindowWithCreateLocation} from 'mapControl/infoWindowWithCreateLocation'

const processPoints = function (geometry, callback, thisArg) {
  if (geometry instanceof window.google.maps.LatLng) {
    callback.call(thisArg, geometry)
  } else if (geometry instanceof window.google.maps.Data.Point) {
    callback.call(thisArg, geometry.get())
  } else {
    geometry.getArray().forEach(function (g) {
      processPoints(g, callback, thisArg)
    })
  }
}

export const removeZones = (zoneIDs, map) => {
  const {getState, setState} = useMapStore
  const markers = getState().zoneMarkers || []

  const newMarkers = markers.filter(marker => {
    if (zoneIDs.includes(marker.id)) {
      marker.setMap(null)
      return false
    } else {
      return true
    }
  })
  setState({zoneMarkers: newMarkers})
  zoneIDs.forEach(zoneID => {
    const feature = map.data.getFeatureById(zoneID)
    if (feature) {
      map.data.remove(feature)
    }
  })
}

export const drawZones = (zones, map) => {
  const {getState, setState} = useMapStore
  const markers = getState().zoneMarkers || []
  const zonesGeoJSON = createLandmarkGeoJson(zones)
  var bounds = new window.google.maps.LatLngBounds()
  const newMarkers = []
  zonesGeoJSON.features.forEach(feature => {
    var landmarkLabel = feature.geometry.labelcoords
    var labelCenterX = -(feature.properties.Name.length * 6) * 0.5
    const labelClass = 'zone-marker-label'
    var newMarker = new MarkerWithLabel({
      position: landmarkLabel,
      draggable: false,
      map: map,
      labelContent: feature.properties.Name,
      labelAnchor: new window.google.maps.Point(labelCenterX, 5),
      labelClass: labelClass,
      labelStyle: {opacity: 0.9},
      icon: ZoneMarker,
      id: feature.properties.id,
    })

    newMarkers.push(newMarker)

    // add listener to the location for zoom in to the location on click
    if (newMarker) {
      // const latlng = new window.google.maps.LatLng(landmarkLabel)
      // const {infowindow, content} = infoWindowWithCreateLocation({
      //   latlng,
      //   history,
      //   onNearestAssets: () => {
      //     getClosestAssets({assets, latlng, map})
      //   },
      // })
      window.google.maps.event.addListener(newMarker, 'click', function () {
        var clickedBounds = new window.google.maps.LatLngBounds()
        const featureId = feature.properties.id
        map.data.forEach(function (clickedFeature) {
          if (featureId === clickedFeature.getProperty('id')) {
            // console.log(
            //   'calling processPoints',
            //   feature.getGeometry().getArray(),
            // )
            processPoints(
              clickedFeature.getGeometry(),
              clickedBounds.extend,
              clickedBounds,
            )
            map.fitBounds(clickedBounds)
            // infowindow.setContent(content)
            // infowindow.open(map, newMarker)
          }
        })
      })
    }
  })

  setState({
    zoneMarkers: [...markers, ...newMarkers],
  })
  //add zonesGeoJSON to the map data
  map.data.addGeoJson(zonesGeoJSON, {
    idPropertyName: 'id',
  })

  map.data.forEach(feature => {
    if (feature.getGeometry().getType() === 'Polygon') {
      processPoints(feature.getGeometry(), bounds.extend, bounds)
    }
  })

  map.data.setStyle(feature => {
    const type = feature.getGeometry().getType()
    const color =
      feature.getProperty('color') === 'green' ? '#006200' : '#F6A930'
    if (type === 'Polygon') {
      return {
        strokeColor: '#666666',
        strokeOpacity: 1,
        strokeWeight: 3,
        fillColor: color,
        fillOpacity: 0.6,
      }
    } else {
      return {
        icon: feature.getProperty('icon'),
      }
    }
  })
  if (bounds) map.fitBounds(bounds)
}

export const filterZones = ({allZones, searchValue, typeSelected}) => {
  let zones = allZones
  let types = []
  if (searchValue) {
    zones = matchSorter(allZones, searchValue, {
      keys: [
        'Landmark_Name',
        'Landmark_Style',
        'Landmark_Label',
        'Landmark_Address_Line1',
        'Landmark_Address_Line2',
        'Landmark_City',
        'Landmark_Region',
        'Landmark_Country',
      ],
      threshold: matchSorter.rankings.CONTAINS,
    })
  }

  //   if (showing === 'Zones') {
  //     zones = zones.filter(loc => loc.Landmark_Category === 1)
  //   }

  if (typeSelected !== 'All') {
    const typeFilteredLocations = []
    zones.forEach(l => {
      if (l.Landmark_Style === typeSelected) {
        typeFilteredLocations.push(l)
      }
    })
    zones = typeFilteredLocations
  }

  zones.forEach(location => {
    if (location.Landmark_Style && !types.includes(location.Landmark_Style)) {
      types.push(location.Landmark_Style)
    }
  })
  types = types.sort((a, b) => a.localeCompare(b))
  types.unshift('All')
  return {zones: zones, types}
}
