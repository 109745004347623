import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'
import {CustomerTemplateType} from '../notificationsHelpers'

const getCustomerTemplate = async (idRDEtaDefaultTemplate: number) => {
  const {data}: {data: CustomerTemplateType} = await Axios.get(
    `/rd/eta/templates/customer/${idRDEtaDefaultTemplate}`,
  )
  return data
}

export default function useCustomerTemplate({
  enabled,
  idRDEtaDefaultTemplate,
}: {
  enabled: boolean
  idRDEtaDefaultTemplate: number
}) {
  const user = useStore(state => state.user)
  return useQuery(
    ['customerEtaTemplate'],
    () => getCustomerTemplate(idRDEtaDefaultTemplate),
    {
      enabled: enabled && Boolean(user?.token),
    },
  )
}
