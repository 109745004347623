const generateGeoJSONCircle = (lat, lng, radius, numSides) => {
  let points = [],
    degreeStep = 360 / numSides

  for (let i = 0; i < numSides; i++) {
    let gpos = window.google.maps.geometry.spherical.computeOffset(
      new window.google.maps.LatLng({
        lat: lat,
        lng: lng,
      }),
      radius,
      degreeStep * i,
    )
    points.push([gpos.lng(), gpos.lat()])
  }

  // Duplicate the last point to close the geojson ring
  points.push(points[0])

  return points
}

function parsePolygon(Landmark_Location_Text) {
  var output = []
  var txt = Landmark_Location_Text.split('(')
  txt = txt[txt.length - 1].split(')')
  var text_arr_2 = txt[0].split(', ')
  var latlng
  for (
    var i = 0;
    i < text_arr_2.length;
    i++ //the last point returned by getlandmark is the duplicate of the first point
  ) {
    latlng = text_arr_2[i].split(' ')
    output[i] = [parseFloat(latlng[0]), parseFloat(latlng[1])]
  }
  return output
}
export const createLandmarkGeoJson = function (selectedLocations) {
  var polygonJson = selectedLocations.map(function (landmark) {
    var coordinates = null
    var labelcoords
    if (landmark.Landmark_Type.toLowerCase() === 'circle') {
      coordinates = generateGeoJSONCircle(
        landmark.Latitude,
        landmark.Longitude,
        landmark.Landmark_Radius,
        180,
      )

      labelcoords = new window.google.maps.LatLng({
        lat: landmark?.Latitude || 0,
        lng: landmark?.Longitude || 0,
      })
    } else if (landmark.Landmark_Type.toLowerCase() === 'polygon') {
      coordinates = parsePolygon(landmark.Landmark_Location_Text)
      var bounds = new window.google.maps.LatLngBounds()
      coordinates.forEach(function (points) {
        bounds.extend(new window.google.maps.LatLng(points[1], points[0]))
      })
      labelcoords = bounds.getCenter()
    }
    return {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [coordinates],
        labelcoords: labelcoords,
      },
      properties: {
        color: landmark.Landmark_Category === 2 ? 'green' : 'yellow',
        id: landmark.idLandmark,
        Name: landmark.Landmark_Name,
        AllowAdminToEdit: landmark.AllowAdminToEdit,
      },
    }
  })

  var geojson = {
    type: 'FeatureCollection',
    features: polygonJson,
  }
  return geojson
}
